import { lazy, useContext } from 'react';
import { View, ScrollView, FlatList, Dimensions } from 'react-native';
import { Text, Divider, Chip, Button, HelperText, ActivityIndicator, FAB, IconButton, } from 'react-native-paper';
import * as XLSX from "xlsx"
import * as React from 'react';
import { useTheme } from 'react-native-paper';
import * as Network from 'expo-network';


import { Badge } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";

import StudentMarks from "../components/StudentMarks"

// const StudentMarks = lazy(() => import("../components/StudentMarks"))
import DImage from "../components/DImage"

// const DImage = lazy(() => import("../components/DImage"))

import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import Global from "../constants/global";
import { collection, doc, getFirestore, onSnapshot, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
const grade = [
    { min: 90, max: 100, grade: "A+" },
    { min: 80, max: 89, grade: "A" },
    { min: 70, max: 79, grade: "B+" },
    { min: 60, max: 69, grade: "B" },
    { min: 50, max: 59, grade: "C+" },
    { min: 40, max: 49, grade: "C" },
    { min: 33, max: 39, grade: "D" },
    { min: 0, max: 32, grade: "E" }
]
import Preferance from '../constants/Preferances';
import { listsTask } from '../task/listTasks';
import { setUpdatesConfig } from '@expo/config-plugins/build/android/Updates';
let once = false
const cy = Global.getYear()
export default function GenerateMarks({ navigation, route }) {
    const classes = useSelector((state) => state.list.Classes)
    const [focus, setFocus] = React.useState({})
    const [seleClass, setClass] = React.useState(undefined)
    const [classStudent, setStudents] = React.useState({ list: [], counter: 0 })
    const [multi, setMulti] = React.useState(false)
    // const [merging, setMerging] = React.useState(false)
    const [fetching, setFetching] = React.useState(false)
    const [selectAll, setSelectAll] = React.useState(false)
    const [connected, setConnected] = React.useState(0)
    const [updated, setUpdated] = React.useState({ updated: false, navigated: false })
    // const [updatCount, setCount] = React.useState(0)
    // const [data, setData] = React.useState({ student: undefined, sheet: [], absent: [], left: [], pass: [], fail: [], border: [], pending: [], totalNeeded: 0, totalGained: 0, consolidated: [], grandTotal: 0 });
    const [subjects, setSubjects] = React.useState(undefined)
    const users = useSelector(React.useMemo(Global.getUserList, []))
    const allSubjects = useSelector(s => s.list.Subjects)
    // const [noExam, setNoExam] = React.useState([])
    const dispatch = useDispatch()
    async function checkNet() {
        const res = await Network.getNetworkStateAsync();
        if (res.isConnected && res.isInternetReachable) {
            setConnected(1)
        } else {
            setConnected(-1)
        }
        // {
        //   type: NetworkStateType.CELLULAR,
        //   isConnected: true,
        //   isInternetReachable: true,
        // }
    }
    React.useEffect(() => {
        checkNet()
        // console.log(route.params.data.forClass)
        // const eids: [] = []
        // route.params.data?.forClass?.map(e => {
        //     eids.push(e.id)
        // })
        // const q = query(collection(getFiresto),re(), Global.USERS where(`class${cy}.classId`, "in", eids));
        // // console.log(eids)

        // const s = onSnapshot(
        //     q,
        //     (docc) => {
        //         let tklist: [] = []
        //         let del = []
        //         docc.docChanges().forEach((change) => {
        //             const source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
        //             if (source == "Server") {
        //                 if (change.type === "removed") {
        //                     dispatch(listsTask.actions.remove({ type: Global.USERS, data: change.doc.data() }))
        //                 } else {
        //                     const d = change.doc.data()
        //                     tklist.push({ data: d })
        //                 }
        //             }
        //         });
        //         // console.log("UUUSr", tklist)
        //         dispatch(listsTask.actions.updateActivity({ type: Global.USERS, data: tklist, isArray: true }))
        setFetching(false)
        //     });
        // return () => {
        //     s()
        // }
    }, [])

    const [multiStudent, setMultiStudent] = React.useState([])
    const [changes, setChanges] = React.useState([])
    const [hideLeft, setHideLeft] = React.useState(false)
    const pref = useContext(Preferance)

    const { colors } = useTheme();

    React.useEffect(() => {
        if (route?.params?.data) {
            // console.log(route?.params?.data?.forClass)
            setFocus(route?.params?.data)
        }
        //        console.log("route.params?.allExams", route.params?.allExams)
    }, [route.params])

    React.useEffect(() => {
        if (!multi) {
            setMultiStudent([])
        }
    }, [multi])

    // function chk() {
    //     let uok = 0
    //     let uerr = 0
    //     users.filter(f => f?.classId !== undefined && f?.left !== true).map(u => {
    //         const c = u.marks[2022]["yZUMbExod1b7RkSOwnyS"]
    //         if (c) {
    //             Object.keys(c).forEach(function (key) {
    //                 if (Global.isSubject(c, key)) {
    //                     if (!c[key].subject_id) {
    //                         console.log(u.id, u.name, u.class, u.roll, key)
    //                         uerr++
    //                     } else {
    //                         uok++
    //                     }
    //                 }
    //             })
    //         }
    //     })
    //     console.log("uok=", uok, "uerr=", uerr)

    // }
    // function updateAndMerge() {
    //     const data = users
    //     const ndata: [] = []
    //     data.map(e => {
    //         ndata.push(JSON.parse(JSON.stringify(e)))
    //     })
    //     const exam = route?.params?.data
    //     let sr = 0
    //     exam.forClass.map(cl => {
    //         cl.subjects.map(sub => {
    //             ndata.map(u => {
    //                 if (u.classId == cl.id) {  // this ensures update in current year exams only
    //                     try {
    //                         // console.log(u.marks[exam.year][exam.id][sub.id])
    //                         const tm = u.marks[exam.year][exam.id][sub.id]
    //                         if (tm?.totalMarks == undefined || tm.totalMarks == 0) {
    //                             console.log(sr++, u.marks[exam.year][exam.id][sub.id].name, u.class, u.name)
    //                         }
    //                     } catch (e) {
    //                         console.log("errorDon")
    //                     }
    //                 }
    //             })
    //         })
    //     })

    // }
    function download() {
        const workbook = XLSX.utils.book_new();
        const e1id = route.params.allExams.e1.id
        const e2id = route.params.allExams.e2?.id
        //console.log(e1id)
        //console.log(e2id)
        seleClass?.subjects.map(sub => {
            const headers = ["", "", "", "", "", "", "", "", "", "", ""]
            const l2: [] = []
            const tag = "class" + seleClass.year
            const clnm = seleClass.name + (seleClass.div ? " વર્ગ " + seleClass.div : "") + " "
            l2.push({ roll: "શ્રી ઠક્કરબાપા વિદ્યામંદિર, અમદાવાદ" })
            //            l2.push({ roll: "ઠક્કરબાપાનગર, અમદાવાદ ૩૮૨૩૫૦ " })
            l2.push({ roll: "એસ.એસ.સી  માર્ચ " + (seleClass.year + 1) + " આંતરિક મૂલ્યાંકન ગુણ" })
            l2.push({ roll: clnm, name: "", gr: "", E1: "", em1: "વિષય - " + sub.name, E2: "", em2: "વર્ષ " + (seleClass.year) + "-" + (seleClass.year + 1 - 2000) })
            l2.push({ roll: "રોલ", name: "વિદ્યાર્થી નું નામ", gr: "GR", E1: "પ્રથમ પરીક્ષાના માર્કસ", em1: "૫ ગુણ", E2: "દ્વિતીય/પ્રિલિમિનરી ના માર્કસ", em2: "પ ગુણ", home: "ગૃહકાર્ય/વર્ગકાર્ય પ ગુણ", proj: "પ્રોજેક્ટ કાર્ય ૫ ગુણ", tot: "કુલ ગુણ ૨૦ ગુણ", rond: "પૂર્ણાંક ગુણ ૨૦" })
            let row = 5
            classStudent.list.map(stud => {
                const data = stud[tag]
                const isLeft = stud.left == true
                //                const me = { "roll": data?.roll ? data?.roll : stud?.roll, name: stud.name, gr: data?.gr ? data?.gr : stud?.gr, E1: 0, em1: 0, E2: 0, em2: 0, home: 0, proj: 0, tot: isLeft ? "Left" : "=round(e" + row + "+g" + row + "+h" + row + "+i" + row + ",2)", rond: isLeft ? "Left" : "=round(j" + row + ",0)" }              // console.log(data)
                const me = { "roll": data?.roll ? data?.roll : stud?.roll, name: stud.name, gr: data?.gr ? data?.gr : stud?.gr, E1: 0, em1: 0, E2: 0, em2: 0, home: 0, proj: 0, tot: isLeft ? "Left" : 0, rond: isLeft ? "Left" : 0 }              // console.log(data)
                try {
                    const m1 = stud["marks"][seleClass.year][e1id][sub.id]
                    me.E1 = isLeft ? "LEFT" : m1.theory == "Absent" ? "Ab" : m1?.theory
                    me.em1 = isLeft ? "LEFT" : m1.theory == "Absent" ? "Ab" : 0

                    if (typeof m1.theory == "number") {
                        if (m1.theory > 0) {
                            const tm1 = (m1?.theory * 5) / m1?.totalMarks
                            me.em1 = tm1.toFixed(2)
                            // me.em1 = { t: "n", v: 0, f: isLeft ? "LEFT" : m1.theory == "Absent" ? "Ab" : "=round(d" + row + "*5/" + m1?.totalMarks + ",2)", }
                        }
                    }
                } catch (e) { }
                try {
                    // const me = { "roll": data?.roll ? data?.roll : stud?.roll, name: stud.name, gr: data?.gr ? data?.gr : stud?.gr, E1: 0, em1: 0, E2: 0, em2: 0, home: 0, proj: 0, tot: isLeft ? "Left" : "=round(e" + row + "+g" + row + "+h" + row + "+i" + row + ",2)", rond: isLeft ? "Left" : "=round(j" + row + ",0)" }              // console.log(data)

                    const m2 = stud["marks"][seleClass.year][e2id][sub.id]
                    me.E2 = isLeft ? "LEFT" : m2?.theory == "Absent" ? "Ab" : m2?.theory
                    me.em2 = isLeft ? "LEFT" : m2?.theory == "Absent" ? "Ab" : 0

                    if (typeof m2.theory == "number") {
                        if (m2.theory > 0) {
                            const tm2 = (m2?.theory * 5) / m2?.totalMarks
                            me.em2 = Number(tm2.toFixed(2))

                            //            me.em2 = Math.round(tm2)
                            //              me.em2 = isLeft ? "LEFT" : m2.theory == "Absent" ? "Ab" : "=round(d" + row + "*5/" + m2?.totalMarks + ",2)"

                        }
                    }

                } catch (e) { }
                l2.push(me)
                row++
            })
            const worksheet = XLSX.utils.json_to_sheet(l2)
            XLSX.utils.book_append_sheet(workbook, worksheet, sub.name);
            XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
        })
        XLSX.writeFile(workbook, "Summary " + seleClass.name + (seleClass.div ? " " + seleClass.div : "") + " " + seleClass.year + "-" + (seleClass.year + 1 - 2000) + ".xlsx");



    }

    function getTotalMarks(cid: string, sid: string) {
        const sub = focus.forClass?.find(f => f.id == cid)
        // console.log("gaining")
        if (sub) {
            const bus = sub.subjects.find(s => s.id == sid)
            return bus.totalMarks
        }

        return -21
    }

    function refresh() {
        const data = users.filter(e => e["class" + focus?.year]?.classId == seleClass.id)

        try {
            const newUser: [] = []

            data.map(s => {
                if (s.marks) {
                    const exam = s.marks[focus.year][focus.id]

                    if (exam) {
                        let totalMax = 0
                        let totalGained = 0
                        let totalPassGained = 0
                        let pending: [] = []
                        let failed: [] = []
                        let absent: [] = []
                        let pass: [] = []
                        Object.keys(exam).forEach(function (key) {
                            if (Global.isSubject(exam, key)) {
                                totalMax = totalMax + (exam[key].totalMarks ? exam[key].totalMarks : getTotalMarks(seleClass.id, key))
                                const gv = Global.getValue(exam[key], "A")

                                // console.log(s.name, gv, exam[key])

                                const sMarks = (gv == "A" ? 0 : gv)
                                totalGained = totalGained + sMarks
                                if (exam[key].status == "Pass") {
                                    totalPassGained = totalPassGained + sMarks
                                    pass.push(exam[key].name)
                                } else if (exam[key].status == "Absent") {
                                    absent.push(exam[key].name)
                                } else if (exam[key].status == "Fail") {
                                    failed.push(exam[key].name)
                                } else if (exam[key].status == "Pending" && exam[key].totalMarks > 0) {
                                    pending.push(exam[key].name)
                                }
                            }
                        });
                        let status = ""
                        //console.log("FINALSTATUS", s.name, failed.length, pending.length, absent.length)
                        if (failed.length == 0 && pending.length == 0 && absent.length == 0) {
                            status = "Pass"
                        } else {
                            if (absent.length > 0) {
                                status = "Absent"
                            } else if (failed.length > 0) {
                                status = "Fail"
                            } else if (pending.length > 0) {
                                status = "Pending"
                            } else {
                                console.log("Strange", s)
                            }
                        }
                        let pc = 0
                        if (status == "Pass") {
                            pc = Math.round((totalGained * 100) / totalMax)
                        }
                        newUser.push({ id: s.id, name: s.name, pass: pass, failed: failed, absent: absent, pending: pending, max: totalMax, gained: totalGained, passGain: totalPassGained, status: status, left: s.left == true, pc: pc })
                    }
                } else {
                    //                    console.log(s)
                }
            })
            //            sorting for rank
            const data1 = newUser.sort((a, b) => a?.gained < b?.gained ? 0 : -1)
            let topMark = 10000
            let rank = 0
            data1.map(e => {
                e.rank = 0
                if (e.status == "Pass" && e.left !== true) {
                    if (topMark == e.gained) {
                        e.rank = rank
                        rank++
                    } else {
                        if (topMark > e.gained) {
                            topMark = e.gained
                            rank++
                            e.rank = rank
                        }
                    }
                }
            })
            const db = getFirestore()
            const yr = focus.year
            const ex = focus.id
            const chan = []
            data1.map(e => {
                try {
                    //                    console.log(e.rank, e.name, "gained =" + e.gained, "max=" + e.max, e.status)
                    const ref = doc(db, "Users", e.id)
                    const ori_isr = users.find(ou => ou.id == e.id)
                    let change = false
                    if (ori_isr.marks[yr][ex].rank !== e.rank) {
                        change = true
                        console.log(e.name, "oold rank =" + ori_isr.marks[yr][ex].rank, e.rank, e)
                    }
                    if (ori_isr.marks[yr][ex].pass?.length !== e.pass.length) {
                        console.log(e.name, "oold pas =" + ori_isr.marks[yr][ex].pass, e.pass, e)
                        change = true

                    }
                    if (ori_isr.marks[yr][ex].failed?.length !== e.failed.length) {
                        console.log(e.name, "oold failed =" + ori_isr.marks[yr][ex].failed, e.failed, e)

                    }
                    if (ori_isr.marks[yr][ex].pending?.length !== e.pending.length) {
                        console.log(e.name, "oold pending =" + ori_isr.marks[yr][ex].pending, e.pending, e)

                    }
                    if (ori_isr.marks[yr][ex].absent?.length !== e.absent.length) {
                        console.log(e.name, "oold absent =" + ori_isr.marks[yr][ex].absent, e.absent, e)
                        change = true

                    }
                    if (ori_isr.marks[yr][ex].status !== e.status) {
                        console.log(e.name, "oold status =" + ori_isr.marks[yr][ex].status, e.status, e)
                        change = true

                    }
                    if (ori_isr.marks[yr][ex].gained !== e.gained) {
                        console.log(e.name, "oold gained =" + ori_isr.marks[yr][ex].gained, e.gained, e)
                        change = true

                    }
                    if (ori_isr.marks[yr][ex].max !== e.max) {
                        console.log(e.name, "oold max =" + ori_isr.marks[yr][ex].max, e.max, e)
                        change = true
                    }
                    if (ori_isr.marks[yr][ex].pc !== e.pc) {
                        console.log(e.name, "oold pc =" + ori_isr.marks[yr][ex].pc, e.pc, e)
                        change = true
                    }
                    if (change) {
                        console.log(e.name, {
                            [`marks.${yr}.${ex}.rank`]: e.rank,
                            [`marks.${yr}.${ex}.pass`]: e.pass,
                            [`marks.${yr}.${ex}.failed`]: e.failed,
                            [`marks.${yr}.${ex}.pending`]: e.pending,
                            [`marks.${yr}.${ex}.absent`]: e.absent,
                            [`marks.${yr}.${ex}.status`]: e.status,
                            [`marks.${yr}.${ex}.subjectMarks`]: e.gained,
                            [`marks.${yr}.${ex}.totalMarks`]: e.max,
                            [`marks.${yr}.${ex}.pc`]: e.pc,
                        })
                        console.log("done change")
                        updateDoc(ref, {
                            [`marks.${yr}.${ex}.rank`]: e.rank,
                            [`marks.${yr}.${ex}.pass`]: e.pass,
                            [`marks.${yr}.${ex}.failed`]: e.failed,
                            [`marks.${yr}.${ex}.pending`]: e.pending,
                            [`marks.${yr}.${ex}.absent`]: e.absent,
                            [`marks.${yr}.${ex}.status`]: e.status,
                            [`marks.${yr}.${ex}.subjectMarks`]: e.gained,
                            [`marks.${yr}.${ex}.totalMarks`]: e.max,
                            [`marks.${yr}.${ex}.pc`]: e.pc,
                        }, { merge: true }).then(e => { console.log("Done") })

                    }
                } catch (e) { console.log(e) }
            })

        } catch (e) {
            console.log("err", e)
        }
    }

    /*FOR PASS FAIL AND PC*/
    function validate() {
        const data = users.filter(e => e["class" + focus?.year]?.classId == seleClass.id)
        const changes: [] = []

        try {
            data.map(s => {
                if (s.marks && s.left !== true && s.name.trim().toLowerCase() !== "left") {
                    const exam = s.marks[focus.year][focus.id]

                    if (exam) {
                        let totalMax = 0
                        Object.keys(exam).forEach(function (key) {
                            if (Global.isSubject(exam, key)) {
                                let tm = getTotalMarks(seleClass.id, key)
                                // console.log("totRecMa", tm)
                                totalMax = totalMax + (exam[key].totalMarks ? exam[key].totalMarks : tm)
                                const gv = Global.getValue(exam[key], "A")
                                console.log(s.name, exam[key].name, gv)
                                const sMarks = (gv == "A" ? 0 : gv)
                                // let tm = 0
                                if (exam[key].totalMarks !== tm) {
                                    changes.push({ student_name: s.name, student_id: s.id, year: exam.year, exam: exam.id, subject: key, subject_name: exam[key].name, change: "subject", entry: "totalMarks", value: tm, ori: exam[key].totalMarks })
                                }
                                let nstatus = ""
                                if (exam[key]?.theory == "Absent") {
                                    nstatus = "Absent"
                                } else if (sMarks > 0) {
                                    nstatus = Global.getPassFail(sMarks, tm)
                                } else {
                                    nstatus = "Pending"
                                }
                                if (nstatus !== exam[key].status) {
                                    changes.push({ student_name: s.name, student_id: s.id, year: exam.year, exam: exam.id, subject: key, subject_name: exam[key].name, change: "subject", entry: "status", value: nstatus, ori: exam[key].status, marks: sMarks })
                                }
                            }
                        });
                    }
                } else {
                    //                    console.log(s)
                }
            })

        } catch (e) {
            console.log("err", e)
        }
        setChanges(changes)
    }

    // function makeMarks() {
    //     const data = users.find(e => e.id == "YEG3kadb2Mr3iD4EnI0X")
    //     const up = ["yevVQZo9zl8SJ1GGmt2f"]
    //     const got = data.marks
    //     delete got.student
    //     const db = getFirestore()
    //     try {
    //         up.map(u => {
    //             const a = users.find(e => e.id == u)

    //             const me = JSON.parse(JSON.stringify(got)) // for student object

    //             const uu = { ...a } // updat
    //             delete uu.marks

    //             console.log(me)
    //             const mme = me[2022]["yZUMbExod1b7RkSOwnyS"]
    //             mme.student = uu
    //             mme.pc = 0
    //             mme.subjectMarks = 0
    //             mme.status = "Pending"
    //             Object.keys(mme).forEach(function (key) {
    //                 if (Global.isSubject(mme, key)) {
    //                     mme[key].theory = 0
    //                     mme[key].status = "Pending"
    //                     mme[key].student_id = u
    //                     mme[key].student_name = a.name
    //                     mme[key].roll = a.roll
    //                 }
    //             });

    //             const ref = doc(db, "Users", u)
    //             console.log(me)
    //             updateDoc(ref, { marks: me }, { merge: true }).then(e=> console.log("done".e))
    //         })
    //     } catch (e) { console.log("erro", e) }
    // }
    function getValue(marks, id, sid, isF, roll) {
        try {
            const t1 = marks[focus.year][id][sid].theory
            const i1 = marks[focus.year][id]?.[sid]?.internal ? marks[focus.year][id]?.[sid]?.internal : 0
            const p1 = marks[focus.year][id]?.[sid]?.practical ? marks[focus.year][id]?.[sid]?.practical : 0
            const t = Global.getInt(t1)
            const i = Global.getInt(i1)
            const p = Global.getInt(p1)
            const tt = t1 == "Absent" ? 0 : (t + i + p)
            const tm = marks[focus.year][id][sid].totalMarks
            if (roll == 1) {
                console.log(marks[focus.year][id][sid])
            }
            // if (isF) {
            //     console.log(marks[focus.year][id][sid], { isAbsent: t == "Absent", theory: tt, status: t == "Absent" ? 0 : tt, pc: t == "Absent" ? 0 : (tt * 100) / tm })
            // }
            return { otheory: t, practical: p, internal: i, isAbsent: t1 == "Absent", theory: tt, status: t1 == "Absent" ? 0 : tt, pc: t1 == "Absent" ? 0 : (tt * 100) / tm }
        } catch (e) {

            // if (isF) {
            //     console.log("hasAnErr", e)
            // }
            return { theory: 0, status: 0, pc: 0, otheory: 0, practical: 0, internal: 0 }
        }
    }

    React.useEffect(() => {
        if (updated.updated) {
            // console.log("updated 1", seleClass)
            const exam = route?.params?.data
            if (seleClass) {
                // console.log("refresher 1", focus?.year, seleClass.id)
                const data = users.filter(e => e["class" + focus?.year]?.classId == seleClass.id)
                users.filter(e => {
                    //                    console.log(e["class" + focus?.year])
                    if (e["class" + focus?.year]?.classId == seleClass.id) {

                    } else {

                    }
                }
                )
                const ndata: [] = []

                data.map(e => {
                    ndata.push(JSON.parse(JSON.stringify(e)))
                })

                let sdata = Global.sortStudent(ndata, focus.year)

                const sub: [] = seleClass?.subjects //seleClass?.subjects?.find(f => f.id == seleClass.id)?.subjects
                //                console.log("refresher 2", exam)
                // sdata.map(s => {
                //     console.log(s["class" + focus.year]?.roll)
                // })

                // console.log(go)
                if (seleClass?.onlyList == true) {
                } else {
                    let subjects: [] = []
                    sub?.map(f => {
                        if (f.totalMarks > 0 || f.grade) {
                            const ff = { ...f }
                            if (!ff.order) {
                                const g = allSubjects.find(a => a.id == ff.id)
                                if (g.order) {
                                    ff.order = g.order
                                }
                                ff.teacherName = g.teacherName
                            }
                            subjects.push(ff)
                        }
                    })
                    subjects = subjects.sort((a, b) => (a?.order ? a?.order : 20) - (b?.order ? b?.order : 20))
                    //                    console.log("refresher 3", subjects)


                    if (exam.final) {
                        sdata.map(student => {
                            let o
                            let display: [] = []
                            let gained = 0
                            let total = 0
                            let totalSiddhi = 15
                            let totalKripa = 10
                            let kripaGiven = 0
                            let siddhiGiven = 0

                            try {
                                o = student.marks[focus?.year][route.params.allExams?.marksheet?.id]
                                let finalStatus = "Pass"
                                //                                console.log(student.marks[focus?.year], o, route.params.allExams?.marksheet?.id)
                                Object.keys(o).forEach(function (key) {
                                    if (Global.isSubject(o, key)) {
                                        const sgot = allSubjects.find(oo => oo.id == key)
                                        let ord = 10
                                        let grd = false
                                        if (sgot) {
                                            ord = sgot.order
                                            grd = sgot?.finalGrad ? sgot.finalGrad : false
                                        } else {
                                            // console.log("spanky",key,subjects)
                                        }
                                        if (student["class2023"]?.roll == 1) {
                                            console.log(student.name,  sgot)
                                        }
                                        let mar1 = getValue(student?.marks, route.params.allExams?.e1?.id, key, false, student["class2023"]?.roll)
                                        let mar2 = getValue(student?.marks, route.params.allExams?.e2?.id, key, false, student["class2023"]?.roll)
                                        let internal = getValue(student?.marks, route.params.allExams?.internal?.id, key, false, student["class2023"]?.roll)
                                        let ffinal = getValue(student?.marks, route.params.allExams?.e3?.id, key, true, student["class2023"]?.roll)
                                        const mar = mar1?.status + mar2?.status + internal?.status + ffinal?.status
                                        const res = Math.round(mar / (grd ? 1 : 2))// 2 digit round
                                        const sd = {
                                            id: key,
                                            order: ord,
                                            name: o[key].name,
                                            e1: mar1?.status,
                                            e2: mar2?.status,
                                            e1A: mar1.isAbsent,
                                            e2A: mar2.isAbsent,
                                            intA: internal.isAbsent,
                                            finA: ffinal.isAbsent,
                                            grad: grd,
                                            internal: internal?.status,
                                            final: ffinal?.status,
                                            ftheory: ffinal?.otheory,
                                            fpractical: ffinal?.practical,
                                            selfMarks: res,
                                            subTotal: (mar1?.status ? mar1?.status : 0) + (mar2?.status ? mar2?.status : 0) + (internal?.status ? internal?.status : 0) + (ffinal?.status ? ffinal?.status : 0),
                                            totalMarks: 100
                                            // subStatus: ss,
                                            // siddhi: sgiven,
                                            // kripa: kgiven,
                                            // theory: o[key].theory
                                        }
                                        //                                    o[key].totalMarks
                                        display.push(sd)
                                    }
                                })
                                display = display.sort((a, b) => (a?.order ? a?.order : 11) - (b?.order ? b?.order : 11))
                                let tpc = 0
                                let cnt = 0
                                display.map(m => {
                                    if (m.grad) {
                                        //                                    console.log("has grad")
                                        const red = grade.find(f => m.theory >= f.min && m.theory <= f.max)
                                        m.grade = red?.grade
                                    } else {
                                        tpc = tpc + m.selfMarks
                                        cnt++
                                    }
                                })
                                o.pc = Math.round((tpc / cnt) * 100) / 100
                                totalSiddhi = o.pc > 33 ? (o.pc - 33) > 15 ? 15 : (o.pc - 33) : 0
                                totalSiddhi = Math.round(totalSiddhi)
                                const meSiddhi = totalSiddhi
                                try {
                                    display.map(dd => {
                                        let sgiven = 0
                                        let kgiven = 0
                                        dd.siddhi = 0
                                        dd.kripa = 0
                                        dd.left = student.left ? true : student.name.trim().toLowerCase() == "left" ? true : false
                                        const isFailed = dd.selfMarks < 33
                                        // console.log("dd", student.name, isFailed, dd)
                                        gained = gained + dd.selfMarks
                                        total = total + dd.totalMarks
                                        if (isFailed && !dd.grad) {
                                            let needed = 33 - dd.selfMarks
                                            if (needed <= (totalSiddhi + totalKripa)) {
                                                if (needed <= totalSiddhi) {
                                                    totalSiddhi = totalSiddhi - needed
                                                    sgiven = sgiven + needed
                                                    siddhiGiven = siddhiGiven + sgiven
                                                    needed = 0
                                                } else if (needed > totalSiddhi) {
                                                    needed = needed - totalSiddhi
                                                    sgiven = sgiven + totalSiddhi
                                                    siddhiGiven = siddhiGiven + sgiven
                                                    totalSiddhi = 0
                                                }
                                                if (needed > 0 && totalKripa >= needed) {
                                                    totalKripa = totalKripa - needed
                                                    kgiven = kgiven + needed
                                                    needed = 0
                                                    kripaGiven = kgiven + kripaGiven
                                                }
                                            }
                                        }
                                        dd.siddhi = sgiven
                                        dd.kripa = kgiven
                                        dd.primStatus = isFailed ? "Fail" : "Pass"
                                        dd.theory = dd.selfMarks + dd.siddhi + dd.kripa
                                        if ((dd.theory) < 33 && dd.totalMarks > 0) {
                                            dd.secStatus = "Fail"
                                        } else {
                                            if (dd.totalMarks > 0) {
                                                dd.secStatus = "Pass"
                                            } else {
                                                dd.secStatus = ""
                                            }
                                        }
                                        if (dd.secStatus == "Fail" && finalStatus == "Pass") {
                                            finalStatus = "Fail"
                                        }
                                    })
                                } catch (e) { console.log("errr", e) }
                                display.map(m => {
                                    if (m.grad) {
                                        const red = grade.find(f => m.theory >= f.min && m.theory <= f.max)
                                        m.grade = red?.grade
                                    }
                                })
                                o.selfMarks = tpc
                                o.subjectCount = cnt
                                o.status = finalStatus
                                o.remSiddhi = totalSiddhi
                                o.remKripa = totalKripa
                                o.siddhiGiven = siddhiGiven
                                o.kripaGiven = kripaGiven
                                display.push({
                                    id: "",
                                    name: "Total",
                                    e1: " ",
                                    e2: " ",
                                    internal: " ",
                                    final: " ",
                                    subStatus: " ",
                                    selfMarks: " ",
                                    siddhi: meSiddhi - totalSiddhi,
                                    kripa: 10 - totalKripa,
                                    theory: " "
                                })
                                console.log("display:", display)
                            } catch (e) {
                                // console.log("displayerr", e)
                            }
                            student.display = display
                        })

                        let topMark = 10000
                        let rank = 0
                        sdata = sdata?.sort((a, b) =>
                            b.marks[focus?.year][route.params.allExams?.marksheet?.id]?.selfMarks - a.marks[focus?.year][route.params.allExams?.marksheet?.id]?.selfMarks)

                        sdata?.map(student => {
                            try {
                                let o = student.marks[focus?.year][route.params.allExams?.marksheet?.id]
                                o.rank = 0
                                if (o.status == "Pass" && o.left !== true && o.kripaGiven == 0) {
                                    if (topMark == o.selfMarks) {
                                        o.rank = rank
                                        rank++
                                    } else {
                                        if (topMark > o.selfMarks) {
                                            topMark = o.selfMarks
                                            rank++
                                            o.rank = rank
                                        }
                                    }
                                }
                            } catch (e) { }

                        })
                        sdata = sdata?.sort((a, b) => a?.roll < b?.roll ? -1 : 0)
                        // console.log(sdata)
                        subjects?.map(subj => {
                            let pend = 0
                            sdata?.map(student => {

                                const pending = student.display.filter(disp => {
                                    return disp.id == subj.id &&
                                        (disp.final == 0 || disp.internal == 0)
                                        && disp.finA !== true
                                        && disp.intA !== true
                                        && student.left !== true
                                        && student.name.trim().toLowerCase() !== "left"
                                })
                                // if (pending) {
                                //     console.log("pending" + subj.name, student.name)
                                // }
                                pend = pend + (pending ? pending.length : 0)
                            })
                            subj.pending = pend
                        })
                        setSubjects(subjects)

                    } else {
                        const mergSubjects: [] = []

                        subjects?.map(sub => {
                            const ms = { ...sub }
                            let pending = 0
                            let absent = 0
                            let fail = 0
                            let pass = 0
                            data.map(u => {
                                try {
                                    let res = u.marks[exam.year][exam.id][sub.id]
                                    if (res && u.left !== true) {
                                        if (res.status) {
                                            if (res.status == "Fail") {
                                                fail++
                                            } else if (res.status == "Absent") {
                                                absent++
                                            } else if (res.status == "Pass") {
                                                pass++
                                            } else {
                                                pending++
                                            }
                                        }
                                    } else {
                                        if (res == undefined) {
                                            //                                    console.log(u.roll, u.name, sub.id, sub.name)
                                        }
                                    }

                                } catch { }
                            })
                            ms.pending = pending
                            ms.fail = fail
                            ms.pass = pass
                            ms.absent = absent
                            // console.log("ms", ms)
                            mergSubjects.push(ms)
                        })
                        //                        console.log("refresher 4", mergSubjects)
                        setSubjects(mergSubjects)
                    }
                }
                //                console.log("refresher 5", sdata)
                sdata = Global.sortStudent(sdata, focus.year)

                setStudents({ list: sdata, counter: classStudent.counter + 1 })

            }
        } else {
            if (users && !updated.updated) {
                try {
                    // setTimeout(() => {
                    //     setMerging(true)
                    // })
                    let updated = 1
                    const up: [] = []
                    const db = getFirestore()
                    //                    const batch = writeBatch(db)
                    users.map(m => {
                        const student = JSON.parse(JSON.stringify(m))
                        const exam = route.params.data
                        const got = exam.forClass.find(i => i.id == student["class" + Global.getYear()]?.classId)
                        if (got) {
                            let what = false
                            if (!student.marks?.["" + exam?.year]) {
                                student.marks = { ...student.marks, [exam?.year]: {} }
                            }

                            if (!student.marks[exam?.year]?.[exam?.id]) {
                                const nexam = JSON.parse(JSON.stringify(exam))
                                delete nexam.forClass
                                const nst = JSON.parse(JSON.stringify(student))
                                delete nst.marks
                                delete nst.class2023
                                delete nst.class2022
                                delete nst.earlier
                                const ngot = JSON.parse(JSON.stringify(got))
                                delete ngot.days
                                student.marks[exam?.year] = { ...student.marks[exam?.year], [exam?.id]: { exam: nexam, student: nst, classInfo: ngot } }
                            }
                            const studentStream = student["class" + exam.year].stream

                            got.subjects.map(sub => {
                                const stream = sub.stream

                                let shouldAdd = false
                                if (stream > 0) {
                                    shouldAdd = (studentStream == stream) || (studentStream == 0)
                                } else {
                                    shouldAdd = true
                                }
                                // console.log("checking3", sub.name, shouldAdd, stream, student.stream)

                                if (shouldAdd) {
                                    // console.log("checking4 adding", sub.name)
                                    if (!student.marks[exam.year]?.[exam?.id]?.[sub?.id]) {
                                        what = true
                                        student.marks[exam.year][exam.id] = {
                                            ...student.marks[exam.year][exam.id], [sub?.id]: {
                                                ...sub,
                                                subject_id: sub?.id,
                                                student_id: student.id,
                                                totalMarks: sub?.totalMarks,
                                                status: "Pending",
                                                theory: 0, practical: 0, internal: 0,
                                                left: (student.left == true ? true : false),
                                                student_name: student.name,
                                                roll: student["class" + exam.year].roll,
                                            }
                                        }
                                        // console.log("checking4 updated", student.marks)
                                    }
                                }
                            })
                            if (what) {
                                up.push(student)
                                try {
                                    // if (student.name == "મકવાણા સુજલકુમાર દિનેશભાઈ"){s
                                    // const ref = doc(db, "Users", student.id)
                                    // // if (!once) {
                                    // // console.log("Changed ", student.name, student.marks)

                                    // // if (!once) {
                                    // if (student["class" + 2023]["classId"] == "4PS60C0pD3wat9Ymb3Vw") {
                                    //     // once = true
                                    //     const val = student.marks[exam.year][exam.id]
                                    //     console.log(student, student.id, [`marks.${exam.year}.${exam.id}`], val)

                                    //     updateDoc(ref,
                                    //         { [`marks.${exam.year}.${exam.id}`]: val }, { merge: true }).then(() => {
                                    //             //                                      let u = updatCount - 1
                                    //             console.log("Done")
                                    //             //                                        setCount(u)
                                    //         }).catch(e => {
                                    //             console.log("err", e)
                                    //         })
                                    // }
                                    // }
                                    //                                    }
                                } catch (e) { console.log("fbupdate err", e) }
                            }
                        }


                    })
                    // batch.commit()
                    setTimeout(() => {
                        // setMerging(false)
                        setUpdated({ updated: true })
                        // setCount(up.length)
                    })
                    // setUpsert(up)
                    console.log("up", up)
                    // setTimeout(() => {

                    // }, up.length > 10 ? 10000 : 10)
                } catch (e) {
                    console.log("update err", e)
                }
            }
        }
        setMultiStudent([])
    }, [seleClass, users])

    return (
        <View style={{ flex: 1, paddingLeft: 10 }}>
            <ScrollView>
                <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                    <View style={{ backgroundColor: "white", alignSelf: "stretch" }}>
                        <DImage uri="https://img.freepik.com/premium-vector/cute-little-boy-feel-happy-because-get-good-grade-from-exam_97632-4655.jpg" />
                    </View>
                    {connected == 1 ? <View style={{ paddingLeft: 20 }}>
                        <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                            <Text style={{ alignSelf: "stretch", textAlign: "center" }} variant="headlineSmall">{route.params.migrate ? "Migrate" : (focus?.name ? focus?.name : "")} </Text>
                            <ActivityIndicator animating={fetching} />

                            {/* {updatCount == 0 && merging && <Text>{" Refreshing.... "}</Text>}
                    {updatCount > 0 && <Text>{" updating record " + updatCount}</Text>} */}
                        </View>
                        {/* <Button onPress={() => 
                    classStudent.list.map(f=>{
                        try{
                            // ["1tGlWCO0zuZlVQOGII5A"]["class2022"]
                            if (f["marks"]["2023"]["1sc6NUMYZMoOADR4UgIc"],f["marks"]["2023"]["1sc6NUMYZMoOADR4UgIc"]["student"]["class2022"]){
                                console.log("culprit")
                            }
                        }catch(e){}
                    })
                    }>Check</Button> */}
                        <Text style={{ marginTop: 20 }}>Click on {route.params.migrate ? "Old class" : "class"}</Text>
                        <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                            {focus?.forClass?.map((t, i) => {
                                return <Chip key={i} style={{ margin: 3 }} disabled={fetching || !connected} selected={t.id == seleClass?.id} onPress={() => {
                                    setClass({ ...t })
                                }} >{t.name + (t?.div ? " " + t.div : "")}</Chip>
                            })}
                            {route.params.migrate && <View style={{ flexDirection: "row" }}>
                                {classes.map((c, i) => {
                                    const cclass = parseInt(c.name.replace(/\D/g, ""))
                                    if ((cclass == 10 || cclass == 12) && (c.year == (cy - 1))) {
                                        return <Chip key={i} style={{ margin: 3 }} selected={c.id == seleClass?.id} onPress={() => {
                                            setClass({ ...c, onlyList: true })
                                        }} >{c.name + (c.div ? " " + c.div : "")}</Chip>
                                    }
                                })}
                            </View>}
                            {/* {route.params.migrate && <Text style={{ marginLeft: 7, marginRight: 7, paddingTop: 7, paddingBottom: 7, textDecorationLine: "underline" }}
                            onPress={() => {
                                navigation.navigate("NewStudent",
                                    { data: { bday: dayjs().unix(), classId: "", class: "" } })

                            }}
                        >Create New Student</Text>} */}
                            {route.params.migrate && <Text style={{ paddingTop: 7, paddingBottom: 7, textDecorationLine: "underline" }}
                                onPress={() => {
                                    navigation.navigate("StudentList")

                                }}
                            >All Student List</Text>}

                        </View>
                        <Divider />

                        {pref?.current?.master == true && route?.params?.migrate !== true && <Button onPress={() => {
                            validate()
                        }}>Validate</Button>}

                        {seleClass && <View>
                            {focus?.locked == true ?
                                <View style={{ marginLeft: 20, flexDirection: "row" }}>
                                    {route.params?.migrate !== true && <AntDesign name="Safety" size={24} color="green" />}
                                    {route.params?.migrate !== true && <Text variant="titleLarge">Result is Locked </Text>}
                                </View>
                                :
                                <Text variant="titleLarge">{focus?.final ? "" : "To insert Subjectwise marks click on Subject below "}</Text>
                            }
                            {route.params.migrate !== true && <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                                {subjects?.map((t, i) => {
                                    return <View style={{ justifyContent: "center", alignItems: "center" }}>

                                        <Chip disabled={focus?.locked == true} style={{ borderStyle: "solid", borderWidth: 1, margin: 6 }} onPress={() => {
                                            if (focus?.locked !== true && !focus?.final) {
                                                // if (focus?.version == 1) {
                                                //     navigation.navigate("OnlySubjectMarks", { forClass: focus, subject: t })
                                                // } else {
                                                navigation.navigate("SubjectwiseMarks", {
                                                    exam: focus, subject: t, allExams: route.params?.allExams, class: seleClass, fresh:
                                                        () => { }
                                                })
                                                // console.log("subject", t)
                                            }
                                            // }
                                        }} >{t.name}</Chip>
                                        <Badge size={25} style={{ backgroundColor: t.pending == 0 ? "green" : "orange", color: t.pending == 0 ? "white" : "black", position: "absolute", top: 0, right: 0 }}>{t.pending ? t.pending : ""}</Badge>
                                    </View>
                                })}
                                <IconButton icon="download" onPress={() => { download() }} />

                            </View>}
                            {focus?.final && route.params?.migrate !== true && <View style={{ flexDirection: "row" }}>
                                <Chip selected={hideLeft == true} onPress={() => {
                                    setHideLeft(!hideLeft)
                                }}>Hide Left</Chip>
                                {pref?.current?.supervisor == true && <Chip onPress={() => {
                                    const ncl: [] = []
                                    classStudent.list.map(m => {
                                        const ram = m.marks[focus.year][focus.id]
                                        m.display.map(rd => {
                                            ram[rd.id] = rd
                                        })
                                        if (!m.left && m.name.trim()?.toLowerCase() !== "left") {
                                            ncl.push(m)
                                        }
                                    })
                                    navigation.navigate("Marksheet", { list: ncl, exam: focus, final: true })
                                }}>Print Marksheets (except left)</Chip>}
                                <Chip onPress={() => {
                                    navigation.navigate("FinalSummarySheet", { student: classStudent.list, seleClass: seleClass, examyear: focus?.year, eid: focus.id, subjects: subjects })
                                }}>Print Summary</Chip>
                                <Chip onPress={() => {
                                    const ncl: [] = []
                                    classStudent.list.map(m => {
                                        const ram = m.marks[focus.year][focus.id]
                                        m.display.map(rd => {
                                            ram[rd.id] = rd
                                        })
                                        if (!m.left && m.name.trim()?.toLowerCase() !== "left") {
                                            ncl.push(m)
                                        }
                                    })
                                    navigation.navigate("FinalTarij", { student: classStudent.list, seleClass: seleClass, examyear: focus?.year, eid: focus.id, subjects: subjects })
                                }}>Print તારીજ</Chip>
                            </View>}
                            {focus?.isInternal && route?.params?.migrate !== true && <View style={{ flexDirection: "row" }}>
                                <Chip selected={hideLeft == true} onPress={() => {
                                    setHideLeft(!hideLeft)
                                }}>Hide Left</Chip>

                                <Chip onPress={() => navigation.navigate("InternalSummary", { class: seleClass, student: classStudent.list, subjects: subjects, exam: focus, tarij: 2 })}>Summary Sheet</Chip>
                                <Chip onPress={() => {
                                    let inverse = false
                                    classStudent.list.map(s => {
                                        const ex = s?.marks?.[focus.year]?.[focus.id]
                                        subjects.map(sub => {
                                            if (ex[sub.id]) {
                                                const ii = ex[sub.id].internal
                                                let e1 = undefined
                                                let e2 = undefined
                                                let e3 = undefined
                                                let e4 = undefined
                                                if (ii == 15) {
                                                    e1 = 4
                                                    e2 = inverse ? 3 : 4
                                                    e3 = inverse ? 4 : 3
                                                    e4 = 4

                                                } else if (ii == 16) {
                                                    e1 = 4
                                                    e2 = inverse ? 5 : 3
                                                    e3 = inverse ? 3 : 5
                                                    e4 = 4

                                                } else if (ii == 17) {
                                                    e1 = 4
                                                    e2 = inverse ? 5 : 4
                                                    e3 = inverse ? 4 : 5
                                                    e4 = 4

                                                } else if (ii == 18) {
                                                    e1 = 5
                                                    e2 = inverse ? 5 : 4
                                                    e3 = inverse ? 4 : 5
                                                    e4 = 4

                                                } else if (ii == 19) {
                                                    e1 = 5
                                                    e2 = 5
                                                    e3 = 4
                                                    e4 = 5

                                                } else if (ii == 20) {
                                                    e1 = 5
                                                    e2 = 5
                                                    e3 = 5
                                                    e4 = 5
                                                }
                                                inverse = !inverse
                                                ex[sub.id].i1 = e1
                                                ex[sub.id].i2 = e2
                                                ex[sub.id].i3 = e3
                                                //let ee4 = (ii - ((Number.isNaN(e1) ? 0 : e1) + (Number.isNaN(e2) ? 0 : e2) + (Number.isNaN(e3) ? 0 : e3)))
                                                ex[sub.id].i4 = e4
                                                //                                            console.log(s.name, sub.name, ii, ex.i1, ex.i2, ex.i3, ex.i4)
                                            }
                                        })
                                    })
                                    //     console.log("res", classStudent.list)
                                    classStudent?.list.map(f => console.log(f.name, f["class" + focus.year]?.roll))
                                    navigation.navigate("InternalSummary1", { class: seleClass, student: classStudent.list, subjects: subjects, exam: focus, tarij: 2 })
                                }

                                }>Segrigated</Chip>
                            </View>}

                            {focus?.locked !== true && !focus?.final &&
                                <Text>Note : After any change in maks, Rank and refresh is required to click, which will refresh totals will give proper rank to students.</Text>
                            }

                            {focus?.locked !== true && !focus?.final && <View style={{ flexDirection: "row" }}>
                                <Badge style={{ backgroundColor: "orange" }}></Badge>
                                <Text> indicates result pending to insert</Text>
                            </View>}

                        </View>
                        }
                        <Divider />
                        {seleClass && !focus?.isInternal && !focus?.final && <Text variant="titleLarge" style={{ marginTop: 10, }}>Result Options</Text>}
                        {seleClass && !focus?.isInternal && !focus?.final && <ScrollView style={{ maxHeight: 40 }} horizontal contentContainerStyle={{ maxHeight: 40 }}>
                            <Chip selected={hideLeft == true} onPress={() => {
                                setHideLeft(!hideLeft)
                            }}>Hide Left</Chip>

                            <Chip style={{ margin: 5, height: 30 }} onPress={() => refresh()}>Rank & Refresh Status</Chip>
                            <Chip style={{ margin: 5, height: 30 }} onPress={() => {
                                try {
                                    const data1 = classStudent.list?.sort((a, b) => {
                                        const ar = a?.marks[focus.year][focus.id].rank
                                        const br = b?.marks[focus.year][focus.id].rank
                                        if (ar && br) {
                                            return (ar || 0) - (br || 0);
                                        } else if (ar || br) {
                                            return !(ar) - !(br)
                                        } else {
                                            return (ar || 0) - (br || 0);
                                        }
                                    })
                                    //
                                    setStudents({ list: [...data1], counter: classStudent.counter + 1 })
                                    // setStudents()
                                } catch (e) { console.log(e) }
                            }}>Sort RankWise</Chip>
                            <Chip style={{ margin: 5, height: 30 }} onPress={() => {
                                try {
                                    const data1 = classStudent.list?.sort((a, b) => a?.["class" + focus.year]?.roll < b?.["class" + focus.year]?.roll ? -1 : 0)
                                    setStudents({ list: [...data1], counter: classStudent.counter + 1 })

                                    // setStudents([...data1])
                                } catch (e) { }

                            }}>Sort RollWise</Chip>
                            {pref?.current?.supervisor == true && <Chip selected={multi} style={{ margin: 5, height: 30 }} onPress={() => setMulti(!multi)}>Bulk Print</Chip>}
                            <Chip style={{ margin: 5, height: 30 }} onPress={() => navigation.navigate(focus.version == 1 ? "SummarySheet" : "NewSummarySheet", { class: seleClass, student: classStudent.list, subjects: subjects, exam: focus, tarij: 1 })}>Summary Sheet</Chip>
                            <Chip style={{ margin: 5, height: 30 }} onPress={() => navigation.navigate(focus.version == 1 ? "SummarySheet" : "NewSummarySheet", { class: seleClass, student: classStudent.list, subjects: subjects, exam: focus, tarij: 0 })}>તારીજ</Chip>
                            <Chip style={{ margin: 5, height: 30 }} onPress={() => navigation.navigate(focus.version == 1 ? "SummarySheet" : "NewSummarySheet", { class: seleClass, student: classStudent.list, subjects: subjects, exam: focus, tarij: 2 })}>Subject Sheet</Chip>
                            {focus?.exam == 3 && <Chip style={{ margin: 5, height: 30 }} onPress={() =>
                                navigation.navigate("PresentDays", { class: seleClass, student: classStudent.list, exam: focus })
                            }>હાજર દિવસ </Chip>}
                        </ScrollView>}
                        <View style={{ flexDirection: "row" }}>
                            {multi && pref?.current?.supervisor == true && <Chip onPress={() => {
                                if (multi) {
                                    const multiStudent: [] = []
                                    classStudent.list?.map(item => {
                                        if (item.left !== true) {
                                            //                                    const mdata = { class: seleClass, subjects: item.consolidated, exam: focus, student: item, gained: item.totalGained, total: item.grandTotal, rank: item.rank, pc: Math.round((item.totalGained / item.totalNeeded) * 100) }
                                            multiStudent.push(item)
                                        }
                                    })
                                    navigation.navigate("Marksheet", { list: multiStudent, exam: focus })
                                }
                            }}>
                                Print All (Except Left)
                            </Chip>}

                            {multi && pref?.current?.supervisor == true && <Chip onPress={() => {
                                if (multi) {
                                    // console.log(multiStudent)
                                    navigation.navigate("Marksheet", { list: multiStudent, exam: focus })

                                }
                            }}>
                                Print Selected
                            </Chip>}
                        </View>

                        <Divider />
                        {seleClass && <Text style={{ marginTop: 10 }} variant="titleLarge">Student List</Text>}
                        <View>
                            {
                                changes.map((c, s) => {
                                    return <View style={{ flexDirection: "row" }}>
                                        <Text>{s + 1} {c.student_name} , subject={c.subject_name}, change={c.change},field={c.entry}, value={c.value} ,ori={c.ori}- {c.marks}</Text>
                                        <Button onPress={() => {
                                            const yr = focus.year
                                            const ex = focus.id
                                            const sub = c.subject
                                            const field = c.entry
                                            const value = c.value
                                            const ref = doc(getFirestore(), "Users", c.student_id)
                                            //console.log(`marks.${yr}.${ex}.${sub}.${field}`, value)
                                            updateDoc(ref, {
                                                [`marks.${yr}.${ex}.${sub}.${field}`]: value
                                            }, { merge: true }).then(e => {
                                                console.log("Done")
                                            })

                                        }}>Fix</Button>
                                    </View>
                                })
                            }
                        </View>
                        <FlatList
                            data={hideLeft ? classStudent.list.filter(ff => ff?.left !== true && ff.name?.toLowerCase()?.trim() !== "left") : classStudent.list}
                            key="id"
                            contentContainerStyle={{ backgroundColor: "transparent" }}
                            keyboardShouldPersistTaps={'handled'}
                            extraData={multi + "" + classStudent.counter}
                            renderItem={({ item, index }) => {
                                return <StudentMarks
                                    onlyList={seleClass?.onlyList}
                                    classes={classes}
                                    migrate={route.params?.migrate}
                                    allExams={route.params.allExams}
                                    navigation={navigation} examid={focus.id}
                                    examyear={focus.year}
                                    student={item}
                                    multi={multi}
                                    selectAll={selectAll}
                                    exam={focus}
                                    setMultiSelect={(isMulti) => {
                                        let newMulti: [] = []
                                        const gotit = multiStudent.findIndex(e => (e.id == item.id))
                                        // console.log("multi" + gotit, item)
                                        if (gotit == -1 && isMulti) {
                                            multiStudent.push(item)
                                            newMulti = [...multiStudent]
                                            // console.log("pushing" + gotit, item)
                                        } else {
                                            newMulti = multiStudent.filter(e => (e.id !== item.id))
                                            // console.log("replacing" + gotit, item)
                                        }
                                        setMultiStudent(newMulti)
                                    }} />
                            }}
                        />
                    </View> : <Text variant="titleMedium" style={{ flex: 1, textAlign: "center", color: "red" }}>{connected == 0 ? "Checking Connection" : "Internet Connection issue !"}</Text>}
                </View >
            </ScrollView >


        </View >
    );


}

/*
        // const data = users.filter(e => e.classId == seleClass.id)
        // const a: [] = []
        // baselist = data.map(e => {
        //     a.push({ ...e })
        // })
        // const nnewP = a // editable userlist
        // const ali: [] = []
        // nnewP.map((std, i) => {
        //     const studentSheet = { ...std } // student record
        //     let sheet: [] = []
        //     const cl = focus?.forClass?.find(f => f.id == std.classId)  // search from class details from exam obj
        //     if (std?.stream) {
        //         // sheet = cl?.subjects?.filter(stc => (std.stream == stc.stream) || stc.stream == undefined || stc.stream == 0) 
        //         sheet = cl?.subjects?.filter(stc => ((std.stream == stc.stream) || stc.stream == undefined || stc.stream == 0) && stc.totalMarks > 0)
        //     } else {
        //         sheet = cl?.subjects?.filter(stc => stc.totalMarks > 0)
        //     }
        //     // And above, get all subjects relevant to stream of student
        //     // get already entered marks
        //     // const studentMarks = marks.filter(m => m.student_id == std.id && m.class_id == std.classId && m.examid == focus.id)
        //     const exam = std.marks[focus.year][focus.id]

        //     const allExams: [] = []
        //     Object.keys(exam).forEach(function (key) {
        //         if (Global.isSubject(exam, key))
        //             if (exam.year == focus.year) {
        //                 allExams.push(exam[key])
        //             }
        //     });


        //     const absent: [] = []
        //     const left: [] = []
        //     const pass: [] = []
        //     const fail: [] = []
        //     const not_prepared: [] = []
        //     let totalGained = 0
        //     let totalNeeded = 0
        //     let grandTotal = 0
        //     const consolidated: [] = []
        //     let subjectResult = ""
        //     let isLeft = std.left == true


        //     sheet.map(s => {        // scanning all subjects
        //         const newSheet = { ...s }
        //         let got
        //         if (isFinal) {
        //             let subTotal = 0
        //             let internal = 0
        //             let needed = 0
        //             let status = ""
        //             allExams.map(e => {
        //                 e.marks?.map(mark => {
        //                     if (mark.subject_id == s.id) {
        //                         const tstatus = mark.theory == "Absent" ? "Absent" : mark.theory < mark.totalNeeded ? "Fail" : "Pass"
        //                         if (tstatus == "Absent" && !status) {
        //                             status = "Absent"
        //                         } else if (tstatus == "Fail" && !status) {
        //                             status = "Fail"
        //                         }
        //                         if (mark.theory !== "Absent") {
        //                             subTotal = subTotal + mark.theory
        //                             internal = internal + (mark.internal ? mark.internal : 0)
        //                         }
        //                         needed = needed + mark.totalMarks
        //                         newSheet["e" + e.exam] = {
        //                             theory: mark.theory,
        //                             internal: mark.internal,
        //                             totalMarks: mark.totalNeeded,
        //                             status: tstatus
        //                         }
        //                     }
        //                 })
        //             })
        //             got = { theory: subTotal, internal: internal, totalMarks: needed, totalGained: subTotal, subject_id: s.id, subject_status: status ? status : "Pass" }
        //             if (subTotal > 0) {
        //                 console.log("goot", got)
        //             }
        //         } else {
        //             got = exam[s.id] // getting marks of that subject
        //         }

        //         if (got) {
        //             newSheet.theory = got.theory
        //             // let total = got.theory
        //             const coreSubject = allSubjects.find(a => a.id == s.id)
        //             if (coreSubject) {
        //                 newSheet.teacher = coreSubject.teacher
        //                 newSheet.teacherName = coreSubject.teacherName
        //                 newSheet.name = coreSubject.name
        //                 newSheet.order = coreSubject?.order ? coreSubject.order : 0
        //             }
        //             let total = 0
        //             if (got.theory !== "Absent") {
        //                 total = got.theory
        //             } else {
        //                 absent.push(newSheet)
        //                 subjectResult = "Absent"
        //             }
        //             if (got.practical) {
        //                 newSheet.practical = got.practical
        //                 total = total + +got.practical
        //             }
        //             if (got.internal) {
        //                 newSheet.internal = got.internal
        //                 total = total + got.internal
        //             }
        //             try {
        //                 const rr = parseInt(total)
        //                 if (rr) {
        //                     totalGained = totalGained + rr
        //                 }
        //             } catch (e) { }

        //             totalNeeded = totalNeeded + newSheet.totalMarks
        //             if (isLeft) {
        //                 subjectResult = "Left"
        //                 left.push(newSheet)
        //             } else if (got.staus !== "Absent") {
        //                 newSheet.total = total
        //                 const needed = Math.round(newSheet.totalMarks / 3)
        //                 if (got.status == "Pass") {
        //                     subjectResult = "Pass"
        //                     pass.push(newSheet)
        //                     newSheet.required = needed
        //                 } else {
        //                     if (got.status == "Fail") {
        //                         subjectResult = "Fail"
        //                         fail.push(newSheet)
        //                     }
        //                 }
        //             }
        //         } else {
        //             subjectResult = "Pending"
        //             not_prepared.push(s)
        //         }
        //         newSheet.subject_status = subjectResult
        //         newSheet.isLeft = isLeft
        //         grandTotal = grandTotal + s.totalMarks
        //         consolidated.push(newSheet)
        //     })

        //     studentSheet.sheet = sheet
        //     studentSheet.absent = absent
        //     studentSheet.pass = pass

        //     studentSheet.fail = fail
        //     studentSheet.pending = not_prepared
        //     studentSheet.totalGained = totalGained
        //     studentSheet.totalNeeded = totalNeeded
        //     if (studentSheet.left == true) {
        //         console.log("left " + studentSheet.roll, studentSheet.name)

        //     }
        //     studentSheet.consolidated = consolidated
        //     studentSheet.grandTotal = grandTotal
        //     if (studentSheet.left == true) {
        //         studentSheet.status = "Left"
        //     } else {
        //         if (absent.length > 0) {
        //             studentSheet.status = "Absent"
        //         } else {
        //             if (not_prepared.length > 0) {
        //                 studentSheet.status = "Pending"
        //             } else {
        //                 if (fail.length > 0) {
        //                     studentSheet.status = "Fail"
        //                 } else {
        //                     studentSheet.status = "Pass"
        //                 }
        //             }
        //         }
        //     }

        //     ali.push(studentSheet)
        // })

        // // sorting for rank
        // const ali1 = ali.sort((a, b) => a?.totalGained < b?.totalGained ? 0 : -1)
        // let topMark = 10000
        // let rank = 0
        // ali1.map(e => {
        //     if (e.marks[focus.year][focus.id].status == "Pass" && e.left !== true) {
        //         const gained = e.marks[focus.year][focus.id].totalGained
        //         if (topMark == gained) {
        //             e.rank = rank
        //             rank++
        //         } else {
        //             if (topMark > gained) {
        //                 topMark = gained
        //                 rank++
        //                 e.rank = rank
        //             }
        //         }
        //     }
        // })



        // // putting order // and teacher name
        // // for onece its ok but for older marksheet you will have new teacher
        // //  for subj seq. and teacher name, this is done.
        // const ali2 = ali1.sort((a, b) => a?.roll < b?.roll ? -1 : 0)

        // setStudents(ali2)
        // const subs = seleClass?.subjects?.filter(f => f?.totalMarks > 0)
        // const newSubj: [] = []
        // subs.map((e, i) => {
        //     let pend = 0
        //     ali2.map(al => {
        //         if (al.left !== true) {
        //             try {
        //                 const st = al.marks[focus.year][focus.id][e.id].status
        //                 pend = pend + ((st == "Pass" || st == "Fail" || st == "Absent") ? 0 : 1)

        //             } catch { }
        //             // pend = pend + (al.pending.find(s => s.id == e.id) ? 1 : 0)
        //         }
        //     })
        //     const nsub = { ...e }
        //     nsub.resultPending = pend
        //     const forTeacher = allSubjects.find(a => a.id == e.id)
        //     if (forTeacher) {
        //         nsub.name = forTeacher.name
        //         nsub.teacher = forTeacher.teacher
        //         nsub.teacherName = forTeacher.teacherName
        //         nsub.order = forTeacher?.order ? forTeacher.order : 0
        //     }
        //     newSubj.push(nsub)
        // })
        // setSubjects(newSubj)
        // // console.log("newSubj", ali2)
        // else {
        //     if (seleClass && allSubjects && route?.params?.data?.final == true) {
        //         const data = users.filter(e => e.classId == seleClass.id)
        //         const a: [] = []
        //         baselist = data.map(e => {
        //             a.push({ ...e })
        //         })
        //         const nnewP = a // editable userlist
        //         const ali: [] = []
        //         nnewP.map((std, i) => {
        //             const studentSheet = { ...std } // student record
        //             let sheet: [] = []
        //             const cl = focus?.forClass?.find(f => f.id == std.classId)  // search from class details from exam obj
        //             if (std?.stream) {
        //                 // sheet = cl?.subjects?.filter(stc => (std.stream == stc.stream) || stc.stream == undefined || stc.stream == 0) 
        //                 sheet = cl?.subjects?.filter(stc => ((std.stream == stc.stream) || stc.stream == undefined || stc.stream == 0) && stc.totalMarks > 0)
        //             } else {
        //                 sheet = cl?.subjects?.filter(stc => stc.totalMarks > 0)
        //             }
        //             // And above, get all subjects relevant to stream of student
        //             // get already entered marks
        //             // const studentMarks = marks.filter(m => m.student_id == std.id && m.class_id == std.classId && m.examid == focus.id)
        //             const studentExam = std.exam ? std.exam : []
        //             const allExams = studentExam?.filter(exam => (exam.year == route?.params?.data?.year && exam.final !== true))
        //             let ultraMaster = ""
        //             let ultraGained = 0
        //             let ultraRequired = 0

        //             sheet.map(subject => {
        //                 /// marks in student record
        //                 const allGot: [] = []
        //                 let theory = 0
        //                 allExams.map(m => {
        //                     const got = m?.marks?.find(mm => mm.subject_id == subject.id)
        //                     const sob = subject["exam" + m.exam]
        //                     let totalGained = 0
        //                     let totalRequired = 0
        //                     if (got) {
        //                         if (got.theory == "Absent") {
        //                             master_status = "Absent"
        //                             ultraMaster = "Absent"
        //                             sob.subject_status = "Absent"
        //                             sob.theory = "Absent"
        //                         } else {
        //                             const total = (got.theory ? got.theory : 0)
        //                             const needed = Math.round(got.totalMarks / 3)
        //                             sob.totalRequired = totalRequired + needed
        //                             sob.totalGained = totalGained + total
        //                             ultraGained = ultraGained + total
        //                             ultraRequired = ultraRequired + needed
        //                             theory = theory + total
        //                             internal = (got.internal ? got.internal : 0)
        //                             if (total >= needed) {
        //                                 sob.subject_status = "Pass"
        //                             } else {
        //                                 sob.subject_status = "Fail"
        //                                 master_status = "Fail"
        //                                 ultraMaster = "Fail"
        //                             }
        //                         }
        //                         //                                allGot.push({ examid: m.id, examname: m.name, marks: got.theory, required: total, status: status, master_status: master_status, totalGained: totalGained, totalRequired: totalRequired })
        //                     } else {
        //                         sob.subject_status = "Pending"
        //                     }
        //                 })

        //             })

        //             let isLeft = std.name.toLowerCase().trim() == "left" || std.left == true
        //             ultraMaster = isLeft ? "Left" : ultraMaster

        //             studentSheet.result = sheet
        //             studentSheet.status = ultraMaster
        //             studentSheet.totalGained = ultraGained
        //             ali.push(studentSheet)
        //         })

        //         // sorting for rank
        //         const ali1 = ali.sort((a, b) => a?.totalGained < b?.totalGained ? 0 : -1)
        //         let topMark = 10000
        //         let rank = 0
        //         ali1.map(e => {
        //             if (e.status == "Pass") {
        //                 if (topMark == e.totalGained) {
        //                     e.rank = rank
        //                     rank++
        //                 } else {
        //                     if (topMark > e.totalGained) {
        //                         topMark = e.totalGained
        //                         rank++
        //                         e.rank = rank
        //                     }
        //                 }
        //             }
        //         })



        //         // putting order // and teacher name
        //         // for onece its ok but for older marksheet you will have new teacher
        //         //  for subj seq. and teacher name, this is done.
        //         const ali2 = ali1.sort((a, b) => a?.roll < b?.roll ? -1 : 0)

        //         setStudents(ali2)
        //         const subs = seleClass?.subjects?.filter(f => f?.totalMarks > 0)
        //         const newSubj: [] = []
        //         subs.map((e, i) => {
        //             const nsub = { ...e }
        //             const forTeacher = allSubjects.find(a => a.id == e.id)
        //             if (forTeacher) {
        //                 nsub.name = forTeacher.name
        //                 nsub.teacher = forTeacher.teacher
        //                 nsub.teacherName = forTeacher.teacherName
        //                 nsub.order = forTeacher?.order ? forTeacher.order : 0
        //             }
        //             newSubj.push(nsub)
        //         })

        //         setSubjects(newSubj)
        //     }
        //}
*/