import * as React from 'react';
import {  View, Dimensions } from 'react-native';
import { Image } from 'expo-image';
import {Text} from 'react-native-paper'
import { TouchableOpacity } from 'react-native-gesture-handler';
import Global from "../constants/global";
import { Card } from "react-native-paper"
const w = Dimensions.get("window").width
const w1 = w > 768 ? 768 : w
const wd = (w1 / 2) - 20
const hi = (wd * 2) / 3

const AboutGroup = React.memo((op) => {
    return (
        <View style={{ backgroundColor: "transparent" }}>
            <View style={{ flexWrap: "wrap", justifyContent: "center", alignItems: "center", flexDirection: "row", borderRadius: 20, flex: 1, }}>
                <Card style={{}} onPress={() => op.navigation.navigate("AboutSTVM", { stvm: op.stvm })} style={{ borderRadius: 10, width: wd, height: hi }}>
                    <Image contentFit='cover' style={{ width: wd, height: hi }} source={{ uri: op?.stvm?.baseField[0].pic }} />
                    <View style={{ left: 0, top: 0, width: wd, height: hi, position: "absolute", backgroundColor: "rgba(0,0,0,0.5)" }}></View>
                    <View
                        style={{ alignItems: "center", justifyContent: "center", left: 0, top: 0, margin: 5, width: wd, height: hi, position: "absolute", backgroundColor: "transparent" }}>
                        <Text variant="titleMedium" style={{ color: "white", fontWeight: "bold" }}>{op?.stvm?.baseField[0].title}</Text>
                    </View>
                </Card>
                <Card onPress={() => alert("Under Development")} style={{ borderRadius: 10, width: wd, height: hi }}>
                    <Image contentFit='cover' style={{ width: wd, height: hi }} source={{ uri: op?.stvm?.baseField[1].pic }} />
                    <View style={{ top: 0, width: wd, height: hi, position: "absolute", backgroundColor: "rgba(0,0,0,0.5)" }}></View>
                    <View style={{ alignItems: "center", justifyContent: "center", top: 0, width: wd, height: hi, position: "absolute" }}>
                        <Text variant="titleMedium" style={{ color: "white", fontWeight: "bold" }}>{op?.stvm?.baseField[1].title}</Text>
                    </View>
                </Card>
                <Card onPress={() => op.navigation.navigate("Team", { type: 1 })} style={{ borderRadius: 10, width: wd, height: hi }}>
                    <Image contentFit='cover' style={{ width: wd, height: hi }} source={{ uri: op?.stvm?.baseField[2].pic }} />
                    <View style={{ left: 0, top: 0, width: wd, height: hi, position: "absolute", backgroundColor: "rgba(0,0,0,0.1)" }}></View>
                    <View style={{ alignItems: "center", justifyContent: "center", left: 0, top: 0, width: wd, height: hi, position: "absolute" }}>
                        <Text variant="titleMedium" style={{ color: "white", fontWeight: "bold" }}>{op?.stvm?.baseField[2].title}</Text>
                    </View>

                </Card>
                <Card onPress={() => op.navigation.navigate("Team", { type: 0 })} style={{ borderRadius: 10, width: wd, height: hi }}>
                    <Image contentFit='cover' style={{ width: wd, height: hi }} source={{ uri: op?.stvm?.baseField[3].pic }} />
                    <View style={{ top: 0, width: wd, height: hi, position: "absolute", backgroundColor: "rgba(0,0,0,0.5)" }}></View>
                    <View style={{ alignItems: "center", justifyContent: "center", top: 0, margin: 5, width: wd, height: hi, position: "absolute" }}>
                        <Text variant="titleMedium" style={{ color: "white", fontWeight: "bold" }}>{op?.stvm?.baseField[3].title}</Text>
                    </View>
                </Card>

            </View>
        </View>
    );
})
export default AboutGroup