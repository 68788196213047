import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { View } from 'react-native';
import { Text, Card, IconButton, Portal, Dialog, Button, useTheme, TextInput, Chip, Divider, Checkbox, Snackbar, Badge } from 'react-native-paper';
import Modal from 'react-native-modal';
import StudentPlainRow from './StudentRowPlain';
import Global from '../constants/global';
import DChip from './DChip';
import { arrayUnion, doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { FlatList } from 'react-native-gesture-handler';
import { totalMemory } from 'expo-device';

export default function PresentDays({ navigation, route }) {
    const [data, setData] = useState({ list: [], totalDays: 0 })
    useEffect(() => {
        const list = []
        route.params.student.map(s => {
            list.push(s)
        })
        const got = route.params.exam.forClass.find(f => f.id == route.params.class.id)
        // console.log(route.params.student)
        console.log("exam got", route.params.exam, got)
        setData({ list: list, totalDays: got?.totalDays ? got?.totalDays : 0 })
    }, [route.params])

    return <View>
        <YearAttendence seleClasss={route.params.class} exam={route.params.exam} cldiv={route.params.class?.div} clname={route.params.class?.name} reset={(yr) => {
            setData(p => ({ ...p, totalDays: yr }))
        }} />
        <FlatList
            data={data.list}
            renderItem={(item) => {
                return <Row totalDays={data.totalDays} item={item.item} list={data.list} cy={route.params.exam.year} reset={(l) => {
                    console.log("LLL", l)
                    setData(p => ({ ...p, list: [...l] }))
                }} />
            }}
        />
        <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
            <Button style={{ flex: 1 }} onPress={() => {
                navigation.goBack()
            }}>Cancel</Button>
            <Button style={{ flex: 1 }} mode="contained" onPress={() => {
                console.log(data.list)
                data.list.map(m => {
                    const ori = route.params.student["class" + route.params.exam.year]
                    const newVal = m["class" + route.params.exam.year]?.present
                    console.log(ori, newVal)
                    if ((ori !== newVal) && (newVal !== undefined)) {
                        const ref = doc(getFirestore(), "Users", m.id)
                        if (ref) {
                            console.log("Adding", newVal)
                            console.log(m)
                            updateDoc(ref, { [`class${route.params.exam.year}.present`]: newVal, [`class${route.params.exam.year}.totalDays`]: data.totalDays }, { merge: true })
                        } else {
                            console.log("noref")

                        }
                    }
                })
            }}>Save</Button>

        </View>
    </View >
}
const Row = ({ totalDays, list, item, cy, reset }) => {
    const [it, setIt] = useState()
    useEffect(() => {
        setIt(item)
    }, [item])
    // console.log(item?.["class" + cy]?.present)
    return <View style={{ alignItems: "center", flexDirection: "row", flex: 1, borderBottomWidth: 1, marginTop: 15 }}>
        {/* <Text style={{ width: 50, textAlign: "center" }} variant="headlineSmall">{it?.["class" + cy]?.roll}</Text>
        <Text style={{ flex: 1 }} variant="titleMedium">{it?.name}</Text> */}
        <View style={{ flex: 1 }}>
            <StudentPlainRow hideDown={true} info={item} withGr={true} withStream={true} currentYear={cy} />
        </View>
        {item.left ?
            <Text style={{ flex: 1, textAlign: "center" }}>Left</Text>
            : <TextInput disabled={totalDays ? false : true} selectTextOnFocus style={{ textAlign: "center", fontSize: 20, flex: 1, backgroundColor: "transparent" }}
                keyboardType="numeric" dense
                value={it?.["class" + cy]?.present ? it?.["class" + cy]?.present + "" : ""}
                placeholder={totalDays ? "હાજર દિવસ" : ""}
                onChangeText={(t) => {
                    try {
                        const i = parseInt(t)
                        console.log("totl", i, totalDays)
                        if (i <= totalDays) {
                            const newList = []
                            list.map(m => {
                                if (m.id == item.id) {
                                    m["class" + cy].present = i + ""
                                }
                                newList.push(m)

                            })
                            setIt(p => ({ ...p, present: i }))
                            reset(newList)
                        }
                    } catch { }
                }
                } />}
    </View >
}

const YearAttendence = ({ seleClasss, exam, clname, cldiv, reset }) => {
    const [data, setData] = useState()
    useEffect(() => {
        console.log("got", seleClasss)
        const got = exam.forClass.find(f => f.id == seleClasss.id)
        console.log("got", got)
        setData(got)
    }, [exam])
    return <View>
        <Text variant="titleLarge">{clname} {cldiv}</Text>
        <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
            <TextInput style={{ fontSize: 22, fontWeight: "bold", textAlign: "center", flex: 1, backgroundColor: "transparent" }}
                label="કુલ દિવસ"
                value={data?.totalDays ? data?.totalDays + "" : ""} onChangeText={(t) => {
                    try {
                        const i = parseInt(t)
                        setData(p => ({ ...p, totalDays: i }))
                    } catch { }
                }} />
            <Button onPress={() => {
                const ref = doc(getFirestore(), "Exams", exam.id)
                if (ref) {
                    console.log(data)
                    const newForClass: [] = []
                    exam.forClass.map(f => {
                        if (f.id == data.id) {
                            newForClass.push(data)
                        } else {
                            newForClass.push(f)
                        }
                    })
                    console.log(newForClass)
                    updateDoc(ref, { forClass: newForClass }, { merge: true })
                } else {
                    alert("Error:102")
                }
                reset(data.totalDays)
            }} mode='contained'>Save</Button>
        </View>
        <Text style={{ marginTop: 40 }} variant="titleMedium">વિદ્યાર્થીના કુલ હાજર દિવસ </Text>

    </View >
}