import dayjs from "dayjs"
import { collection, doc, getFirestore, setDoc } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"
import { Dimensions, ScrollView } from "react-native"
import Modal from "react-native-modal"
import { Button, Chip, Text, TextInput } from "react-native-paper"
import Toast from "react-native-root-toast"
import Global from "../constants/global"
import { View } from "./Themed"
import Preferances from "../constants/Preferances"
const NewStaff = React.memo((op) => {
    const [teach, setTeacher] = useState(op.teacher)
    useEffect(() => {
        setTeacher(op.teacher)
    }, [op.teacher])

    return < Modal onBackdropPress={() => op.dismiss()} isVisible={op.show} style={{ backgroundColor: op.color.surface, margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >
        <View>
            <TextInput label={"Name"} value={teach.name} onChangeText={(t) => setTeacher(p => ({ ...p, name: t }))} />
            {op.isTeacher && <TextInput label={"Experience"} value={teach.experience} onChangeText={(t) => setTeacher(p => ({ ...p, experience: t }))} />}
            {op.isTeacher && <TextInput label={"Education"} value={teach.education} onChangeText={(t) => setTeacher(p => ({ ...p, education: t }))} />}
            {op.isTeacher && <TextInput label={"Exams"} value={teach.exams} onChangeText={(t) => setTeacher(p => ({ ...p, exams: t }))} />}
            {!op.isTeacher && <TextInput label={"Designation"} value={teach.designation} onChangeText={(t) => setTeacher(p => ({ ...p, designation: t }))} />}
            {!op.isTeacher && <TextInput label={"Description"} value={teach.desc} onChangeText={(t) => setTeacher(p => ({ ...p, desc: t }))} />}
            <TextInput label={"Mobile (will be login id)"} value={teach.mobile} onChangeText={(t) => setTeacher(p => ({ ...p, mobile: t }))} />
            {!teach?.id && <Text style={{ fontStyle: "italic" }}>Note : New users can login using 0000 password and above loginid</Text>}
            {op.isTeacher && <ScrollView horizontal style={{ marginTop: 10 }}>
                <Text>Type :</Text>
                {Global.TYPES.map((e, i) =>
                    <Chip selected={teach.type == i} onPress={() => setTeacher(p => ({ ...p, type: i }))}>{e}</Chip>
                )}
            </ScrollView>}
            <View style={{ flexDirection: "row", marginTop: 10, backgroundColor: "transparent" }}>
                <Text>નિવૃત્ત : {op.isTeacher ? "ટીમ મેમ્બર  " : "ટ્રસ્ટી  "}</Text>
                <Chip style={{ margin: 2 }} selected={!teach.retired} onPress={() => setTeacher(p => ({ ...p, retired: false }))}>Working</Chip>
                <Chip style={{ margin: 2 }} selected={teach.retired} onPress={() => setTeacher(p => ({ ...p, retired: true }))}>Retired</Chip>
            </View>


            <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent", alignSelf: "stretch" }}>
                <Button style={{ flex: 1 }} onPress={() => {
                    op.dismiss()
                }}>Cancel</Button>
                <Button style={{ flex: 1 }} onPress={async () => {
                    const data = { ...teach }
                    if (data.id) {
                        const db = getFirestore()
                        let ref
                        const data = { ...teach }
                        ref = doc(db, "Users", data.id)

                        data.stamp = dayjs().unix()
                        try {
                            setDoc(ref, { flag: -10 }, { merge: true })
                            op.dismiss()
                            Toast.show("Deleted")
                        } catch (e) {
                            //      console.log("err", e)
                            Toast.show("Error : saving data. Check internet connection")
                        }

                    }
                }}>Delete</Button>
                <Button mode="contained" style={{ flex: 1 }} onPress={async () => {
                    const data = { ...teach }
                    if (data?.name && data?.mobile) {
                        const db = getFirestore()
                        let ref
                        if (!data.id) {
                            ref = doc(collection(db, "Users"))
                            data.password = "0000"
                            data.loginid = data.mobile
                            data.id = ref.id
                        } else {
                            ref = doc(db, "Users", data.id)
                        }

                        data.stamp = dayjs().unix()
                        try {
                            setDoc(ref, data, { merge: true })
                            op.dismiss()
                            Toast.show("Saved")
                        } catch (e) {
                            console.log("err", e)
                            Toast.show("Error : saving data. Check internet connection")
                        }
                    } else {
                        Toast.show("Error : Atleast  name and mobile no is required")
                    }
                }}>Ok</Button>
            </View>
        </View>
    </Modal >
}
)
export default NewStaff