import { useSelector } from 'react-redux'

import { StyleSheet, ScrollView, SectionList } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow } from 'react-native-paper';
import { View } from "../components/Themed"
import * as React from 'react';
import NewStaff from '../components/NewStaff';
import { FlatList } from 'react-native';
import { useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import DAvatar from '../components/DAvatar';
import Global from '../constants/global';
import Preferances from '../constants/Preferances';

export default function Team({ route, navigation }) {
    const postList = useSelector(s => s.list.Users)
    const [list, setList] = React.useState({ live: [], retired: [] })
    const [teacher, setTeacher] = React.useState({ data: {}, show: false })
    const pref = React.useContext(Preferances)
    const { colors } = useTheme()
    const TeamRow = ({ item }) => <View style={{ flexDirection: "row", alignItems: "center", alignSelf: "stretch", flex: 1}}>
        <DAvatar source={item.dp} type={Global.ROW_DP} />
        <View style={{ justifyContent: "center", alignSelf: "stretch", flex: 1 }}>
            <View style={{ flexDirection: "row", alignSelf: "stretch", alignItems: "baseline" }}>
                <Text variant="headlineSmall" style={{ marginLeft: 10 }}>{item.name}</Text>
                <Text style={{ marginLeft: 10, flex: 1 }}>{isTeacher ? "(" + Global.TYPES[item.type] + ")" : item?.designation ? "(" + item?.designation + ")" : ""}</Text>
            </View>
            {isTeacher && item.experience && <Text style={{ marginLeft: 10 }}>{item.experience} experience</Text>}
            {isTeacher && item.education && <Text style={{ marginLeft: 10 }}>{item.education}</Text>}
            {isTeacher && item.exams && <Text style={{ marginLeft: 10 }}>{item.exams} Qualified</Text>}
            {!isTeacher && item.desc && <Text style={{ marginLeft: 10 }}>{item.desc} </Text>}
        </View>
        {pref?.current?.id && (pref?.current?.id == item.id || (pref?.current?.type == Global.PRINCIPAL && !pref?.current?.retired) || (pref?.current?.allowEditProfile)) && <IconButton icon="dots-vertical" onPress={() => setTeacher({ data: item, show: true })} />}
    </View>

    React.useEffect(() => {
        if (route?.params?.type == 0) {
            const totlaList: [] = postList.filter(t => !t.retired && (t.type == Global.PRINCIPAL || t.type == Global.TEACHER || t.type == Global.VISITING_TEACHER || t.type == Global.ADMIN || t.type == Global.STAFF))
            let nnewP = totlaList.sort((a, b) => a?.type < b?.type ? -1 : 0)
            const retireList: [] = postList.filter(t => (t.retired && (t.type == Global.PRINCIPAL || t.type == Global.TEACHER || t.type == Global.VISITING_TEACHER || t.type == Global.ADMIN || t.type == Global.STAFF)))
            let nnewR = retireList.sort((a, b) => a?.type < b?.type ? -1 : 0)
            setList({ live: nnewP, retired: nnewR })
        } else {
            const totlaList: [] = postList.filter(t => !t.retired && (t.type == Global.TRUSTEE))
            let nnewP = totlaList.sort((a, b) => a?.seq < b?.seq ? -1 : 0)
            const retireList: [] = postList.filter(t => (t.retired && t.type == Global.TRUSTEE))
            let nnewR = retireList.sort((a, b) => a?.seq < b?.seq ? -1 : 0)
            setList({ live: nnewP, retired: nnewR })
        }
    }, [postList])
    const isTeacher = route?.params?.type == 0
    navigation.setOptions({ title: isTeacher ? "Team" : 'Trust' })
    const isWorkingInSchool = (!pref.current.retired)
        && (pref?.current?.type == Global.PRINCIPAL || pref?.current?.type == Global.TEACHER || pref?.current?.type == Global.ADMIN || pref?.current?.type == Global.STAFF || pref?.current?.type == Global.VISITING_TEACHER)

    return (
        <View style={{ flex: 1,backgroundColor: colors.background  }}>
            <ScrollView style={{ flex: 1 }}>
                <Text style={{ fontWeight: "bold" }}># કાર્યરત {isTeacher ? "ટીમ" : "ટ્રસ્ટીઓ"}</Text>
                <FlatList
                    data={list.live}
                    renderItem={({ item }) => {
                        return <TeamRow item={item} />
                    }}
                />
                <Divider style={{ marginTop: 10 }} />
                <Text style={{ fontWeight: "bold", marginTop: 20 }} ># નિવૃત {isTeacher ? "ટીમ" : "ટ્રસ્ટીઓ"}</Text>
                <FlatList
                    data={list.retired}
                    renderItem={({ item }) => {
                        return <TeamRow item={item} />
                    }} />

                <NewStaff color={colors} teacher={teacher.data} show={teacher.show} dismiss={() => setTeacher(p => ({ ...p, show: false }))} />
            </ScrollView >
            {isWorkingInSchool && pref?.current?.id && <FAB
                icon="plus"
                style={{
                    position: 'absolute',
                    margin: 16,
                    right: 0,
                    bottom: 0,
                }}
                onPress={() =>
                    setTeacher(({
                        show: true,
                        data: {
                            education: '',
                            experience: '',
                            exam: '',
                            mobile: '',
                            name: "",
                            type: isTeacher ? 1 : 5,
                            retired: false
                        }
                    }))}
            />}
        </View>
    );

}

