import { FontAwesome } from '@expo/vector-icons';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import * as React from 'react';
import { ColorSchemeName, Pressable } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import Home from '../screens/Home';
import LinkingConfiguration from './LinkingConfiguration';
import { useSelector, useDispatch } from 'react-redux';
import Preferances from '../constants/Preferances';


import Migrate from '../screens/Migrate';
import Login from '../screens/Login';
import GenerateMarks from '../screens/GenerateMarks';
import FinalTarij from '../screens/FinalTarij';

import InternalSummary from '../screens/InternalSummary';
import InternalSummary1 from '../screens/InternalSummary1';

import FinalSummarySheet from '../screens/FinalSummarySheet';
import SubjectwiseMarks from '../screens/SubjectwiseMarks';
import NewPost from '../components/NewPost';
import NewExam from '../screens/NewExam';
import PostGallary from '../components/PostGallary';
import LikesList from '../screens/LikesList';
import Comments from '../screens/Comments';
import AboutSTVM from '../screens/AboutSTVM';
import Marksheet from '../screens/Marksheet';

import Team from '../screens/Team';
import Users from '../screens/Users';
import StudentList from '../screens/StudentList';
// import OnlySubjectMarks from '../screens/OnlySubjectMarks';
import NewSummarySheet from '../screens/NewSummarySheet';


import Arrengements from '../screens/Arrengements';
import TimeTable from '../screens/TimeTable';
import Credits from '../screens/Credits';
import ContactUs from '../screens/ContactUs';
import Donation from '../screens/Donation';
import DonationList from '../screens/DonationList';
import Exam from '../screens/Exam';
import Profile from '../screens/Profile';
import SummarySheet from '../screens/SummarySheet';
import NotiList from '../screens/NotiList';
import NewNotification from '../screens/NewNotification';
import NewStudent from '../screens/NewStudent';
import PrintMarksheet from '../screens/PrintMarksheet';

import Backup from '../screens/Backup';
import PresentDays from '../components/PresentDays';
// import PutMarks from '../screens/PutMarks';

export default function Navigation({ colorScheme }) {
  const pref = useSelector(state => state.login)
  return <Preferances.Provider value={pref}>
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme}>
      <RootNavigator />
    </NavigationContainer>
  </Preferances.Provider>
}

const Drawer = createStackNavigator();

function RootNavigator() {
  return (
    <Drawer.Navigator initialRouteName="Home">
      <Drawer.Screen name="Home" component={Home} options={{ headerShown: false }} />
      <Drawer.Screen name="Teachers" component={Home} options={{ headerShown: false }} />
      <Drawer.Screen name="Students" component={Home} options={{ headerShown: false }} />
      <Drawer.Screen name="Classes" component={Home} options={{ headerShown: false }} />
      <Drawer.Screen name="Subjects" component={Home} options={{ headerShown: false }} />
      <Drawer.Screen name="Events" component={Home} options={{ headerShown: false }} />
      <Drawer.Screen name="Tasks" component={Home} options={{ headerShown: false }} />
      <Drawer.Screen name="NotiList" component={NotiList} options={{ title: "Notifications" }} />
      <Drawer.Screen name="GenerateMarks" component={GenerateMarks} options={{ title: "Put Marks" }} />
      <Drawer.Screen name="SubjectwiseMarks" component={SubjectwiseMarks} options={{ title: "Subjectwise Marks" }} />

      <Drawer.Screen name="NewPost" component={NewPost} options={{ title: "Post" }} />
      <Drawer.Screen name="PostGallary" component={PostGallary} options={{ title: "Post" }} />
      <Drawer.Screen name="LikesList" component={LikesList} options={{ title: "Likes" }} />
      <Drawer.Screen name="Comments" component={Comments} options={{ title: "Comments" }} />
      <Drawer.Screen name="AboutSTVM" component={AboutSTVM} options={{ title: "About" }} />
      <Drawer.Screen name="Team" component={Team} options={{ title: "Team" }} />
      <Drawer.Screen name="Users" component={Users} options={{ title: "Users" }} />
      <Drawer.Screen name="Arrangements" component={Arrengements} options={{ title: "Arrangements" }} />
      <Drawer.Screen name="Migrate" component={Migrate} options={{ title: "Migrate" }} />
      <Drawer.Screen name="TimeTable" component={TimeTable} options={{ title: "Time Table" }} />
      <Drawer.Screen name="Credits" component={Credits} options={{ title: "Credits" }} />
      <Drawer.Screen name="ContactUs" component={ContactUs} options={{ title: "Contact Us" }} />
      <Drawer.Screen name="Donation" component={Donation} options={{ title: "Donation" }} />
      <Drawer.Screen name="DonationList" component={DonationList} options={{ title: "Donation" }} />
      <Drawer.Screen name="StudentList" component={StudentList} options={{ title: "Students..." }} />
      <Drawer.Screen name="Profile" component={Profile} options={{ title: "Profile" }} />
      <Drawer.Screen name="Login" component={Login} options={{ headerShown: false }} />
      <Drawer.Screen name="NewNavigation" component={NewNotification} options={{ title: "Navigation" }} />
      <Drawer.Screen name="NewStudent" component={NewStudent} options={{ title: "Student" }} />
      <Drawer.Screen name="Backup" component={Backup} options={{ title: "Backup" }} />
      <Drawer.Screen name="Exams" component={Exam} options={{ title: "Exams" }} />
      <Drawer.Screen name="Marksheet" component={Marksheet} options={{ headerShown: false, title: "Marksheet" }} />
      <Drawer.Screen name="NewExam" component={NewExam} options={{ title: "Exam" }} />
      <Drawer.Screen name="PresentDays" component={PresentDays} options={{ title: "હાજર દિવસ" }} />
      {/* <Drawer.Screen name="PutMarks" component={PutMarks} options={{ title: "Marks" }} /> */}
      <Drawer.Screen name="PrintMarksheet" component={PrintMarksheet} options={{ headerShown: false }} />
      {/* <Drawer.Screen name="OnlySubjectMarks" component={OnlySubjectMarks} options={{ title: "Subject Marks" }} /> */}
      <Drawer.Screen name="SummarySheet" component={SummarySheet} options={{ headerShown: false, title: "Summary Sheet" }} />
      <Drawer.Screen name="NewSummarySheet" component={NewSummarySheet} options={{ headerShown: false, title: "Summary Sheet" }} />
      <Drawer.Screen name="FinalSummarySheet" component={FinalSummarySheet} options={{ headerShown: false, title: "Final Result Summary Sheet" }} />
      <Drawer.Screen name="FinalTarij" component={FinalTarij} options={{ headerShown: false, title: "Final Result તારીજ" }} />
      <Drawer.Screen name="InternalSummary" component={InternalSummary} options={{ headerShown: false, title: "Summary Sheet" }} />
      <Drawer.Screen name="InternalSummary1" component={InternalSummary1} options={{ headerShown: false, title: "Summary Sheet" }} />

    </Drawer.Navigator>
  );
}

////     @flyerhq/react-native-link-preview @react-native-async-storage/async-storage  @react-navigation/bottom-tabs  @react-navigation/drawer @react-navigation/stack    @reduxjs/toolkit     dayjs     expo-firestore-offline-persistence     expo-notifications     firebase     react-native-gesture-handler     react-native-modal     react-native-paper     react-native-reanimated     react-native-root-toast      react-native-youtube-iframe      react-redux     sharp-cli      @expo/config-plugins 