import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import Global from '../constants/global';
import Modal from 'react-native-modal';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import ClassRow from '../components/ClassRow';


let baselist: [] = []
export default function Credits({ navigation, route }) {
    const { colors } = useTheme();
    return (
        <ScrollView style={{ flex: 1 }}>

            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                <Text style={{ fontSize: 50, flex: 1, textAlign: "center" }}>🙏</Text>
                <Text variant="titleMedium" style={{ padding: 10 }}>જ્ઞાનત્સમાજોતકર્ષ, જ્ઞાન થકી સમાજ માં એક સ્મિત, એક હુંકાર, એક સંકલ્પ જે ઉત્કૃષ્ઠ સમાજનો આધાર છે </Text>
                <Text variant="titleMedium" style={{ padding: 10,textAlign:"justify" }}>તેને જીવી જાણનાર શ્રી ઠક્કરબાપા, આ સપનું જોનાર અમારા સંસ્થાપક ટ્રસ્ટીઓ, તેને આગળ વધારવા માટે ઝઝૂમનારા પ્રવર્તમાન ટ્રસ્ટીઓ, તેનું કાળજીપૂર્વક જતન કરનારા અમારા હાલના તેમજ પૂર્વ શિક્ષકો અને સ્ટાફ, તેમાં સહભાગી થનાર અમારા હરીજન અને સિંધી સોસાયટી ના સાથીઓ, અમારા આરાધ્ય એવા વિદ્યાર્થીઓ, તેમના અભિભવકો, સદા પડખે ઊભી રહેનારી સરકાર અને અધિકારીઓનો, અમોને સહાય કરનાર તેમજ અમારા સાથે લાગણીઓના તંતુએ બંધાયેલ દરેકનો અને અંતે સર્વના અંતરમાં સમાનરૂપે વિરાજેલ પરમકૃપાળુ પરમાત્માનો અમો હૃદયપૂર્વક આભાર માનીએ છીએ</Text>
            </View >
        </ScrollView>
    );


}

