import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Share, Dimensions, Linking, StyleSheet, TouchableOpacity } from 'react-native';
import Global from "../constants/global"
import { useDispatch, useSelector } from 'react-redux';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import * as Sharing from 'expo-sharing';
import { View } from 'react-native';
import DAvatar from './DAvatar'
import { Text, Card, IconButton, Portal, Dialog, Button, FAB, Badge, Avatar } from 'react-native-paper';
import Modal from 'react-native-modal';
import Preferances from '../constants/Preferances';
import dayjs from 'dayjs';
import { Image } from 'expo-image';
import { listsTask } from '../task/listTasks';
import relativeTime from 'dayjs/plugin/relativeTime'

interface option {
    info: any
    isWorkingInSchool: any
    navigation: any
}

dayjs.extend(relativeTime)

const NotificationRow = React.memo((op: option) => {
    // const [isOpen, setOpen] = useState(false);
    const [data, setData] = React.useState(op.info);
    // const [sendTo, setSendTo] = useState("");
    // // const [views, setView] = useState("");
    // function onClose() { setOpen(false) }
    // const [show, setShow] = React.useState(false)


    useEffect(() => {
        setData(op.info)
        // const all = data?.sendTo?.find(e => e == -1)
        // if (all) {
        //     setSendTo("All")
        // } else {
        //     const x: [] = []
        //     data?.sendTo?.map(e => x.push(Global.TYPES[e]))
        //     setSendTo(x)
        // }
    }, [op.info])
    const dispatch = useDispatch()

    return (
        <Card style={{ marginTop: 8, marginBottom: 8, flex: 1 }}>
            {/* <TouchableOpacity style={{ flex: 1 }} onPress={() => setShow(true)}> */}
            <Card.Cover source={data?.dp?.startsWith("https://") ? data?.dp : Global.getPicUrl(data?.dp)} />
            <Card.Content>
                <Text variant="titleLarge" style={{ flex: 1 }}>{data.title}</Text>
                <Text style={{}}>{data.desc}</Text>
                <View style={{ flexDirection: "row", marginTop: 14, alignItems: "center" }}>
                    {/* <Text>Sent to : {data?.sendTo?.map(e => Global.TYPES[e] + ", ")}</Text> */}
                    <Text style={{ flex: 1 }}>{dayjs.unix(data?.createdOn).fromNow()} </Text>
                    {data?.createdByDp && <Avatar.Image size={24} source={data?.createdByDp?.startsWith("https://") ? data?.createdByDp : Global.getPicUrl(data?.createdByDp)} />}
                    <Text style={{ flex: 1 }}>{data?.createdByDp ? "" : " •"} {data?.createdByName}</Text>
                    {data.responseId && <View style={{ alignItems: "baseline", flex: 1, flexDirection: "row" }}>
                        <Text> • {data.views} views </Text>
                        <IconButton size={14} hitSlop={10} icon="refresh" onPress={async () => {
                            const res = await Global.getDetails(data.responseId)
                            if (res !== undefined) {
                                const ref = doc(getFirestore(), "Notifications", data.id)
                                setDoc(ref, { views: res }, { merge: true })
                            }
                        }} />
                    </View>
                    }

                </View>
            </Card.Content>
            {op.isWorkingInSchool && <Card.Actions>
                <IconButton icon="share-outline" onPress={() => {
                    Sharing.shareAsync(Global.getImageLink(data.dp), { mimeType: "image/*", dialogTitle: data.title })
                }} />

                <IconButton icon="content-copy" onPress={() => {
                    op.navigation.navigate("NewNotification", { data: { ...data, id: "" } })
                }} />
                <IconButton icon="delete-empty-outline" onPress={() => {
                    dispatch(listsTask.actions.Alert({
                        message: "Are you Sure to Delete ?", type: 2, duration: 6000, button: "Delete", callback: () => {
                            const db = getFirestore()
                            const ref = doc(db, "Notifications", data.id)
                            setDoc(ref, { flag: -10 }, { merge: true })
                            setTimeout(() => {
                                dispatch(listsTask.actions.Alert({ message: "Deleted" }))
                            }, 500)
                        }
                    }));
                }} />

            </Card.Actions>
            }
            {/* </TouchableOpacity> */}
            {/* <Modal
                isVisible={show}
                onBackdropPress={() => setShow(false)}
                onDismiss={() => setShow(false)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: op.color }}>
                    <View style={{ justifyContent: "center" }}>
                        <Text variant="titleLarge" style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{data?.name}...</Text>
                        {data?.mobile &&
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <TouchableOpacity style={{ flex: 1 }} onPress={() => { Linking.openURL('tel:' + data?.mobile); onClose() }} >
                                    <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
                                        <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="phone-outgoing" />
                                        <Text style={{ paddingLeft: 10, paddingRight: 10 }} >{"Mobile -"} ({data?.mobile})</Text>
                                    </View>
                                </TouchableOpacity>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="whatsapp" onPress={() => { Share.share({ title: "નમસ્કાર", "message": "આપનો દિવસ શુભ રહે 🙂" }) }} />
                            </View>
                        }
                        {data?.receipt &&
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="printer" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10, flex: 1 }} >{"Receipt -"} {data?.receipt}</Text>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="whatsapp" onPress={() => { Share.share({ title: "નમસ્કાર", "message": "આપના યોગદાન હૃદયપૂર્વક આભાર.\ન Receipt No :" + data.receipt + "\n\nવિગતો જોવા ક્લિક કરો https://stvmeducation.web.app?donation=" + data.id }) }} />
                            </View>
                        }
                        <TouchableOpacity onPress={() => op.navigation.navigate("Donation", { mode: 2, data: data })}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="lead-pencil" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Edit</Text>
                            </View>
                        </TouchableOpacity>

                    </View>
                    <IconButton icon={"window-close"} onPress={() => setShow(false)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </Modal > */}
            {/* <Modal
                isVisible={showDelete}
                onBackdropPress={() => setShowDelete(false)}
                onDismiss={() => setShowDelete(false)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: op.color }}>
                    <View style={{ padding: 10, justifyContent: "center" }}>
                        <Text variant="headlineSmall">Are You sure you want to delete User ? </Text>
                        <View style={{ flex: 1, alignSelf: "stretch", flexDirection: "row" }}>
                            <Button style={{ flex: 1 }} onPress={() => setShowDelete(false)}>Cancel</Button>
                            <Button style={{ flex: 1 }} onPress={() => {
                                setShow(false)
                                const db = getFirestore();
                                const r = doc(db, "Users", data.id)
                                if (r) {
                                    setDoc(r, { flag: -10 }, { merge: true })
                                }
                            }} mode="contained">Delete</Button>
                        </View>
                    </View>
                    <IconButton icon={"window-close"} onPress={() => setShowDelete(false)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </Modal > */}

        </Card >)
})

//const Bhudev = React.memo(OBhudev)
export default NotificationRow