// YD Rana
// KS Jayswani
// MM Dodiya bsmH7vDNBk8hUvzsHzGE
// ht pandya z2BWQlaq9Tw3CTUlc4JJ
// aasha zGY9fiEZFVpPUaZ54EYC
// arti 7NmYgLcALuapMr78PH3S
// pinky 8YGRBwB5ntF76ugrXRMX

import { ScrollView, View } from 'react-native';
import { Button, IconButton, Text, useTheme } from "react-native-paper";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import * as XLSX from "xlsx"
import Preferance from '../constants/Preferances';

import dayjs from 'dayjs';
import { MaterialIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import Global from '../constants/global';

const totalTag = "Total Students"
const resultTag = "Result (%)"
const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
      page-break-after: always;
    }
  }

  @media print {
    .pagebreak {
      page-break-after: always;
    }
  }
`;
export default function SummarySheet({ navigation, route }) {
    const [value, setValue] = useState({ class: undefined, student: [], subjects: [], exam: undefined, tarij: 0, totalStudents: 0, attend: 0, pass: 0, fail: 0, ranklist: [], left: 0, year: "" })
    const originalSUbjects = useSelector(s => s.list.Subjects)
    const pref = useContext(Preferance)
    const Page = ({ s, type }) => {
        return <div style={{ display: "flex", flexDirection: "column", pageBreakInside: "avoid", flex: 1 }}>
            <Text style={{ flex: 1, padding: 10, marginTop: 40, backgroundColor: "green", color: "white", textAlign: "center" }} variant="titleLarge">પરીક્ષાનું ગુણપત્રક - {value.exam?.name} - વર્ષ {Global.YearString(value.exam?.year)}</Text>
            <View style={{ flexDirection: "row", marginTop: 20, }}>
                <Text style={{ flex: 2, fontWeight: "900" }} variant="titleLarge">{value.class?.name}  {value.class?.div}</Text>
                <Text variant="titleLarge" style={{ textAlign: "center", flex: 2 }}>પરીક્ષાની તારીખ  __________</Text>
            </View>
            <View style={{ marginTop: 20, flexDirection: "row" }}>
                <View style={{ flexDirection: "row", flex: 2 }}>
                    <Text style={{}} variant="titleLarge">વિષય  </Text>
                    <Text style={{ fontWeight: "900", borderBottomWidth: 1 }} variant="titleLarge">{s?.name} {type == 0 ? "" : (type == 1 ? "Theory" : "Practical")} </Text>
                </View>
                <View style={{ flexDirection: "row", flex: 1 }}>
                    <Text style={{}} variant="titleLarge">કુલગુણ  </Text>
                    <Text style={{ borderBottomWidth: 1, fontWeight: "900" }} variant="titleLarge">{type == 0 ? s?.totalMarks : (type == 1 ? s?.theoryMarks : s?.practicalMarks)}</Text>
                </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: 30 }}>
                <RollSheet till={17} above={0} subject={s} theory={type} />
                <RollSheet till={34} above={18} subject={s} theory={type} />
                <RollSheet till={51} above={35} subject={s} theory={type} />
                <RollSheet till={68} above={52} subject={s} theory={type} />
                <RollSheet till={85} above={69} subject={s} theory={type} />
            </View>
            <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.pass}</Text>
                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>પાસ</Text>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.fail}</Text>
                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>નાપાસ </Text>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.absent}</Text>
                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>Absent</Text>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.fail + s.pass + s.absent}</Text>
                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>કુલ</Text>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{Math.round((s.pass * 100) / (s.fail + s.pass))}%</Text>
                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>ટકાવારી</Text>
                </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 40 }}>
                <Text variant="titleLarge" style={{ textAlign: "center" }}>તપાસનારની સહી </Text>
                <Text variant="titleLarge" style={{ flex: 2, textAlign: "center", borderBottomWidth: 1, borderStyle: "solid" }}>{ }</Text>
                <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>તારીખ</Text>
                <Text variant="titleLarge" style={{ flex: 1, borderBottomWidth: 1, borderStyle: "solid" }}>{""}</Text>
            </View>

            <View style={{ flexDirection: "row", marginTop: 40 }}>
                <Text variant="titleLarge" style={{ textAlign: "center" }}>વર્ગ શિક્ષકની સહી </Text>
                <Text variant="titleLarge" style={{ flex: 2, textAlign: "center", borderBottomWidth: 1, borderStyle: "solid" }}>{ }</Text>
                <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>તારીખ</Text>
                <Text variant="titleLarge" style={{ flex: 1, borderBottomWidth: 1, borderStyle: "solid" }}>{""}</Text>
            </View>
        </div>
    }
    const RollSheet = ({ till, above, subject, theory }) => {
        const russia = value.student.filter(e => (e["class" + value.exam.year]?.roll <= till && e["class" + value.exam.year]?.roll >= above) && e.totalling !== true)
        return <View>
            {russia.length > 0 &&
                <View style={{ alignItems: "center", justifyContent: "center", marginRight: 2 }}>
                    <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid" }}>
                        <Text variant="titleMedium" style={{ backgroundColor: "#DCDCDC", width: 65, textAlign: "center", borderRightWidth: 1, fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>રોલ નં</Text>
                        <Text variant="titleMedium" style={{ width: 65, textAlign: "center", fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>ગુણ</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        {
                            russia.map(s => {
                                const re = s.marks[value.exam.year][value.exam.id][subject.id]
                                const roll = s["class" + value.exam.year]?.roll
                                const fal = re?.status == "Fail" ? { borderBottomWidth: 1, color: "red" } : {}
                                const able = (re?.status == "Absent" || s.isLeft == true) ? { backgroundColor: "#DCDCDC" } : {}
                                return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderStyle: "solid", height: 33 }}>
                                    <Text variant="titleMedium" style={{ alignSelf: "stretch", backgroundColor: "#DCDCDC", width: 65, textAlign: "center", borderRightWidth: 1, paddingTop: 2, paddingBottom: 2 }}>{roll}</Text>
                                    <View style={{ paddingBottom: 4, alignItems: "center", justifyContent: "center", width: 65, ...able }}>
                                        <Text variant="titleMedium" style={{ textAlign: "center", paddingTop: 2, paddingBottom: 2, ...fal }}>{s.left == true ? "Left" : theory == 0 ? Global.getValue(re, "Ab") : (theory == 1 ? (re?.theory == "Absent" ? "Absent" : re.theory) : (re?.theory == "Absent" ? "Absent" : re.practical))}</Text>
                                    </View>
                                </View>
                            })
                        }
                    </View>
                </View>
            }
        </View >
    }

    useEffect(() => {
        if (originalSUbjects && route.params) {
            let classStudents: [] = route.params.student
            let exam = route.params.exam
            let selectedClass = exam.forClass.find(e => e.id == route.params.class.id)
            const subjectList1 = selectedClass.subjects?.filter(f => f.totalMarks > 0)
            const subjectList2: [] = []
            subjectList1.map(s => {
                const got = originalSUbjects.find(f => f.id == s.id)
                if (got) {
                    subjectList2.push({ ...s, order: got.order, teacherName: got.teacherName })
                } else {
                    subjectList2.push({ ...s })
                }
            })
            const subjectList = subjectList2.sort((a, b) => a?.order < b?.order ? -1 : 0)


            // if (AllStudents) {
            //     classStudents = AllStudents.filter(e => e.class == selectedClass.id)
            // }

            let consAttent = 0
            let tstudent = classStudents?.length
            let Oattend = 0
            let Opass = 0
            let Ofail = 0
            let Oleft = 0
            let Ototal = 0
            let Oabsent = 0
            let Onotprepared = 0

            classStudents.map((student, i) => {
                const result = student.marks[exam.year][exam.id]
                if (result) {
                    result.seq = i
                    Ototal++
                    if (student?.left == true) {  //changed isLeft
                        Oleft++
                    } else {
                        if (result.status == "Absent") {
                            Oabsent++
                        } else {
                            if (result.status == "Pending") {
                                Onotprepared++
                            } else {
                                if (result.status == "Fail") {
                                    Ofail++
                                    Oattend++
                                }
                                if (result.status == "Pass") {
                                    Opass++
                                    Oattend++
                                }
                            }
                        }
                    }
                }
            })

            subjectList.map((s, i) => {
                let attend = 0
                let pass = 0
                let fail = 0
                let left = 0
                let total = 0
                let absent = 0
                let notprepared = 0
                classStudents.map(student => {
                    const result = student.marks[exam.year][exam.id][s.id]

                    if (result) {
                        result.seq = i
                        total++
                        if (student?.isLeft == true) {
                            left++
                        } else {
                            if (result.status == "Absent") {
                                absent++
                            } else {
                                if (result.status == "Pending") {
                                    notprepared++
                                } else {
                                    if (result.status == "Fail") {
                                        fail++
                                        attend++
                                    }
                                    if (result.status == "Pass") {
                                        pass++
                                        attend++
                                    }
                                }
                            }
                        }
                    } else {
                        // student.consolidated.push({ ...s, seq: i })
                        console.log("resultn ot found", student)
                    }

                })
                s.attend = attend
                s.pass = pass
                s.fail = fail
                s.left = left
                s.total = total
                s.absent = absent
                s.pending = notprepared
                if (attend > consAttent) {
                    consAttent = attend
                }
            })
            // res("Left", "left", subj, stud)
            // res("Absent", "absent", subj, stud)
            // res("Pending", "pending", subj, stud)
            // res("Fail", "fail", subj, stud)
            // res("Attended", "attend", subj, stud)
            // res("Pass", "pass", subj, stud)
            // res(totalTag, "", subj, stud)
            // res(resultTag, "", subj, stud)

            // stud.map(e => {
            //     const sorted = e.consolidated.sort((a, b) => a.seq - b.seq)
            //     e.consolidated = sorted
            // })
            const ranklist = classStudents?.filter(f => {
                const r = f?.marks[exam.year][exam.id].rank
                return r !== 0 && r !== undefined && r <= 5
            }).sort((a, b) => {
                const ar = a?.marks[exam.year][exam.id].rank
                const br = b?.marks[exam.year][exam.id].rank
                if (ar && br) {
                    return (ar || 0) - (br || 0);
                } else if (ar || br) {
                    return !(ar) - !(br)
                } else {
                    return (ar || 0) - (br || 0);
                }
            })
            console.log("ranklist",ranklist)

            const pass = classStudents?.filter(f => f?.marks[exam.year][exam.id].status == "Pass" && f.left !== true)?.length
            const fail = classStudents?.filter(f => f?.marks[exam.year][exam.id].status == "Fail" && f.left !== true)?.length
            const left = classStudents?.filter(f => f.left == true)?.length
            const sheet = []
            classStudents.map(m => {
                const me = m.marks[exam.year][exam.id]
                const reg = { student: m, examObject: me }
                const subjects: [] = []
                subjectList.map(s => {
                    const mm = me[s.id]
                    subjects.push({ ...mm, subject_id: s.id })
                })
                reg.subject = subjects
                sheet.push(reg)
            })
            setValue({ sheet: sheet, subjects: subjectList, class: { ...route.params.class }, student: classStudents, exam: { ...route.params.exam }, tarij: route.params.tarij, totalStudents: tstudent, attend: consAttent, pass: pass, fail: fail, ranklist: ranklist, left: left, year: exam.year, overAll: { pass: Opass, fail: Ofail, total: Ototal, absent: Oabsent, attend: Oattend } })
        }
    }, [route.params, originalSUbjects])

    const ref = useRef()
    const Row1 = ({ roll, gr, name, title, left }) => {
        const tt = title ? { textAlign: "center" } : { fontSize: 10 }
        return <><div style={{ textAlign: "center", display: "flex", width: 30, maxWidth: 30, borderWidth: 1, borderStyle: "solid" }}>{roll}</div>
            <div style={{ width: 40, maxWidth: 40, borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>{gr}</div>
            <div style={{ width: 150, maxWidth: 150, borderWidth: 1, borderStyle: "solid", ...tt, textDecorationLine: left == true ? 'line-through' : "" }}>{name}</div></>
    }

    const Row2 = ({ value, title, status }) => {
        const tt = title ? { fontSize: 10 } : {}
        return <div style={{ width: 45, maxWidth: 45, borderWidth: 1, borderStyle: "solid", justifyContent: "center", alignItems: "center" }}>
            <div variant={"titleSmall"} style={{ textAlign: "center", ...tt, textDecoration: status == "Fail" ? "underline" : "none", color: (status == "Fail" || status == "Absent") ? "red" : "black" }}> {value}</div>
        </div>
    }

    const Row3 = ({ v1, v2, v3 }) => {
        return <><div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 60, borderWidth: 1, borderStyle: "solid", textAlign: "center", fontWeight: "900" }}>{v1}</div>
            <div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 55, borderWidth: 1, borderStyle: "solid", textAlign: "center", fontWeight: "900" }}>{v2}</div>
            <div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 55, borderWidth: 1, borderStyle: "solid", textAlign: "center", fontWeight: "900" }}>{v3}</div></>
    }
    const t = useTheme()
    return <View>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start" }}>
            <IconButton icon={"arrow-left"} onPress={() => navigation.goBack()} />
            <ReactToPrint
                pageStyle={pageStyle}
                trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                content={() => ref.current}
            />
            {pref?.current.supervisor == true && <Button onPress={() => {
                try {
                    const workbook = XLSX.utils.book_new();

                    const headers = ["રોલ", "GR", "વિદ્યાર્થી નું નામ"]
                    value.subjects.map(s => headers.push(s.name))
                    headers.push("પરિણામ")
                    headers.push("મેળવેલ ગુણ")
                    headers.push("Rank")
                    const l2: [] = []
                    value?.sheet?.filter(e => e?.totalling !== true)?.map(s => {
                        const me = { "roll": s.student["class" + value.exam.year]?.roll, gr: s.student["class" + value.exam.year]?.gr, name: s.student.name + (s.student.left == true ? "(Left)" : "") }
                        value.subjects.map(e => {
                            const vv = s.subject.find(f => f?.subject_id == e.id)
                            if (vv) {
                                me[e.name] = Global.getValue(vv, "Ab")
                            } else {
                                me[e.name] = ""
                            }
                        }
                        )
                        me.status = s.student.left == true ? "Left" : s.examObject.status
                        me.marks = s.examObject.subjectMarks
                        me.rank = s.examObject.rank ? s.examObject.rank : ""
                        l2.push(me)
                    })
                    const worksheet = XLSX.utils.json_to_sheet(l2)
                    console.log(l2)
                    XLSX.utils.book_append_sheet(workbook, worksheet, "SummarySheet");
                    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
                    XLSX.writeFile(workbook, "SummarySheet" + value.class.name + (value.class.div ? " " + value.class.div : "") + ".xlsx");
                } catch (e) {
                    console.log("me the e", e)
                    alert("error")
                }
            }}>Export</Button>}
        </View>
        <View>
            <div ref={ref} style={{ display: "block", width: "98%", marginLeft: 10, boxSizing: "border-box" }}>
                {value.tarij == 1 ? <style type="text/css" media="print">{"@page { size: landscape, margin: 25mm 25mm 25mm 25mm}"}</style> :
                    <style type="text/css" media="print">{"@page { size: portrait}"}</style>
                }
                {value.tarij == 0 &&
                    <div style={{ marginLeft: 100, display: "block", marginRight: 30 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Text style={{ flex: 1, padding: 10, marginTop: 10, backgroundColor: "green", color: "white", textAlign: "center" }} variant="titleLarge">પરિણામ પત્રક - {value.exam?.name}</Text>
                            <View style={{ flexDirection: "row" }}>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1, }}>
                                    <Text variant="titleLarge">{value.class?.name}</Text>
                                </View>
                                {value.class?.div && <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1, }}>
                                    <Text variant="titleLarge">વર્ગ {value.class?.div}</Text>
                                </View>}
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1 }}>
                                    <Text variant="titleLarge">વર્ષ {Global.YearString(value.year + "")}</Text>
                                </View>
                            </View>
                            <View style={{ padding: 2, borderWidth: 2, marginTop: 20 }}>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">વિદ્યાર્થીઓની કુલ સંખ્યા</Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{(value?.overAll?.total - value.left)}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">પરીક્ષામાં બેઠેલા વિદ્યાર્થીઓની સંખ્યા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value?.overAll?.attend}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">ઉત્તીર્ણ થનાર વિદ્યાર્થીઓની સંખ્યા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value?.overAll?.pass}</Text>
                                </View>
                                {/* <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                        <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">ઉપલા વર્ગમાં ચઢાવેલ વિદ્યાર્થીઓની સંખ્યા </Text>
                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value.student.length}</Text>
                    </View> */}
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">નાપાસ થનાર વિદ્યાર્થીઓની સંખ્યા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value?.overAll?.fail}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">વિદ્યાર્થીઓની કુલ સંખ્યા પર પરિણામના ટકા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{Math.round((value?.overAll?.pass * 100) / (value?.overAll?.total - value.left))}%</Text>
                                </View>

                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">પરીક્ષામાં બેઠેલ વિદ્યાર્થીઓની સંખ્યા પર પરિણામના ટકા</Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{Math.round((value.overAll?.pass * 100) / value.overAll?.attend)}%</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 30, flex: 1, alignSelf: "stretch" }}>
                                <View style={{ alignItems: "center", justifyContent: "center", flex: 1, alignSelf: "stretch" }}>
                                    <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid", backgroundColor: "#DCDCDC" }}>
                                        <Text style={{ flex: 150, flexShrink: 0, textAlign: "center", borderRightWidth: 1 }}>વિષય </Text>
                                        <Text style={{ flex: 150, textAlign: "center", borderRightWidth: 1 }}>વિષયશિક્ષક</Text>
                                        <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>પરીક્ષામાં બેઠેલ સંખ્યા</Text>
                                        <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>ઉતીર્ણ સંખ્યા</Text>
                                        <Text style={{ flex: 50, textAlign: "center" }}>ટકાવારી</Text>
                                    </View>
                                    <View style={{ alignSelf: "stretch", flex: 1 }}>
                                        {
                                            value.subjects.map(s => {
                                                return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderStyle: "solid" }}>
                                                    <Text style={{ flexShrink: 0, flex: 150, borderRightWidth: 1 }}>{s.name}</Text>
                                                    <Text style={{ flex: 150, borderRightWidth: 1 }}>{s.teacherName}</Text>
                                                    <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>{s.attend}</Text>
                                                    <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>{s.pass}</Text>
                                                    <Text style={{ flex: 50, textAlign: "center" }}>{Math.round((s.pass * 100) / s.attend)} %</Text>
                                                </View>
                                            })
                                        }
                                    </View>
                                </View>
                            </View>
                            <View style={{ alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid", backgroundColor: "#DCDCDC" }}>
                                    <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>ક્રમાંક નં</Text>
                                    <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>રોલ નં</Text>
                                    <Text style={{ flex: 300, textAlign: "center", borderRightWidth: 1 }}>વિદ્યાર્થીનું નામ</Text>
                                    <Text style={{ flex: 80, textAlign: "center", borderRightWidth: 1 }}>મેળવેલ ગુણ</Text>
                                    <Text style={{ flex: 80, textAlign: "center" }}>ટકાવારી (%)</Text>
                                </View>
                                <View style={{ alignSelf: "stretch", flex: 1 }}>
                                    {
                                        value.ranklist.map((s) => {
                                            return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid" }}>
                                                <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>{s.marks[value.exam.year][value.exam.id].rank}</Text>
                                                <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>{s["class" + value.exam.year]?.roll}</Text>
                                                <Text style={{ flex: 300, borderRightWidth: 1 }}>{s.name}</Text>
                                                <Text style={{ flex: 80, textAlign: "center", borderRightWidth: 1 }}>{s.marks[value.exam.year][value.exam.id].subjectMarks}</Text>
                                                <Text style={{ flex: 80, textAlign: "center" }}>{s.marks[value.exam.year][value.exam.id].pc} %</Text>
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 70 }}>
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>  વર્ગ શિક્ષક  </Text>
                                </View>
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>  તારીખ  </Text>
                                </View>
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>  આચાર્ય  </Text>
                                </View>
                            </View>
                        </div>
                        <div className="pagebreak" />
                    </div>
                }
                {value.tarij == 1 &&
                    <View style={{ flexDirection: "row", marginTop: 20 }}>
                        <Text variant="titleLarge" style={{ textAlign: "center", flex: 1 }} >Summary Sheet - {value.exam?.name} {Global.YearString(value.exam?.year)}</Text>
                        <Text variant="titleLarge" style={{ flex: 1, marginLeft: 30, marginRight: 30 }}>{value.class?.name} {value.class?.div}</Text>
                    </View>
                }
                {value.tarij == 1 && <View>
                    <table style={{ paddingLeft: 60, width: "100%", paddingRight: 20 }}>
                        <thead style={{ padding: 0, margin: 0 }}>
                            <div style={{ display: "flex", pageBreakInside: "avoid", flexDirection: "row", marginTop: 30 }}>
                                <Row1 roll={"રોલ"} gr={"GR"} name={"વિદ્યાર્થી નું નામ"} title={true} />
                                {
                                    value.subjects.map(e => {
                                        return <Row2 value={e.name} title={true} />
                                    })
                                }
                                <Row3 v1={"પરિણામ"} v2={"મેળવેલ ગુણ"} v3={"Rank"} />
                            </div>
                        </thead>
                        <tbody style={{ padding: 0, margin: 0 }}>
                            {
                                value?.sheet?.filter(e => e?.totalling !== true)?.map(s => {
                                    return <div style={{ display: "flex", pageBreakInside: "avoid", flexDirection: "row" }}>
                                        <Row1 roll={s.student["class" + value.exam.year]?.roll} gr={s.student["class" + value.exam.year]?.gr} name={s.student.name + (s.student.left == true ? "(Left)" : "")} title={false} left={s.student.left} />
                                        {
                                            s.subject.map(e => {
                                                return <Row2 value={Global.getValue(e, "Ab")} title={false} status={s.student.left == true ? "Left" : e?.status} />
                                            })
                                        }
                                        <Row3 v1={s.student.left == true ? "Left" : s.examObject.status} v2={s.examObject.subjectMarks} v3={s.examObject.rank} />
                                    </div>
                                })

                            }
                        </tbody>
                    </table>
                </View>}
                {value.tarij == 2 && <div style={{ paddingLeft: 80, paddingRight: 40, display: "block" }}>
                    {console.log("ssss", value)}
                    {value.subjects.map(s => {
                        return <View >
                            <Page s={s} type={s.practical ? 1 : 0} />
                            {s.practical && <Page s={s} type={s.practical ? 2 : 0} />}
                        </View>
                    })
                    }
                    <div className="pagebreak" />
                </div>}

            </div>

            {/* <Text style={{ flex: 1 }}  >Generated on {dayjs().format("DD/MM/YY HH:mm")}</Text> */}
        </View>
    </View >


}