import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { Alert, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, FlatList, StatusBar } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Chip, Button, HelperText } from 'react-native-paper';

import * as React from 'react';
import DonationRow from '../components/DonationRow';
import { useTheme } from 'react-native-paper';
import NotificationRow from '../components/NotificationRow';
import ReactNativeModal from 'react-native-modal';
import { collection, doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import DImage from '../components/DImage';
import { AntDesign } from '@expo/vector-icons';
import Global from '../constants/global';
import DYear from '../components/DYear';
import DIcon from '../components/DIcon';
import Loading from '../components/Loading';


let baselist: [] = []


export default function ExamList({ navigation, route }) {
    const allExamList = useSelector((state) => state.list.Exams)
    // const marks = useSelector((state) => state.list.Marks)
    const [alllist, setAllList] = React.useState({ baselist: [] })
    const [focus, setFocus] = React.useState(undefined)
    const [currentYear, setCY] = React.useState(Global.getYear())
    const [show, setShow] = React.useState(false)
    const [copySub, setCopy] = React.useState(false)

    React.useEffect(() => {
        const a = []
        baselist = allExamList.map(e => {
            if (e.flag !== -10 && e.year == currentYear) {
                a.push({ ...e })
            }
        })
        const nnewP = a.sort((a, b) => a?.createdOn < b?.createdOn ? -1 : 0)
        setAllList({ baselist: nnewP })
        console.log(nnewP)
    }, [allExamList, currentYear]);


    async function delStudent() {
        const db = getFirestore()
        const d = doc(db, "Exams", focus.id)
        console.log("its d", focus.id)
        await setDoc(d, { flag: -10 }, { merge: true })
        setFocus(undefined)
        Toast.show("Deleted")
    }

    async function copy() {
        const db = getFirestore()
        const d = doc(collection(db, "Exams"))
        const nex = { ...focus }
        nex.id = d.id
        nex.stamp = new Date().getTime()
        let y = new Date().getFullYear()
        const m = new Date().getMonth()
        if (m <= 3) {
            y = y - 1
        }
        nex.year = 2024
        nex.name = focus.name
        nex.locked = false
        await setDoc(d, nex, { merge: true })
        console.log(nex)
        setFocus(undefined);
        Toast.show("Created")
    }
    const ExamRow = React.memo((r) => <TouchableOpacity onPress={() => setFocus(r.item)}>
        <View>
            <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 10, marginBottom: 10, flex: 1 }} >
                {r.item.locked ? <AntDesign name="Safety" size={24} color="green" /> : <Text style={{ width: 24 }} > </Text>}
                <View style={{ flexDirection: "row", flex: 1, alignItems: "baseline" }}>
                    <Text variant="headlineSmall">{r.item.name}</Text>
                    <Text style={{ fontStyle: "italic" }}>{r.item.final ? " (Final)" : ""} </Text>
                </View>
                <Text style={{ paddingLeft: 12, paddingRight: 13, fontSize: 18 }}>⌵</Text>
            </View>
        </View>
        <Divider />
    </TouchableOpacity>)

    const { colors } = useTheme();
    return (
        <View style={{ backgroundColor: colors.background, flex: 1, paddingTop: route?.params?.topMargin ? StatusBar.currentHeight : 0 }}>
            <View style={{ backgroundColor: "white", alignSelf: "stretch" }}>
                <DImage uri="https://img.freepik.com/premium-vector/list-concept-undone-checklist-deadline-poor-time-management-vector-illustration-time-management-concept-effective-business-planning_453397-50.jpg" />
            </View>
            <TouchableOpacity onPress={() => setShow(true)}>
                <View style={{ alignItems: "center", flexDirection: "row", marginBottom: 10 }}>
                    <Text variant="titleLarge">Select Year </Text>
                    <Text variant="titleLarge" style={{ fontWeight: "bold" }}> {Global.YearString(currentYear)}</Text>
                    <DIcon name="cog" />
                </View>
            </TouchableOpacity>
            <FlatList
                data={alllist.baselist}
                key="id"
                contentContainerStyle={{ backgroundColor: "transparent" }}
                keyboardShouldPersistTaps={'handled'}
                renderItem={({ item, index }) =>
                    <TouchableOpacity onPress={() => setFocus(item)}>
                        <ExamRow
                            item={item} />
                    </TouchableOpacity>
                } />

            <FAB
                icon="plus"
                style={{
                    position: 'absolute',
                    margin: 16,
                    right: 0,
                    bottom: 0,
                }}
                onPress={() => navigation.navigate("NewExam")}
            />
            <DYear show={show} year={currentYear} close={() => {
                setShow(false)
            }} change={(yr) => {
                setCY(yr)
                setShow(false)
            }} />

            <ReactNativeModal
                isVisible={focus !== undefined}
                onBackdropPress={() => setFocus(undefined)}
                onDismiss={() => setFocus(undefined)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: colors.surface }}>
                    <View style={{ justifyContent: "center" }}>
                        <Text variant="titleLarge" style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{focus?.name}...</Text>
                        <TouchableOpacity onPress={() => { setFocus(undefined); navigation.navigate("NewExam", { data: focus }) }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="lead-pencil" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Edit</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            // if (focus?.version == 2) {
                            const e1 = allExamList.find(f => f.year == focus.year && f.exam == 1)
                            const e2 = allExamList.find(f => f.year == focus.year && f.exam == 2)
                            const e3 = allExamList.find(f => f.year == focus.year && f.exam == 3)
                            const e4 = allExamList.find(f => f.year == focus.year && f.isInternal == true)
                            const marksheet = allExamList.find(f => f.year == focus.year && f.final == true)
                            navigation.navigate("GenerateMarks", { data: focus, allExams: { e1: e1, e2: e2, e3: e3, internal: e4, marksheet: marksheet } })
                            setFocus(undefined);
                            // } else {
                            //     navigation.navigate("PutMarks", { data: focus })
                            // }
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="checkbox-marked-circle-plus-outline" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Put Marks</Text>
                            </View>
                        </TouchableOpacity>
                        {focus?.final && focus?.locked && <TouchableOpacity onPress={() => {
                            // if (focus?.version == 2) {
                            const e1 = allExamList.find(f => f.year == focus.year && f.exam == 1)
                            const e2 = allExamList.find(f => f.year == focus.year && f.exam == 2)
                            const e3 = allExamList.find(f => f.year == focus.year && f.exam == 3)
                            const e4 = allExamList.find(f => f.year == focus.year && f.isInternal == true)
                            const marksheet = allExamList.find(f => f.year == focus.year && f.final == true)
                            navigation.navigate("GenerateMarks", { migrate: true, data: focus, allExams: { e1: e1, e2: e2, e3: e3, internal: e4, marksheet: marksheet } })
                            setFocus(undefined);
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="sign-direction" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Migrate</Text>
                            </View>
                        </TouchableOpacity>
                        }
                        {/* <TouchableOpacity onPress={() => {
                            copy()
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="content-copy" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Copy And Create New Exam</Text>
                            </View>
                        </TouchableOpacity> */}
                        {/* <TouchableOpacity onPress={() => {
                            setCopy(focus.forClass)
                            console.log(focus.forClass)
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="content-copy" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Copy Subjects</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            const ref = doc(getFirestore(), "Exams", focus.id)
                            updateDoc(ref, { forClass: copySub }, { merge: true })
                            console.log(copySub)
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="content-copy" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Past Subjects</Text>
                            </View>
                        </TouchableOpacity> */}

                        <TouchableOpacity onPress={() =>
                        // Alert.alert('Delete', 'Sure want to delete student ' + focus.name, [
                        //     { text: 'Canecl' },
                        //     { text: 'No' },
                        //     {
                        //         text: 'Yes', onPress: () => {
                        //             delStudent()
                        //         }
                        //     },
                        // ]
                        { Toast.show("Cannot Delete Locked Exam") }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="delete" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Delete</Text>
                            </View>
                        </TouchableOpacity>

                    </View>
                    <IconButton icon={"window-close"} onPress={() => setFocus(undefined)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </ReactNativeModal >


        </View >

    );


}

