import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { View } from 'react-native';
import { Text, Card, IconButton, Portal, Dialog, Button, useTheme, TextInput, Chip, Divider, Checkbox, Snackbar, Badge } from 'react-native-paper';
import Modal from 'react-native-modal';
import StudentPlainRow from './StudentRowPlain';
import Global from '../constants/global';
import DChip from './DChip';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';

interface option {
    student: any
    navigation: any
    multi: boolean
    selectAll: boolean
    examid: string,
    examyear: number
}
// const cy = Global.getYear()
const StudentMarks = (op: option) => {
    // const [data, setData] = React.useState({ student: undefined, sheet: [], absent: [], left: [], pass: [], fail: [], border: [], pending: [], totalNeeded: 0, totalGained: 0, consolidated: [], grandTotal: 0 });
    const [data, setData] = React.useState({ display: [], student: undefined, examid: "", pending: 0, examyear: 0, marksObject: undefined, pipedSubject: [], multiSelect: false, selectAll: false, final: false, hasInternal: false, hasPractical: false, gained: -1, total: -1, pc: -1, status: "", exam: undefined });
    const [show, setShow] = React.useState(false)
    const [multi, setMulti] = React.useState(false)
    const cy = op.examyear
    // const marks = useSelector(s => s.list.Marks)
    const { colors } = useTheme()



    useEffect(() => {
        if (op) {
            if (op?.onlyList) {
                setData({
                    student: op.student,
                    examid: op.examid,
                    examyear: op.examyear,
                    multiSelect: op.multi,
                    selectAll: op.selectAll,
                    final: op.exam.final == true,
                    pc: 0, rank: 0, status: "", exam: op.exam,
                    selfMarks: 0, subjectCount: 0
                })
            } else {
                if (op.exam.final) {
                    //     // let o
                    //     // let pend = 0
                    //     // let hasInternal = false
                    //     // let hasPractical = false
                    //     // let gained = -1
                    //     // let total = -1
                    //     // let pc = -1
                    //     // const subjects: [] = []
                    //     // const display: [] = []

                    //     // let status = ""
                    //     // try {
                    //     //     o = op.student.marks[op.examyear][op.allExams?.marksheet?.id]
                    //     //     const secStatus = { status: "Pass", totalMarks: 0, totalSiddhi: 0, totalKripa: 0 }
                    //     //     let totalSiddhi = 15
                    //     //     let totalKripa = 10
                    //     //     let finalStatus = "Pass"
                    //     //     // const got = coreSubjects.find(f => f.id == key)
                    //     //     Object.keys(o).forEach(function (key) {
                    //     //         if (Global.isSubject(o, key)) {
                    //     //             let mar1 = getValue(op.student?.marks, op.allExams?.e1?.id, key)
                    //     //             let mar2 = getValue(op.student?.marks, op.allExams?.e2?.id, key)
                    //     //             let internal = getValue(op.student?.marks, op.allExams?.internal?.id, key)
                    //     //             let ffinal = getValue(op.student?.marks, op.allExams?.e3?.id, key)

                    //     //             const mar = mar1?.status + mar2?.status + internal?.status + ffinal?.status
                    //     //             const res = Math.round(mar / 2)// 2 digit round
                    //     //             const isFailed = res < 33
                    //     //             let sgiven = 0
                    //     //             let kgiven = 0
                    //     //             o[key].siddhi = 0
                    //     //             o[key].kripa = 0
                    //     //             if (isFailed) {
                    //     //                 let needed = 33 - res
                    //     //                 if (needed <= (totalSiddhi + totalKripa)) {
                    //     //                     if (needed <= totalSiddhi) {
                    //     //                         console.log(op.student.name, "sidd0=" + totalSiddhi, "need=" + needed)
                    //     //                         totalSiddhi = totalSiddhi - needed
                    //     //                         console.log(op.student.name, "sidd1=" + totalSiddhi, "need=" + needed)
                    //     //                         sgiven = sgiven + needed
                    //     //                         needed = 0
                    //     //                     } else if (needed > totalSiddhi) {
                    //     //                         needed = needed - totalSiddhi
                    //     //                         sgiven = sgiven + totalSiddhi
                    //     //                         totalSiddhi = 0
                    //     //                     }
                    //     //                     if (needed > 0 && totalKripa >= needed) {
                    //     //                         totalKripa = totalKripa - needed
                    //     //                         console.log(op.student.name, "kripa=" + totalKripa, "need=" + needed)
                    //     //                         kgiven = kgiven + needed
                    //     //                         needed = 0
                    //     //                     }
                    //     //                 }
                    //     //             }
                    //     //             o[key].siddhi = sgiven
                    //     //             o[key].kripa = kgiven
                    //     //             o[key].primStatus = isFailed ? "Fail" : "Pass"
                    //     //             o[key].selfMarks = res
                    //     //             o[key].theory = res + o[key].siddhi + o[key].kripa
                    //     //             let ss = ""
                    //     //             if ((o[key].theory) < 33 && o[key].totalMarks > 0) {
                    //     //                 o[key].secStatus = "Fail"
                    //     //                 ss = "Fail"
                    //     //             } else {
                    //     //                 if (o[key].totalMarks > 0) {
                    //     //                     o[key].secStatus = "Pass"
                    //     //                     ss = "Pass"
                    //     //                 } else {
                    //     //                     o[key].secStatus = ""
                    //     //                     ss = ""
                    //     //                 }
                    //     //             }
                    //     //             if (o[key].secStatus == "Fail" && finalStatus == "Pass") {
                    //     //                 finalStatus = "Fail"
                    //     //             }
                    //     //             const sd = {
                    //     //                 id: key,
                    //     //                 name: o[key].name,
                    //     //                 e1: mar1?.status,
                    //     //                 e2: mar2?.status,
                    //     //                 internal: internal?.status,
                    //     //                 final: ffinal?.status,
                    //     //                 subStatus: ss,
                    //     //                 selfMarks: res,
                    //     //                 siddhi: sgiven,
                    //     //                 kripa: kgiven,
                    //     //                 theory: o[key].theory
                    //     //             }

                    //     //             display.push(sd)

                    //     //             // pend = pend + ((o[key].status == "Pass" || o[key].status == "Fail" || o[key].status == "Absent") ? 0 : 1)
                    //     //             subjects.push(o[key])
                    //     //         }
                    //     //     })
                    //     //     display.push({
                    //     //         id: "",
                    //     //         name: "Total",
                    //     //         e1: " ",
                    //     //         e2: " ",
                    //     //         internal: " ",
                    //     //         final: " ",
                    //     //         subStatus: " ",
                    //     //         selfMarks: " ",
                    //     //         siddhi: 15 - totalSiddhi,
                    //     //         kripa: 10 - totalKripa,
                    //     //         theory: " "
                    //     //     })

                    //     //     gained = o.subjectMarks
                    //     //     total = o.totalMarks
                    //     //     pc = o.pc
                    //     //     o.status = finalStatus
                    //     //     status = finalStatus
                    //     //     o.remSiddhi = totalSiddhi
                    //     //     o.remKripa = totalKripa
                    //     // } catch { }
                    //     // const t: [] = []
                    //     // Object.keys(o).forEach(function (key) {
                    //     //     if (Global.isSubject(o, key)) {
                    //     //         const g = t.find(f => f.id == o[key])
                    //     //         if (!g) {
                    //     //             t.push({ id: o[key].id, name: o[key].name })
                    //     //         }
                    //     //     }
                    //     // })


                    //     // const dr1: [] = []
                    //     // t.map(m => {
                    //     //     const got = display.find(c => c.id == m.id)
                    //     //     if (!got) {
                    //     //         dr1.push({ theory: 0, id: m.id, name: m.name })
                    //     //     } else {
                    //     //         dr1.push(got)
                    //     //     }
                    //     // })
                    //     setData({  pipedSubject: subjects, pending: pend, marksObject: o, student: op.student, examid: op.examid, examyear: op.examyear, multiSelect: op.multi, selectAll: op.selectAll, final: o?.final == true, hasInternal: hasInternal, hasPractical: hasPractical, gained: gained, total: total, pc: pc, status: status, exam: op.exam })
                    if (!op.exam?.onlyList) {
                        const pc = op.student.marks[op.examyear][op.allExams?.marksheet?.id]?.pc
                        const rank = op.student.marks[op.examyear][op.allExams?.marksheet?.id]?.rank
                        const status = op.student.marks[op.examyear][op.allExams?.marksheet?.id]?.status
                        const count = op.student.marks[op.examyear][op.allExams?.marksheet?.id]?.subjectCount
                        const selfMarks = op.student.marks[op.examyear][op.allExams?.marksheet?.id]?.selfMarks
                        setData({ student: op.student, examid: op.examid, examyear: op.examyear, multiSelect: op.multi, selectAll: op.selectAll, final: op.exam.final == true, pc: pc, rank: rank, status: status, exam: op.exam, selfMarks: selfMarks, subjectCount: count })
                    }
                } else {
                    let o
                    let pend = 0
                    let hasInternal = false
                    let hasPractical = false
                    let gained = -1
                    let total = -1
                    let pc = -1
                    const subjects: [] = []
                    let status = ""
                    const tmclass = op.exam.forClass.find(ff => ff.id == op.student["class" + op.exam.year].classId)
                    try {
                        o = op.student.marks[op.examyear][op.examid]
                        Object.keys(o).forEach(function (key) {
                            if (Global.isSubject(o, key)) {
                                const og = tmclass.subjects.find(ff => ff.id == o[key].subject_id)
                                if (og) {
                                    if (o[key].totalMarks > 0) {
                                        pend = pend + ((o[key].status == "Pass" || o[key].status == "Fail" || o[key].status == "Absent") ? 0 : 1)
                                        subjects.push(o[key])
                                        if (o[key].internal > 0) {
                                            hasInternal = true
                                        }
                                        if (o[key].internal > 0) {
                                            hasPractical = true
                                        }
                                    }
                                }
                            } else {
                                // console.log("estra sub", o[key])
                            }
                        });
                        gained = o.subjectMarks
                        total = o.totalMarks
                        pc = o.pc
                        status = o.status
                    } catch { }

                    setData({ pipedSubject: subjects, pending: pend, marksObject: o, student: op.student, examid: op.examid, examyear: op.examyear, multiSelect: op.multi, selectAll: op.selectAll, final: o?.final == true, hasInternal: hasInternal, hasPractical: hasPractical, gained: gained, total: total, pc: pc, status: status, exam: op.exam })
                }

            }
            // }
        }
    }
        , [op])
    //    console.log(data)

    useEffect(() => {
        op.setMultiSelect(multi)
    }, [multi])

    // console.log("display", data.display)
    // const cond = (mar1?.pc ? 1 : 0) + (mar2?.pc ? 1 : 0)
    // const condM = (mar1?.pc ? mar1?.pc : 0) + (mar2?.pc ? mar2?.pc : 0)
    // const alert = cond > 0 ? condM / cond : 0 // is avg pc
    // const tm = values?.totalMarks
    // const p1 = Math.floor((tm * ((alert * 0.9) / 100)))
    // const p2 = Math.ceil((tm * ((alert * 1.1) / 100)))
    // const warn = values?.theory > 0 && (values?.theory < p1 || values?.theory > p2)
    const failedIn = data.student?.display?.filter(e => e.secStatus == "Fail")?.length
    //console.log("myclasss", op.classes)
    return (
        <View>
            {
                ((op.migrate !== true)) && <View style={{ backgroundColor: data?.exam?.final && data.status == "Pass" ? "aquamarine" : data?.exam?.final && data.status !== "Pass" ? "#ffb3b3" : "transparent", marginTop: 8, marginBottom: 8, alignSelf: "stretch" }}>
                    <View style={{}}>
                        <TouchableOpacity style={{ flex: 1 }} onPress={() => data.multiSelect ? setMulti(!multi) : data?.exam?.final ? () => { } : setShow(true)}>
                            <MyData dark={true} data={data} failedIn={failedIn} multi={multi} migrate={op.migrate} classes={op.classes} cy={cy} />
                        </TouchableOpacity>
                        <Divider leftInset />
                    </View>
                    <Modal
                        isVisible={show}
                        onBackdropPress={() => setShow(false)}
                        onDismiss={() => setShow(false)}
                        style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                        <ScrollView style={{ backgroundColor: colors.surface, padding: 10, paddingBottom: 30, paddingRight: 20 }}>
                            <View style={{ flexDirection: "row" }}>
                                <Text variant="headlineSmall" style={{ color: "orange", flex: 1 }}>{data.student?.name}</Text>
                            </View>
                            <View style={{ justifyContent: "center" }}>
                                <View style={{ flexDirection: "row" }}>
                                    <Text variant="titleLarge" style={{ flex: data?.final ? 1 : 1 }}>{"Subject"}</Text>
                                    <View style={{ flexDirection: "row", flex: data?.final ? 5 : 1 }}>
                                        <View style={{ flexDirection: "row", flex: 1 }}>
                                            <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>{"મેળવેલ ગુણ"}</Text>
                                            {(data.hasInternal || data.hasPractical) && <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>{"મેળવેલ ગુણ"}</Text>}
                                            <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>{"કુલગુણ"}</Text>
                                        </View>
                                    </View>
                                </View>
                                {
                                    data.pipedSubject?.map(e => {
                                        return <View style={{ flexDirection: "row" }}>
                                            <Text variant="titleLarge" style={{ flex: data.final ? 2 : 1 }}>{e.name}</Text>
                                            <View style={{ flexDirection: "row", flex: (data.final) ? 4 : 1 }}>
                                                <Text variant="titleLarge" style={{ flex: 1, textAlign: "center", color: e.status == "Fail" ? "red" : undefined }}>{e.theory}{e.practical ? "+" + e.practical : ""}{e.internal ? "+" + e.internal : ""}</Text>
                                                <Text variant="titleLarge" style={{ flex: 1, textAlign: "center", color: e.status == "Fail" ? "red" : undefined }}>{e.totalMarks}</Text>
                                            </View>
                                        </View>
                                    })
                                }
                                < Divider />
                                <View style={{ flexDirection: "row", borderTopWidth: 1, borderStyle: "solid", borderColor: "gray" }}>
                                    <Text variant="titleLarge" style={{ flex: 1 }}>Total</Text>
                                    <View style={{ flexDirection: "row", flex: 1 }}>
                                        <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>{data.gained > -1 ? data.gained : ""}</Text>
                                        <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>{data.total > -1 ? data.total : ""}</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <Text variant="titleLarge" style={{ flex: 1 }}>Status : </Text>
                                    <Text variant="titleLarge" style={{ flex: 1, color: data.status == "Fail" ? "red" : data.status == "Pass" ? "green" : data.status == "Pending" ? "orange" : "black" }}>{data.status}</Text>
                                </View>
                            </View>
                            {(data.status == "Pass" || data.status == "Fail") &&
                                <TouchableOpacity onPress={() => {
                                    setShow(false);
                                    op.navigation.navigate("Marksheet", { list: [data.student], exam: data.exam, bulkPrint: false })
                                }}>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <IconButton style={{ marginRight: 20 }} icon="printer" />
                                        <Text variant="titleLarge">Print</Text>
                                    </View>
                                </TouchableOpacity>
                            }

                            <IconButton icon={"window-close"} onPress={() => setShow(false)} style={{ position: "absolute", top: 0, right: 0 }} />
                        </ScrollView>
                    </Modal >
                </View>}
            {(data?.student?.left !== true && op.migrate == true && (data.status == "Pass" || data.status == "Fail" || op.onlyList == true)) ?
                <MyData data={data} failedIn={failedIn} multi={multi} migrate={op.migrate} classes={op.classes} /> : <></>}

        </View>)
}
// const DaysPresent = ({ name, id, present }) => {
//     const [days, setDays] = useState("")
//     useEffect(() => {
//         setDays(present ? present : "")
//     }, [present])

//     return <View style={{ alignItems: "center", flexDirection: "row", flex: 1 }}>
//         <TextInput placeholder={name} selectTextOnFocus style={{  backgroundColor: "transparent" }} keyboardType="numeric" dense value={days + ""} label={"હાજર દિવસ "} onChangeText={(t) => {
//             () => {
//                 try {
//                     const i = parseInt(t)
//                     setDays(i + "")
//                 } catch { }

//             }
//         }} />
//         <Button onPress={() => {
//             const erf = doc(getFirestore(), "Users", id)
//             updateDoc(erf, { [`class${cy}.present`]: days }
//                 , { merge: true })

//         }}>Save</Button>
//     </View >
// }

export default memo(StudentMarks)

const Migration = ({ student, classes, status, rank, pc }) => {
    const [mig, setMig] = useState({ newclass: "", teacher: "", classname: "", stvm: true, status: 0, reason: "", newroll: 0, newgr: 0, roll: 0, saved: false })
    const cclass = parseInt(student.class.replace(/\D/g, ""))
    const newclass = cclass + 1
    const availClass = classes?.filter(f => {
        const listclassNo = parseInt(f.name.replace(/\D/g, ""))
        // console.log("mycc=" + cclass, f.name, "listclassNo=" + listclassNo, "newclass=" + newclass, "cy=" + cy, "f.year=" + f.year,)
        if (status == "Pass") {
            return listclassNo == newclass && (f.year == cy)
        } else {
            if (status == "Fail") {
                return listclassNo == cclass && (f.year == cy)
            } else {
                return (listclassNo == cclass || listclassNo == newclass) && (f.year == cy)
            }
        }
    })
    // console.log("finally", availClass)
    return <View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <DChip onPress={() => setMig(p => ({ ...p, status: 0 }))} selected={mig.status == 0} >In STVM </DChip>
                <DChip onPress={() => setMig(p => ({ ...p, status: 1 }))} selected={mig.status == 1} >Left study</DChip>
                <DChip onPress={() => setMig(p => ({ ...p, status: 2 }))} selected={mig.status == 2} >Moved to other school </DChip>
            </View>
        </View>
        {mig.status !== 0 && <TextInput label={"Reason"} value={mig.reason} onChangeText={(t) => { setMig(p => ({ ...p, reason: t })) }} />}
        {mig.status == 0 && <View>
            <View style={{ flexDirection: "row" }}>
                <Text>{status == "Pass" ? "New Class " + newclass : "New Class"} </Text>
                {availClass.map(cl => {
                    return <DChip onPress={() => {
                        setMig(p => ({ ...p, newclass: cl.id, classname: cl.name }))
                    }} selected={mig.newclass == cl.id || availClass.length == 1} >{cl.name} {cl.div}</DChip>
                })}
            </View>
            <TextInput placeholder={'New GR No'} value={mig.newgr ? mig.newgr + "" : ""} onChangeText={(t) => {
                const val = parseInt(t)
                setMig(p => ({ ...p, newgr: isNaN(val) ? 0 : val }))

            }} />
            <TextInput placeholder='New Roll' value={mig.roll ? mig.roll + "" : ""} onChangeText={(t) => {
                const val = parseInt(t)
                setMig(p => ({ ...p, roll: isNaN(val) ? 0 : val }))
            }
            } />
        </View>}
        <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <Button mode="contained" style={{ padding: 5 }} onPress={() => {
                const erf = doc(getFirestore(), "Users", student.id)
                const toput = {}
                if (!student.earlier) {
                    student.earlier = []
                }
                toput.earlier = student.earlier
                toput.earlier.push({ year: cy - 1, class: student.class, classId: student.classId, gr: student.gr, name: student.name, reason: mig.reason, movedstatus: mig.status, status: status ? status : "", pc: pc ? pc : "", rank: rank ? rank : "", roll: student.roll })
                if (mig.status == 0) {
                    if (availClass.length == 1) {
                        toput.class = availClass[0].name
                        toput.classId = availClass[0].id
                    } else {
                        if (mig.newclass) {
                            toput.class = mig.classname
                            toput.classId = mig.newclass
                        } else {
                            alert("Select new class")
                            return
                        }
                    }
                    if (mig.newgr) {
                        toput.gr = mig.newgr
                    }
                    if (mig.roll) {
                        toput.roll = mig.roll
                    } else {
                        toput.roll = 0
                    }
                } else {
                    toput.class = ""
                    toput.classId = ""
                }

                try {
                    setMig(p => ({ ...p, saved: true }))
                    setTimeout(() => {
                        updateDoc(erf, {
                            ...toput,
                            [`classId${cy - 1}`]: student.classId,
                            [`class${cy - 1}`]: student.class,
                            [`classId${cy}`]: toput.classId ? toput.classId : "",
                            [`class${cy}`]: toput.class ? toput.class : ""
                        }, { merge: true })
                    }, 200)
                } catch (e) {
                    alert("Error saving data")
                }
            }}>Update</Button>
            <Text variant="headlineLarge" style={{ width: 100, marginLeft: 10, color: "green" }}>{mig.saved ? "Saved" : ""}</Text>
        </View>
        <Divider bold leftInset />
    </View >


}

const MyData = ({ data, failedIn, multi, migrate, classes, cy, dark = false }) => {

    return <View style={{ marginBottom: 5, marginTop: 2 }}>
        <StudentPlainRow dark={true} info={data.student} withGr={false} currentYear={cy} nodivider={true} />

        {
            !data?.exam?.final ? <View style={{ flexDirection: "row", paddingLeft: 30, flex: 1, alignItems: "center" }}>
                <View style={{ flexDirection: "row" }}><Text style={{ color: "blue" }}>{data.marksObject?.rank ? "Rank " + data.marksObject?.rank : ""}</Text></View>
                {data.pending ? <Badge style={{ backgroundColor: "orange", color: "black" }} size={25}>{data.pending}</Badge> : <></>}
                {/* <Text style={{ color: "orange" }}>{data.pending ? "Pending " + data.pending : ""} </Text> */}
                {!data?.exam?.isInternal && data.marksObject?.status == "Absent" &&
                    <Badge style={{ backgroundColor: "gray", color: "white" }}>{data?.marksObject?.absent?.map((e, i) => (i == 0 ? "" : ",") + e)}</Badge>}
                {/* <Text style={{ color: "red", paddingRight: 20 }}>  [Absent {data?.marksObject?.absent?.map((e, i) => (i == 0 ? "" : ",") + e)}]</Text> */}
                {!data?.exam?.isInternal && data.marksObject?.status == "Fail" && data?.marksObject?.failed?.length > 0 &&
                    <Badge style={{ backgroundColor: "red", color: "white" }}>{data?.marksObject?.failed?.map((e, i) => (i == 0 ? "" : ",") + e)}</Badge>
                }
                {/* // <Text style={{ color: "red" }}>  [Failed {data?.marksObject?.failed?.map((e, i) => (i == 0 ? "" : ",") + e)}]</Text> */}

                {!data?.exam?.isInternal && data.marksObject?.status == "Pass" && data.pending == 0 &&
                    <View style={{ flexDirection: "row" }}>
                        {/* <Badge style={{ backgroundColor: "red" }}>{data?.marksObject?.pass?.map((e, i) => (i == 0 ? "" : ",") + e)}</Badge> */}
                        <Text style={{ color: "green" }}>  {data.pc > -1 ? "Pass (" + data.pc + "%, માર્કસ=" + data.marksObject.subjectMarks + ") " : ""}</Text>
                    </View>
                }
            </View> : <View style={{ paddingLeft: 30 }}>
                <View style={{ flexDirection: "row" }}>
                    <Text variant={"titleLarge"} style={{ color: data.status == "Pass" ? "green" : "red" }}>{data.status} {data.pc ? data.pc + "%" : ""}</Text>
                    <Text variant={"titleLarge"} style={{ color: "blue" }}> {data.rank ? "Rank " + data.rank : ""}</Text>
                    <Text style={{ color: dark ? "black" : undefined }}> {(data.selfMarks)}/{data.subjectCount}</Text>
                    <View style={{ alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                        {failedIn > 0 && failedIn < 3 && <IconButton icon={"lightbulb-on"} iconColor='white' />}
                        {failedIn > 0 && failedIn < 3 && <Text variant={"titleLarge"} style={{ backgroundColor: "yellow", color: dark ? "black" : undefined }}>{failedIn ? " " + failedIn + " " : ""}</Text>}
                    </View>
                </View>
                {migrate == true ?
                    <Migration classes={classes} student={data.student} rank={data?.rank} pc={data.pc} status={data.status} />
                    : <ScrollView horizontal style={{}}>
                        <View>
                            <Text> </Text>
                            <Text style={{ color: dark ? "black" : undefined }}>પ્રથમ ક.</Text>
                            <Text style={{ color: dark ? "black" : undefined }}>દ્વિતીય ક.</Text>
                            <Text style={{ color: dark ? "black" : undefined }}>આંતરિક</Text>
                            <Text style={{ color: dark ? "black" : undefined }}>વાર્ષિક</Text>
                            <Text style={{ color: dark ? "black" : undefined }}>કુલ(100 માંથી)</Text>
                            <Text style={{ color: dark ? "black" : undefined }}>સિદ્ધિગુણ</Text>
                            <Text style={{ color: dark ? "black" : undefined }}>કૃપાગુણ</Text>
                            <Text style={{ color: dark ? "black" : undefined }}>Marksheet</Text>
                        </View>
                        {
                            data.student?.display?.map(e => {
                                return <View style={{ alignItems: "center", width: 60 }}>
                                    <Text style={{ color: dark ? "black" : undefined }} numberOfLines={1} ellipsizeMode="clip">{e.name}</Text>
                                    <Text style={{ color: dark ? "black" : undefined }}>{e.e1}</Text>
                                    <Text style={{ color: dark ? "black" : undefined }}>{e.e2}</Text>
                                    <Text style={{ color: dark ? "black" : undefined }}>{e.internal}</Text>
                                    <Text style={{ color: dark ? "black" : undefined }}>{e.final}</Text>
                                    <Text style={{ color: dark ? "black" : undefined }}>{e.selfMarks}</Text>
                                    <Text style={{ backgroundColor: e.siddhi > 0 ? "gray" : undefined, color: e.siddhi > 0 ? (dark ? "black" : "white") : dark ? "black" : undefined }}> {e.siddhi} </Text>
                                    <Text style={{ backgroundColor: e.kripa > 0 ? "gray" : undefined, color: e.kripa > 0 ? (dark ? "black" : "white") : dark ? "black" : undefined }}> {e.kripa} </Text>
                                    <Text style={{ backgroundColor: e.secStatus == "Pass" ? undefined : e.name == "Total" ? undefined : "yellow", color: (dark ? "black" : "white") }}> {e.grad ? e.grade : e.theory} </Text>
                                    {/* <Text style={{}}>{e.subStatus}</Text> */}
                                </View>
                            })
                        }

                    </ScrollView>}
            </View>
        }

        {multi && <IconButton style={{ position: "absolute", top: 10 }} icon={"check-circle"} iconColor="green" />}
    </View>
}