import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, ScrollView, Platform, Dimensions } from 'react-native';
import * as Notifications from 'expo-notifications';
import { View } from '../components/Themed';
import { TextInput, Button, Text, HelperText, ActivityIndicator } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux'
import { loginTasks } from '../task/loginTasks';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Global from '../constants/global';
import { useTheme } from 'react-native-paper';
import { doc, getFirestore, setDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import DAvatar from '../components/DAvatar';
import { Image } from "expo-image"

const w = Dimensions.get("window").width
const h = Dimensions.get("window").height
const mob = Global.isMobile()
const Welcome = React.memo(() => <View style={{ padding: 20, backgroundColor: "transparent" }}>
    <Text style={{ color: mob ? "white" : "gray", alignSelf: "stretch", textAlign: "center" }} variant="headlineSmall" >Welcome to</Text>
    <View style={{ height: 64, alignItems: "center", backgroundColor: "transparent" }}>
        <DAvatar size={Global.ROW_DP} source={Global.getUrl("LOGO.jpg")} />
    </View>
    <Text style={{ fontSize: 30, paddingBottom: 20, color: mob ? "white" : "orange", alignSelf: "stretch", textAlign: "center" }} variant="headlineLarge" >Shri Thakkarbapa Vidyamandir</Text>
    <Text style={{ color: mob ? "white" : "gray" }} variant="headlineSmall" >Lets get Started...</Text>
</View>)

export default function Login({ navigation }) {
    const [secure, setSecure] = useState(true);

    const dispatch = useDispatch();

    const storeData = async (value) => {
        try {
            AsyncStorage.setItem('savedLoginId', value)
        } catch (e) {
            // saving error
        }
    }

    const theme = useTheme();

    const Input = () => {
        const [loginid, setLoginid] = useState("");
        const [password, setPassword] = useState("");
        const [sent, setSent] = useState(false);
        const [message, setMessage] = useState({ message: "", isErr: false, processing: false });
        const [showScreen, setScreen] = useState(false);
        const [otp, setOtp] = useState("");
        const [selectUser, setSelectUser] = useState(undefined);

        function getOtp() {
            let val = ""
            if (!otp) {
                val = Math.floor(1000 + Math.random() * 9000) + ""
                setOtp(val)
            } else {
                val = otp
            }
            return val
        }

        const Timmer = (clearM) => {
            const [timer, setTimer] = useState(0)
            const [countLimit, setCountLimit] = useState(new Date().getTime())
            const [clear, setClear] = useState(undefined)
            useEffect(() => {
                const cl = setInterval(() => {
                    setTimer(new Date().getTime() - countLimit)
                }, 1000)

                setClear(cl)
            }, [])
            useEffect(() => {
                if (timer > (60 * 1000)) {
                    clearInterval(clear)
                    setCountLimit(new Date().getTime())
                    clearM.clr()
                }
            }, [timer])


            return <Text style={{ flex: 1, textAlign: "center", paddingTop: 6 }}>OTP Sent. wait {60 - Math.round(timer / 1000)} </Text>
        }

        async function gon() {
            AsyncStorage.getItem('savedLoginId').then(e => {
                try {
                    if (e?.includes("undefined")) {
                        setScreen(true)
                    } else {
                        if (e) {
                            process(true, e)
                        } else {
                            setScreen(true)
                        }
                    }
                } catch (e) {
                    setScreen(true)
                }


            })
        }
        useEffect(() => {
            gon()
        }, [])

        async function process(secure: boolean, lid: string) {
            if ((secure && lid) || (password && loginid)) {
                let res = undefined
                if (secure) {
                    res = await Global.getData(lid)
                } else {
                    res = await Global.getData(loginid)
                    if (res?.password !== password) {
                        res = undefined
                    }
                }

                if (res) {
                    res = { ...res }
                    dispatch(loginTasks.actions.allowLogin({ user: res }));
                    navigation.goBack()
                    storeData(res.loginid ? res.loginid : res.mobile ? res.mobile : "")
                    setMessage({ message: "Login Successfull", isErr: false, processing: false })
                    // if (Platform.OS == "android") {
                    //     // setTimeout(() => {
                    //     //     try {
                    //     //         Notifications.getExpoPushTokenAsync().then(tk => {
                    //     //             const token = { ...tk }
                    //     //             //console.log("bhudevnoti 2", token)
                    //     //             if (token?.data) {
                    //     //                 if (!res?.token) {
                    //     //                     //console.log("bhudevnoti 3", token)
                    //     //                     res.token = "nothing"
                    //     //                 }
                    //     //                 if (token.data !== res.token) {
                    //     //                     //console.log("bhudevnoti 4", token)
                    //     //                     const db = getFirestore()
                    //     //                     setDoc(doc(db, "Users", res.id), { token: token.data }, { merge: true })
                    //     //                 }
                    //     //             }
                    //     //         }).catch(e =>
                    //     //             //console.log("bhudevnoti 5", e)
                    //     //         )
                    //     //         //                    ExponentPushToken[pnxBpkM84_5-2sa-rLEeB9]
                    //     //     } catch (e) {
                    //     //         //console.log("bhudevnoti 6", e)
                    //     //     }
                    //     //     // //console.log("bhudevnoti 5")

                    //     // })
                    //     //            navigation.navigate("Home")
                    // }
                } else {
                    if (secure) {
                        setScreen(true)
                    } else {
                        dispatch(loginTasks.actions.rejectLogin(res));
                        setMessage({ message: "Wrong Credentials", isErr: true, processing: false })
                    }
                }
            } else {
                setMessage({ message: "Credentials missing", isErr: true, processing: false })
            }
        }
        async function sendOtp() {
            const res = await Global.getData(loginid)
            if (res) {
                const myotp = getOtp()
                const psw: string = myotp
                const mobile: string = loginid
                const name: string = ""
                let message = 'https://gtech.co.in/store/sendSMSNative.php?' + new URLSearchParams({ type: "FORGOT_PASSWORD", name: "", mobile: mobile, password: psw })
                fetch(message)
                    .then(function (response) {
                        setSelectUser(res)
                        return //console.log(response)
                    })


            } else {
                alert("Error : Mobile no not registered")
            }

        }

        async function processLogin() {
            setMessage({ message: "Working", isErr: false, processing: true })
            setTimeout(() => {
                process(false, "")
            })
        }

        return <View style={{ alignSelf: "stretch" }}>
            {!showScreen && <ActivityIndicator animating />}
            <TextInput
                autoCorrect={false}
                autoFocus

                value={loginid}
                label='Login id (Mobile no / Email id)'
                style={{ backgroundColor: "transparent", fontSize: 15, alignSelf: "stretch" }}
                onChangeText={text => setLoginid(text)} />

            <TextInput
                value={password}
                secureTextEntry={secure}
                label='Password / OTP'
                autoCorrect={false}
                style={{ backgroundColor: "transparent", alignSelf: "stretch" }}
                right={<TextInput.Icon
                    icon={secure ? "eye" : 'eye-off'}
                    size={20}
                    onPress={() => setSecure(!secure)} />
                }
                onChangeText={text => setPassword(text)} />

            <HelperText type={message.isErr ? "error" : "info"} style={{ padding: 10 }} >{message.message}</HelperText>
            {sent ? <Timmer clr={() => setSent(false)} /> : <Text onPress={() => {
                if (loginid.length == 10) {
                    setSent(true)
                    sendOtp()
                } else {
                    alert("Enter mobile no properly")
                }
            }}>Get OTP</Text>
            }

            <View style={{ flexDirection: "row", margin: 10, justifyContent: "center", alignItems: "center", backgroundColor: "transparent", alignSelf: "stretch" }}>
                <Button mode="text" onPress={() => navigation.goBack()} style={{ flex: 1 }} >Back</Button>
                <Button loading={message.processing} mode="contained" onPress={() => {
                    if (selectUser) {
                        //console.log("selectUser", selectUser)
                        dispatch(loginTasks.actions.allowLogin({ user: selectUser }));
                        navigation.goBack()
                        const sav = selectUser.loginid ? selectUser.loginid : selectUser.mobile ? selectUser.mobile : ""
                        //console.log("saving data", sav)
                        storeData(sav)
                        setMessage({ message: "Login Successfull", isErr: false, processing: false })
                    } else {
                        processLogin()
                    }

                }} style={{ flex: 1 }} >Login</Button>
            </View></View >
    }

    return <SafeAreaProvider style={{ backgroundColor: theme.dark ? "black" : "white" }}>
        <ScrollView keyboardShouldPersistTaps="always" >
            <View style={{ flexDirection: mob ? "column" : "row" }}>
                {mob ?
                    <Image contentFit={"cover"}
                        style={{
                            height: h,
                            width: w,
                        }} source={require("../assets/images/login3.jpg")} />
                    :
                    <Image contentFit={"stretch"}
                        style={{
                            height: h,
                            width: w / 2.5
                        }} source={require("../assets/images/login2.jpg")} />
                }
                {mob &&
                    <View
                        style={{
                            position: "absolute", top: 0,
                            height: h,
                            width: w, backgroundColor: "rgba(0,0,0,0.8)"
                        }}
                    >
                    </View>
                }
                <View style={{
                    paddingLeft: mob ? 0 : (w / 2.5), position: "absolute",
                    alignItems: "center", justifyContent: "center", flex: 1,
                    width: w, height: h, backgroundColor: "transparent"
                }}>
                    <View style={{ alignItems: "center", justifyContent: "center", backgroundColor: "transparent", alignSelf: "stretch" }}>
                        <Welcome />
                    </View>
                    <Input />
                </View>
            </View>
        </ScrollView>
    </SafeAreaProvider >



}


