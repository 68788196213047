import React from "react"
import { DatePickerInput } from "react-native-paper-dates"
import Global from "../store/global"

interface option {
    onChange(date: number): any,
    value: number,
    label?: string,
    inputMode?: string
    validateYear?: boolean
}
// const start = new Date(Global.getYear(0) * 1000)
// const end = new Date(Global.getYear(1) * 1000)
const DDate = ({ onChange, value, label = "તારીખ", inputMode = "start", validateYear = true }: option) => {
    const minf = value ? (value.toString().length > 10) : false

    return <DatePickerInput
        locale="en-GB"
        label={label}
        style={{ backgroundColor: "transparent" }}
        withDateFormatInLabel={false}
        inputMode={inputMode?.trim()?.toLowerCase() == "to" ? "end" : inputMode}
        allowFontScaling
        value={value ? new Date(value * (minf ? 1 : 1000)) : undefined}
        // validRange={{ startDate: validateYear ? start : undefined, endDate: validateYear ? end : undefined }}
        onChange={(d) => {
            if (d?.getTime()) {
                onChange(d?.getTime() / (minf ? 1000 : 1))
            }
        }}
    />


}
export default React.memo(DDate)