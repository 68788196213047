import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, StatusBar } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import Global from '../constants/global';
import Modal from 'react-native-modal';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import ClassRow from '../components/ClassRow';


let baselist: [] = []
const dlist = [0, 1, 2, 3, 4, 5]
export default function TimeTable({ navigation, route }) {
    const classList = useSelector((state) => state.list.Classes)
    const subjectList = useSelector((state) => state.list.Subjects)
    const [selectedClass, setSelectedClass] = React.useState([])
    const [classSubject, setClassSubject] = React.useState(undefined)
    const [focus, setFocus] = React.useState(undefined)
    const [ting, setTing] = React.useState(undefined)

    const [focusSubject, setFocusSubject] = React.useState(undefined)

    React.useEffect(() => {
        if (focus && !focus?.days) {
            const dv = [
                { periodNum: 1, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { periodNum: 2, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { periodNum: 3, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { periodNum: 4, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { isBreak: true, day: 0 },
                { periodNum: 5, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { periodNum: 6, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { periodNum: 7, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { periodNum: 8, subjectId: "", subjectName: "", teacher: "", day: 0 },
                { periodNum: 1, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { periodNum: 2, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { periodNum: 3, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { periodNum: 4, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { isBreak: true, day: 1 },
                { periodNum: 5, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { periodNum: 6, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { periodNum: 7, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { periodNum: 8, subjectId: "", subjectName: "", teacher: "", day: 1 },
                { periodNum: 1, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { periodNum: 2, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { periodNum: 3, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { periodNum: 4, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { isBreak: true, day: 2 },
                { periodNum: 5, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { periodNum: 6, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { periodNum: 7, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { periodNum: 8, subjectId: "", subjectName: "", teacher: "", day: 2 },
                { periodNum: 1, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { periodNum: 2, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { periodNum: 3, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { periodNum: 4, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { isBreak: true, day: 3 },
                { periodNum: 5, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { periodNum: 6, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { periodNum: 7, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { periodNum: 8, subjectId: "", subjectName: "", teacher: "", day: 3 },
                { periodNum: 1, subjectId: "", subjectName: "", teacher: "", day: 4 },
                { periodNum: 2, subjectId: "", subjectName: "", teacher: "", day: 4 },
                { periodNum: 3, subjectId: "", subjectName: "", teacher: "", day: 4 },
                { periodNum: 4, subjectId: "", subjectName: "", teacher: "", day: 4 },
                { isBreak: true, day: 4 },
                { periodNum: 5, subjectId: "", subjectName: "", teacher: "", day: 4 },
                { periodNum: 6, subjectId: "", subjectName: "", teacher: "", day: 4 },
                { periodNum: 7, subjectId: "", subjectName: "", teacher: "", day: 4 },
                { periodNum: 8, subjectId: "", subjectName: "", teacher: "", day: 4 },

                { periodNum: 1, subjectId: "", subjectName: "", teacher: "", day: 5 },
                { periodNum: 2, subjectId: "", subjectName: "", teacher: "", day: 5 },
                { periodNum: 3, subjectId: "", subjectName: "", teacher: "", day: 5 },
                { isBreak: true, day: 5 },
                { periodNum: 4, subjectId: "", subjectName: "", teacher: "", day: 5 },
                { periodNum: 5, subjectId: "", subjectName: "", teacher: "", day: 5 },
                { periodNum: 6, subjectId: "", subjectName: "", teacher: "", day: 5 }
            ]
            dv.map((e, i) => e.seq = i)
            // const days: [] = []
            // Global.DAYS.map((d, i) => {
            //     if (i == 5) {
            //         days.push({ title: d, daynum: i, period: sat })
            //     } else {
            //         const ndv: [] = []
            //         dv.map((e, i) => ndv.push({ ...e, day: i }))
            //         days.push({ title: d, daynum: i, period: ndv })
            //     }
            // })
            const filtered = { ...focus }
            filtered.days = dv
            //      console.log("filtered", filtered)
            setSelectedClass(filtered)
        } else {
            setSelectedClass(focus)
        }

    }, [focus])
    React.useEffect(() => {
        if (ting) {
            const l = subjectList.filter(e => e.classid == selectedClass.id)
            setClassSubject(l)
        }
        //        console.log("ting", ting)
    }, [ting])

    function updateClass(subject: any) {
        const upd: [] = []
        selectedClass.days.map(e => {
            if (e.day == ting.day && e.periodNum == ting.periodNum) {
                const s = { ...e }
                s.subjectId = subject.id
                s.subjectName = subject.name
                upd.push(s)
            } else {
                upd.push(e)
            }
        })
        const work = { ...selectedClass }
        work.days = upd
        const db = getFirestore()
        const ref = doc(db, "Classes", selectedClass.id)
        setDoc(ref, { days: upd }, { merge: true })
        setTing(undefined)
        setSelectedClass(work)

    }
    const { colors } = useTheme();

    const Period = (op) => {
        // const sub = op.period.find(f => f.periodNum == op.periodNum && op.day.daynum == f.day)
        // console.log("periodOnm", combo.periodNum)
        return <TouchableOpacity onPress={() => { setTing(op.period) }}>
            <View style={{ padding: 1 }}>

                {op.period.isBreak ?
                    <Text style={{ flex: 1, textAlign: "center" }}>Break</Text> :
                    <View style={{}}>
                        <Text style={{ flex: 1, textAlign: "center" }}>{op?.period?.periodNum}</Text>
                        <Text style={{ flex: 1, fontSize: 8, textAlign: "center" }}>{op?.period?.subjectName ? (op.period.subjectName).substring(0, 15) : ""}</Text>
                        < Divider />
                    </View>
                }
            </View>
        </TouchableOpacity>
    }

    return (
        <View style={{ backgroundColor: colors.background, flex: 1, paddingTop: route?.params?.topMargin ? StatusBar.currentHeight : 0 }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ flexDirection: "row" }}>
                    <Text style={{ flex: 1 }} variant="headlineSmall">Classes</Text>
                    {/* <FAB icon={"plus"} onPress={() => { setClass({ name: "", section: "", div: "" }) }} /> */}
                </View>

                <FlashList
                    data={classList}
                    key="id"
                    estimatedItemSize={70}
                    keyboardShouldPersistTaps={'handled'}
                    renderItem={({ item, index }) =>
                        <TouchableOpacity onPress={() => setFocus(item)}>
                            <ClassRow item={item} />
                        </TouchableOpacity>
                    } />
            </ScrollView>
            {selectedClass !== undefined && <ScrollView style={{ flex: 1 }}>
                <View style={{ alignItems: "baseline", flexDirection: "row" }}>
                    <Text variant="headlineSmall">Time Table - {selectedClass.name}</Text>
                    <Text style={{ paddingLeft: 20 }}> (Click on period)</Text>
                </View>
                <ScrollView horizontal contentContainerStyle={{ flex: 1, alignSelf: "stretch" }}>
                    {dlist.map((d) => {
                        return <View style={{ flex: 1 }}>
                            <Text style={{ textAlign: "center" }}>{Global.DAYS[d]}</Text>
                            {selectedClass?.days?.filter(e => e.day == d).map(p => {
                                return <Period period={p} />
                            })}
                        </View>
                    })}
                </ScrollView>
            </ScrollView>}
            < Modal onBackdropPress={() => setTing(undefined)} isVisible={ting !== undefined} style={{ backgroundColor: colors.surface, margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >
                <View style={{ padding: 10 }}>
                    <Text variant="titleLarge" >Select Subjects for...</Text>
                    <Text style={{ padding: 10 }}>Class : {selectedClass?.name}</Text>
                    <Text style={{ padding: 10 }}>Day : {Global.DAYS[ting?.day]}</Text>
                    <Text style={{ padding: 10 }}>Period : {ting?.periodNum}</Text>

                    {/* <Text>Teacher : {ting?.period[ting?.periodNum + 1]?.teacher}</Text> */}
                    <ScrollView horizontal style={{ marginTop: 10 }}>

                        {classSubject?.map((e, i) =>
                            <Chip style={{ margin: 3 }} selected={ting?.subjectId == e.id} onPress={() => updateClass(e)}>{e.name}</Chip>
                        )}
                    </ScrollView>

                    <IconButton icon="close" style={{ position: "absolute", right: 0, top: 0 }} size={24} onPress={() => setTing(undefined)} />
                </View>
            </Modal >

        </View >
    );


}


