import { useSelector } from 'react-redux'
import { arrayUnion, doc, getFirestore, setDoc } from 'firebase/firestore';

import { ActivityIndicator, SafeAreaView, View, Text, StyleSheet } from 'react-native';
import { TextInput, IconButton } from 'react-native-paper';

import * as React from 'react';
import CommentRow from '../components/CommentRow';
import { FlatList } from 'react-native';
import { useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import Global from '../constants/global';

export default function Comments({ route, navigation }) {
    const [mycomment, setComment] = React.useState('');
    const [status, setStatus] = React.useState(0)
    const [activity, setActivity] = React.useState(undefined)
    const postList = useSelector(s => s.list.Posts)
    React.useEffect(() => {
        if (route?.params?.entry && postList) {
            const got = postList.find(e => e.id == route?.params?.entry?.id)
            setActivity(got)
        }
    }, [route?.params?.entry, postList])
    const canEdit = ((route.params.login?.allowedToComment == undefined && Global.isEdit(route.params?.login?.type)) || route.params?.login?.allowedToComment == 1)

    const fref = React.useRef()
    const iref = React.useRef()
    function save() {
        let comment = {};
        comment = { ...comment, id: route?.params?.login?.name + (new Date().getTime()) };
        comment = { ...comment, person_id: route?.params?.login?.name };
        comment = { ...comment, dp: route?.params?.login?.dp ? route.params.login.dp : "" };
        comment = { ...comment, message: mycomment };
        comment = {
            ...comment, date: dayjs().unix()
        };
        saveAsync(activity, comment, route.params?.entry?.id)
    }

    async function saveAsync(act, comment, id: string) {
        setStatus(1)
        setTimeout(async () => {
            let comments = []
            if (act?.comments?.length > 0) {
                comments.unshift(...act.comments)
            }
            comments.unshift(comment)
            let db = getFirestore();
            try {
                const docRef = await setDoc(doc(db, "Posts", id), { comments: arrayUnion(comment) }, { merge: true });
                setStatus(0)
                setComment("")

            } catch (e) {
                console.log('into ccoment err', e)

                setStatus(-1)
            }
            fref?.current?.scrollToOffset({ animated: true, offset: 0 })
            iref?.current?.focus()
        })

    }
    const { colors } = useTheme()
    return (
        <SafeAreaView style={{ backgroundColor: colors.background, paddingLeft: 12, flex: 1 }}>
            <FlatList
                data={activity?.comments}
                extraData={activity?.comments?.length}
                initialNumToRender={4}
                ref={fref}
                initialScrollIndex={0}
                ListEmptyComponent={<Text>Become Firt to Comment</Text>}
                ListFooterComponent={<Text style={{ alignSelf: "stretch", textAlign: "center" }}>______________</Text>}
                renderItem={({ item }) => <CommentRow item={item} />}
            />
            {canEdit && <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TextInput
                    ref={iref}
                    autoFocus
                    label={"કોમેન્ટ્સ.... "}
                    onSubmitEditing={() => save()}
                    value={mycomment}
                    onChangeText={(text) => setComment(text)}
                    style={styles.input} />

                {status == 1 ? <ActivityIndicator animating /> :
                    <IconButton icon="send" iconColor={colors.secondary} onPress={() => {
                        if (route?.params?.login?.id) {
                            if (status !== 1) { save() }
                        } else {
                            if (!route.params?.login?.id) {
                                navigation.navigate("Login");
                            }
                        }
                        // else { setVisible(true) }
                    }} />
                }
            </View>}
        </SafeAreaView>
    );

}

const styles = StyleSheet.create({
    input: {
        borderRadius: 8,
        outlineWidth: 0,
        flex: 1,
        margin: 6,
    }
});

