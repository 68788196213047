import { View } from '../components/Themed';
import Preferances from '../constants/Preferances';
import * as DocumentPicker from 'expo-document-picker';

import * as React from 'react';
import { List, AnimatedFAB, FAB, Portal, Button, TextInput, Chip, IconButton, Text, ActivityIndicator } from 'react-native-paper';
import { Dimensions, FlatList, ScrollView, Pressable } from 'react-native';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import Modal from 'react-native-modal'
import Global from '../constants/global';
import * as ImagePicker from 'expo-image-picker';
import ScaledImage from './ScaledImage';
import { SaveFormat } from 'expo-image-manipulator';
import * as ImageManipulator from 'expo-image-manipulator';
import Toast from 'react-native-root-toast';
import DCalendar from './DCalendar';
import DImage from './DImage';


export default function NewPost(op) {
    const [groupEntry, setGroupEntry] = React.useState(undefined);
    const pref = React.useContext(Preferances)
    const [state, setState] = React.useState({ open: false });
    const [working, setWorking] = React.useState(false);

    const [post, setPost] = React.useState(undefined);

    const onStateChange = ({ open }) => setState({ open });

    function updatePost() {
        const med: [] = []
        groupEntry?.media?.map(e => {
            if (e.id == post.id) {
                med.push(post)
            } else {
                med.push(e)
            }
        })
        if (post?.isCover) {
            setGroupEntry(p => ({ ...p, media: med, cover: post.content }))
        } else {
            setGroupEntry(p => ({ ...p, media: med }))
        }
        setPost(undefined)
    }
    function deletePost() {
        const med: [] = []
        groupEntry?.media?.map(e => {
            if (e.id !== post.id) {
                med.push(e)
            }
        })
        setGroupEntry(p => ({ ...p, media: med, stamp: dayjs().unix() }))
        setPost(undefined)

    }
    async function reduce(r) {
        if (r.type == "image") {
            const red = await ImageManipulator.manipulateAsync(
                r.uri,
                [{ resize: { width: 500 } }], // resize to width of 300 and preserve aspect ratio 
                { compress: 1, format: SaveFormat.WEBP },
            )
            groupEntry.media.push({ local: red, saved: 1, id: Global.getUniqueID() })
            setGroupEntry({ ...groupEntry, media: [...groupEntry.media] })
        } else {
            groupEntry.media.push({ local: r, saved: 1, id: Global.getUniqueID() })
            setGroupEntry({ ...groupEntry, media: [...groupEntry.media] })
        }
        // groupEntry.media.map(m => {
        //     if (m.uri == r.uri) {
        //         mn.push({ local: red, saved: 1 })
        //     } else {
        //         mn.push(r)
        //     }
        // })
    }

    const pickVideo = async () => {
        let result = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: true,
            type: '*/*',
        })

        if (!result.selected) {
            result.selected = [result]
        }
        if (result.type == "success") {
            result.selected.map(async (res, i) => {
                if (!groupEntry?.media) {
                    groupEntry.media = []
                }
                groupEntry.media.push({ local: res, saved: 1, id: Global.getUniqueID() })
                setGroupEntry({ ...groupEntry, media: [...groupEntry.media] })
            })
        }
    }

    const pickImage = async () => {
        setWorking(true)
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: true,
            aspect: [4, 3]
        });

        if (!result.cancelled) {
            if (!groupEntry?.media) {
                groupEntry.media = []
            }
            if (Array.isArray(result.selected)) {
                result.selected.map(r => {
                    reduce(r)
                })
            } else {
                reduce(result)
            }

        }
        setWorking(false)
    };


    const { open } = state;

    // React.useEffect(() => {
    //     console.log("group entry changed", groupEntry)
    // }, [groupEntry])

    React.useEffect(() => {
        if (op.route?.params?.entry) {
            //   console.log("op.route.params.entry", op.route.params.entry.date)
            setGroupEntry({ ...op.route.params.entry })
        }
    }, [op.route?.params?.entry])

    // async function savePost() {
    //     let ref;
    //     const db = getFirestore()
    //     if (groupEntry?.id) {
    //         ref = doc(db, "Posts", groupEntry.id)
    //     } else {
    //         ref = doc(collection(db, "Posts", groupEntry.id))
    //         groupEntry.id = ref.id
    //     }
    //     try {
    //         await setDoc(ref, { groupEntry }, { merge: true })
    //         groupEntry.map(async e => {
    //             if (e.uri) {
    //                 await Global.upload(e, "", () => { }, "")
    //                 console.log("uploaded", e)
    //             }
    //         })
    //     } catch (e) {

    //     }

    // }
    const w = Dimensions.get("window").width / 2
    return <View style={{ flex: 1 }}>
        <ScrollView>
            <View style={{ backgroundColor: "white", alignSelf: "stretch" }}>
                <DImage uri="https://static.vecteezy.com/system/resources/thumbnails/005/318/193/small/refer-a-friend-concept-illustration-idea-for-landing-page-template-marketing-suggestion-as-attraction-method-promotion-recommendation-link-sharing-for-product-offer-hand-drawn-flat-styles-vector.jpg" />
            </View>

            <TextInput
                value={groupEntry?.title}
                style={{ alignSelf: "stretch" }}
                label={"Title"}
                onChangeText={(text) => setGroupEntry(p => ({ ...p, title: text }))}
            />
            <TextInput
                value={groupEntry?.desc}
                style={{ alignSelf: "stretch" }}
                multiline
                numberOfLines={3}
                label={"Content"}
                onChangeText={(text) => setGroupEntry(p => ({ ...p, desc: text }))}
            />
            {groupEntry !== undefined && <DCalendar
                label={"Date"}
                callback={(val, b, dt) => {
                    if (val !== op.dt) {

                        setGroupEntry(p => ({ ...p, date: val }))
                    }
                }}
                current={groupEntry?.date ? groupEntry?.date : dayjs().unix()}
            />}
            <TextInput
                value={groupEntry?.cheif ? groupEntry?.cheif : ""}
                onChangeText={(t) => setGroupEntry(p => ({ ...p, cheif: t }))}
                label={"Chief Guest  (Optional)"} />

            <TextInput
                value={groupEntry?.atten ? groupEntry?.atten : ""}
                onChangeText={(t) => setGroupEntry(p => ({ ...p, atten: t }))}
                label={"Attendees  (Optional)"} />

            <TextInput
                value={groupEntry?.parti ? groupEntry?.parti : ""}
                onChangeText={(t) => setGroupEntry(p => ({ ...p, parti: t }))}
                label={"Participants  (Optional)"} />

            <TextInput
                value={groupEntry?.winner ? groupEntry?.winner : ""}
                onChangeText={(t) => setGroupEntry(p => ({ ...p, winner: t }))}
                label={"Winners (Optional)"} />


            <Text style={{ marginTop: 20 }}>Select Who can see this Post </Text>
            <ScrollView horizontal style={{ maxHeight: 45 }}>
                {Global.VISIBILITY.map((e, iii) =>
                    <Chip style={{ height: 40, margin: 2 }} selected={groupEntry?.type == iii} onPress={() => setGroupEntry(p => ({ ...p, type: iii }))}>{Global.VISIBILITY[iii]}</Chip>
                )}
            </ScrollView>
            <FlatList
                data={groupEntry?.media}
                style={{ flex: 1 }}
                numColumns={2}
                renderItem={(i) => {
                    {
                        if (i.item.saved == 0) {
                            return <View style={{ width: w, height: w, alignItems: "center", justifyContent: "center" }}  >
                                <ActivityIndicator animating />
                            </View>
                        }
                        const toc = i.item?.local ? i.item?.local?.uri : i.item?.content
                        // const isImage = toc?.includes(".jpg") || toc?.includes(".jpeg") || toc?.includes(".png") || toc?.includes(".jfif") || toc?.includes(".gif")
                        const uri = toc?.includes("file:/") ? toc : Global.getPicUrl(toc)

                        return <View>
                            <ScaledImage width={w - 10} height={w} uri={uri} />
                            {(i.item?.title || i.item?.desc) && <View
                                colors={['rgba(0,0,0,1)', 'transparent']}
                                end={{ x: 1, y: 0.5 }}
                                start={{ x: 0, y: 1 }}
                                style={{ width: "100%", position: "absolute", padding: 5, bottom: 0 }}>
                                {i.item?.title && <Text style={{ color: "white" }}>{i.item.title}</Text>}
                                {i.item?.desc && <Text style={{ color: "white" }} variant="labelSmall">{i.item.desc}</Text>}
                            </View>}

                            {groupEntry?.cover && groupEntry?.cover == toc && <IconButton style={{ position: "absolute", top: 0, left: 0, backgroundColor: "green" }} iconColor={"white"} icon={"panorama-variant-outline"} />}

                            <IconButton style={{ position: "absolute", top: 0, right: 0 }} onPress={() => {
                                i.item.isCover = i.item.content == groupEntry?.cover
                                if (!i.item.id) {
                                    i.item.id = "so" + i.index
                                }
                                setPost(i.item)
                            }} icon={"dots-vertical"} />
                        </View>
                    }
                }}
            />
            {working && <View style={{ alignItems: "center", justifyContent: "center", position: "absolute", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)" }}>
                <ActivityIndicator animating />
            </View>}
            <FAB.Group
                open={open}
                icon={'plus'}
                actions={[
                    {
                        icon: 'youtube',
                        label: 'Youtube',
                        onPress: () => console.log('Pressed star'),
                    },
                    {
                        icon: 'file-image-plus-outline',
                        label: 'Image Link',
                        onPress: () => console.log('Pressed email'),
                    },
                    {
                        icon: 'image-multiple',
                        label: 'Gallary Image',
                        onPress: () => pickImage(),
                    },
                    {
                        icon: 'video-outline',
                        label: 'Video & Docx',
                        onPress: () => pickVideo(),
                    },
                ]}
                onStateChange={onStateChange}
                onPress={() => {
                    if (open) {

                    }
                }}
            />
        </ScrollView>
        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent", alignSelf: "stretch" }}>
            <Button style={{ flex: 1 }} onPress={() => op.navigation.goBack()}>Cancel</Button>
            <Button mode="contained" style={{ flex: 1 }} onPress={async () => {
                const db = getFirestore()
                let ref
                const data = { ...groupEntry }
                if (!data.id) {
                    ref = doc(collection(db, "Posts"))
                    data.id = ref.id
                } else {
                    ref = doc(db, "Posts", data.id)
                }
                if (!data?.createdBy) {
                    data.createdBy = pref?.current?.id
                    data.createdByName = pref?.current?.name
                }

                data.stamp = dayjs().unix()
                const medi: [] = []
                groupEntry?.media?.map((e, inn) => {
                    if (e.local?.uri) {
                        const nm = Global.uploadName(e.local, "").name
                        Global.upload(e.local, nm, () => { }, "")
                        e.content = nm
                        delete e.local
                        delete e.saved
                        delete e.id
                        medi.push(e)
                    } else {
                        medi.push(e)
                    }
                })

                try {
                    if (data?.cover) {
                        medi.splice(0, 0, medi.splice(medi.findIndex(f => f.content == data.cover), 1)[0]);
                    }
                } catch (e) { }


                data.media = medi
                data.postedBy = {
                    name: pref.current?.name,
                    id: pref.current?.id,
                    pic: pref.current?.dp ?pref.current?.dp : "",
                }
                try {
                    //    console.log("saving data",data)
                    await setDoc(ref, data, { merge: true })
                    setTimeout(() => Toast.show("Saved"), 500)
                    op.navigation.goBack()
                } catch (e) {
                       console.log("err", e)
                    Toast.show("Error : saving data. Check internet connection")
                }
            }}>Save</Button>
        </View>
        <Modal isVisible={post ? true : false} onBackdropPress={() => setPost(undefined)} style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >
            <View>
                <TextInput
                    value={post?.title ? post?.title : ""}
                    onChangeText={(t) => setPost(p => ({ ...p, title: t }))}
                    label={"Title"} />
                <TextInput
                    value={post?.desc ? post?.desc : ""}
                    onChangeText={(t) => setPost(p => ({ ...p, desc: t }))}
                    label={"Description"} />
                <View style={{ padding: 10, paddingBottom: 40, paddingTop: 20, flexDirection: "row" }}>
                    <Text style={{ marginRight: 10 }}>Is this Cover Image (Optional) ?</Text>
                    <Chip style={{ marginRight: 10 }} selected={post?.isCover} onPress={() => setPost(p => ({ ...p, isCover: true }))} >Yes</Chip>
                    <Chip selected={!post?.isCover} onPress={() => setPost(p => ({ ...p, isCover: false }))} >No</Chip>
                </View>

                <View style={{ flexDirection: "row" }}>
                    <Button style={{ flex: 1 }} onPress={() => setPost(undefined)}>Cancel</Button>
                    <Button style={{ flex: 1 }} onPress={() => deletePost()}>Delete</Button>
                    <Button style={{ flex: 1 }} mode="contained" onPress={() => updatePost()}>Save</Button>
                </View>
            </View>
        </Modal >

    </View >

}