import Global from "../constants/global"
import { Avatar } from 'react-native-paper'
import ScaledImage from "./ScaledImage"
import React, { useEffect, useState } from "react"
//    file: string, title: string, type: number = Global.ROW_DP, style: any, size: number = 64

function DAvatar(op) {
    const [data, setData] = useState({ source: "", msize: 0, tsize: 0, title: "" })
    useEffect(() => {
        const source = (op?.source?.includes("https://") || op?.source?.includes("http://")) ? op.source : op.source ? Global.getPicUrl(op.source) : ""
        const msize = op.type == -1 ? op.size : getSize(op.type)
        const tsize = op?.textSize ? op.textSize : msize
        setData({ source: source, msize: msize, tsize: tsize, title: op.title })
    }, [op])
    if (data.source) {
        return <ScaledImage width={data.msize} height={data.msize} uri={data.source} style={{ borderRadius: data.msize / 2 }} />
    } else {
        return op.icon ?
            <Avatar.Icon icon={op.icon} size={data.msize} />
            :
            <Avatar.Text size={data.tsize} label={data.title?.length > 1 ? op.title.substring(0, 1) : ""} />
    }

}

function getSize(type: number) {
    if (type) {
        if (type == Global.ROW_DP) {
            return 64
        }
        if (type == Global.FORM_DP) {
            return 120
        }
        if (type == Global.FULL_DP) {
            return 240
        }
        if (type == Global.SMALL_DP) {
            return 48
        }
        if (type == Global.BIG_ROW_DP) {
            return 70
        }
    }
    return 48
}
export default React.memo(DAvatar)
