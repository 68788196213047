import { ScrollView, View } from 'react-native';
import { IconButton, Text, useTheme } from "react-native-paper";
import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';

import dayjs from 'dayjs';
import { MaterialIcons } from '@expo/vector-icons';
import Global from '../constants/global';

const totalTag = "Total Students"
const resultTag = "Result (%)"
const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
      page-break-after: always;
    }
  }

  @media print {
    .pagebreak {
      page-break-after: always;
    }
  }
`;

export default function SummarySheet({ navigation, route }) {
    const [value, setValue] = useState({ class: undefined, student: [], subjects: [], exam: undefined, tarij: 0, totalStudents: 0, attend: 0, pass: 0, fail: 0, ranklist: [], left: 0, year: "" })

    const bcol = useTheme().dark ? "transparent" : "#DCDCDC"

    const RollSheet = ({ till, above, marks }) => {
        const russia = marks.filter(e => (e.roll <= till && e.roll >= above) && e?.totalling !== true)
        return <View>
            {russia.length > 0 &&
                <View style={{ alignItems: "center", justifyContent: "center", marginRight: 2 }}>
                    <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid" }}>
                        <Text variant="titleMedium" style={{ backgroundColor: bcol, width: 65, textAlign: "center", borderRightWidth: 1, fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>રોલ નં</Text>
                        <Text variant="titleMedium" style={{ width: 65, textAlign: "center", fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>ગુણ</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        {
                            russia?.map(s => {
                                const able = (s.marks == "Ab" || s.marks == "Left") ? { backgroundColor: bcol } : {}
                                return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderStyle: "solid", height: 33 }}>
                                    <Text variant="titleMedium" style={{ backgroundColor: bcol, width: 65, textAlign: "center", borderRightWidth: 1, paddingTop: 2, paddingBottom: 2 }}>{s?.roll}</Text>
                                    <View style={{ paddingBottom: 4, alignItems: "center", justifyContent: "center", width: 65, ...able }}>
                                        <Text variant="titleMedium" style={{ textAlign: "center", paddingTop: 2, paddingBottom: 2 }}>{s?.marks}</Text>
                                    </View>
                                </View>
                            })
                        }
                    </View>
                </View>
            }
        </View >
    }
    function res(name: string, key: string, subj: any, addIn: any) {
        const fail = { roll: "", name: name }
        const fsubj = JSON.parse(JSON.stringify(subj))
        if (name == resultTag) {
            fsubj.map(e => {
                const got = subj.find(os => os.id == e.id)
                if (got.attend > 0) {
                    e.theory = Math.round((got.pass * 100) / got.attend) + ""
                } else {
                    e.theory = 0
                }
            })
            fail.result = true
        } else {
            if (name == totalTag) {
                fsubj.map(e => {
                    const got = subj.find(os => os.id == e.id)
                    e.theory = got.left + got.absent + got.pending + got.fail + got.pass
                })

            } else {
                fsubj.map(e => {
                    const got = subj.find(os => os.id == e.id)
                    e.theory = got[key]
                })
            }
        }

        fail.consolidated = fsubj
        fail.totalling = true
        addIn.push(fail)
    }

    useEffect(() => {
        if (value.class == undefined) {
            const subj = [...route.params.subjects]
            const stud = [...route.params.student]
            const djs = dayjs.unix(route.params.exam?.createdOn)

            let yr = ""
            if (djs.month() < 6) {
                yr = (djs.year() - 1) + "-" + djs.format("YY")
            } else {
                const y = djs.year()
                const ndjs = djs.add(1, "year")
                yr = y + "-" + ndjs.format("YY")
            }

            setValue({ student: stud, subjects: subj, class: { ...route.params.class }, exam: { ...route.params.exam }, year: yr })
        }
    }, [route.params])

    const ref = useRef()
    const t = useTheme()
    return <ScrollView>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start" }}>
            <IconButton icon={"arrow-left"} onPress={() => navigation.goBack()} />
            <ReactToPrint
                pageStyle={pageStyle}
                trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                content={() => ref.current}
            />
        </View>
        <ScrollView horizontal>
            <div ref={ref} style={{ display: "block", width: "98%", marginLeft: 10, boxSizing: "border-box" }}>
                {value.subjects.map(s => {



                    const allStudents: [] = []

                    value.student.map(student => {
                        const res = student?.marks?.[value.exam.year]?.[value.exam.id]?.[s.id]
                        const std = student?.marks?.[value.exam.year]?.[value.exam.id]?.student
                        let marks = ""
                        let marksi1
                        let marksi2
                        let marksi3
                        let marksi4
                        if (std?.left == true || std?.name.trim().toLowerCase() == "left") {
                            marks = "Left"
                        } else {
                            if (res) {
                                if (res.theory == 'Absent') {
                                    marks = "Ab"
                                } else {
                                    marks = res.internal
                                    marksi1 = res?.i1
                                    marksi2 = res?.i2
                                    marksi3 = res?.i3
                                    marksi4 = res?.i4
                                }
                            }
                        }
                        allStudents.push({ roll: student["class" + value.exam.year]?.roll, marks: marks, i1: marksi1, i2: marksi2, i3: marksi3, i4: marksi4 })
                    })



                    return <div style={{ display: "flex", flexDirection: "column", pageBreakInside: "avoid", flex: 1 }}>
                        <Text style={{ flex: 1, padding: 10, marginTop: 40, backgroundColor: "green", color: "white", textAlign: "center" }} variant="titleLarge">પરીક્ષાનું ગુણપત્રક - {value.exam?.name} {Global.YearString(value.exam?.year)}</Text>
                        <View style={{ flexDirection: "row", marginTop: 20, }}>
                            <Text style={{ flex: 2, fontWeight: "900" }} variant="titleLarge">{value.class?.name}  {value.class?.div}</Text>
                            <Text variant="titleLarge" style={{ textAlign: "center", flex: 2 }}>મૂલ્યાંકનની તારીખ  __________</Text>
                        </View>
                        <View style={{ marginTop: 20, flexDirection: "row" }}>
                            <View style={{ flexDirection: "row", flex: 2 }}>
                                <Text style={{}} variant="titleLarge">વિષય  </Text>
                                <Text style={{ fontWeight: "900", borderBottomWidth: 1 }} variant="titleLarge">{s?.name}  </Text>
                            </View>
                            <View style={{ flexDirection: "row", flex: 1 }}>
                                <Text style={{}} variant="titleLarge">કુલગુણ  </Text>
                                <Text style={{ borderBottomWidth: 1, fontWeight: "900" }} variant="titleLarge">{s?.totalMarks}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 30 }}>
                            <RollSheet till={17} above={0} marks={allStudents} />
                            <RollSheet till={34} above={18} marks={allStudents} />
                            <RollSheet till={51} above={35} marks={allStudents} />
                            <RollSheet till={68} above={52} marks={allStudents} />
                            <RollSheet till={85} above={69} marks={allStudents} />
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 40 }}>
                            <Text variant="titleLarge" style={{ textAlign: "center" }}>તપાસનારની સહી </Text>
                            <Text variant="titleLarge" style={{ flex: 2, textAlign: "center", borderBottomWidth: 1, borderStyle: "solid" }}>{ }</Text>
                            <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>તારીખ</Text>
                            <Text variant="titleLarge" style={{ flex: 1, borderBottomWidth: 1, borderStyle: "solid" }}>{""}</Text>
                        </View>

                        <View style={{ flexDirection: "row", marginTop: 40 }}>
                            <Text variant="titleLarge" style={{ textAlign: "center" }}>વર્ગ શિક્ષકની સહી </Text>
                            <Text variant="titleLarge" style={{ flex: 2, textAlign: "center", borderBottomWidth: 1, borderStyle: "solid" }}>{ }</Text>
                            <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>તારીખ</Text>
                            <Text variant="titleLarge" style={{ flex: 1, borderBottomWidth: 1, borderStyle: "solid" }}>{""}</Text>
                        </View>
                    </div>
                })}
                <div className="pagebreak" />
            </div>

            {/* <Text style={{ flex: 1 }}  >Generated on {dayjs().format("DD/MM/YY HH:mm")}</Text> */}
        </ScrollView>
    </ScrollView >


}