import { useDispatch, useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, StatusBar } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Chip } from 'react-native-paper';

import * as React from 'react';
import DonationRow from '../components/DonationRow';
import { useTheme } from 'react-native-paper';
import NotificationRow from '../components/NotificationRow';
import { listsTask } from '../task/listTasks';
import Prferances from '../constants/Preferances';
import Global from '../constants/global';


let baselist: [] = []
export default function NotiList({ navigation, route }) {
    const bhudevList = useSelector((state) => state.list.Notifications)
    const [alllist, setAllList] = React.useState({ baselist: [] })
    const pref = React.useContext(Prferances)
    React.useEffect(() => {
        const a = []
        baselist = bhudevList.map(e => {
            if (e.flag !== -10) {
                a.push({ ...e })
            }
        })
        const nnewP = a.sort((a, b) => a?.createdOn < b?.createdOn ? 0 : -1)
        setAllList({ baselist: nnewP })
    }, [bhudevList]);
    const isWorkingInSchool = (!pref.current.retired)
        && (pref?.current?.type == Global.PRINCIPAL || pref?.current?.type == Global.TEACHER || pref?.current?.type == Global.ADMIN || pref?.current?.type == Global.STAFF || pref?.current?.type == Global.VISITING_TEACHER)

    const dispatch = useDispatch()

    const { colors } = useTheme();
    return (
        <View style={{ backgroundColor: colors.background, flex: 1, paddingTop: route?.params?.topMargin ? StatusBar.currentHeight : 0 }}>

            <FlashList
                data={alllist.baselist}
                key="id"
                estimatedItemSize={200}
                contentContainerStyle={{ backgroundColor: "transparent" }}
                keyboardShouldPersistTaps={'handled'}
                renderItem={({ item, index }) =>
                    <NotificationRow
                        info={item}
                        navigation={navigation}
                        isWorkingInSchool={isWorkingInSchool}/>
                } />

            {isWorkingInSchool && <FAB
                icon="plus"
                style={{
                    position: 'absolute',
                    margin: 16,
                    right: 0,
                    bottom: 0,
                }}
                onPress={() => {
                    dispatch(listsTask.actions.Alert({ message: "Deleted" }));

                    navigation.navigate("NewNotification")
                }}
            />}
        </View >
    );


}

