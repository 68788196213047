import { View, Image, Dimensions, FlatList } from 'react-native';
import Global from "../constants/global"
import { Button, Card, IconButton, Portal, Dialog, Surface } from 'react-native-paper';

import React, { useState, useRef, useEffect, useContext } from 'react';
import { Text, useTheme } from 'react-native-paper';
import Preferances from '../constants/Preferances'
import PostRow from "./PostRow"
import { useSelector } from 'react-redux';
import { FlashList } from '@shopify/flash-list';


const Posts = React.memo((op) => {
    const posts = useSelector(s => s.list.Posts)
    const [list, setList] = useState([])
    const loading = <View style={{ alignSelf: "stretch" }}><Text style={{ alignSelf: "stretch" }}>Loading...</Text></View>
    const nothing = <View style={{ alignSelf: "stretch" }}><Text style={{ alignSelf: "stretch" }}>No Data found</Text></View>
    const pref = useContext(Preferances)
    useEffect(() => {
        const res = posts.filter(e =>
            e.type == 0
            || (e.type == 1 && (pref?.current?.type == Global.PRINCIPAL || pref?.current?.type == Global.TRUSTEE))
            || (e.type == 2 && (pref?.current?.category == Global.CAT_CORE_TEAM))
            || (e.type == 3 && (pref?.current?.type == Global.TEACHER))
            || (e.type == 4 && (pref?.current?.type.isClassTeacher))
            || (e.type == 5 && (pref?.current?.type == Global.STUDENT))
            || (e.type == 6 && (pref?.current?.type == Global.PARENT))
            || (e.type == 7 && (pref?.current?.type == Global.GUEST))
        )
        const n: [] = []
        res.map(e => {
            n.push({ ...e })
        })
        const n1 = n.sort((a, b) => a.stamp > b.stamp ? -1 : 1)
        setList(n1)
    }, [posts])

    return <View style={{ backgroundColor: "transparent", justifyContent: "center", flex: 1, alignSelf: "stretch" }}>

        <FlashList
            data={list}
            key="id"
            keyboardShouldPersistTaps="always"
            ListEmptyComponent={[] ? nothing : loading}
            estimatedItemSize={500}
            initialNumToRender={3}
            removeClippedSubviews
            style={{ flex: 1 }}
            contentContainerStyle={{ flex: 1 }}
            renderItem={(item) =>
                // <View style={{ marginLeft: Global.isMobile() ? 5 : 0,marginRight: Global.isMobile() ? 5 : 0  }}>
                <PostRow login={pref.current} navigation={op.navigation} row={{ ...item.item }} />
                // </View>
            } />
    </View>

})
export default Posts


// interface detail {
//     row: ActivityDataExt
//     currentSession: any
//     //    size: number
//     call(): () => void
//     callback(): void
//     index: number
//     edit(): void
//     del(): void
//     L: any
// }

// const MActivityRow = (op: detail) => {
//     const L = useContext(Language)
//     let views = 0
//     const [showDelete, setDelete] = React.useState(false)
//     const [zoom, setZoom] = React.useState({ title: "", desc: "", zoom: false, source: "", type: "IMAGE" })
//     try {
//         views = [...new Set(op.row?.comments?.map(item => item.person_id))].length
//     } catch (e) { }
//     let m = moment.unix(op.row.stamp).startOf('day')
//     const diff = moment().diff(m, 'days')
//     const ago = m.format("DD-MMMM") + (diff == 0 ? ", Today" : diff > 0 ? ", " + diff + " days ago" : "")
//     let bes = ""
//     if (op.row.type == Global.DEATH && op.row.basnu) {
//         bes = moment(op.row.basnu).format("DD/MMM")
//     }
//     let dpic = ""
//     if (op.row?.pic) {
//         dpic = Global.getPicUrl(op.row?.pic)
//     } else {
//         dpic = Asset.fromModule(getDefPic(op.row.type)).uri
//     }
//     const navigation = useNavigation()
//     const { colors } = useTheme()
//     const imgs = []
//     let cover
//     if (op.row?.pics?.length > 0) {
//         cover = op.row?.pics[0]
//         op.row?.pics?.map((e, i) => {
//             if (i > 0) {
//                 imgs.push(e)
//             }
//         })
//     }
//     let cols = 2
//     if (imgs.length == 1) {
//         cols = 1
//     } else if (imgs.length == 2) {
//         cols == 2
//     } else if (imgs.length == 3) {
//         cols == 3
//     } else {
//         cols = imgs.length % 2 == 0 ? 2 : 3
//     }
//     imgs.length % 2 == 0 ? 2 : 3
//     //    const [slides, setSlides] = useState({ activeSlide: 0, length: 0 });

//     function element(src: string, title: string, desc: string) {
//         return <Card mode="elevated" >
//             <Pressable onPress={() => setZoom({ title: title, desc: desc, zoom: true, source: Global.getPicUrl(src), type: "IMAGE" })}>
//                 <Card.Cover source={{ uri: Global.getPicUrl(src) }} />
//             </Pressable>
//             <View style={{ backgroundColor: "rgba(0,0,0,0.8)", alignSelf: "stretch", position: "absolute", bottom: 0, width: "100%" }}>
//                 {title ? <Text variant="titleMedium" numberOfLines={1} style={{ paddingLeft: 15, color: "white" }}>{title}</Text> : <></>}
//                 {desc ? <Text variant="bodySmall" numberOfLines={1} style={{ paddingLeft: 15, paddingBottom: 5, color: "white" }}>{desc}</Text> : <></>}
//             </View>
//         </Card>
//     }
//     return <Card style={{ alignSelf: "stretch", flex: 1, margin: Dimensions.get("window").width < 600 ? 0 : 10, marginBottom: 25 }} elevation={5} mode="elevated" >
//         <Portal>
//             <Dialog style={{ backgroundColor: colors.background }} visible={showDelete} onDismiss={() => setDelete(false)}>
//                 <Dialog.Title>Delete ?</Dialog.Title>
//                 <Dialog.Content>
//                     <Text>{L.lang.DELETE_ACT}</Text>
//                     <Text variant="headlineSmall">{op.row.title}</Text>
//                     <Text>{op.row.message}</Text>
//                 </Dialog.Content>
//                 <View style={{ justifyContent: "space-evenly", flexDirection: "row", padding: 10 }}>
//                     <Button mode='contained' onPress={() => { setDelete(false) }}>{L.lang.NO_CANCEL}</Button>
//                     <Button onPress={() => {
//                         setDelete(false);
//                         Toast.show("Post will be deleted soon")
//                         setTimeout(() => {
//                             const db = getFirestore();
//                             const docRef = doc(db, "List", op.row.id)
//                             setDoc(docRef, { flag: -10 }, { merge: true }).then(() => {
//                                 Toast.show("Deleted")
//                             }).catch(e => {
//                                 Toast.show("Error")
//                             })
//                         })

//                     }}>{L.lang.DELETE_CONF} </Button>
//                 </View>
//             </Dialog>
//         </Portal>

//         <View>
//             {op.row?.pics ?
//                 <View>
//                     {element(cover.pic, cover.title, cover.desc)}
//                     <MasonryList
//                         data={imgs}
//                         keyExtractor={(item): string => item.pic}
//                         numColumns={cols}
//                         renderItem={(src) => element(src.item.pic, src.item.title, src.item.desc)}
//                         showsVerticalScrollIndicator={false}
//                     />
//                     {/*<Carousel
//                         data={row.pics}
//                         autoplay
//                         loop
//                         shouldOptimizeUpdates
//                         renderItem={(src) => {
//                             return <Card mode="elevated"  >
//                                 <Pressable onPress={() => setZoom({ zoom: true, source: Global.getPicUrl(src.item.pic), type: "IMAGE" })}>
//                                     <Card.Cover source={{ uri: Global.getPicUrl(src.item.pic) }} />
//                                 </Pressable>
//                                 <View style={{ backgroundColor: "rgba(0,0,0,0.8)", alignSelf: "stretch" }}>
//                                     {src.item.title ? <Text variant="headlineSmall" style={{ paddingLeft: 15, color: "white" }}>{src.item.title}</Text> : <></>}
//                                     {src.item.desc ? <Text style={{ paddingLeft: 15, paddingBottom: 5, color: "white" }}>{src.item.desc}</Text> : <></>}
//                                 </View>
//                             </Card>
//                         }}
//                         onSnapToItem={(index) => setSlides({ activeSlide: index, length: row.pics.length })}
//                         sliderWidth={Dimensions.get("window").width}
//                         itemWidth={Dimensions.get("window").width - 60}
//                     />*/}
//                     {/* <Text variant='titleSmall' style={{ position: "absolute", right: 2, top: 0 }} > {slides.activeSlide + 1}/{slides.length}</Text> */}
//                     {/*<Pagination
//                         dotsLength={slides.length}
//                         activeDotIndex={slides.activeSlide}
//                         dotStyle={{
//                             width: 10,
//                             height: 10,
//                             borderRadius: 5,
//                             backgroundColor: colors.onTertiaryContainer
//                         }}
//                         inactiveDotStyle={{
//                             backgroundColor: colors.tertiary
//                         }}
//                         inactiveDotOpacity={0.4}
//                         inactiveDotScale={0.6}
//                     />*/}
//                 </View>
//                 :
//                 <Pressable onPress={() => setZoom({ zoom: true, source: dpic, type: "IMAGE" })}>
//                     <DattImage
//                         source={dpic}
//                         id={op.row.pic}
//                     />
//                 </Pressable>
//             }


//             {
//                 Global?.CURRENT_USER?.id ?
//                     <View style={{ alignItems: "center", alignSelf: "stretch", flex: 1, flexDirection: "row", backgroundColor: colors.background, width: "100%" }}>
//                         <Card mode="elevated" style={{ shadowColor: "transparent", flex: 1 }} onPress={() => navigation.navigate("Comment", { activity_id: op.row.id })} >
//                             <View style={{ alignSelf: "stretch", flexDirection: "row", alignItems: "center", flex: 1, justifyContent: "center" }}>
//                                 <IconButton icon="comment-multiple-outline" />
//                                 <Text>{op.row.comments.length}</Text>
//                             </View>
//                         </Card>
//                         {
//                             Global.CURRENT_USER.id == op.row.postedby ?
//                                 <Card mode="elevated" style={{ shadowColor: "transparent", flex: 1 }} onPress={() => navigation.navigate("Activity", { Data: op.row })} >
//                                     <View style={{ alignSelf: "stretch", alignItems: "center", justifyContent: "center", flex: 1 }}>
//                                         <IconButton icon="pencil-outline" />
//                                     </View>
//                                 </Card>
//                                 : <></>}
//                         {
//                             Global.CURRENT_USER.id == op.row.postedby ?
//                                 <Card mode="elevated" style={{ shadowColor: "transparent", flex: 1 }} onPress={() => setDelete(true)} >
//                                     <View style={{ alignSelf: "stretch", alignItems: "center", justifyContent: "center", flex: 1 }}>
//                                         <IconButton icon="delete-outline" />
//                                     </View>
//                                 </Card>
//                                 : <></>}

//                     </View> : <></>
//             }
//         </View >

//         <View style={{ paddingLeft: 5, paddingRight: 5 }}>
//             <Text variant="headlineSmall">{op.row.title}</Text>
//             <Text>{op.row?.message?.length > 350 ? op.row?.message?.substring(0, 350) : op.row?.message} </Text>
//             {
//                 op.row.type == Global.DEATH ?
//                     <View>
//                         <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
//                             <Text style={{ textAlign: "center", color: "red", textDecorationLine: "underline", marginRight: 5 }}>{L.lang.BESNU}</Text>
//                             <FontAwesome5 name="sad-tear" color="red" />
//                         </View>
//                         {op.row.basnu ? <Text style={{ fontStyle: "italic" }}>Date :{op.row.besnu}</Text> : <></>}
//                         {op.row.address ? <Text style={{ fontStyle: "italic" }}>Place :{op.row.address}</Text> : <></>}
//                         {op.row.contact ? <Text style={{ fontStyle: "italic" }}>Contact :{op.row.contact}</Text> : <></>}
//                         {op.row.smessage ? <Text style={{}}>{op.row.smessage}</Text> : <></>}
//                     </View>
//                     : <></>
//             }
//             <View style={{ alignItems: "center", justifyContent: "space-between", flexDirection: "row", marginBottom: 5 }} >
//                 <Text variant="labelSmall" style={{ fontStyle: "italic" }}>
//                     {ago} by {op.row.posted_for1_name ? op.row.posted_for1_name : op.row.postedby_nm}
//                 </Text>
//             </View>
//         </View>
//         <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} visible={zoom.zoom} onDismiss={() => setZoom({ zoom: false, source: "", type: "IMAGE" })} animationType="fade" transparent>
//             {
//                 zoom.type == "IMAGE" ?
//                     <Pressable onPress={() => { setZoom({ zoom: false, source: "", type: "IMAGE" }) }}>
//                         <View style={{ backgroundColor: "rgba(0,0,0,0.9)", height: Dimensions.get("window").height, width: Dimensions.get("window").width, alignItems: "center", justifyContent: "center" }}>
//                             {/* <GallerySwiper
//                             onSingleTapConfirmed={() => { setZoom({ zoom: false, source: "", type: "IMAGE" }) }}
//                             images={[{ uri: zoom.source }]}
//                         /> */}
//                             <DImage source={{ uri: zoom.source }} style={{ width: Dimensions.get("window").width, aspectRatio: 3 / 2 }} />
//                             <View style={{ backgroundColor: "rgba(0,0,0,0.8)", alignSelf: "stretch" }}>
//                                 {zoom.title ? <Text variant="titleMedium" style={{ paddingLeft: 15, color: "white" }}>{zoom.title}</Text> : <></>}
//                                 {zoom.desc ? <Text variant="bodySmall" style={{ paddingLeft: 15, paddingBottom: 5, color: "white" }}>{zoom.desc}</Text> : <></>}
//                             </View>

//                         </View>
//                     </Pressable>
//                     :
//                     <View style={{ backgroundColor: "rgba(0,0,0,0.9)", height: Dimensions.get("window").height, flex: 1, alignSelf: "stretch" }}>
//                         <Video
//                             source={{ uri: zoom.source }}
//                             isMuted={false}
//                             shouldPlay
//                             contentFit='contain'
//                             useNativeControls
//                             isLooping
//                             style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height }}
//                         />
//                         <IconButton style={{ position: "absolute", top: 0, right: 0 }} iconColor={"dimgray"} icon="close" size={24} onPress={() => setZoom({ zoom: false, source: "", type: "IMAGE" })} />

//                     </View>
//             }
//         </Modal>

//     </Card >
// }
// const ActivityRow = React.memo(MActivityRow)
// export default ActivityRow
