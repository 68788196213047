import { ScrollView, View } from 'react-native';
import { IconButton, Text, useTheme } from "react-native-paper";
import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';

import dayjs from 'dayjs';
import { MaterialIcons } from '@expo/vector-icons';

const totalTag = "Total Students"
const resultTag = "Result (%)"
const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
      page-break-after: always;
    }
  }

  @media print {
    .pagebreak {
      page-break-after: always;
    }
  }
`;

export default function SummarySheet({ navigation, route }) {
    const [value, setValue] = useState({ class: undefined, student: [], subjects: [], exam: undefined, tarij: 0, totalStudents: 0, attend: 0, pass: 0, fail: 0, ranklist: [], left: 0, year: "" })


    const RollSheet = ({ till, above, subject }) => {
        const russia = value.student.filter(e => (e.roll <= till && e.roll >= above) && e.totalling !== true)
        return <View>
            {russia.length > 0 &&
                <View style={{ alignItems: "center", justifyContent: "center", marginRight: 2 }}>
                    <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid" }}>
                        <Text variant="titleMedium" style={{ backgroundColor: "#DCDCDC", width: 65, textAlign: "center", borderRightWidth: 1, fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>રોલ નં</Text>
                        <Text variant="titleMedium" style={{ width: 65, textAlign: "center", fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>ગુણ</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        {
                            russia.map(s => {
                                const re = s.consolidated.find(f => f.id == subject.id)
                                const fal = re.subject_status == "Fail" ? { borderBottomWidth: 1, color: "red" } : {}
                                const able = (re.subject_status == "Absent" || re.isLeft) ? { backgroundColor: "#DCDCDC" } : {}
                                return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderStyle: "solid", height: 33 }}>
                                    <Text variant="titleMedium" style={{ backgroundColor: "#DCDCDC", width: 65, textAlign: "center", borderRightWidth: 1, paddingTop: 2, paddingBottom: 2 }}>{s.roll}</Text>
                                    <View style={{ paddingBottom: 4, alignItems: "center", justifyContent: "center", width: 65, ...able }}>
                                        <Text variant="titleMedium" style={{ textAlign: "center", paddingTop: 2, paddingBottom: 2, ...fal }}>{s.name.toLowerCase().trim() == "left" ? "Left" : re.theory}</Text>
                                    </View>
                                </View>
                            })
                        }
                    </View>
                </View>
            }
        </View >
    }
    function res(name: string, key: string, subj: any, addIn: any) {
        const fail = { roll: "", name: name }
        const fsubj = JSON.parse(JSON.stringify(subj))
        if (name == resultTag) {
            fsubj.map(e => {
                const got = subj.find(os => os.id == e.id)
                if (got.attend > 0) {
                    e.theory = Math.round((got.pass * 100) / got.attend) + ""
                } else {
                    e.theory = 0
                }
            })
            fail.result = true
        } else {
            if (name == totalTag) {
                fsubj.map(e => {
                    const got = subj.find(os => os.id == e.id)
                    e.theory = got.left + got.absent + got.pending + got.fail + got.pass
                })

            } else {
                fsubj.map(e => {
                    const got = subj.find(os => os.id == e.id)
                    e.theory = got[key]
                })
            }
        }

        fail.consolidated = fsubj
        fail.totalling = true
        addIn.push(fail)
    }

    useEffect(() => {
        if (value.class == undefined) {
            const subj = [...route.params.subjects]
            const stud = [...route.params.student]
            let consAttent = 0
            let tstudent = route.params.student.length
            subj.map((s, i) => {
                let attend = 0
                let pass = 0
                let fail = 0
                let left = 0
                let total = 0
                let absent = 0
                let notprepared = 0
                stud.map(student => {
                    const got = student.consolidated.find(cons => cons.id == s.id)

                    if (got) {
                        got.seq = i
                        total++
                        if (got.subject_status == "Absent") {
                            absent++
                        } else {
                            if (student.name.toLowerCase().trim() == "left") {
                                left++
                            } else {
                                if (got.subject_status == "Pending") {
                                    notprepared++
                                } else {
                                    if (got.subject_status == "Fail") {
                                        fail++
                                        attend++
                                    }
                                    if (got.subject_status == "Pass") {
                                        pass++
                                        attend++
                                    }
                                }
                            }
                        }
                    } else {
                        student.consolidated.push({ ...s, seq: i })
                    }

                })
                s.attend = attend
                s.pass = pass
                s.fail = fail
                s.left = left
                s.total = total
                s.absent = absent
                s.pending = notprepared
                if (attend > consAttent) {
                    consAttent = attend
                }
            })
            res("Left", "left", subj, stud)
            res("Absent", "absent", subj, stud)
            res("Pending", "pending", subj, stud)
            res("Fail", "fail", subj, stud)
            res("Attended", "attend", subj, stud)
            res("Pass", "pass", subj, stud)
            res(totalTag, "", subj, stud)
            res(resultTag, "", subj, stud)

            stud.map(e => {
                const sorted = e.consolidated.sort((a, b) => a.seq - b.seq)
                e.consolidated = sorted
            })
            const ranklist = stud.filter(e => e.rank && e.rank <= 10)?.sort((a, b) => a.rank - b.rank)
            const pass = stud.filter(e => e.status == "Pass")?.length
            const fail = stud.filter(e => e.status == "Fail")?.length
            const left = stud.filter(e => e.name?.toLowerCase()?.trim() == "left" && e?.totalling !== true)?.length

            const djs = dayjs.unix(route.params.exam?.createdOn)

            let yr = ""
            if (djs.month() < 6) {
                yr = (djs.year() - 1) + "-" + djs.format("YY")
            } else {
                const y = djs.year()
                const ndjs = djs.add(1, "year")
                yr = y + "-" + ndjs.format("YY")
            }
            setValue({ subjects: subj, class: { ...route.params.class }, student: stud, exam: { ...route.params.exam }, tarij: route.params.tarij, totalStudents: tstudent, attend: consAttent, pass: pass, fail: fail, ranklist: ranklist, left: left, year: yr })
        }
    }, [route.params])

    const ref = useRef()
    const Row1 = ({ roll, gr, name, title }) => {
        const tt = title ? {} : { fontSize: 10 }
        return <><div style={{ textAlign: "center", display: "flex", width: 30, maxWidth: 30, borderWidth: 1, borderStyle: "solid" }}>{roll}</div>
            <div style={{ width: 40, maxWidth: 40, borderWidth: 1, borderStyle: "solid", textAlign: "center" }}>{gr}</div>
            <div style={{ width: 150, maxWidth: 150, borderWidth: 1, borderStyle: "solid", ...tt }}>{name}</div></>
    }

    const Row2 = ({ value, title }) => {
        const tt = title ? { fontSize: 10 } : {}
        return <div style={{ width: 45, maxWidth: 45, borderWidth: 1, borderStyle: "solid", justifyContent: "center", alignItems: "center" }}>
            <div variant={"titleSmall"} style={{ textAlign: "center", ...tt }}> {value}</div>
        </div>
    }

    const Row3 = ({ v1, v2, v3 }) => {
        return <><div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 60, borderWidth: 1, borderStyle: "solid", textAlign: "center", fontWeight: "900" }}>{v1}</div>
            <div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 55, borderWidth: 1, borderStyle: "solid", textAlign: "center", fontWeight: "900" }}>{v2}</div>
            <div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 55, borderWidth: 1, borderStyle: "solid", textAlign: "center", fontWeight: "900" }}>{v3}</div></>
    }
    const t = useTheme()
    return <ScrollView>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start" }}>
            <IconButton icon={"arrow-left"} onPress={() => navigation.goBack()} />
            <ReactToPrint
                pageStyle={pageStyle}
                trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                content={() => ref.current}
            />
        </View>
        <ScrollView horizontal>
            <div ref={ref} style={{ display: "block", width: "98%", marginLeft: 10, boxSizing: "border-box" }}>
                {value.tarij == 1 ? <style type="text/css" media="print">{"@page { size: landscape, margin: 25mm 25mm 25mm 25mm}"}</style> :
                    <style type="text/css" media="print">{"@page { size: portrait}"}</style>
                }
                {value.tarij == 0 &&
                    <div style={{ marginLeft: 100, display: "block", marginRight: 30 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Text style={{ flex: 1, padding: 10, marginTop: 10, backgroundColor: "green", color: "white", textAlign: "center" }} variant="titleLarge">પરિણામ પત્રક - {value.exam?.name}</Text>
                            <View style={{ flexDirection: "row" }}>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1, }}>
                                    <Text variant="titleLarge">{value.class?.name}</Text>
                                </View>
                                {value.class?.div && <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1, }}>
                                    <Text variant="titleLarge">વર્ગ {value.class?.div}</Text>
                                </View>}
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1 }}>
                                    <Text variant="titleLarge">વર્ષ {value.year}</Text>
                                </View>
                            </View>
                            <View style={{ padding: 2, borderWidth: 2, marginTop: 20 }}>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">વિદ્યાર્થીઓની કુલ સંખ્યા</Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{(value.totalStudents - value.left)}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">પરીક્ષામાં બેઠેલા વિદ્યાર્થીઓની સંખ્યા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value.attend}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">ઉત્તીર્ણ થનાર વિદ્યાર્થીઓની સંખ્યા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value.pass}</Text>
                                </View>
                                {/* <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                        <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">ઉપલા વર્ગમાં ચઢાવેલ વિદ્યાર્થીઓની સંખ્યા </Text>
                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value.student.length}</Text>
                    </View> */}
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">નાપાસ થનાર વિદ્યાર્થીઓની સંખ્યા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{value.fail}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">વિદ્યાર્થીઓની કુલ સંખ્યા પર પરિણામના ટકા </Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{Math.round((value.pass * 100) / (value.totalStudents - value.left))}%</Text>
                                </View>

                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ flex: 4, borderRightWidth: 1, borderStyle: "solid" }} variant="titleMedium">પરીક્ષામાં બેઠેલ વિદ્યાર્થીઓની સંખ્યા પર પરિણામના ટકા</Text>
                                    <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{Math.round((value.pass * 100) / value.attend)}%</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 30, flex: 1, alignSelf: "stretch" }}>
                                <View style={{ alignItems: "center", justifyContent: "center", flex: 1, alignSelf: "stretch" }}>
                                    <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid", backgroundColor: "#DCDCDC" }}>
                                        <Text style={{ flex: 150, flexShrink: 0, textAlign: "center", borderRightWidth: 1 }}>વિષય </Text>
                                        <Text style={{ flex: 150, textAlign: "center", borderRightWidth: 1 }}>વિષયશિક્ષક</Text>
                                        <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>પરીક્ષામાં બેઠેલ સંખ્યા</Text>
                                        <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>ઉતીર્ણ સંખ્યા</Text>
                                        <Text style={{ flex: 50, textAlign: "center" }}>ટકાવારી</Text>
                                    </View>
                                    <View style={{ alignSelf: "stretch", flex: 1 }}>
                                        {
                                            value.subjects.map(s => {
                                                return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderStyle: "solid" }}>
                                                    <Text style={{ flexShrink: 0, flex: 150, borderRightWidth: 1 }}>{s.name}</Text>
                                                    <Text style={{ flex: 150, borderRightWidth: 1 }}>{s.teacherName}</Text>
                                                    <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>{s.attend}</Text>
                                                    <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>{s.pass}</Text>
                                                    <Text style={{ flex: 50, textAlign: "center" }}>{Math.round((s.pass * 100) / s.attend)} %</Text>
                                                </View>
                                            })
                                        }
                                    </View>
                                </View>
                            </View>
                            <View style={{ alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid", backgroundColor: "#DCDCDC" }}>
                                    <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>ક્રમાંક નં</Text>
                                    <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>રોલ નં</Text>
                                    <Text style={{ flex: 300, textAlign: "center", borderRightWidth: 1 }}>વિદ્યાર્થીનું નામ</Text>
                                    <Text style={{ flex: 80, textAlign: "center", borderRightWidth: 1 }}>મેળવેલ ગુણ</Text>
                                    <Text style={{ flex: 80, textAlign: "center" }}>ટકાવારી (%)</Text>
                                </View>
                                <View style={{ alignSelf: "stretch", flex: 1 }}>
                                    {
                                        value.ranklist.map((s) => {
                                            console.log("summsheet", s)
                                            return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid" }}>
                                                <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>{s.rank}</Text>
                                                <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>{s.roll}</Text>
                                                <Text style={{ flex: 300, borderRightWidth: 1 }}>{s.name}</Text>
                                                <Text style={{ flex: 80, textAlign: "center", borderRightWidth: 1 }}>{s.totalGained}</Text>
                                                <Text style={{ flex: 80, textAlign: "center" }}>{Math.round((s.totalGained * 100) / s.grandTotal)} %</Text>
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 70 }}>
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>  વર્ગ શિક્ષક  </Text>
                                </View>
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>  તારીખ  </Text>
                                </View>
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>  આચાર્ય  </Text>
                                </View>
                            </View>
                        </div>
                        <div className="pagebreak" />
                    </div>
                }
                {value.tarij == 1 &&
                    <View style={{ flexDirection: "row", marginTop: 20 }}>
                        <Text variant="titleLarge" style={{ textAlign: "center", flex: 1 }} >Summary Sheet - {value.exam?.name}</Text>
                        <Text variant="titleLarge" style={{ flex: 1, marginLeft: 30, marginRight: 30 }}>{value.class?.name} {value.class?.div}</Text>
                    </View>
                }
                {value.tarij == 1 && <ScrollView>
                    <table style={{ paddingLeft: 60, width: "100%", paddingRight: 20 }}>
                        <thead style={{ padding: 0, margin: 0 }}>
                            <div style={{ display: "flex", pageBreakInside: "avoid", flexDirection: "row", marginTop: 30 }}>
                                <Row1 roll={"રોલ"} gr={"GR"} name={"વિદ્યાર્થી નું નામ"} />
                                {
                                    value.subjects.map(e => {
                                        return <Row2 value={e.name} title={true} />
                                    })
                                }
                                <Row3 v1={"પરિણામ"} v2={"મેળવેલ ગુણ"} v3={"Rank"} />
                            </div>
                        </thead>
                        <tbody style={{ padding: 0, margin: 0 }}>
                            {
                                value.student.filter(e => e?.totalling !== true).map(s => {

                                    return <div style={{ display: "flex", pageBreakInside: "avoid", flexDirection: "row" }}>
                                        <Row1 roll={s.roll} gr={s.gr} name={s.name} title={false} />

                                        {
                                            s.consolidated.map(e => {
                                                return <Row2 value={e.theory == "Absent" ? "Ab" : e.theory} title={false} />

                                            })
                                        }
                                        <Row3 v1={s.status} v2={s.totalGained} v3={s.rank} />
                                    </div>
                                })

                            }
                        </tbody>
                    </table>
                </ScrollView>}
                {value.tarij == 2 && <div style={{ paddingLeft: 80, paddingRight: 40, display: "block" }}>
                    {value.subjects.map(s => {

                        //  let fail = 0
                        //  let pass = 0
                        //  let ab = 0
                        //  console.log("subjects",s)
                        //  s.students.map(ss => {
                        //      console.log("student",s)
                        //      ss.consolidated.map(e => {
                        //          if (e.totalling !== true) {
                        //              fail = fail + (e.status == "Fail" ? 1 : 0)
                        //              pass = pass + (e.status == "Pass" ? 1 : 0)
                        //              ab = ab + (e.status == "Absent" ? 1 : 0)
                        //          }
                        //      })
                        //  })



                        return <div style={{ display: "flex", flexDirection: "column", pageBreakInside: "avoid", flex: 1 }}>
                            <Text style={{ flex: 1, padding: 10, marginTop: 40, backgroundColor: "green", color: "white", textAlign: "center" }} variant="titleLarge">પરીક્ષાનું ગુણપત્રક - {value.exam?.name}</Text>
                            <View style={{ flexDirection: "row", marginTop: 20, }}>
                                <Text style={{ flex: 2, fontWeight: "900" }} variant="titleLarge">{value.class?.name}  {value.class?.div}</Text>
                                <Text variant="titleLarge" style={{ textAlign: "center", flex: 2 }}>પરીક્ષાની તારીખ  __________</Text>
                            </View>
                            <View style={{ marginTop: 20, flexDirection: "row" }}>
                                <View style={{ flexDirection: "row", flex: 2 }}>
                                    <Text style={{}} variant="titleLarge">વિષય  </Text>
                                    <Text style={{ fontWeight: "900", borderBottomWidth: 1 }} variant="titleLarge">{s?.name}  </Text>
                                </View>
                                <View style={{ flexDirection: "row", flex: 1 }}>
                                    <Text style={{}} variant="titleLarge">કુલગુણ  </Text>
                                    <Text style={{ borderBottomWidth: 1, fontWeight: "900" }} variant="titleLarge">{s?.totalMarks}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 30 }}>
                                <RollSheet till={17} above={0} subject={s} />
                                <RollSheet till={34} above={18} subject={s} />
                                <RollSheet till={51} above={35} subject={s} />
                                <RollSheet till={68} above={52} subject={s} />
                                <RollSheet till={85} above={69} subject={s} />
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 10 }}>
                                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.pass}</Text>
                                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>પાસ</Text>
                                </View>
                                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.fail}</Text>
                                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>નાપાસ </Text>
                                </View>
                                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.absent}</Text>
                                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>Absent</Text>
                                </View>
                                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{s.total}</Text>
                                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>કુલ</Text>
                                </View>
                                <View style={{ justifyContent: "center", alignItems: "center", margin: 5, flex: 1 }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}>{Math.round((s.pass * 100) / (s.attend))}%</Text>
                                    <Text variant="titleLarge" style={{ textAlign: "center", borderTopWidth: 1 }}>ટકાવારી</Text>
                                </View>
                            </View>

                            <View style={{ flexDirection: "row", marginTop: 40 }}>
                                <Text variant="titleLarge" style={{ textAlign: "center" }}>તપાસનારની સહી </Text>
                                <Text variant="titleLarge" style={{ flex: 2, textAlign: "center", borderBottomWidth: 1, borderStyle: "solid" }}>{ }</Text>
                                <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>તારીખ</Text>
                                <Text variant="titleLarge" style={{ flex: 1, borderBottomWidth: 1, borderStyle: "solid" }}>{""}</Text>
                            </View>

                            <View style={{ flexDirection: "row", marginTop: 40 }}>
                                <Text variant="titleLarge" style={{ textAlign: "center" }}>વર્ગ શિક્ષકની સહી </Text>
                                <Text variant="titleLarge" style={{ flex: 2, textAlign: "center", borderBottomWidth: 1, borderStyle: "solid" }}>{ }</Text>
                                <Text variant="titleLarge" style={{ flex: 1, textAlign: "center" }}>તારીખ</Text>
                                <Text variant="titleLarge" style={{ flex: 1, borderBottomWidth: 1, borderStyle: "solid" }}>{""}</Text>
                            </View>
                        </div>
                    })}
                    <div className="pagebreak" />
                </div>}

            </div>

            {/* <Text style={{ flex: 1 }}  >Generated on {dayjs().format("DD/MM/YY HH:mm")}</Text> */}
        </ScrollView>
    </ScrollView >


}