import React, { memo, useEffect } from "react";

import { View } from "react-native";
import { Divider, Text } from "react-native-paper";
import Global from "../constants/global";


const MStudentPlainRow = (op) => {
    const [data, setData] = React.useState(op.info);
    const [withGr, setWithGr] = React.useState(true);
    const [stream, setWithStream] = React.useState(false);


    useEffect(() => {
        setData(op.info)
        setWithGr(op.withGr)
        if (op.withStream) {
            setWithStream(op.withStream)
        }
    }, [op.info])
    const l = data?.left == true ? { textDecorationLine: 'line-through', textDecorationStyle: 'solid' } : {}
    if (op.dark){
        l.color = "black"
    }
    return < View >
        <View style={{ flexDirection: "row" }}>
            <Text style={{ width: 50, textAlign: "center" }} variant="headlineSmall">{data?.["class" + op.currentYear]?.roll}</Text>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                    <Text style={l} variant="headlineSmall">{data?.name}</Text>
                    {data?.left == true && <Text style={{ color: "red" }}>(Left)</Text>}
                </View>
                <View style={{ flexDirection: "row" }}>
                    {withGr && <Text>{data?.["class" + op.currentYear]?.gr}</Text>}
                    {stream && data?.["class" + op.currentYear]?.stream && <Text>   ({Global.STREAM[data["class" + op.currentYear]?.stream]})</Text>}
                </View>
            </View>
            {!op?.hideDown && <Text style={{ paddingLeft: 12, paddingRight: 13, fontSize: 18 }}>⌵</Text>}
        </View>
        {op.nodivider == true ? <></> : <Divider />}
    </View >

}
const StudentPlainRow = memo(MStudentPlainRow)
export default StudentPlainRow