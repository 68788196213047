import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Share, Alert } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import { collection, doc, getFirestore, increment, setDoc, updateDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import Prferances from '../constants/Preferances';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Global from "../constants/global";
import DCalendar from "../components/DCalendar";
import DImage from "../components/DImage";
import DDate from "../components/DDate";

let baselist: [] = []
let cy = Global.getYear()
const ccy = "class" + cy
export default function NewStudent({ navigation, route }) {
    // const translate = require('google-translate-api');
    const [focus, setFocus] = React.useState({})
    const [working, setWorking] = React.useState(false)
    const [classList, setClassList] = React.useState([])
    const classes = useSelector((state) => state.list.Classes)
    React.useEffect(() => {
        if (classes) {
            const s: [] = []
            classes.map(p => {
                if (p.year == cy) {
                    s.push({ ...p })
                }
            })
            const nnewP = s.sort((a, b) => a?.seq < b?.seq ? -1 : 0)
            setClassList(nnewP)
        }
    }, [classes])
    // const users = useSelector(s => s.list.Users)
    const { colors } = useTheme();
    const pref = React.useContext(Prferances)
    React.useEffect(() => {
        if (route?.params?.data) {
            const data = route?.params?.data
            if (!data[ccy]) {
                const ab = {
                    class: route?.params?.class ? route?.params?.class : "",
                    classId: route?.params?.classId ? route?.params?.classId : "",
                }
                data[ccy] = ab
            }
            console.log("data", data)
            setFocus(data)

        }
    }, [route.params])
    React.useEffect(() => {
        console.log(focus)
    }, [focus])

    const rf = React.useRef()
    return (
        <ScrollView style={{ flex: 1 }}>

            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                <View style={{ backgroundColor: "white", alignSelf: "stretch" }}>
                    <DImage width={170} uri="https://i.pinimg.com/564x/d2/57/67/d257676b21bfdda5ca88d3b7e4b54d87.jpg" />
                </View>

                <TextInput ref={rf} autoFocus label="Name" value={focus?.name ? focus?.name : ""} onChangeText={(t) => setFocus(p => ({ ...p, name: t }))} />
                <TextInput keyboardType="number-pad" label="Roll No" value={focus[ccy]?.roll ? focus[ccy]?.roll + "" : ""} onChangeText={(t) => {
                    const rl = parseInt(t)
                    if (!Number.isNaN(rl)) {
                        const f = { ...focus[ccy] }
                        f.roll = rl
                        const f1 = {}
                        f1[ccy] = f
                        setFocus(p => ({ ...p, ...f1 }))
                    }
                }
                } />
                <TextInput label="GR No." value={focus[ccy]?.gr ? focus[ccy]?.gr : ""} onChangeText={(t) => {
                    const f = { ...focus[ccy] }
                    f.gr = t
                    const f1 = {}
                    f1[ccy] = f
                    setFocus(p => ({ ...p, ...f1 }))
                }} />
                <View style={{ paddingTop: 7, paddingBottom: 7 }}>
                    <DDate label="Birth date"
                        value={focus?.bday}
                        onChange={(d) => {
                            setFocus(p => ({ ...p, bday: d }))
                        }}
                    />
                </View>
                {/* <DCalendar
                    current={focus?.bday}
                    callback={(un, obj, boo) => {
                        if (focus?.bday !== un) {
                            setFocus(p => ({ ...p, bday: un }))
                        }
                    }} /> */}

                <TextInput keyboardType="number-pad" label="Mobile No." value={focus?.mobile ? focus?.mobile : ""} onChangeText={(t) => setFocus(p => ({ ...p, mobile: t }))} />
                <TextInput error={focus?.length !== 18} keyboardType="number-pad" label="UID No." value={focus[ccy]?.uid ? focus[ccy].uid : ""} onChangeText={(t) => {
                    const f = { ...focus[ccy] }
                    f.uid = t
                    const f1 = {}
                    f1[ccy] = f
                    setFocus(p => ({ ...p, ...f1 }))
                }
                } />
                <TextInput label="Address" value={focus?.address ? focus?.address : ""} onChangeText={(t) => setFocus(p => ({ ...p, address: t }))} />
                <Text style={{ marginTop: 20 }}>Studying in :</Text>
                <View style={{ flexDirection: "row", flexWrap: "wrap", }}>
                    {classList.map((e, i) =>
                        <Chip style={{ margin: 3 }} selected={focus[ccy]?.classId == e.id} onPress={() => {
                            const f = { ...focus[ccy] }
                            f.class = e.id
                            f.classId = e.name
                            if (e?.div) {
                                f.div = e?.div
                            }
                            const f1 = {}
                            f1[ccy] = f
                            setFocus(p => ({ ...p, ...f1 }))
                        }}>{e.name + (e?.div ? " " + e.div : "")}
                        </Chip>
                    )}
                </View>
                <ScrollView horizontal style={{ marginTop: 10 }}>
                    <View style={{ alignItems: "center", flexDirection: "row" }}>
                        <Text>Stream : </Text>
                        {Global.STREAM.map((e, i) =>
                            <Chip style={{ margin: 2 }} selected={focus[ccy]?.stream == i} onPress={() => {
                                const f = { ...focus[ccy] }
                                f.stream = i
                                const f1 = {}
                                f1[ccy] = f
                                setFocus(p => ({ ...p, ...f1 }))
                            }
                            }>{e}</Chip>
                        )}
                    </View>
                </ScrollView>

                <View style={{ flex: 1, alignItems: "center", flexDirection: "row", marginTop: 40, marginBottom: 60 }}>
                    <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Back</Button>
                    <Button style={{ flex: 1 }} loading={working} mode="contained" onPress={async () => {
                        setWorking(true)
                        if (focus?.name && focus[ccy]?.roll) {
                            const db = getFirestore()
                            let ref
                            if (focus?.id) {
                                ref = doc(db, "Users", focus.id)
                            } else {
                                ref = doc(collection(db, "Users"))
                                focus.id = ref.id
                                focus.createdOn = dayjs().unix()
                                focus.createdBy = pref?.current?.id
                                focus.createdByName = pref?.current?.name
                                focus.exam = []
                            }
                            focus.stamp = dayjs().unix()
                            await setDoc(ref, focus)
                            Toast.show("Saved ")
                            setFocus({ classId: focus.classId, class: focus.class })
                            rf?.current?.focus()
                        } else {
                            Toast.show("Error : Name અને Rollno  જરૂરી છે")
                        }
                        setWorking(false)
                    }}>Save</Button>
                </View>
            </View >
        </ScrollView >
    );


}

