import { memo } from "react";
import { Divider, Text } from "react-native-paper";
import { View } from "./Themed";

const MClassRow = (op) => <View style={{backgroundColor:"transparent", flex: 1, paddingLeft: 30 }}>
    <Text variant="titleLarge">{op.item.name} {op.item.div}</Text>
    <Text style={{ fontSize: 10, marginLeft: 7 }}>{op.item.classTeacherName}</Text>
    <Divider />
</View>

const ClassRow = memo(MClassRow)
export default ClassRow 
