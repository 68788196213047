import { View } from './Themed';
import Preferances from '../constants/Preferances';

import * as React from 'react';
import { List, AnimatedFAB, FAB, Portal, Button, TextInput, Chip, IconButton, Text, ActivityIndicator } from 'react-native-paper';
import { Dimensions, FlatList, ScrollView, Pressable } from 'react-native';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import Modal from 'react-native-modal'
import Global from '../constants/global';
import * as ImagePicker from 'expo-image-picker';
import ScaledImage from './ScaledImage';
import DAvatar from './DAvatar';


export default function PostGallary(op) {
    const [groupEntry, setGroupEntry] = React.useState(undefined);

    React.useEffect(() => {
        if (op.route?.params?.entry) {
            setGroupEntry(JSON.parse(JSON.stringify(op.route.params.entry)))
        }
    }, [op.route?.params?.entry])
    let ago = dayjs.unix(groupEntry?.stamp).diff(dayjs(), "days")

    const w = Dimensions.get("window").width / 2
    return <View style={{ flex: 1 }}>
        <ScrollView>
            <View style={{ flexDirection: "row", paddingLeft: 10 }}>
                {groupEntry?.postedBy && <DAvatar title={groupEntry.postedBy.name} source={groupEntry.postedBy.pic ? Global.getPicUrl(groupEntry.postedBy.pic) : ""} variant="headlineLarge" />}
                {groupEntry?.title ? <Text variant="headlineMedium" style={{ paddingLeft: 15 }}>{groupEntry.title}</Text> : <></>}
            </View>
            {groupEntry?.desc ? <Text variant="titleSmall" style={{ paddingLeft: 15, paddingBottom: 5 }}>{groupEntry?.desc}</Text> : <></>}
            {groupEntry?.cheif ? <Text style={{ fontWeight: "bold", paddingLeft: 15, paddingBottom: 5 }}>મુખ્ય અતિથી : {groupEntry?.cheif}</Text> : <></>}
            {groupEntry?.atten ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>આમંત્રિત મહેમાનો : {groupEntry?.atten}</Text> : <></>}
            {groupEntry?.date ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>તારીખ : {dayjs.unix(groupEntry?.date).format("DD/MMM/YYYY")}</Text> : <></>}
            {groupEntry?.parti ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>સ્પર્ધકો : {groupEntry?.parti}</Text> : <></>}
            {groupEntry?.winner ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>વિજેતા : {groupEntry?.winner}</Text> : <></>}
            <View style={{ flexDirection: "row", paddingLeft: 10 }}>
                {groupEntry?.postedBy ? <Text variant="labelSmall" style={{ flex: 1, textAlign: "right" }}>By {groupEntry.postedBy.name} </Text> : <></>}
                {ago ? <Text variant="labelSmall" style={{ textAlign: "right" }}>{Math.abs(ago)} days ago </Text> : <></>}
            </View>

            <FlatList
                data={groupEntry?.media}
                style={{ flex: 1 }}
                numColumns={2}
                renderItem={(i) => {
                    {
                        const toc = i.item?.local ? i.item?.local?.uri : i.item?.content
                        const uri = toc?.includes("file:/") ? toc :
                            (toc?.includes("https://") || toc?.includes("http://") ? toc : Global.getPicUrl(toc))
                        console.log("its uri", uri)
                        return <View>
                            <ScaledImage width={w - 10} height={w} uri={uri} />
                            {i.item?.title && i.item?.desc && <View>
                                {i.item?.title && <Text style={{ color: "white" }}>{i.item.title}</Text>}
                                {i.item?.desc && <Text style={{ color: "white" }} variant="labelSmall">{i.item.desc}</Text>}
                            </View>}
                        </View>

                    }
                }}
            />
        </ScrollView>
        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent", alignSelf: "stretch" }}>
            <Button style={{ flex: 1 }} onPress={() => op.navigation.goBack()}>Back</Button>

        </View>
    </View >

}