import * as React from 'react';
import { Audio } from 'expo-av';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View } from './Themed';
import { Platform } from 'react-native';
import { IconButton } from 'react-native-paper';

const PlaySchoolSong = React.memo(() => {
    const [sound, setSound] = React.useState({ sound: undefined, autoPlay: 0 });
    const [playing, setPlaying] = React.useState(false);
    const [initiated, setInitiated] = React.useState(false);

    async function playSound(toPlay: boolean) {
        if (toPlay) {
            if (!initiated) {
                AsyncStorage.getItem('playSong').then(async e => {
                    if (e !== "Y") {
                        if (Platform.OS == "web") {
                            //ref?.current?.click()
                            setInitiated(true)
                        }
                        setTimeout(async () => {
                            await sound.sound.playAsync();
                            AsyncStorage.setItem('playSong', "Y")
                            setPlaying(true)
                        })
                    } else {
                        setInitiated(true)
                        setPlaying(false)
                    }
                })
            } else {
                await sound.sound.playAsync();
                setInitiated(true)
                setPlaying(true)
            }
        } else {
            await sound?.sound?.pauseAsync()
            setInitiated(true)
            setPlaying(false)
        }
    }

    React.useEffect(() => {
        if (sound.sound) {
            playSound(true)
        }
        return sound
            ? () => {
                sound?.sound?.unloadAsync();
            }
            : undefined;
    }, [sound])

    async function setUp() {
        const { sound } = await Audio.Sound.createAsync(require("../assets/sound/school.mp3"))
        setSound({ sound: sound });
    }

    React.useEffect(() => {
        setUp()
    }, [])


    const ref = React.useRef()
    // const FancyButton = React.forwardRef((props, ref) =>
    // <IconButton style={{ backgroundColor: "transparent" }} background="transparent" ref={ref} icon={playing ? "volume-high" : "volume-off"} onPress={() => playSound(!playing)} />
    // );

    return <IconButton  icon={playing ? "volume-high" : "volume-off"} onPress={() => playSound(!playing)} />
})
export default PlaySchoolSong