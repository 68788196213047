import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { View, } from '../components/Themed';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button } from 'react-native-paper';

import * as React from 'react';
import Loading from '../components/Loading';

// const UserRow = React.lazy(() => import('../components/UserRow'))
import UserRow from '../components/UserRow'

import { useTheme } from 'react-native-paper';
import Global from '../constants/global';


let baselist: [] = []
export default function Users({ navigation, route }) {
    // const [filter, setFilter] = React.useState(route?.params?.filter);

    const baselist = useSelector(React.useMemo(Global.getUserList, []))
    const [alllist, setAllList] = React.useState({ baselist: [] })

    // const nav = useNavigation()
    // const L = useContext(Language)

    // const debounceLoadData = React.useCallback(debounce((q: string) => {
    //     dataSearch(q)
    // }, 500), []);

    React.useEffect(() => {
        // setTimeout(() => {
        // baselist = bhudevList.filter(e => e.flag !== -10)
        // let tit = route?.params?.title
        // if (route?.params?.filter) {
        //     dataSearch(route?.params?.filter)
        // } else if (route?.params?.relation) {
        //     baselist = baselist.filter(e => e.gender == route?.params?.gender)
        // }
        setAllList({ baselist: [...baselist] })
        // })

    }, [baselist]);
    //    9429627086

    function dataSearch(text: string) {
        if (text) {
            let list: [] = baselist.filter((ele) =>
                (ele.name + ele.class + ele.gr + ele.uid + (ele?.class2022?.gr ? ele?.class2022?.gr : "") + (ele?.class2023?.gr ? ele?.class2023?.gr : "") as String).toLowerCase().includes(text.toLowerCase())) as []
            setAllList(p => ({ ...p, baselist: list }))
        } else {
            setAllList(p => ({ ...p, baselist: baselist }))
        }
    }


    const { colors } = useTheme();
    return (
        <View style={{ backgroundColor: colors.background, flex: 1, paddingTop: route?.params?.topMargin ? StatusBar.currentHeight : 0 }}>
            {/* <Button onPress={() => {
                let one = 0
                let non = 0
                const db = getFirestore()
                alllist.baselist.map(e => {
                    try {
                        if (e.class2022.classId == e.class2023.classId) {
                            const ref = doc(db, "Users", e.id)
                            console.log("changeUID", e.class2022.year)
                            updateDoc(ref, { "class2022": "", "class2023.year": 2023 }, { merge: true })
                                .then(e => { console.log("Done") })
                        }
                    } catch (e) {
                    }
                    // if (e.earlier) {
                    //     const got = e.earlier.find(f => f.year == 2022)
                    //     if (got) {
                    //         const ref = doc(db, "Users", e.id)
                    //         setDoc(ref, { "class2022": got }, { merge: true })
                    //             .then(e => { console.log("Done") })
                    //         console.log(got)
                    //     }
                    // }
                    // if (e.classId2022) {
                    //     const c202 = { classId: e.classId2022, year: 2022, class: e.class }
                    //     if (!e.classId2023) {
                    //         c202.roll = e.roll
                    //         c202.gr = e.gr
                    //         if (e.stream !== undefined) {
                    //             c202.stream = e.stream
                    //         }
                    //         if (dob) {
                    //             c202.dob = e.dob
                    //         }
                    //     }
                    //     console.log(one++, e)
                    //     // const ref = doc(db, "Users", e.id)
                    //     // updateDoc(ref, { "class2022": c202 }, { merge: true })
                    //     //     .then(e => { console.log("Done") })
                    // }
                    // if (e.classId2023) {
                    //     const ref = doc(db, "Users", e.id)
                    //     const c202 = { classId: e.classId2023, year: 2023, class: e.class2023 }
                    //     if (e.gr) {
                    //         c202.gr = e.gr
                    //     }
                    //     if (e.roll) {
                    //         c202.roll = e.roll
                    //     }
                    //     if (e.stream !== undefined) {
                    //         c202.stream = e.stream
                    //     }
                    //     if (e.dob) {
                    //         c202.dob = e.dob
                    //     }
                    //     try {
                    //         setDoc(ref, { "class2023": c202 }, { merge: true })
                    //             .then(e => console.log(non++, "Done")).catch(e => console.log("err", e))
                    //     } catch (e) {
                    //         console.log("errror", e)
                    //     }

                    //     console.log(one++, e, e.classId2022, e.classId2023, e.class, e.name)
                    // }
                })

                //const db = getFirestore()
                //                const d = doc(db, "Users", deletConf.id)
                //setDoc(d, {flag: -10 }, {merge: true })

            }}>Update</Button> */}
            {/* <Button onPress={() => {
                // let one = 0
                // let non = 0
                // const db = getFirestore()
                // alllist.baselist.map(e => {
                //     if (e.marks == undefined && e.class && e.class == "ધોરણ 11") {
                //         console.log(e.roll, e.gr, e.name)
                //         const db = getFirestore()
                //         const d = doc(db, "Users", e.id)
                //         setDoc(d, { class2023: e.class2022, class2022: "" }, { merge: true }).then(e => console.log(non++, "done"))
                //     }
                // })


                //const db = getFirestore()
                //                const d = doc(db, "Users", deletConf.id)
                //setDoc(d, {flag: -10 }, {merge: true })

            }}>Creed</Button> */}

            <View style={{ flexDirection: "row", alignItems: "center", paddingRight: 5 }} >
                <View style={{ alignSelf: "stretch", flex: 1 }}>
                    <View style={{ flexDirection: 'row', alignItems: "center" }}>
                        <TextInput
                            autoFocus
                            placeholder="Search..."
                            style={{ alignSelf: "stretch", flex: 1 }}
                            dense
                            onChangeText={(text) => {
                                dataSearch(text)
                            }}
                        />
                        <IconButton icon='eye' />
                        <Text>{alllist.baselist.length}</Text>
                    </View>
                </View>
            </View>
            <FlashList
                data={alllist.baselist}
                key="id"
                estimatedItemSize={200}
                contentContainerStyle={{ backgroundColor: "transparent" }}
                keyboardShouldPersistTaps={'handled'}
                renderItem={({ item, index }) =>
                    <UserRow
                        info={item}
                        navigation={navigation}
                        color={colors.surfaceVariant}
                        index={index} />
                } />


        </View>
    );


}

