import * as React from 'react';
import { Dimensions, View, TouchableOpacity, Pressable } from 'react-native';
import { IconButton, Text, TextInput } from "react-native-paper";
import Modal from "react-native-modal"
import { Image } from "expo-image"

const Zoom = React.memo((op) => {
    const [show, setShow] = React.useState(undefined)
    return <Modal isVisible={op.show} onBackdropPress={() => op.close()} style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="fadeIn" animationOut={"fadeOut"}>
        <Pressable onPress={() => op.close()}>
            <View style={{ backgroundColor: "rgba(0,0,0,0.7)", height: Dimensions.get("window").height, width: Dimensions.get("window").width, alignItems: "center", justifyContent: "center" }}>
                <Image contentFit="contain" source={op.source} style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height }} />
            </View>
        </Pressable>
    </Modal>

})
export default Zoom