import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Share } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Chip, Modal, HelperText } from 'react-native-paper';

import * as React from 'react';
import { Button, useTheme } from 'react-native-paper';
import { useSelector } from "react-redux";
import OnlyStudentEntry from "../components/OnlyStudentEntry";
import { addDoc, collection, doc, FieldPath, Firestore, getFirestore, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import Toast from "react-native-root-toast";
import Global from "../constants/global";
import Preferance from '../constants/Preferances';
import { current } from "@reduxjs/toolkit";
import * as Network from 'expo-network';


const Timmer = (clear) => {
    const [secondsPassed, setSecondsPassed] = React.useState(0);
    const [clearT, setClear] = React.useState(undefined);



    const startTime = clear.limit;
    const timeLeft = startTime - secondsPassed


    React.useEffect(() => {
        const timer = setInterval(() => {
            setSecondsPassed(sp => sp + 1);
        }, 1000);
        setClear(timer)
        return () => { clearInterval(timer) }
    }, []);

    React.useEffect(() => {
        if (secondsPassed > clear.limit) {
            clearInterval(clearT)
            clear.clr()
        }
    }, [secondsPassed])

    return <Text style={{ flex: 1, textAlign: "center" }}>Wait {timeLeft} </Text>
}
async function checkNet() {
    const res = await Network.getNetworkStateAsync();
    return res.isConnected && res.isInternetReachable
}
const SaveButton = React.memo((para) => {
    const [saveWorking, setSaveWorking] = React.useState({ mess: "", len: 0, working: false, timmer: false })
    // console.log("State", para)


    async function save() {
        const isConnected = await checkNet()
        if (isConnected) {
            setSaveWorking(p => ({ ...p, mess: "Preparing...", working: true }))
            try {
                const dif: [] = []

                para.focus.entry.map(e => {
                    const has = para.focus.originalList.find(o => o.student_id == e.student_id && o.subject_id == e.subject_id)
                    if (has) {
                        if ((e.entry == true && e.theory == 0)) {
                            dif.push(e)
                        } else {
                            if ((has.theory == e.theory && has.practical == e.practical && has.internal == e.internal && has.theoryMarks !== undefined)) {
                                // all is same do nothing
                            } else {
                                dif.push(e)
                            }
                        }
                    } else {
                        dif.push(e)
                    }
                })
                // crreate a diff list
                const db = getFirestore()
                const mye: [] = []
                //                const stamp = new Date().getTime()
                for (let ii = 0; ii < dif.length; ii++) {
                    const e = dif[ii]
                    try {
                        //console.log("saving e", e)
                        const student_id = e.student_id
                        const student = para.users.find(u => u.id == student_id)
                        //                        const student = JSON.parse(JSON.stringify(st))
                        if (student) {
                            const ref = doc(db, "Users", student_id)
                            if (para.focus.exam.year > 2000 && para.focus?.exam?.id?.length > 3 && para.focus?.subject?.id?.length > 3) {
                                // console.log("has focus ok")

                                const yr = para.focus.exam.year
                                const su = para.focus.subject.id
                                const ex = para.focus.exam.id

                                // initial status with current subject & update overall status
                                let status = "Pending"


                                const o = student.marks[yr][ex]

                                let pass: [] = []
                                let failed: [] = []
                                let absent: [] = []
                                let pending: [] = []
                                if (e.status == "Absent") {
                                    status = "Absent"
                                    // console.log("me absent", focus.subject.name, focus.subject)
                                    absent.push(para.focus.subject.name)
                                } else {
                                    if (e.status == "Fail") {
                                        status = "Fail"
                                        failed.push(para.focus.subject.name)
                                    } else {
                                        pass.push(para.focus.subject.name)
                                        let hasFail = false
                                        let hasAbsent = false
                                        let hasPending = false
                                        Object.keys(o).forEach(function (key) {
                                            if (Global.isSubject(o, key)) {
                                                if (key !== focus.id) {
                                                    if (o["status"] == "Fail") {
                                                        if (hasFail == false) {
                                                            hasFail = true
                                                        }
                                                    }
                                                    if (o["status"] == "Absent") {
                                                        if (hasAbsent == false) {
                                                            hasAbsent = true
                                                        }
                                                    }
                                                    if (o["status"] == "Pending") {
                                                        if (hasPending == false) {
                                                            hasPending = true
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                        status = hasAbsent ? "Absent" : hasFail ? "Fail" : hasPending ? "Pending" : "Pass"
                                    }
                                }
                                let gained = 0
                                let totalMarks = 0
                                // console.log("meo", o)
                                //  Scan all subjects and create result
                                Object.keys(o).forEach(function (key) {
                                    if (Global.isSubject(o, key)) {
                                        if (key !== para.focus.subject.id) {
                                            gained = gained + (o[key].theory > 0 ? o[key].theory : 0) + (o[key].practical ? o[key].practical : 0) + (o[key].internal ? o[key].internal : 0)
                                            totalMarks = totalMarks + o[key].totalMarks
                                            if (!o[key].status) {
                                                pending.push(o[key].name)
                                            } else if (o[key].status == "Absent") {
                                                absent.push(o[key].name)
                                            } else if (o[key].status == "Pass") {
                                                pass.push(o[key].name)
                                            } else if (o[key].status == "Fail") {
                                                failed.push(o[key].name)
                                            }
                                        }
                                    }
                                });
                                gained = gained +
                                    (e.theory == "Absent" ? 0 : e.theory) +
                                    (e.practical ? e.practical : 0) +
                                    (e.internal ? e.internal : 0)
                                totalMarks = totalMarks + e.totalMarks
                                let pc = Math.round((gained * 100) / totalMarks)
                                e.entry = true
                                //                            console.log(`ee`, e)
                                if (e?.marks) {
                                    delete e.marks
                                }
                                if (e?.student?.result) {
                                    delete e.student.result
                                }
                                saveDocument(ref, yr, ex, su, e, status, pass, failed, pending, absent, gained, totalMarks, pc)
                                setSaveWorking(p => ({ ...p, mess: `Preparing ${ii}/${dif.length}`, len: dif.length }))
                                mye.push({ ...e })
                            }
                        }
                    } catch (e) {
                    }
                }
                // dif.map(async (e,ii) => {
                // })
                try {
                    //console.log("saving 1")
                    setSaveWorking(p => ({ ...p, mess: `Creating Log...` }))
                    addDoc(collection(db, "Log"), {
                        Module: "Update Marks",
                        stamp: new Date().getTime(),
                        ip: Global.IP,
                        entry: mye,
                        className: para.focus?.subject?.className ? para.focus?.subject?.className : "",
                        subject: para.focus?.subject?.name ? para.focus?.subject?.name : "",
                        by: para.current.id,
                        byname: para.current.name
                    })
                    // setSaveWorking(p => ({ ...p, timer: true }))
                } catch (e) { }
                //            console.log("saving 2")
                setSaveWorking(p => ({ ...p, timmer: true, mess: "" }))
                //          console.log("saving 4")
            } catch (w) {
                alert("Error : 104")
            }
        } else {
            alert("Internet Connection issue")
        }
    }
    async function saveDocument(ref: any, yr: any, ex: any, su: any, e: any, status: any, pass: any, failed: any, pending: any, absent: any, gained: any, totalMarks: any, pc: any) {
        await updateDoc(ref, {
            [`marks.${yr}.${ex}.${su}`]: e,
            [`marks.${yr}.${ex}.status`]: status,
            [`marks.${yr}.${ex}.pass`]: pass,
            [`marks.${yr}.${ex}.failed`]: failed,
            [`marks.${yr}.${ex}.pending`]: pending,
            [`marks.${yr}.${ex}.absent`]: absent,
            [`marks.${yr}.${ex}.subjectMarks`]: gained,
            [`marks.${yr}.${ex}.totalMarks`]: totalMarks,
            [`marks.${yr}.${ex}.pc`]: pc,
        }, { merge: true })

    }

    return <View style={{ marginBottom: 60, alignSelf: "stretch", flexDirection: "row" }}>
        <Button disabled={saveWorking.working} onPress={() => para.navigation.goBack()} style={{ minHeight: 40, flex: 1 }} >Back</Button>
        <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "row", flex: 1 }}>
                <Text style={{ flex: 1, textAlign: "center" }}>{saveWorking.mess}</Text>
                {saveWorking.timmer && <Timmer limit={saveWorking.len} clr={() => {
                    setSaveWorking(p => ({ ...p, mess: `Done`, timmer: false }))
                    // para?.fresh()
                    setTimeout(() => {
                        setSaveWorking(p => ({ ...p, working: false }))
                        para.navigation.goBack()
                    }, 1500)
                }} />}
            </View>
            <Button loading={saveWorking.working} onPress={() => {
                if (!saveWorking.working) {
                    save()
                }
            }
            } style={{ flex: 1, minHeight: 40 }} mode="contained">Save</Button>
        </View>

    </View>
})

export default function SubjectwiseMarks({ navigation, route }) {
    const [focus, setFocus] = React.useState({ exam: undefined, subject: undefined, student: [], pra: false, the: true, int: false, marks: [], entry: [], originalList: [], onlyOnce: true })
    const users = useSelector(React.useMemo(Global.getUserList, []))
    // const marks = useSelector(s => s.list.Marks)
    //    const allSubjects = useSelector(s => s.list.Subjects)
    const pref = React.useContext(Preferance)

    const theme = useTheme();
    const colors = theme.colors

    React.useEffect(() => {
        return () => { setFocus({ exam: undefined, subject: undefined, student: [], pra: false, the: true, int: false, marks: [], entry: [], originalList: [], onlyOnce: true }) }
    }, [])

    React.useEffect(() => {
        if (route?.params?.exam && users && focus.exam == undefined && focus.onlyOnce) {
            let ss = undefined
            // console.log("exammm", route?.params?.exam?.year)
            const data1 = users.filter(e => e["class" + route?.params?.exam?.year]?.classId == route?.params?.class?.id)
            // console.log("data1", data1)
            const editable: [] = []


            const stream = route?.params?.subject?.stream
            if (stream > 0) {
                ss = data1.filter(e => {
                    let st = 0
                    try {
                        st = e["class" + route?.params?.exam?.year]?.stream
                    } catch (e) { }
                    return (st == stream) || (st == 0)
                }
                )
            } else {
                ss = data1
            }
            // console.log("strema", stream)

            // console.log("ss", ss)

            ss.map(eu => {
                const e = JSON.parse(JSON.stringify(eu))
                if (!e.marks["" + route?.params?.exam?.year]) {
                    e.marks = { ...e.marks, [route?.params?.exam?.year]: {} }
                }
                if (!e.marks[route?.params?.exam?.year]?.[route?.params?.exam?.id]) {
                    e.marks[route?.params?.exam?.year] = { ...e.marks[route?.params?.exam?.year], [route?.params?.exam?.id]: {} }
                }
                if (!e.marks[route?.params?.exam?.year]?.[route?.params?.exam?.id]?.[route?.params?.subject?.id]) {
                    const theo = route?.params?.subject?.theoryMarks
                    const totm = route?.params?.subject?.totalMarks
                    e.marks[route?.params?.exam?.year][route?.params?.exam?.id] = {
                        ...e.marks[route?.params?.exam?.year][route?.params?.exam?.id], [route?.params?.subject?.id]: {
                            ...route?.params?.subject,
                            subject_id: route?.params?.subject?.id,
                            student_id: e.id,
                            totalMarks: route?.params?.subject?.totalMarks,
                            theoryMarks: theo == undefined ? totm : theo,
                            theory: 0, practical: 0, internal: 0,
                            left: (e.left == true ? true : false),
                            student_name: e.name,
                            roll: e.roll,
                        }
                    }
                }
                //                console.log("eee", e)
                editable.push(e)
            })



            const a: [] = []
            editable.map(e => {
                a.push(JSON.parse(JSON.stringify(e)))
            })
            const nnewP = a.sort((a, b) => a?.["class" + route?.params?.exam?.year]?.roll < b?.["class" + route?.params?.exam?.year]?.roll ? -1 : 0)
            const nmarks: [] = []
            a.map(ee => {
                try {
                    const e = JSON.parse(JSON.stringify(ee))
                    const got = e.marks[route?.params?.exam?.year][route?.params?.exam?.id][route?.params?.subject?.id]
                    // const allMarks =  ? e.exam : []
                    //                    const gotExam = allExams.find(em => em.id == route?.params?.exam?.id)
                    //                  const got = gotExam?.marks?.find(m => m.subject_id == route?.params?.subject?.id)
                    if (got) {
                        const theo = route?.params?.subject?.theoryMarks
                        const totm = route?.params?.subject?.totalMarks
                        nmarks.push({
                            ...got,
                            subject_id: route?.params?.subject?.id,
                            student_id: e.id,
                            student_name: e.name,
                            left: (e.left == true ? true : false),
                            roll: e["class" + route?.params?.exam?.year].roll,
                            marks: e.marks,
                            internalMarks: route?.params?.subject?.internalMarks ? route?.params?.subject?.internalMarks : 0,
                            practicalMarks: route?.params?.subject?.practicalMarks ? route?.params?.subject?.practicalMarks : 0,
                            theoryMarks: theo == undefined ? totm : theo,
                            totalMarks: route?.params?.subject?.totalMarks,
                        })
                    } else {
                        const o = {
                            subject_id: route?.params?.subject?.id,
                            student_id: e.id,
                            totalMarks: route?.params?.subject?.totalMarks,
                            internalMarks: route?.params?.subject?.internalMarks ? route?.params?.subject?.internalMarks : 0,
                            practicalMarks: route?.params?.subject?.practicalMarks ? route?.params?.subject?.practicalMarks : 0,
                            theory: 0, practical: 0, internal: 0,
                            left: (e.left == true ? true : false),
                            student_name: e.name,
                            roll: e["class" + route?.params?.exam?.year].roll,
                            marks: e.marks,
                        }
                        nmarks.push(o)
                    }

                } catch (e) {
                    console.log("err", e)

                }
            })
            // console.log("NMarks", nmarks)
            if (focus.originalList.length == 0) {
                setFocus(p => ({ ...p, exam: route?.params?.exam, subject: route?.params?.subject, student: nnewP, the: true, pra: focus?.subject?.practicalMarks, int: focus?.subject?.internalMarks, entry: nmarks, originalList: JSON.parse(JSON.stringify(nmarks)), onlyOnce: false }))
            } else {
                setFocus(p => ({ ...p, exam: route?.params?.exam, subject: route?.params?.subject, student: nnewP, the: true, pra: focus?.subject?.practicalMarks, int: focus?.subject?.internalMarks, entry: nmarks, onlyOnce: false }))
            }
        } else {
        }
    }, [route.params, users])

    return (
        <ScrollView style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                <View style={{ paddingLeft: 5 }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text variant="headlineSmall">{focus?.subject?.className ? focus?.subject?.className : ""} </Text>
                        <Text variant="headlineSmall">  {focus?.subject?.name ? focus?.subject?.name : ""} </Text>
                    </View>
                    {(focus.pra || focus.int) && <Text >Theory Marks : {focus?.subject?.theoryMarks} </Text>}
                    {focus.pra && <Text >Practicle Marks : {focus?.subject?.practicalMarks} </Text>}
                    {focus.pra && <Text >Internal Marks : {focus?.subject?.internalMarks} </Text>}
                    <Text >Total Marks : {focus?.subject?.totalMarks} </Text>

                    <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <HelperText style={{}}>Note: Range indicator </HelperText>
                        <HelperText>indicates 10% +/- of average% of all previous exams. This is just an indicator to avoid typing erros, and in noway it should affect actual marks decision of teacher</HelperText>
                    </View>
                </View>
                <FlashList
                    data={focus.entry}
                    key="id"
                    estimatedItemSize={50}
                    contentContainerStyle={{ backgroundColor: "transparent" }}
                    keyboardShouldPersistTaps={'handled'}
                    renderItem={({ item, index }) => {
                        return item ?
                            <OnlyStudentEntry allExams={route.params.allExams} year={focus.exam.year} par={focus.pra} int={focus.int} item={item} thisExam={focus} updateMarks={(what: number, marks: string, student_id: string) => {
                                if (marks == "") {
                                    const allMarks: [] = []
                                    focus.entry.map(e => {
                                        allMarks.push(e)
                                    })
                                    const su = allMarks.findIndex(e => e.student_id == student_id && e.subject_id == focus.subject.id)
                                    const ent = su > -1 ? allMarks[su] : { student_id: student_id, subjct_id: focus.subject.id }
                                    ent.status = ""
                                    ent.entry = false
                                    if (what == 0) {
                                        ent.theory = ""
                                    } else if (what == 1) {
                                        ent.internal = 0
                                    } else if (what == 2) {
                                        ent.practical = 0
                                    }

                                    let rec = Global.getCombo(ent)

                                    ent.status = Global.getPassFail(rec, item.totalMarks)
                                    if (su == -1) {
                                        allMarks.push(ent)
                                    } else {
                                        allMarks[su] = ent
                                    }
                                    setFocus(p => ({ ...p, entry: allMarks }))

                                } else {
                                    let v1 = parseInt(marks)
                                    let v = 0
                                    if (v1) {
                                        v = v1
                                    } else {
                                        v = 0
                                    }
                                    // console.log("update " + v, item.totalMarks)
                                    if (v <= item.totalMarks) {
                                        const allMarks: [] = []
                                        focus.entry.map(e => {
                                            allMarks.push(e)
                                        })
                                        const su = allMarks.findIndex(e => e.student_id == student_id && e.subject_id == focus.subject.id)
                                        const ent = su > -1 ? allMarks[su] : { student_id: student_id, subjct_id: focus.subject.id }
                                        ent.status = ""
                                        ent.entry = true
                                        if (what == 0) {
                                            if (marks == "Absent") {
                                                ent.status = "Absent"
                                                ent.theory = "Absent"
                                            } else {
                                                ent.theory = v
                                            }
                                        } else if (what == 1) {
                                            ent.internal = v
                                        } else if (what == 2) {
                                            ent.practical = v
                                        }
                                        let rec = Global.getCombo(ent)

                                        ent.status = ent.status == "Absent" ? "Absent" :
                                            Global.getPassFail(rec, item.totalMarks)
                                        if (su == -1) {
                                            allMarks.push(ent)
                                        } else {
                                            allMarks[su] = ent
                                        }
                                        setFocus(p => ({ ...p, entry: allMarks }))
                                    }

                                }
                            }
                            } /> : <></>
                    }} />
                <SaveButton fresh={route.params.fresh} users={users} current={pref.current} navigation={navigation} focus={focus} />
            </View >
        </ScrollView >
    );

}

