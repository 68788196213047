import { Button, Divider, IconButton, Text } from 'react-native-paper';
import Modal from "react-native-modal";
import { Dimensions, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import React, { lazy, useContext } from 'react';
import Preferance from '../constants/Preferances';
// const DAvatar = lazy(() => import('./DAvatar'));
import DAvatar from './DAvatar'

import Global from '../constants/global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch } from 'react-redux';
import { loginTasks } from '../task/loginTasks';
import Toast from 'react-native-root-toast';
import Loading from './Loading';


const MHemberger = (op) => {
    const [show, setShow] = React.useState(false)
    const [expandDonation, setExpandDonation] = React.useState(false)
    const [checking, setChecking] = React.useState(true)

    const col = "white"
    const dispatch = useDispatch()
    const pref = useContext(Preferance)
    async function cheeck() {
        const e = await AsyncStorage.getItem('savedLoginId')
        try {
            if (e) {
                const r = await Global.getData(e)
                // console.log("gotData()", r)
                if (r) {
                    dispatch(loginTasks.actions.allowLogin({ user: r }));
                }
            }
        } catch (e) {
        }
        setChecking(false)
    }

    React.useEffect(() => {
        cheeck()
    }, [])

    function Goto(screen: string, params: any) {
        // console.log("GOTO", screen, params)
        op.navigation.navigate(screen, params)
    }
    const isWorkingInSchool = (!pref.current?.retired)
        && (pref?.current?.type == Global.PRINCIPAL || pref?.current?.type == Global.TEACHER || pref?.current?.type == Global.ADMIN || pref?.current?.type == Global.STAFF || pref?.current?.type == Global.VISITING_TEACHER)

    return <View style={{}}>
        {pref?.current?.id ?
            <IconButton hitSlop={20} icon="menu" onPress={() => { setShow(!show) }} /> :
            <Button loading={checking} mode="contained" onPress={() => Goto("Login", undefined)}>Login</Button>}

        <Modal
            backdropOpacity={0.5}
            propagateSwipe
            style={{ margin: 0 }}
            isVisible={show}
            onBackdropPress={() => setShow(false)}
            animationIn={"slideInRight"}
            animationOut={"slideOutRight"}>
            <View style={{ position: "absolute", right: 0, backgroundColor: "#36454F", height: Dimensions.get("window").height }}>
                <View style={{ alignSelf: "stretch", padding: 20, backgroundColor: "#485d6a" }}>
                    <View style={{ paddingStart: 10, flexDirection: "row", alignItems: "center", }}>

                        <DAvatar type={Global.BIG_ROW_DP} source={pref?.current?.dp} />
                        <View style={{ paddingStart: 10 }}>
                            <Text style={{ color: "#ffcc66", fontSize: 24 }}>{pref?.current?.name}</Text>
                            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <Text style={{ color: col }}>{pref?.current?.type ? Global.TYPES[pref?.current?.type] : pref?.current?.["class" + Global.getYear()]?.classId ? "Student" : ""}</Text>
                                <IconButton style={{ padding: 0, margin: 0 }} iconColor={col} size={14} icon="pencil" onPress={() => { setShow(false); Goto("Profile", { data: pref!.current, isLoggedIn: true }) }} />
                            </View>
                        </View>
                    </View>
                    <Text style={{ color: "lightgray", flex: 1, textAlign: "center" }}>{Global.IP}</Text>
                </View>
                <ScrollView>
                    <TouchableOpacity onPress={() => { setShow(false); Goto("Profile", { data: pref!.current, isLoggedIn: true }) }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <IconButton iconColor={col} icon={"account-circle-outline"} />
                            <Text style={{ padding: 10, color: col }}>My Profile</Text>
                        </View>
                    </TouchableOpacity>
                    {/* {isWorkingInSchool && pref?.current?.id && <TouchableOpacity onPress={() => { setShow(false); alert("Under development") }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <IconButton iconColor={col} icon={"human-male-board"} />
                            <Text style={{ padding: 10, color: col }}>My Student's Zone</Text>
                        </View>
                    </TouchableOpacity>
                    } */}
                    {/* <TouchableOpacity onPress={() => Goto("NewPost")}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton iconColor={col} icon={"account-circle-outline"} />
                                <Text style={{ padding: 10, color: col }}>My Posts</Text>
                            </View>
                        </TouchableOpacity> */}
                    <Divider />
                    {isWorkingInSchool &&
                        <DSCreen
                            col={col}
                            label={"New Post"}
                            icon={"hexagon-multiple-outline"}
                            click={() => { setShow(false); Goto("NewPost", undefined) }}
                        />}
                    {/* <TouchableOpacity onPress={() => { setShow(false); Goto("NewPost", undefined) }}>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <IconButton iconColor={col} icon={"hexagon-multiple-outline"} />
                                    <Text style={{ padding: 10, color: col }}>New Post</Text>
                                </View>
                            </TouchableOpacity> */}
                    {isWorkingInSchool &&
                        <DSCreen
                            col={col}
                            label={"Notification"}
                            icon={"bell-ring"}
                            click={() => { setShow(false); Goto("NotiList", undefined) }}
                        />
                        // <TouchableOpacity onPress={() => { setShow(false); Goto("NotiList", undefined) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"bell-ring"} />
                        //         <Text style={{ padding: 10, color: col }}>Notification</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }
                    {/* {isWorkingInSchool && <TouchableOpacity onPress={() => { alert("Under Delvelopment"); setShow(false) }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <IconButton iconColor={col} icon={"calendar-check-outline"} />
                            <Text style={{ padding: 10, color: col }}>Internal Tasks</Text>
                        </View>
                    </TouchableOpacity>} */}
                    {isWorkingInSchool &&
                        <DSCreen
                            col={col}
                            label={"Attendence"}
                            icon={"set-center"}
                            click={() => { setShow(false); alert("Under Delvelopment") }}
                        />

                        // <TouchableOpacity onPress={() => { alert("Under Delvelopment"); setShow(false) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"set-center"} />
                        //         <Text style={{ padding: 10, color: col }}>Attendence</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }
                    <Divider />
                    {isWorkingInSchool && pref?.current?.id &&
                        <DSCreen
                            col={col}
                            label={"Student Record"}
                            icon={"google-classroom"}
                            click={() => { Goto("StudentList", undefined); setShow(false) }}
                        />
                        // <TouchableOpacity onPress={() => { Goto("StudentList", undefined); setShow(false) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"google-classroom"} />
                        //         <Text style={{ padding: 10, color: col }}>Student Record</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }
                    {isWorkingInSchool && pref?.current?.id &&
                        <DSCreen
                            col={col}
                            label={"Exams"}
                            icon={"content-paste"}
                            click={() => { Goto("Exams", undefined); setShow(false) }}
                        />

                        // <TouchableOpacity onPress={() => { setShow(false); Goto("Exams", undefined) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"content-paste"} />
                        //         <Text style={{ padding: 10, color: col }}>Exams</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }
                    {/* {isWorkingInSchool && pref?.current?.id && <TouchableOpacity onPress={() => { ; setShow(false); Goto("NotiList") }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <IconButton iconColor={col} icon={"bell-ring"} />
                            <Text style={{ padding: 10, color: col }}>Notifications</Text>
                        </View>
                    </TouchableOpacity>} */}
                    {isWorkingInSchool && pref?.current?.id &&

                        <DSCreen
                            col={col}
                            label={"Classes & Subjects"}
                            icon={"calendar-check-outline"}
                            click={() => { Goto("Arrangements", undefined); setShow(false) }}
                        />
                        // <TouchableOpacity onPress={() => { setShow(false); Goto("Arrangements", undefined) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"calendar-check-outline"} />
                        //         <Text style={{ padding: 10, color: col }}>Classes & Subjects</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }


                    {isWorkingInSchool && pref?.current?.id &&

                        <DSCreen
                            col={col}
                            label={"Time Table"}
                            icon={"timetable"}
                            click={() => { Goto("TimeTable", undefined); setShow(false) }}
                        />

                        // <TouchableOpacity onPress={() => { setShow(false); Goto("TimeTable", undefined) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"timetable"} />
                        //         <Text style={{ padding: 10, color: col }}>Time Table</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }

                    {isWorkingInSchool &&

                        <DSCreen
                            col={col}
                            label={"Users"}
                            icon={"account-group-outline"}
                            click={() => { Goto("Users", undefined); setShow(false) }}
                        />

                        // <TouchableOpacity onPress={() => { ; setShow(false); Goto("Users", undefined) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"account-group-outline"} />
                        //         <Text style={{ padding: 10, color: col }}>Users</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }
                    <Divider />
                    <DSCreen
                        col={col}
                        label={"Donation"}
                        icon={"chevron-down"}
                        click={() => { setExpandDonation(!expandDonation) }}
                    />

                    {/* <TouchableOpacity onPress={() => { setExpandDonation(!expandDonation) }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton iconColor={col} icon={"hand-coin-outline"} />
                                <Text style={{ padding: 10, color: col, flex: 1 }}>Donation</Text>
                                <IconButton iconColor={col} icon={"chevron-down"} />
                            </View>
                        </TouchableOpacity> */}
                    {expandDonation && <View style={{ marginLeft: 30, marginRight: 10 }}>

                        <DSCreen
                            col={col}
                            label={"Enquiry"}
                            icon={"hand-coin-outline"}
                            click={() => {
                                setShow(false); Goto("Donation", {
                                    mode: 0, data: { name: pref?.current?.name, senderId: pref?.current?.id, mobile: pref?.current?.mobile }
                                })

                            }}
                        />
                        {/* 
                            <TouchableOpacity onPress={() => {
                                setShow(false); Goto("Donation", {
                                    mode: 0, data: { name: pref?.current?.name, senderId: pref?.current?.id, mobile: pref?.current?.mobile }
                                })
                            }}>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <IconButton iconColor={col} icon={"hand-coin-outline"} />
                                    <Text style={{ color: col }}>Enquiry</Text>
                                </View>
                            </TouchableOpacity> */}
                        {isWorkingInSchool &&
                            <DSCreen
                                col={col}
                                label={"View Donations"}
                                icon={"format-list-bulleted"}
                                click={() => {
                                    setShow(false); Goto("DonationList", undefined)
                                }}
                            />
                            // <TouchableOpacity onPress={() => { setShow(false); Goto("DonationList", undefined); }}>
                            //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                            //         <IconButton iconColor={col} icon={"format-list-bulleted"} />
                            //         <Text style={{ color: col }}>View Donations</Text>
                            //     </View>
                            // </TouchableOpacity>

                        }
                    </View>}
                    <Divider />
                    <DSCreen
                        col={col}
                        label={"Credits"}
                        icon={"flag-outline"}
                        click={() => {
                            setShow(false); Goto("Credits", undefined)
                        }}
                    />

                    {/* <TouchableOpacity onPress={() => { setShow(false); Goto("Credits", undefined) }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton iconColor={col} icon={"flag-outline"} />
                                <Text style={{ padding: 10, color: col }}>Credit</Text>
                            </View>
                        </TouchableOpacity> */}
                    <DSCreen
                        col={col}
                        label={"Contact Us"}
                        icon={"connection"}
                        click={() => {
                            Goto("ContactUs", { stvm: op.stvm }); setShow(false)
                        }}
                    />

                    {/* <TouchableOpacity onPress={() => { Goto("ContactUs", { stvm: op.stvm }); setShow(false) }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton iconColor={col} icon={"connection"} />
                                <Text style={{ padding: 10, color: col }}>Contact Us</Text>
                            </View>
                        </TouchableOpacity> */}

                    <DSCreen
                        col={col}
                        label={"Version : " + Global.VERSION}
                        icon={"restore"}
                        click={() => {
                            Toast.show("You are running version " + Global.VERSION)
                        }}
                    />

                    {/* <TouchableOpacity onPress={() => { Toast.show("You are running version " + Global.VERSION) }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton iconColor={col} icon={"restore"} />
                                <Text style={{ padding: 10, color: col }}>Version : {Global.VERSION}</Text>
                            </View>
                        </TouchableOpacity> */}

                    <DSCreen
                        col={col}
                        label={"Force Update"}
                        icon={"air-filter"}
                        click={() => {
                            if (Platform.OS == "web") {
                                window.location.reload()
                            }
                        }}
                    />

                    {/* <TouchableOpacity onPress={() => { window.location.reload() }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton iconColor={col} icon={"air-filter"} />
                                <Text style={{ padding: 10, color: col }}>Force Update</Text>
                            </View>
                        </TouchableOpacity> */}


                    {isWorkingInSchool && pref?.current?.id &&
                        <DSCreen
                            col={col}
                            label={"Backup"}
                            icon={"download"}
                            click={() => {
                                Goto("Backup", undefined); setShow(false)
                            }}
                        />

                        // <TouchableOpacity onPress={() => { Goto("Backup", undefined); setShow(false) }}>
                        //     <View style={{ flexDirection: "row", alignItems: "center" }}>
                        //         <IconButton iconColor={col} icon={"download"} />
                        //         <Text style={{ padding: 10, color: col }}>Backup</Text>
                        //     </View>
                        // </TouchableOpacity>
                    }


                    <DSCreen
                        col={col}
                        label={"Logout"}
                        icon={"logout"}
                        click={() => {
                            AsyncStorage.setItem('savedLoginId', "").then(e => {
                                dispatch(loginTasks.actions.rejectLogin({}));
                            })
                                ; setShow(false)
                        }}
                    />

                    {/* <TouchableOpacity onPress={() => {
                            AsyncStorage.setItem('savedLoginId', "").then(e => {
                                dispatch(loginTasks.actions.rejectLogin({}));
                            })
                                ; setShow(false)

                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton iconColor={col} icon={"logout"} />
                                <Text style={{ padding: 10, color: col }}>Logout</Text>
                            </View>
                        </TouchableOpacity> */}
                </ScrollView>
            </View>
        </Modal>
    </View>
}
const DSCreen = ({ col, icon, label, size = 24, click }) => {
    return <TouchableOpacity onPress={() => click()}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
            <IconButton iconColor={col} icon={icon} size={size} />
            <Text style={{ padding: 10, color: col }}>{label}</Text>
        </View>
    </TouchableOpacity>
}

const Hemberger = React.memo(MHemberger)
export default Hemberger