import * as React from 'react';
import { View, Pressable, Dimensions } from 'react-native';
import { Text } from "react-native-paper";
import DashTitle from './DashTitle';
import Preferance from '../constants/Preferances';
import Global from '../constants/global';
import { useSelector } from 'react-redux';
import { useEvent } from 'react-native-reanimated';

const ImportantForYou = React.memo((op) => {
    const pref = React.useContext(Preferance)
    const exams = useSelector(s => s.list.Exams)
    const [exam, setExam] = React.useState([])
    React.useEffect(() => {
        const e: [] = []
        exams.map(f => {
            if (f.version == 2) {
                e.push({ ...f })
            }
        }
        )
        const nnewP = e.sort((a, b) => a?.createdOn < b?.createdOn ? -1 : 0)
        setExam(nnewP)
    }, [exams])

    return (
        <View style={{ backgroundColor: "transparent", paddingBottom: 20 }}>
            {pref.current?.["class" + Global.getYear()]?.classId && <View>
                <DashTitle title="Results" />
                {exam.map((f, i) => {
                    const upc = pref.current?.marks[f.year][f.id]
                    return <Pressable onPress={() => {
                        if (f.locked == true) {
                            op.navigation.navigate("Marksheet", { list: [pref.current], exam: f, blockPrint: true })
                        } else {
                            alert("Wait... Result under processing.")
                        }
                    }

                    }><View style={{ paddingBottom: 5, paddingTop: 3, marginLeft: 30, flexDirection: "row", flex: 1, alignItems: "baseline" }}>
                            <Text variant="titleMedium">{upc ? "" : "(upcoming)"} {f.name} ({Global.YearString(f.year)})</Text>
                            <Text style={{ fontStyle: "italic" }}>{f.final ? " (Final)" : ""} </Text>
                            <Text style={{ flex: 1, textAlign: "right", paddingRight: 15 }}>View</Text>
                        </View>
                    </Pressable>
                })}
            </View>
            }
        </View >
    );
})
export default ImportantForYou