import Preferances from '../constants/Preferances';

import * as React from 'react';
import { List, AnimatedFAB, FAB, Portal, Button, TextInput, Chip, IconButton, Text, ActivityIndicator } from 'react-native-paper';
import { Dimensions, FlatList, ScrollView, Pressable } from 'react-native';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import Modal from 'react-native-modal'
import Global from '../constants/global';
import * as ImagePicker from 'expo-image-picker';
import { SaveFormat } from 'expo-image-manipulator';
import * as ImageManipulator from 'expo-image-manipulator';
import Toast from 'react-native-root-toast';
import DAvatar from '../components/DAvatar';
import { View } from '../components/Themed';


export default function PostGallary(op) {
    const [groupEntry, setGroupEntry] = React.useState(undefined);

    React.useEffect(() => {
        if (op.route?.params?.entry) {
            setGroupEntry(op.route.params.entry)
        }
    }, [op.route?.params])

    return <View style={{ flex: 1 }}>
        <FlatList
            data={groupEntry}
            renderItem={(i) => {
                return <View style={{ flexDirection: 'row', alignItems: "center" }}>
                    <DAvatar title={i.item.name} source={i.item.dp} variant="headlineLarge" />
                    <View>
                        <Text>  {i.item.name}</Text>
                        {i.item?.stamp && <Text>  {Math.abs(dayjs.unix(i.item?.stamp).diff(dayjs(), "days"))} days ago</Text>}
                    </View>
                </View>
            }}
        />
        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent", alignSelf: "stretch" }}>
            <Button style={{ flex: 1 }} onPress={() => op.navigation.goBack()}>Back</Button>

        </View>
    </View >

}