import React, { useEffect } from "react"
import { Modal, Portal, Button } from 'react-native-paper';
import Preferances from '../constants/Preferances';
import { View } from './Themed';
import { Text } from "react-native-paper";
import { Dimensions, Platform } from "react-native";
import * as Updates from 'expo-updates';
import Toast from "react-native-root-toast";
import Global from "../constants/global";

export default function Update() {
    const pref = React.useContext(Preferances)
    const [update, needUpdate] = React.useState(false)
    Updates.addListener((e) => {
        Toast.show("New App Version found. Dowinloading")
    });


    async function readUpdate() {
        try {
            const update = await Updates.checkForUpdateAsync()
            //            console.log("regarding update - cheking")
            if (update.isAvailable) {
                //    console.log("regarding update its available")
                await Updates.fetchUpdateAsync()
                // NOTIFY USER HERE
                needUpdate(true)
            } else {
                //  console.log("regarding update noting found")
                //console.log("regarding update noting found",update)
            }
        } catch (e) {
            // HANDLE ERROR HERE
            //    console.log("regarding update erorr",e)
        }
    }

    useEffect(() => {
        if (Platform.OS !== "web" && Platform.OS !== "windows") {
            readUpdate()
        }
    }, [])


    // useEffect(() => {
    //     if (version > Global.VERSION) {
    //         needUpdate(true)
    //     }
    // }, [version])

    return <Portal>
        <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} dismissable={false} visible={update} contentContainerStyle={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width }} onDismiss={() => { needUpdate(false) }}>

            <View style={{ justifyContent: "center", }}>
                <Text variant="titleLarge" style={{ margin: 10 }}>Your App is running old version</Text>
                <View style={{ flexDirection: "row", padding: 15, backgroundColor: "transparent" }}>
                    <Text style={{ fontWeight: "bold" }}>{Platform.OS == "web" || Platform.OS == "windows" ? "Your App version : " + pref.version : ""}</Text>
                </View>
                <View style={{ flexDirection: "row", padding: 15, backgroundColor: "transparent" }}>
                    {
                        (Platform.OS == "web" || Platform.OS == "windows")  ? <Button style={{ flex: 1 }} mode='text' onPress={() => { needUpdate(false) }} >Later  </Button> : <></>
                    }
                    <Button style={{ flex: 1 }} mode='contained' onPress={() => {
                        if (Platform.OS == "web" || Platform.OS == "windows") {
                            window.location.reload()
                        } else {
                            Updates.reloadAsync()
                        }
                    }}

                    >Refresh now</Button>
                </View>
                <View>
                </View>
            </View>
        </Modal>
    </Portal>
}   