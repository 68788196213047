import { ScrollView, Dimensions, Platform, FlatList } from 'react-native';

import { View } from '../components/Themed';
import Preferances from '../constants/Preferances';

import * as React from 'react';
import { List, AnimatedFAB, FAB, Portal, Button, TextInput, Chip, IconButton } from 'react-native-paper';
import { Text } from 'react-native-paper';
import { Image } from 'expo-image';
import Modal from "react-native-modal"
const wid = Dimensions.get("window").width
const cols = wid <= 768 ? 1 : Math.floor(wid / 768)
import LottieView from 'lottie-react-native';
import DAvatar from './DAvatar';
import Global from '../constants/global';
import Carousel from 'react-native-reanimated-carousel';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

const w = Dimensions.get('window').width;
const width = w > 768 ? 768 : w
import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';
import { LinearGradient } from 'expo-linear-gradient';

const MDashboardCarousel = (op) => {
    const [isVertical, setIsVertical] = React.useState(false);
    const progressValue = useSharedValue<number>(0);
    const colors = [
        'orange',
        'orange',
        'orange',
        'orange',
        'orange',
        'orange',
    ];
    //    console.log("carousel", op.data)
    const info = op.data?.carousel?.length > 0 ? op.data.carousel : [
        { text1: "Celebrating", text2: "50 Years", image: "class.jpg" },
        { text1: "Class", text2: "9 to 12", image: "class.jpg" },
        { text1: "10,000+", text2: "Students", image: "students4.jpg" },
        { text1: "Qualified", text2: "Teachers", image: "class.jpg" },
        { text1: "Computer", text2: "Lab", image: "computer.jpg" },
        { text1: "Library", text2: "", image: "library.jpg" },
        { text1: "Big", text2: "Play Area", image: "bigground.jfif" },
        { text1: "Education", text2: "to Lowest", image: "student3.jpeg" },
        { text1: "Knowledge", text2: "With Values", image: "class.jpg" },
    ]

    const isMObile = Global.isMobile()

    return <View style={{ backgroundColor: "transparent", justifyContent: "center", alignItems: "center" }}>
        {!isMObile && <View style={{ position: "absolute", width: width, flexDirection: "row", left: 0, top: 0, height: width / 2 }}>
            <Image blurRadius={10} style={{ width: w / 2, height: (width / 2) }} source={{ uri: Global.getPicUrl(info[2].image) }} />
            <Image blurRadius={10} style={{ width: w / 2, height: (width / 2) }} source={{ uri: Global.getPicUrl(info[3].image) }} />
            <View style={{ backgroundColor: "rgba(0,0,0,0.4)", position: "absolute", top: 0, left: 0, height: (width / 2), width: w }}></View>
        </View>}
        <GestureHandlerRootView>
            <Carousel
                loop
                width={width}
                height={width / 2}
                autoPlay={true}
                data={info}
                scrollAnimationDuration={3000}
                mode="parallax"
                onProgressChange={(_, absoluteProgress) =>
                    (progressValue.value = absoluteProgress)
                }
                modeConfig={{
                    parallaxScrollingScale: 0.9,
                    parallaxScrollingOffset: 50,
                }}
                renderItem={(info) => {

                    return <View
                        style={{
                            flex: 1,
                            borderWidth: 1,
                            justifyContent: 'center',
                        }}>
                        <Image contentFit="cover" source={{ uri: Global.getPicUrl(info.item.image) }} style={{ width: "100%", height: "100%" }} />
                        <LinearGradient
                            colors={['rgba(0,0,0,1)', 'transparent']}
                            end={{ x: 1, y: 0.5 }}
                            start={{ x: 0, y: 1 }}
                            style={{ width: "100%", position: "absolute", padding: 10, bottom: 0 }}
                        >
                            <Text variant="headlineLarge" style={{ color: "white" }}>{info.item.text1}</Text>
                            <Text variant="headlineSmall" style={{ color: "orange" }}>{info.item.text2}</Text>
                        </LinearGradient>
                    </View>
                }
                }
            />
        </GestureHandlerRootView >

        {!!progressValue && (
            <View
                style={
                    isVertical
                        ? {
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: 10,
                            alignSelf: 'center',
                            position: 'absolute',
                            right: 5,
                            top: 40,
                        }
                        : {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: 100,
                            alignSelf: 'center',
                        }
                }
            >

                {info.map((backgroundColor, index) => {
                    return (
                        <PaginationItem
                            backgroundColor={"orange"}
                            animValue={progressValue}
                            index={index}
                            key={index}
                            isRotate={isVertical}
                            length={colors.length}
                        />
                    );
                })}
            </View>
        )}
    </View >
}
const PaginationItem: React.FC<{
    index: number;
    backgroundColor: string;
    length: number;
    animValue: Animated.SharedValue<number>;
    isRotate?: boolean;
}> = (props) => {
    const { animValue, index, length, backgroundColor, isRotate } = props;
    const width = 10;

    const animStyle = useAnimatedStyle(() => {
        let inputRange = [index - 1, index, index + 1];
        let outputRange = [-width, 0, width];

        if (index === 0 && animValue?.value > length - 1) {
            inputRange = [length - 1, length, length + 1];
            outputRange = [-width, 0, width];
        }

        return {
            transform: [
                {
                    translateX: interpolate(
                        animValue?.value,
                        inputRange,
                        outputRange,
                        Extrapolate.CLAMP
                    ),
                },
            ],
        };
    }, [animValue, index, length]);
    return (
        <View
            style={{
                backgroundColor: 'transparent',
                width,
                height: width,
                borderRadius: 50,
                overflow: 'hidden',
                transform: [
                    {
                        rotateZ: isRotate ? '90deg' : '0deg',
                    },
                ],
            }}
        >
            <Animated.View
                style={[
                    {
                        borderRadius: 50,
                        backgroundColor,
                        flex: 1,
                    },
                    animStyle,
                ]}
            />
        </View>
    );
};

const DashboardCarousel = React.memo(MDashboardCarousel)
export default DashboardCarousel
