import React, { isValidElement, useEffect, useRef } from 'react';
import { Button, HelperText, TextInput } from 'react-native-paper';
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { Text, View, Pressable } from 'react-native';
import dayjs from 'dayjs';

import { useTheme } from 'react-native-paper';

interface detail {
    label: string,
    current: number
    callback(stamp: number, isValid: boolean, obj: any): void
}

dayjs.extend(customParseFormat)

function DCalendar(op: detail) {
    const { colors } = useTheme()
    const [dt, setDate] = React.useState({ d: 0, m: 0, y: 0 })
    const [result, setResult] = React.useState({ isValid: false, stamp: 0 })

    useEffect(() => {
        //        console.log("recd date " + op.current)
            const djs = dayjs.unix(op.current)
            //  console.log("recd d=" + recd.date() + "-m-" + recd.month() + 1 + "-" + recd.year())
            if (op.current && dayjs(dt.y + '-' + dt.m + "-" + dt.d, "YYYY-M-D", true).unix() !== djs.unix()) {
                //  console.log("recd d=" + recd.date() + "-m-" + recd.month() + 1 + "-" + recd.year())
                setDate({ d: djs.date(), m: djs.month() + 1, y: djs.year() })
            }
    
            // setDate({ d: recd.date(), m: recd.month() + 1, y: recd.year() })
    }, [op])

    useEffect(() => {
        const dj = dayjs(dt.y + '-' + dt.m + "-" + dt.d, "YYYY-M-D", true)
        if (dj.isValid()) {
            dj.format()
            setResult({ isValid: true, stamp: dj.unix() })
            op.callback(dj.unix(), true, dj)
        } else {
            setResult({ isValid: false, stamp: 0 })
        }
    }, [dt])

    let m = useRef()
    let y = useRef()
    return <View>
        <Text>{op.label}</Text>
        <View style={{ flexDirection: "row", alignItems: "center", alignSelf: "flex-start", backgroundColor: colors.surfaceVariant }}>
            <TextInput keyboardType="number-pad"
                error={dt.d > 31 || dt.d <= 0} value={dt.d ? dt.d + "" : ""} multiline={false} numberOfLines={1} style={{ textAlign: "center", width: 60 }} placeholder="DD" maxLength={2}
                onKeyPress={(e) => {
                    if (e.nativeEvent.key == "ArrowUp") {
                        if (dt.d < 31) {
                            setDate(p => ({ ...p, d: dt.d + 1 }))
                        }
                    }
                    if (e.nativeEvent.key == "ArrowDown") {
                        if (dt.d > 1) {
                            setDate(p => ({ ...p, d: dt.d - 1 }))
                        }
                    }
                }}

                onChangeText={(tF) => {
                    let ele = (tF.length == 2 && dt.d.toString().length == 1)
                    const i = parseInt(tF)
                    if (isNaN(i)) {
                        setDate(p => ({ ...p, d: 0 }))
                    } else {
                        if (i >= 0) { setDate(p => ({ ...p, d: i })) }
                    }
                    if (ele) { m.current.focus() }

                }} />
            {/* <TextInput mode="flat" value="-" editable={false} style={{  textAlign: "center", padding: 0, margin: 0 }} /> */}
            {/* <View style={{ backgroundColor: colors.surfaceVariant, height: "100%", justifyContent: "center" }}>
                <Text >-</Text>
            </View> */}
            <Text >-</Text>
            <TextInput ref={m} keyboardType="number-pad" multiline={false} numberOfLines={1} style={{ textAlign: "center", width: 60 }} placeholder="MM" maxLength={2}
                error={dt.m > 12 || dt.d <= 0}
                onKeyPress={(e) => {
                    if (e.nativeEvent.key == "ArrowUp") {
                        if (dt.m < 12) {
                            setDate(p => ({ ...p, m: dt.m + 1 }))
                        }
                    }
                    if (e.nativeEvent.key == "ArrowDown") {
                        if (dt.m > 1) {
                            setDate(p => ({ ...p, m: dt.m - 1 }))
                        }
                    }
                }}
                value={dt.m ? dt.m + "" : ""} onChangeText={(tF) => {
                    let ele = (tF.length == 2 && dt.m.toString().length == 1)
                    const i = parseInt(tF)
                    if (isNaN(i)) {
                        setDate(p => ({ ...p, m: 0 }))
                    } else {
                        if (i >= 0) {
                            setDate(p => ({ ...p, m: i }))
                        }
                    }
                    if (ele) { y.current.focus() }

                }
                } />
            <Text >-</Text>
            <TextInput ref={y} keyboardType="number-pad" multiline={false} numberOfLines={1} style={{ textAlign: "center", width: 80 }} placeholder="YYYY" maxLength={4}
                error={dt.y < 1950 || dt.y > 2030}
                onKeyPress={(e) => {
                    if (e.nativeEvent.key == "ArrowUp") {
                        if (dt.y < 2030) {
                            setDate(p => ({ ...p, y: dt.y == 0 ? new Date().getFullYear() : dt.y + 1 }))
                        }
                    }
                    if (e.nativeEvent.key == "ArrowDown") {
                        if (dt.y > 1950) {
                            setDate(p => ({ ...p, y: dt.y == 0 ? new Date().getFullYear() : dt.y - 1 }))
                        }
                    }
                }}
                value={dt.y ? dt.y + "" : ""} onChangeText={(tF) => {
                    const i = parseInt(tF)
                    if (isNaN(i)) {
                        setDate(p => ({ ...p, y: 0 }))
                    } else {
                        if (i >= 0) { setDate(p => ({ ...p, y: i })) }
                    }
                }} />
        </View>
        {result.isValid ? <HelperText style={{}}>{dayjs.unix(result.stamp).format("dddd D MMMM YYYY")}</HelperText>
            : <HelperText style={{ color: "red" }}>Invalid Date</HelperText>}

    </View >

}


export default DCalendar;