import { ScrollView, View } from 'react-native';
import { Button, HelperText, IconButton, Text, useTheme } from "react-native-paper";
import { Avatar } from "react-native-paper";
import React, { useEffect, useRef, useState } from "react";
import Global from '../constants/global';
import ReactToPrint from 'react-to-print';
import { MaterialIcons } from '@expo/vector-icons';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;


export default function PrintMarksheet({ navigation, route }) {
    // const [value, setValue] = useState({ subjects: undefined, exam: undefined, student: undefined, gained: 0, total: 0 })
    const [list, setList] = useState([])
    useEffect(() => {
        console.log("print list", route.params.list)
        const newList: [] = []
        route.params.list.map(e => {
            const sorted = e.subjects.sort((a, b) => (a?.order ? a.order : 0) - (b?.order ? b.order : 0))
            const ll = { ...e }
            ll.subjects = sorted
            newList.push(ll)
        })
        setList(newList)
    }, [route.params])
    const ht = 27
    const bw1 = 3
    const bw2 = 2
    const ref = useRef()
    let pg1 = 0
    const t = useTheme()
    function purge() {
        const db = getFirestore()
        list.map(value => {
            const st = {}
            const ab = []
            const failed = []
            const pass = []
            const pending = []
            value?.subjects?.filter(e => e.theory !== undefined)?.map(subject => {
                const res = {
                    internal: 0,
                    left: false,
                    name: subject.name,
                    practical: 0,
                    roll: value?.student?.roll,
                    status: subject?.subject_status,
                    strem: value?.student?.stream ? value?.student?.stream : 0,
                    student_id: value?.student?.id,
                    student_name: value?.student?.name,
                    subject_id: subject.id,
                    teacher: subject.teacher ? subject.teacher : "",
                    teacherName: subject.teacherName ? subject.teacherName :"",
                    theory: subject.theory,
                    totalMarks: subject.totalMarks
                }
                st[subject.id] = res
                if (value?.status == "Pass") {
                    pass.push(subject.name)
                } else if (value?.status == "Fail") {
                    failed.push(subject.name)
                } else if (value?.status == "Absent") {
                    ab.push(subject.name)
                } else {
                    pending.push(subject.name)
                }
            })
            st.pc = value?.pc ? value?.pc : 0
            st.rank = value?.student?.status == "Pass" ? (value?.rank) : 0
            st.status = value?.student?.status
            st.year = 2022
            st.subjectMarks = value.gained
            st.totalMarks = value.total
            st.exam = route.params.exam
            st.exam.exam = 1
            st.pass = pass
            st.failed = failed
            st.absent = ab
            st.classInfo = route.params.class
            const nval = JSON.parse(JSON.stringify(value))
            delete nval.days
            st.student = nval

            const ref = doc(db, "Users", value?.student?.id)
            try {
                updateDoc(ref, { [`marks.${2022}.${st.exam.id}`]: st },
                    { merge: true }).then(e => console.log("done", e))
                console.log("done ", st.student.name)
            } catch (e) {
                console.log(st)
                console.log(e)
            }
            // value.gained
            // //value?.student?.status == "Pass" ? (value?.pc ? value?.pc + " %" : "") : "-"
            // const pc = "Pass" ? (value?.pc ? value?.pc + " %" : "") : "-"
            // const rank = value?.student?.status == "Pass" ? (value?.rank) : 0

        })
    }
    return <ScrollView horizontal style={{ flex: 1, alignSelf: "stretch" }}>
        <Button onPress={() => { purge() }}>Purge</Button>
        <View style={{ minWidth: 700 }}>
            <View style={{ flexDirection: "row" }}>
                <IconButton icon="arrow-left" style={{}} onPress={() => navigation.goBack()} />
                <ReactToPrint
                    pageStyle={pageStyle}
                    trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                    content={() => ref.current}
                />
            </View>
            <div ref={ref} style={{ display: "block", width: '100%', marginLeft: "10%", marginRight: "10%" }}>
                <style type="text/css" media="print">{"@page { size: portrait}"}</style>

                {list.map(value => {
                    pg1++
                    return <div style={{ pageBreakInside: "avoid", paddingTop: 30 }}>
                        <div style={{ display: "block", width: '100%', borderWidth: bw1, borderStyle: "solid", marginBottom: 50 }}>

                            <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#DCDCDC", borderBottomWidth: bw2, borderBottomStyle: "solid" }}>
                                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar.Image style={{ marginTop: 5, marginEnd: 10 }} size={50} source={{ uri: "https://gtech.co.in/stvm/LOGO1.jpg" }} />
                                    <Text variant="headlineSmall" style={{ fontWeight: "900" }}>શ્રી ઠક્કરબાપા વિદ્યામંદિર</Text>
                                </View>
                                <View style={{ alignItems: "center", justifyContent: "center", flex: 1, flexDirection: "row" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>{"પ્રથમ કસોટી "} પરિણામ</Text>
                                    <Text variant="titleLarge" style={{ textAlign: "center", fontWeight: "900" }}> : વર્ષ : 2022-23 </Text>

                                </View>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginLeft: 10 }}>
                                <View>
                                    <Text variant="titleMedium">વિદ્યાર્થી નું નામ   :   </Text>
                                    <Text variant="titleMedium" style={{ marginTop: 10, textAlign: "right", flex: 1, fontWeight: "900" }}>{value?.student?.class} {value?.class?.div}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text>
                                        <Text variant="titleMedium" style={{ fontWeight: "900", borderBottomWidth: bw2, borderStyle: "solid" }}>{value?.student?.name}</Text>
                                    </Text>
                                    <View style={{ flex: 1, flexDirection: "row", marginTop: 10 }}>
                                        <Text variant="titleMedium" style={{ textAlign: "right", flex: 1, fontWeight: "900" }}>રોલ નં - {value?.student?.roll}</Text>
                                        <Text variant="titleMedium" style={{ textAlign: "center", flex: 1, fontWeight: "900" }}>GR નં - {value?.student?.gr}</Text>
                                    </View>
                                </View>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <View style={{ width: "70%", alignSelf: "stretch", borderRightWidth: 2, borderStyle: "solid" }}>
                                    <View style={{ alignItems: "center", justifyContent: "center", paddingLeft: 5, backgroundColor: "#DCDCDC", flexDirection: "row", borderTopWidth: bw2, borderStyle: "solid", borderBottomWidth: bw2 }}>
                                        <View style={{ height: (ht * 1.25), flex: 3, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                            <Text variant="titleMedium" style={{ textAlign: "center" }}>અ.નં</Text>
                                        </View>

                                        <View style={{ height: (ht * 1.25), flex: 20, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                            <Text variant="titleMedium" style={{ textAlign: "center" }}>વિષય</Text>
                                        </View>

                                        <View style={{ height: (ht * 1.25), flex: 5, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                            <Text variant="titleMedium" style={{ textAlign: "center" }}>કુલ ગુણ</Text>
                                        </View>

                                        <View style={{ height: (ht * 1.25), flex: 7, alignItems: "center", justifyContent: "center" }}>
                                            <Text variant="titleMedium" style={{ textAlign: "center" }}>મેળવેલ ગુણ</Text>
                                        </View>
                                    </View>

                                    {
                                        value?.subjects?.filter(e => e.theory !== undefined)?.map((subject, sr) => {
                                            let showM = subject.theory
                                            try {
                                                const nt = parseInt(subject.theory)
                                                if (nt !== NaN && nt < 10) {
                                                    showM = "0" + nt
                                                }
                                            } catch (e) {

                                            }
                                            return (
                                                <View style={{ alignItems: "center", justifyContent: "center", paddingLeft: 5, flexDirection: "row", borderBottomWidth: bw2, borderStyle: "solid" }}>
                                                    <View style={{ height: ht, flex: 3, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                                        <Text variant="titleMedium" style={{ textAlign: "center", }}>{sr + 1}</Text>
                                                    </View>

                                                    <View style={{ height: ht, flex: 20, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                                        <Text variant="titleMedium" style={{ textAlignVertical: "center", overflow: "hidden" }}> {subject.name}</Text>
                                                    </View>
                                                    <View style={{ height: ht, flex: 5, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                                        <Text variant="titleMedium" style={{ fontWeight: "900", textAlignVertical: "center", textAlign: "center", }}> {subject.totalMarks}</Text>
                                                    </View>
                                                    <View style={{ height: ht, flex: 7, alignItems: "center", justifyContent: "center" }}>
                                                        <Text variant="titleMedium" style={{ fontWeight: "900", textAlign: "center" }}> {showM}</Text>
                                                    </View>
                                                </View>)
                                        })
                                    }
                                    <View style={{ backgroundColor: "#DCDCDC", paddingLeft: 5, flexDirection: "row", borderBottomWidth: bw2 }}>
                                        <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 23, textAlign: "center", borderRightWidth: bw2, borderStyle: "solid" }}>કુલ ગુણ</Text>
                                        <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 5, textAlign: "center", borderRightWidth: bw2, borderStyle: "solid" }}>{value.total}</Text>
                                        <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 7, textAlign: "center" }}>{value.gained}</Text>
                                    </View>

                                </View>
                                <View style={{ flexDirection: "row", width: "30%", borderTopWidth: bw2, borderBottomWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                    <View style={{ justifyContent: "center", alignItems: 'center' }}>
                                        <Text style={{ height: 35 }} variant="titleMedium">પરિણામ </Text>
                                        <Text style={{ height: 35 }} variant="titleMedium">ટકાવારી </Text>
                                        <Text style={{ height: 35 }} variant="titleMedium">પાસ નંબર </Text>
                                    </View>

                                    <View style={{ justifyContent: "center", alignItems: 'center', borderWidth: bw1, borderStyle: "solid", }}>
                                        <Text variant="titleMedium" style={{ height: 35, borderBottomWidth: bw1, fontWeight: "900", textAlign: "center", width: 100, }}>{value?.student?.status}</Text>
                                        <Text variant="titleMedium" style={{ height: 35, borderBottomWidth: bw1, fontWeight: "900", borderStyle: "solid", width: 100, textAlign: "center" }}> {value?.student?.status == "Pass" ? (value?.pc ? value?.pc + " %" : "") : "-"} </Text>
                                        <Text variant="titleMedium" style={{ height: 35, fontWeight: "900", borderStyle: "solid", width: 100, textAlign: "center" }}> {value?.student?.status == "Pass" ? (value?.rank) : "-"}  </Text>
                                    </View>
                                </View>
                            </div>
                            <div style={{ display: "flex", marginTop: 40, flex: 1, flexDirection: "row" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ flex: 1, textAlign: "center" }}>વર્ગશિક્ષકની  સહી</Text>
                                    {/* <HelperText style={{ flex: 1, textAlign: "center" }}></HelperText> */}
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ flex: 1, textAlign: "center" }}>વાલીની સહી</Text>
                                    {/* <HelperText style={{ flex: 1, textAlign: "center" }}></HelperText> */}
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ flex: 1, textAlign: "center" }}>આચાર્યની સહી</Text>
                                    {/* <HelperText style={{ flex: 1, textAlign: "center" }}></HelperText> */}
                                </View>
                            </div>
                        </div >
                        {pg1 == 2 && <div className="pagebreak" />}
                    </div>
                })}
            </div>
        </View>
    </ScrollView>
}