import { ScrollView, View } from 'react-native';
import { IconButton, Text, useTheme } from "react-native-paper";
import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';

import dayjs from 'dayjs';
import { MaterialIcons } from '@expo/vector-icons';
import Global from '../constants/global';

const totalTag = "Total Students"
const resultTag = "Result (%)"
const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
      page-break-after: always;
    }
  }

  @media print {
    .pagebreak {
      page-break-after: always;
    }
  }
`;
const rMarginL = 1
const rMarginT = 1
const rMarginB = 1
const rW = 60
const rH = 28
const tS = "titleSmall"
const rS = { textAlign: "center", paddingTop: rMarginT, paddingBottom: rMarginB, width: rW, height: rH, borderRightWidth: .25, borderStyle: "solid", flex: 1 }
const rTS = { textAlign: "center", paddingTop: 0, paddingBottom: 0, width: rW, height: 22, borderRightWidth: .25, borderStyle: "solid", flex: 1 }

export default function SummarySheet({ navigation, route }) {
    const [value, setValue] = useState({ class: undefined, student: [], subjects: [], exam: undefined, tarij: 0, totalStudents: 0, attend: 0, pass: 0, fail: 0, ranklist: [], left: 0, year: "" })


    const RollSheet = ({ till, above, marks }) => {
        // console.log("marks", marks)
        const russia = marks.filter(e => e.roll <= till && e.roll >= above && e?.totalling !== true)
        const bcol = useTheme().dark ? "transparent" : "#DCDCDC"
        return <View style={{ marginRight: 2 }}>
            {russia.length > 0 &&
                <View>
                    <View style={{ flexDirection: "row", alignSelf: "stretch", borderLeftWidth: 1, borderTopWidth: 1, borderStyle: "solid", height: rH }}>
                        <Text variant="titleMedium" style={{ ...rS, fontWeight: "900", backgroundColor: bcol }}>રોલ નં</Text>
                        <Text variant="titleMedium" style={{ ...rS, fontWeight: "900" }}>વર્ગકાર્ય</Text>
                        <Text variant="titleMedium" style={{ ...rS, fontWeight: "900" }}>ગૃહકાર્ય</Text>
                        <Text variant="titleMedium" style={{ ...rS, fontWeight: "900" }}>પ્રોજેક્ટ</Text>
                        <Text variant="titleMedium" style={{ ...rS, fontWeight: "900" }}>વર્તણૂક</Text>
                        <Text variant="titleMedium" style={{ ...rS, fontWeight: "900" }}>કુલગુણ</Text>
                    </View>
                    {
                        russia?.map((s, ii) => {
                            // const able = (s.marks == "Ab" || s.marks == "Left") ? { backgroundColor: "#DCDCDC" } : {}
                            // const able = {}
                            return <View style={{ borderTopWidth: 1, flexDirection: "row", height: 22, borderLeftWidth: 1, borderBottomWidth: (ii == (russia.length - 1)) ? 1 : 0 }}>
                                <Text variant={tS} style={{ ...rTS, backgroundColor: bcol, borderBottomWidth: 1 }}>{s.roll}</Text>
                                <Text variant={tS} style={rTS}>{s?.i1}</Text>
                                <Text variant={tS} style={rTS}>{s?.i2}</Text>
                                <Text variant={tS} style={rTS}>{s?.i3}</Text>
                                <Text variant={tS} style={rTS}>{s?.i4}</Text>
                                <Text variant={tS} style={rTS}>{s?.marks}</Text>
                            </View>
                        })
                    }
                </View>
            }
        </View >
    }

    useEffect(() => {
        if (value.class == undefined) {
            const subj = [...route.params.subjects]
            const stud = [...route.params.student]
            const djs = dayjs.unix(route.params.exam?.createdOn)

            let yr = ""
            if (djs.month() < 6) {
                yr = (djs.year() - 1) + "-" + djs.format("YY")
            } else {
                const y = djs.year()
                const ndjs = djs.add(1, "year")
                yr = y + "-" + ndjs.format("YY")
            }

            setValue({ student: stud, subjects: subj, class: { ...route.params.class }, exam: { ...route.params.exam }, year: yr })
        }
    }, [route.params])

    const ref = useRef()
    const t = useTheme()
    return <ScrollView>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start" }}>
            <IconButton icon={"arrow-left"} onPress={() => navigation.goBack()} />
            <ReactToPrint
                pageStyle={pageStyle}
                trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                content={() => ref.current}
            />
        </View>
        <ScrollView horizontal>
            <div ref={ref} style={{ display: "block", width: "98%", marginLeft: 30, boxSizing: "border-box" }}>
                {value.subjects.map(s => {
                    const allStudents: [] = []
                    value.student.map(student => {
                        const res = student?.marks?.[value.exam.year]?.[value.exam.id]?.[s.id]
                        const std = student?.marks?.[value.exam.year]?.[value.exam.id]?.student
                        let marks = ""
                        let marksi1
                        let marksi2
                        let marksi3
                        let marksi4
                        if (std?.left == true || std?.name.trim().toLowerCase() == "left") {
                            marks = "Left"
                        } else {
                            if (res) {
                                if (res.theory == 'Absent') {
                                    marks = "Ab"
                                } else {
                                    marks = res.internal
                                    marksi1 = res?.i1
                                    marksi2 = res?.i2
                                    marksi3 = res?.i3
                                    marksi4 = res?.i4
                                }
                            }
                        }
                        allStudents.push({ roll: student["class" + value.exam.year]?.roll, marks: marks, i1: marksi1, i2: marksi2, i3: marksi3, i4: marksi4 })
                    })


                    return <div style={{ display: "flex", flexDirection: "column", pageBreakInside: "avoid", flex: 1, marginLeft: 50 }}>
                        <Text style={{ flex: 1, padding: 10, backgroundColor: "green", color: "white", textAlign: "center" }} variant="titleLarge">પરીક્ષાનું ગુણપત્રક - {value.exam?.name} {Global.YearString(value.exam?.year)}</Text>
                        <View style={{ flexDirection: "row", marginTop: 8, }}>
                            <Text style={{ flex: 2, fontWeight: "900" }} variant="titleLarge">{value.class?.name}  {value.class?.div}</Text>
                            <Text variant="titleLarge" style={{ textAlign: "center", flex: 2 }}>મૂલ્યાંકનની તારીખ  __________</Text>
                        </View>
                        <View style={{ marginTop: 8, flexDirection: "row" }}>
                            <View style={{ flexDirection: "row", flex: 2 }}>
                                <Text style={{}} variant="titleLarge">વિષય  </Text>
                                <Text style={{ fontWeight: "900", borderBottomWidth: 1 }} variant="titleLarge">{s?.name}  </Text>
                            </View>
                            <View style={{ flexDirection: "row", flex: 1 }}>
                                <Text style={{}} variant="titleLarge">કુલગુણ  </Text>
                                <Text style={{ borderBottomWidth: 1, fontWeight: "900" }} variant="titleLarge">{s?.totalMarks}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 8 }}>
                            <RollSheet till={35} above={0} marks={allStudents} />
                            <RollSheet till={71} above={36} marks={allStudents} />
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 40 }}>
                            <View style={{ flex: 1, flexDirection: "row" }}>
                                <Text variant="titleLarge" style={{ textAlign: "center" }}>તપાસનારની </Text>
                                <View style={{ flex: 1, flexDirection: "row", marginTop: 25 }}>
                                    <Text variant="titleMedium" style={{ textAlign: "center", flex: 1, borderTopWidth: 1, borderStyle: "solid" }}>સહી</Text>
                                    <Text variant="titleMedium" style={{ flex: 1, textAlign: "center", borderTopWidth: 1, borderStyle: "solid", marginRight: 10, marginLeft: 10 }}>તારીખ</Text>
                                </View>
                            </View>
                            <View style={{ flex: 1, flexDirection: "row" }}>
                                <Text variant="titleLarge" style={{ textAlign: "center" }}>વર્ગ શિક્ષકની</Text>
                                <View style={{ flex: 1, flexDirection: "row", marginTop: 25 }}>
                                    <Text variant="titleMedium" style={{ textAlign: "center", flex: 1, borderTopWidth: 1, borderStyle: "solid" }}>સહી</Text>
                                    <Text variant="titleMedium" style={{ flex: 1, textAlign: "center", borderTopWidth: 1, borderStyle: "solid", marginRight: 10, marginLeft: 10 }}>તારીખ</Text>
                                </View>
                            </View>
                        </View>

                    </div>
                })}
                <div className="pagebreak" />
            </div>

            {/* <Text style={{ flex: 1 }}  >Generated on {dayjs().format("DD/MM/YY HH:mm")}</Text> */}
        </ScrollView>
    </ScrollView >


}