import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { Alert, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, FlatList } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Chip, Button, HelperText } from 'react-native-paper';

import * as React from 'react';
import DonationRow from '../components/DonationRow';
import { useTheme } from 'react-native-paper';
import NotificationRow from '../components/NotificationRow';
import ReactNativeModal from 'react-native-modal';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import DImage from '../components/DImage';
import { AntDesign } from '@expo/vector-icons';
import Global from '../constants/global';
import { StatusBar } from 'expo-status-bar';


let baselist: [] = []


export default function ExamList({ navigation, route }) {
    const bhudevList = useSelector((state) => state.list.Exams)
    // const marks = useSelector((state) => state.list.Marks)
    const [alllist, setAllList] = React.useState({ baselist: [] })
    const [focus, setFocus] = React.useState(undefined)

    React.useEffect(() => {
        const a = []
        baselist = bhudevList.map(e => {
            if (e.flag !== -10) {
                a.push({ ...e })
            }
        })
        const nnewP = a.sort((a, b) => a?.createdOn < b?.createdOn ? -1 : 0)
        const year = Global.getYear()

        const e1 = bhudevList.find(f => f.year == year && f.exam == 1)
        const e2 = bhudevList.find(f => f.year == year && f.exam == 2)
        const e3 = bhudevList.find(f => f.year == year && f.exam == 3)
        const e4 = bhudevList.find(f => f.year == year && f.isInternal == true)
        const marksheet = bhudevList.find(f => f.year == year && f.final == true)
        navigation.navigate("GenerateMarks", { data: focus, allExams: { e1: e1, e2: e2, e3: e3, internal: e4, marksheet: marksheet } })

    }, [bhudevList]);
    const { colors } = useTheme();

    return (
        <View style={{ backgroundColor: colors.background, flex: 1, paddingTop: route?.params?.topMargin ? 20 : 0 }}>
            <ReactNativeModal
                isVisible={focus !== undefined}
                onBackdropPress={() => setFocus(undefined)}
                onDismiss={() => setFocus(undefined)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: colors.surface }}>
                    <View style={{ justifyContent: "center" }}>
                        <Text variant="titleLarge" style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{focus?.name}...</Text>
                        <TouchableOpacity onPress={() => { setFocus(undefined); navigation.navigate("NewExam", { data: focus }) }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="lead-pencil" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Edit</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            // if (focus?.version == 2) {
                            const e1 = bhudevList.find(f => f.year == focus.year && f.exam == 1)
                            const e2 = bhudevList.find(f => f.year == focus.year && f.exam == 2)
                            const e3 = bhudevList.find(f => f.year == focus.year && f.exam == 3)
                            const e4 = bhudevList.find(f => f.year == focus.year && f.isInternal == true)
                            const marksheet = bhudevList.find(f => f.year == focus.year && f.final == true)
                            navigation.navigate("GenerateMarks", { data: focus, allExams: { e1: e1, e2: e2, e3: e3, internal: e4, marksheet: marksheet } })
                            setFocus(undefined);
                            // } else {
                            //     navigation.navigate("PutMarks", { data: focus })
                            // }
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="checkbox-marked-circle-plus-outline" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Put Marks</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() =>
                        // Alert.alert('Delete', 'Sure want to delete student ' + focus.name, [
                        //     { text: 'Canecl' },
                        //     { text: 'No' },
                        //     {
                        //         text: 'Yes', onPress: () => {
                        //             delStudent()
                        //         }
                        //     },
                        // ]
                        { Toast.show("Cannot Delete Locked Exam") }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="delete" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Delete</Text>
                            </View>
                        </TouchableOpacity>

                    </View>
                    <IconButton icon={"window-close"} onPress={() => setFocus(undefined)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </ReactNativeModal>


        </View >

    );


}

