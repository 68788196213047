// https://developer.mozilla.org/en-US/docs/Web/Manifest/share_target
import React, { useEffect, useState } from 'react';
import DModal from './DModal';
import { View } from './Themed';
import { Text, Button } from 'react-native-paper';
import { Image } from 'expo-image';


const InstallPrompt = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };
        // console.log("reginstering beforeinstallap")
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);
    // console.log("reginstering bef ", deferredPrompt)

    const handleInstallClick = () => {
        // console.log("inside click")
        //      alert("clicked")
        if (deferredPrompt) {
            // console.log("inside click 1")
            //            alert("prompting")
            deferredPrompt?.prompt();
            deferredPrompt?.userChoice?.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };
    const dp = require("../assets/images/icon.png")

    return (
        <DModal
            fromUp={true}
            in={"slideInUp"}
            out="slideOutDown"
            style={{ flex: 1, alignSelf: "stretch", backgroundColor: "#637b7b" }}
            isVisible={deferredPrompt !== null}
            onDismiss={() => setDeferredPrompt(null)}>
            <Text variant="titleLarge" style={{ fontWeight: "bold", flex: 1, color: "white" }}>{"શ્રી ઠક્કરબાપ વિદ્યામંદિર"}</Text>
            <View style={{ justifyContent: "center", alignItems: "center", flex: 1, flexDirection: "row" }}>
                <Image contentFit={"cover"} source={dp} style={{ height: 100, width: 100 }} />
                <Text style={{ flex: 1, color: "white" }}>For Better Experience, Notification, and easy usage kindly Install App. </Text>
            </View>
            <View style={{ flexDirection: "row", flex: 1, alignSelf: "stretch" }}>
                <Button textColor='white' onPress={() => { setDeferredPrompt(null) }} style={{ flex: 1 }}>Later</Button>
                <Button onPress={handleInstallClick} mode="contained" style={{ flex: 1 }}>Install</Button>
            </View>

        </DModal >

        // <button onClick={handleInstallClick} style={{ display: deferredPrompt ? 'block' : 'none' }}>
        //   Install App
        // </button>
    );
};

export default InstallPrompt;
