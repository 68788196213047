import React, { useState } from "react";
import DModal from "./DModal";
import { Text, View } from "./Themed";
import { TouchableOpacity } from "react-native";
import Global from "../constants/global";
import DIcon from "./DIcon";

const DYear = ({ show, year, change, close }) => {
    const [currentYear, setCY] = useState(year)
    return <DModal
        isVisible={show}
        close={() => close()}
    >
        <Text variant="headlineMedium">Select year</Text>
        <TouchableOpacity onPress={() => { setCY(2022); change(2022) }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                {currentYear == 2022 ? <DIcon name="check" color="green" /> : <View style={{ paddingLeft: 24 }}></View>}
                <Text style={{ padding: 10, marginTop: 10 }} variant="headlineSmall">2022-23</Text>
            </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => { setCY(2023); change(2023) }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                {currentYear == 2023 ? <DIcon name="check" color="green" /> : <View style={{ paddingLeft: 24 }}></View>}
                <Text style={{ padding: 10 }} variant="headlineSmall">2023-24</Text>
            </View>
        </TouchableOpacity>
    </DModal>
}

export default React.memo(DYear)