import { useContext, memo, useState, useEffect } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { Button, Chip, IconButton, Text, TextInput, useTheme } from "react-native-paper";



const DashTitle = memo((op) => {
   // console.log("oppp", op)
    return (<Text style={{ margin: 10 }} variant="headlineSmall">{op.title}</Text>)
})
export default DashTitle