import { Dimensions, ScrollView, StyleSheet } from 'react-native';

import { View } from '../components/Themed';
import Notireg from '../components/Notireg';
import Loader from '../components/Loader';

import * as React from 'react';
import Update from '../components/Update';
import Dashboard from '../components/Dashboard';
import { FAB, useTheme } from 'react-native-paper';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import Snack from './Snack';

export default function Index({ navigation }) {
  const { colors } = useTheme()
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={{ backgroundColor: colors.background }} style={{ flex: 1, height: Dimensions.get("screen").height }}>
        <Loader />
        {/* <Notireg /> */}
        <Dashboard navigation={navigation} />
        <Update />
      </ScrollView>
      <Snack/>

    </SafeAreaView>);
}

