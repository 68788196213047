import { Video } from 'expo-av'
import * as React from 'react'
import {Image as RImage, Platform, View, Dimensions, Pressable,  TouchableOpacity, Linking } from 'react-native'
import { Image } from "expo-image"

import Modal from 'react-native-modal'
import { ActivityIndicator, IconButton } from 'react-native-paper'
import Zoom from './Zoom'
import { Audio } from "expo-av"
import Global from '../constants/global'
interface Props {
    uri: string
    width?: number
    height?: number
    contentFit?: string
    style?
}
interface State {
    source: {}
    width: number
    height: number
    contentFit: string

}

const ScaledImage = React.memo((op) => {
    const [zoom, setZoom] = React.useState(false);
    const [play, setPlay] = React.useState(false);
    const [src, setSrc] = React.useState(undefined);
    const [showVideo, setShowVideo] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({
        source: { uri: op.uri },
        width: 0,
        height: 0,
        clickable: op.clickable ? op.clickable : false,
        contentFit: op.contentFit ? op.contentFit : "cover"
    })
    React.useEffect(() => {
        if (op.height && op.width) {
            setState(p => ({
                ...p, source: { uri: op.uri },
                height: op.height,
                width: op.width,
                clickable: op.clickable ? op.clickable : false,
                contentFit: op.contentFit ? op.contentFit : "cover"
            }))

        }
    }, [op])

    let ss = ""
    if (state.source) {
        ss = state.source.uri.toLowerCase()
    }
    const ext = ss.split('.').pop()?.toLowerCase()
    // console.log("ext ." + ext + ".", state.source.uri)
    const image = ext == "jpg" || ext == "jpeg" || ext == "webp" || ext == "gif" || ext == "png" || ext == "jfif"
    const video = ext == "mp4" || ext == "mpeg"
    const pdf = ext == "pdf"
    const audio = ext == "mp3" || ext == "m4a"
    const other = !image && !video && !audio && !pdf

    const videoRef = React.useRef(null);

    async function playSounc() {
        if (src) {
            src.playAsync()
        } else {
            const { sound } = await Audio.Sound.createAsync({ uri: state.source.uri })
            setSrc(sound);
            await sound.playAsync();
        }
    }
    React.useEffect(() => {
        if (play) {
            playSounc()
        } else {
            src?.pauseAsync()
        }
    }, [play])
    React.useEffect(() => {
        if (image) {
            RImage.getSize(op.uri, (width, height) => {
                if (op.width && op.height) {
                    setState(p => ({ ...p, width: op.width, height: op.height, source: { uri: op.uri } }))
                } else if (op.width && !op.height) {
                    setState(p => ({ ...p, width: op.width, height: height * (op.width / width), source: { uri: op.uri } }))
                } else if (!op.width && op.height) {
                    setState(p => ({ ...p, width: width * (op.height / height), height: op.height, source: { uri: op.uri } }))
                } else {
                    setState(p => ({ ...p, width: width, height: height, source: { uri: op.uri } }))
                }
            }, (error) => {
                //   console.log("ScaledImage:componentWillMount:Image.getSize failed with error: ", error)
            })

        } else {
            setState(p => ({ ...p, source: { uri: op.uri } }))
        }

    }, [])

    return <View>
        {image && <Pressable onPress={() => setZoom(true)}>
            <Image contentFit={state.contentFit} source={state.source} style={[op.style, { height: state.height, width: state.width }]} />

        </Pressable>}
        {video &&
            <View style={{
                width: op.width ? op.width : Dimensions.get("window").width,
                height: op.height ? op.height : (Dimensions.get("window").width * 9) / 16
            }}
            >
                <Video
                    ref={videoRef}
                    style={{
                        width: op.width ? op.width : Dimensions.get("window").width,
                        height: op.height ? op.height : (Dimensions.get("window").width * 9) / 16
                    }}
                    source={state.source}
                    useNativeControls
                    contentFit="cover"
                    isLooping={false}
                />
                <IconButton icon="fullscreen" onPress={() => setShowVideo(true)} style={{ padding: 0, margin: 0, position: "absolute", top: 0, right: 0 }} />
            </View>
        }
        {(pdf || other) &&
            <View
                style={{
                    width: op.width ? op.width : Dimensions.get("window").width,
                    height: op.height ? op.height : (Dimensions.get("window").width * 9) / 16
                    , alignItems: "center", justifyContent: 'center'
                }}
            >
                <TouchableOpacity onPress={() => {
                    // Linking.openURL("http://docs.google.com/gview?embedded=true&url=" + state.source.uri).catch((err) => {
                    //     console.log(err)
                    // });
                    // Sharing.shareAsync(
                    //     "http://docs.google.com/gview?embedded=true&url=" + state.source.uri,
                    //     { dialogTitle: 'share or copy your pdf via' }
                    // ).catch(error => {
                    //     console.log(error);
                    // })
                    //setPdf(true);
                    //setLoading(true)
                    if (Platform.OS == "web") {
                        window.open("http://docs.google.com/gview?embedded=true&url=" + state.source.uri, "_blank")?.focus()
                    } else {
                        if (Platform.OS == "android") {
                            Linking.openURL("http://docs.google.com/gview?embedded=true&url=" + state.source.uri).catch((err) => {
                                console.log(err)
                            });
                        }
                    }
                }}>
                    {pdf && <Image source={require("../assets/images/pdf.png")} style={{ height: 70, width: 50, backgroundColor: "transparent" }} />}
                    {other && <IconButton size={40} icon={"file-document-outline"} />}
                </TouchableOpacity>
            </View>
        }
        {(audio) &&
            <View
                style={{
                    width: op.width ? op.width : Dimensions.get("window").width,
                    height: op.height ? op.height : (Dimensions.get("window").width * 9) / 16
                    , alignItems: "center", justifyContent: 'center'
                }}
            >

                <IconButton size={40} icon={"music"} onPress={() => { setPlay(!play) }} />

            </View>
        }
        {zoom && <Zoom source={state.source} show={zoom} close={() => setZoom(false)} />}
        {showVideo &&

            <Modal isVisible={showVideo} onBackdropPress={() => setShowVideo(false)} style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="fadeIn" animationOut={"fadeOut"}>
                <View style={{ backgroundColor: "rgba(0,0,0,0.7)", height: Dimensions.get("window").height, width: Dimensions.get("window").width, alignItems: "center", justifyContent: "center" }}>
                    <Video
                        ref={videoRef}
                        style={{
                            width: Dimensions.get("window").width,
                            height: (Dimensions.get("window").width * 9) / 16
                        }}
                        source={state.source}
                        useNativeControls
                        contentFit="cover"
                        isLooping={false}
                    />

                    <IconButton iconColor='white' style={{ position: "absolute", top: 0, right: Global.isMobile() ? 0 : 30 }} icon="window-close" onPress={() => setShowVideo(false)} />
                </View>
            </Modal>
        }
        {/* {showPdf &&
            <Modal isVisible={showPdf} style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="fadeIn" animationOut={"fadeOut"}>
                <View>

                    {Platform.OS == "" < WebView javaScriptEnabled={true}
                    bounces={false}
                    onLoadStart={() => setLoading(true)}
                    onLoadEnd={() => setLoading(false)}
                    source={{ uri: "http://docs.google.com/gview?embedded=true&url=" + state.source.uri }}
                    style={{ flex: 1, width: Dimensions.get("window").width, height: Dimensions.get("window").height }} />
                    <IconButton style={{ position: "absolute", right: 0, top: 0 }} icon="close" onPress={() => setPdf(false)} />
                    <ActivityIndicator animating={loading} style={{ position: "absolute", top: 20, left: 20 }} />}
                </View>
            </Modal>
        }
 */}

    </View >
})
export default ScaledImage