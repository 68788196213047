import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Share } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import { collection, doc, getFirestore, increment, setDoc, updateDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import Prferances from '../constants/Preferances';
import dayjs from "dayjs";
import { useSelector } from "react-redux";


let baselist: [] = []
export default function Donation({ navigation, route }) {
    const [focus, setFocus] = React.useState(undefined)
    const [mode, setMode] = React.useState(0)
    const { colors } = useTheme();
    const numbering = useSelector(state => state.list.Numbering)
    const pref = React.useContext(Prferances)
    React.useEffect(() => {
        if (route?.params?.mode) {
            setMode(route.params.mode)
            setFocus(route.params.data)
        } else {
            setMode(0)
        }
    }, [])

    return (
        <ScrollView style={{ flex: 1 }}>

            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                    <IconButton size={60} icon="hand-coin-outline" style={{alignSelf:"center"}}/>

                {mode == 0 && <Text variant="headlineLarge" style={{ flex: 1, textAlign: "center" }}>સાથી હાથ મિલાના </Text>}
                {mode == 0 && <Text variant="titleLarge" style={{ marginTop: 10 }}>આપના હૃદયમાં પરોપકારની ઈચ્છા બદલ ખૂબ ખૂબ અભિનંદન,  આપ અનેક પ્રકારે સહયોગ કરી શકો છો, જેમકે ચોપડીઓ, નોટબુક, ડ્રેસ, કોંપ્યુટર, બોર્ડ, સ્માર્ટબોર્ડ, રમત ગમત ના સાધનો વગેરે.... આપનો નાણાકીય સહયોગ  income tax માં 80c અંતર્ગત બાદ મળવાપાત્ર રહેશે   </Text>}
                {mode == 0 && <Text variant="titleLarge" style={{ marginTop: 10 }}>આપની રૂબરૂ મુલાકાત આવકારદાયક છે</Text>}
                {mode == 0 && <Text variant="titleLarge" style={{ marginTop: 10 }}>સંપર્ક માટે વિગતો. </Text>}
                <TextInput label="આપનું નામ" value={focus?.name ? focus?.name : ""} onChangeText={(t) => setFocus(p => ({ ...p, name: t }))} />
                <TextInput label="મોબાઈલ નં" value={focus?.mobile ? focus?.mobile : ""} onChangeText={(t) => setFocus(p => ({ ...p, mobile: t }))} />
                <TextInput label="email" value={focus?.email ? focus?.email : ""} onChangeText={(t) => setFocus(p => ({ ...p, email: t }))} />
                {mode == 2 && <TextInput keyboardType="number-pad" label="Amount" value={focus?.amount ? focus?.amount + "" : ""}
                    onChangeText={(t) => {
                        try {
                            const res = parseInt(t)
                            if (res !== NaN && res >= 0) {
                                setFocus(p => ({ ...p, amount: res }))
                            }
                        } catch (e) { console.log("its ee", "4") }
                    }} />}
                {mode == 2 && <TextInput label="Utilized in (items, price etc...)" value={focus?.things ? focus?.things : ""}
                    onChangeText={(t) =>
                        setFocus(p => ({ ...p, things: t }))
                    } />}

                <TextInput label="અન્ય વિગત" value={focus?.desc ? focus?.desc : ""} onChangeText={(t) => setFocus(p => ({ ...p, desc: t }))} />
                {mode == 2 && <Text>Note : By clicking on Completed, will create Receipt No. and treat as donation received </Text>}

                <View style={{ flex: 1, alignItems: "center", flexDirection: "row", marginTop: 20 }}>
                    <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Cancel</Button>
                    {mode == 2 && <Button style={{ flex: 1 }} onPress={async () => {
                        console.log("its focus", focus)
                        if (focus?.name && (focus?.mobile || focus?.email)) {
                            const db = getFirestore()
                            let ref
                            if (focus.id) {
                                ref = doc(db, "Donations", focus.id)
                            } else {
                                ref = doc(collection(db, "Donations"))
                                focus.id = ref.id
                                focus.createdOn = dayjs().unix()
                            }
                            if (!focus.receipt) {
                                const n = numbering[0]
                                focus.receipt = n.prefix + n.seprator + n.num + n.seprator + n.sufix
                                const dref = doc(db, "Numbering", "Donations")
                                updateDoc(dref, { num: increment(1) }, { merge: true })
                            }
                            focus.stamp = dayjs().unix()
                            focus.status = 2
                            focus.confirmedByName = pref?.current?.name
                            focus.confirmedById = pref?.current?.id
                            await setDoc(ref, focus)
                            setTimeout(() => Toast.show("Saved"), 500)
                            navigation.goBack()
                        } else {
                            Toast.show("Error : નામ અને સંપર્ક વિગતો જરૂરી છે")
                        }

                    }}>Completed</Button>}
                    <Button style={{ flex: 1 }} mode="contained-tonal" onPress={async () => {
                        if (focus?.name && (focus?.mobile || focus?.email)) {
                            const db = getFirestore()
                            let ref
                            if (focus.id) {
                                ref = doc(db, "Donations", focus.id)
                            } else {
                                ref = doc(collection(db, "Donations"))
                                focus.id = ref.id
                                focus.createdOn = dayjs().unix()
                            }
                            focus.stamp = dayjs().unix()
                            await setDoc(ref, focus)
                            setTimeout(() => Toast.show("Saved"), 500)
                            navigation.goBack()
                        } else {
                            Toast.show("Error : નામ અને સંપર્ક વિગતો જરૂરી છે")
                        }

                    }}>Save</Button>
                </View>
            </View >
        </ScrollView >
    );


}

