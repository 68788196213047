import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, StatusBar } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';
import * as React from 'react';
import { useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import Global from '../constants/global';
import Modal from 'react-native-modal';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import ClassRow from '../components/ClassRow';
import DModal from '../components/DModal';
import DIcon from '../components/DIcon';


let baselist: [] = []
const cy = Global.getYear()
export default function Arrangements({ navigation, route }) {
    const classList = useSelector((state) => state.list.Classes)
    const [sortedClassList, setSortedClassList] = React.useState([])
    const subjectList = useSelector((state) => state.list.Subjects)
    const teachers = useSelector(React.useMemo(Global.getUserList, []))
    const [filteredSubjects, setFIlteredSubjects] = React.useState([])
    const [teacherList, setTeacherList] = React.useState([])
    const [classes, setClass] = React.useState(undefined)
    const [focus, setFocus] = React.useState(undefined)
    const [show, setShow] = React.useState(false)
    const [currentYear, setCY] = React.useState(cy)


    const [focusSubject, setFocusSubject] = React.useState(undefined)

    React.useEffect(() => {
        if (focus) {
            const s: [] = subjectList.filter(e => e.classid == focus.id)
            setFIlteredSubjects(s)
        }
    }, [focus, subjectList])

    React.useEffect(() => {
        if (teachers) {
            const s: [] = teachers.filter(e => (e.retired !== true) && (e.type == Global.PRINCIPAL || e.type == Global.TEACHER || e.type == Global.VISITING_TEACHER))
            setTeacherList(s)
        }
    }, [teachers])

    React.useEffect(() => {
        if (classList) {
            const s: [] = []
            classList.map(p => {
                if (p.year == currentYear) {
                    s.push({ ...p })
                }
            }
            )
            const nnewP = s.sort((a, b) => a?.seq < b?.seq ? -1 : 0)
            setSortedClassList(nnewP)
        }
    }, [classList, currentYear])

    function dismissSubject() {
        setFocusSubject(undefined)
    }
    const { colors } = useTheme();
    return (
        <ScrollView style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.background, flex: 1, paddingTop: route?.params?.topMargin ? StatusBar.currentHeight : 0 }}>
                {/* <Button onPress={() => {
                    sortedClassList.map(c => {
                        const c1 = { ...c }
                        c1.year = 2023
                        const db = getFirestore()
                        const ref = doc(collection(db, "Classes"))
                        c1.id = ref.id
                        c1.stamp = dayjs().unix()
                        const d1: [] = []
                        if (c1?.days) {
                            c1?.days?.map(da => {
                                const dc = { ...da }
                                dc.subjectId = ""
                                dc.subjectName = ""
                                dc.teacher = "teacher"
                                d1.push(dc)
                            })
                            c1.days = d1
                        }
                        try {

                            setDoc(ref, c1, { merge: true })

                            const sub: [] = subjectList.filter(e => e.classid == c.id)
                            sub.map(s => {
                                const s1 = { ...s }
                                const sref = doc(collection(db, "Subjects"))
                                s1.id = sref.id
                                s1.year = c1.year
                                s1.classid = c1.id
                                s1.className = c1.name
                                s1.stamp = dayjs().unix()
                                setDoc(sref, s1, { merge: true })
                            })

                            Toast.show("Done")
                        } catch (e) {
                            console.log(e)
                            Toast.show("Error : saving data. Check internet connection")
                        }

                    })
                }}>Copy Classes to next year</Button> */}
                {/* <Button onPress={() => {
                    subjectList.map(c => {
                        if (!c.year) {
                            console.log(c.name, c)
                            const ref = doc(getFirestore(), "Subjects", c.id)
                            try {
                                setDoc(ref, { year: 2022 }, { merge: true })

                            } catch (e) {
                                console.log(e)
                            }
                        }
                    })
                }}>Update year</Button> */}

                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TouchableOpacity onPress={() => { setShow(true) }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text variant="headlineMedium">Classes</Text>
                            <Text>{Global.getYearString(currentYear)}</Text>
                            <DIcon name="cog" color='black' />
                        </View>
                    </TouchableOpacity>
                    <View style={{ flex: 1 }}></View>
                    <FAB style={{ alignSelf: "flex-end" }} icon={"plus"} onPress={() => { setClass({ name: "", section: "", div: "" }) }} />
                </View>
                <FlashList
                    data={sortedClassList}
                    key="id"
                    estimatedItemSize={70}
                    keyboardShouldPersistTaps={'handled'}
                    renderItem={({ item, index }) =>
                        <TouchableOpacity onPress={() => setFocus(item)}>
                            <View style={{ flexDirection: "row" }}>
                                <ClassRow item={item} />
                                <TouchableOpacity onPress={() => setClass(item)}>
                                    <Text style={{ paddingLeft: 12, paddingRight: 13, fontSize: 18 }}>⌵</Text>
                                </TouchableOpacity>
                            </View>
                        </TouchableOpacity>
                    } />
                {focus !== undefined &&
                    <View style={{ marginTop: 20 }}>
                        <Divider />
                        <View style={{ flexDirection: "row" }}>
                            <Text style={{ flex: 1 }} variant="headlineMedium">Subjects ({focus.name + (focus?.div ? " - " + focus?.div : "")})</Text>
                            <FAB icon={"plus"} onPress={() => { setFocusSubject({ theory: true, name: "", className: focus.name, classid: focus.id }) }} />
                        </View>
                        <FlashList
                            data={filteredSubjects}
                            key="id"
                            estimatedItemSize={70}
                            keyboardShouldPersistTaps={'handled'}
                            renderItem={({ item, index }) =>
                                <TouchableOpacity onPress={() => setFocusSubject(item)}>
                                    <View style={{ flexDirection: "row", paddingLeft: 30 }}>
                                        <Text variant="titleLarge" >{item.name}</Text>
                                        {item.stream !== undefined && <Text> ({Global.STREAM[item.stream]})</Text>}
                                    </View>
                                    <Text style={{ fontSize: 10, marginLeft: 40 }}>{item.teacherName}</Text>
                                    <Divider />
                                </TouchableOpacity>
                            } />
                    </View>}
                <DModal
                    isVisible={show}
                    close={() => setShow(false)}
                >
                    <Text variant="headlineMedium">Select year</Text>
                    <TouchableOpacity onPress={() => { setCY(2022); setShow(false) }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            {currentYear == 2022 ? <DIcon name="check" color="green" /> : <View style={{ paddingLeft: 24 }}></View>}
                            <Text style={{ paddingTop: 15 }} variant="headlineSmall">2022-23</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { setCY(2023); setShow(false) }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            {currentYear == 2023 ? <DIcon name="check" color="green" /> : <View style={{ paddingLeft: 24 }}></View>}
                            <Text style={{}} variant="headlineSmall">2023-24</Text>
                        </View>
                    </TouchableOpacity>
                </DModal>

                <Modal onBackdropPress={() => setClass(undefined)} isVisible={classes !== undefined} style={{ backgroundColor: colors.surface, margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >
                    <View>
                        <TextInput label={"Standard"} value={classes?.name} onChangeText={(t) => setClass(p => ({ ...p, name: t }))} />

                        <TextInput label={"Division (Optional)"} value={classes?.div} onChangeText={(t) => setClass(p => ({ ...p, div: t }))} />

                        <ScrollView horizontal style={{ marginTop: 10 }}>
                            <Text>Section :</Text>
                            {Global.SECTIONS.map((e, i) =>
                                <Chip key={i + "s"} selected={classes?.section == i} onPress={() => setClass(p => ({ ...p, section: i }))}>{e}</Chip>
                            )}
                        </ScrollView>

                        <Text style={{ marginTop: 20 }}>Select Class Teacher  :</Text>
                        <View style={{ flexDirection: "row", justifyContent: "center", flexWrap: "wrap" }}>
                            {teacherList.map((e, i) =>
                                <Chip id={i + ""} style={{ margin: 2 }} selected={classes?.classTeacher == e.id} onPress={() => setClass(p => ({ ...p, classTeacher: e.id, classTeacherName: e.name }))}>{e.name}</Chip>
                            )}
                        </View>

                        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent", alignSelf: "stretch" }}>
                            <Button style={{ flex: 1 }} onPress={() => {
                                setClass(undefined)
                            }}>Cancel</Button>
                            <Button style={{ flex: 1 }} onPress={async () => {
                                const data = { ...classes }
                                if (data.id) {
                                    const db = getFirestore()
                                    let ref
                                    ref = doc(db, "Classes", data.id)

                                    data.stamp = dayjs().unix()
                                    try {
                                        setDoc(ref, { flag: -10 }, { merge: true })
                                        setClass(undefined)
                                        Toast.show("Deleted")
                                    } catch (e) {
                                        //      console.log("err", e)
                                        Toast.show("Error : saving data. Check internet connection")
                                    }

                                }
                            }}>Delete</Button>
                            <Button mode="contained" style={{ flex: 1 }} onPress={async () => {
                                const db = getFirestore()
                                let ref
                                const data = { ...classes }

                                if (!data.id) {
                                    ref = doc(collection(db, "Classes"))
                                    data.id = ref.id
                                } else {
                                    ref = doc(db, "Classes", data.id)
                                }

                                data.stamp = dayjs().unix()
                                try {
                                    setDoc(ref, data, { merge: true })
                                    setClass(undefined)
                                    Toast.show("Saved")
                                } catch (e) {
                                    //      console.log("err", e)
                                    Toast.show("Error : saving data. Check internet connection")
                                }

                            }}>Save</Button>
                        </View>
                    </View>
                </Modal >
                < Modal onBackdropPress={() => setFocusSubject(undefined)} isVisible={focusSubject !== undefined} style={{ backgroundColor: colors.surface, margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >
                    <View>
                        <TextInput label={"Subject Name"} value={focusSubject?.name} onChangeText={(t) => setFocusSubject(p => ({ ...p, name: t }))} />
                        <Text style={{ padding: 10 }}>{focusSubject?.className + (focusSubject?.div ? ("" + focusSubject?.div) : "")}</Text>

                        <ScrollView horizontal style={{ marginTop: 10 }}>
                            <View style={{ alignItems: "center", flexDirection: "row" }}>
                                <Text>Stream : </Text>
                                {Global.STREAM.map((e, i) =>
                                    <Chip id={i + "s"} style={{ margin: 2 }} selected={focusSubject?.stream == i} onPress={() => setFocusSubject(p => ({ ...p, stream: i }))}>{e}</Chip>
                                )}
                            </View>
                        </ScrollView>
                        <ScrollView horizontal style={{ marginTop: 10 }}>
                            <View style={{ alignItems: "center", flexDirection: "row" }}>
                                <Text>Type : </Text>
                                <Chip style={{ margin: 2 }} selected={focusSubject?.theory} onPress={() => setFocusSubject(p => ({ ...p, theory: !focusSubject?.theory }))}>Theory</Chip>
                                <Chip style={{ margin: 2 }} selected={focusSubject?.practical} onPress={() => setFocusSubject(p => ({ ...p, practical: !focusSubject?.practical }))}>Practical</Chip>
                            </View>
                        </ScrollView>

                        <Text style={{ marginTop: 20 }}>Select Teacher  :</Text>
                        <View style={{ flexDirection: "row", justifyContent: "center", flexWrap: "wrap" }}>
                            {teacherList.map((e, i) =>
                                <Chip id={i + "s"} style={{ margin: 2 }} selected={focusSubject?.teacher == e.id} onPress={() => setFocusSubject(p => ({ ...p, teacher: e.id, teacherName: e.name }))}>{e.name}</Chip>
                            )}
                        </View>


                        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent", alignSelf: "stretch" }}>
                            <Button style={{ flex: 1 }} onPress={() => {
                                setFocusSubject(undefined)
                            }}>Cancel</Button>
                            <Button style={{ flex: 1 }} onPress={async () => {
                                const data = { ...focusSubject }
                                if (data.id) {
                                    const db = getFirestore()
                                    let ref
                                    ref = doc(db, "Subjects", data.id)

                                    data.stamp = dayjs().unix()
                                    try {
                                        setDoc(ref, { flag: -10 }, { merge: true })
                                        dismissSubject()
                                        Toast.show("Deleted")
                                    } catch (e) {
                                        //      console.log("err", e)
                                        Toast.show("Error : saving data. Check internet connection")
                                    }

                                }
                            }}>Delete</Button>
                            <Button mode="contained" style={{ flex: 1 }} onPress={async () => {

                                try {
                                    const db = getFirestore()
                                    let ref
                                    const data = { ...focusSubject }
                                    data.stamp = dayjs().unix()

                                    if (!data.id) {
                                        ref = doc(collection(db, "Subjects"))
                                        data.id = ref.id
                                    } else {
                                        ref = doc(db, "Subjects", data.id)
                                    }
                                    if (!focusSubject?.theory && !focusSubject?.practical) {
                                        data.theory = true
                                    }
                                    setDoc(ref, data, { merge: true })
                                    dismissSubject()
                                    Toast.show("Saved")
                                } catch (e) {
                                    //      console.log("err", e)
                                    Toast.show("Error : saving data. Check internet connection")
                                }
                            }}>Save</Button>
                        </View>
                    </View>
                </Modal >

            </View >

        </ScrollView >
    );


}

