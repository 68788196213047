import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, Linking, StyleSheet, TouchableOpacity } from 'react-native';
import Global from "../constants/global"
import { addDoc, collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import DAvatar from './DAvatar'
import { Text, Card, IconButton, Portal, Dialog, Button, TextInput } from 'react-native-paper';
import Modal from 'react-native-modal';
import Loading from './Loading';

interface option {
    info: any
    callback(user: any): void
    navigation: any
    extra: any
    index: number
}
const UserRow = (op: option) => {
    const [isOpen, setOpen] = useState(false);
    const [data, setData] = React.useState(op.info);
    const [showDelete, setShowDelete] = useState(false);
    const [roll, setRoll] = React.useState(0);

    // const { L } = useContext(Preferances)
    function onClose() { setOpen(false) }
    const [show, setShow] = React.useState(false)


    useEffect(() => {
        setTimeout(() => {
            setData(op.info)
        })
    }, [op.info])

    const nav = useNavigation()

    function navigate() {
        return (op.navigation ? op.navigation : nav)
    }
    async function updateRightsInServer(info, newRight) {
        const db = getFirestore();
        const docRef = doc(db, "Users", data.id)
        try {
            let newData = { ...info, type: newRight }
            await setDoc(docRef, newData, { merge: true });
            //            dispatch(bhudevTask.actions.updateRights({ id: data.id, type: newRight }));
        } catch (err) {
        }
    }

    return (

        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8, alignSelf: "stretch" }}>
            <DAvatar title={data.name} file={data.dp} type={Global.ROW_DP} />
            <TouchableOpacity style={{ flex: 1 }} onPress={() => setShow(true)}>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", marginLeft: 4, flex: 1 }}>
                        <Text variant="bodyLarge" style={{ fontWeight: "bold", flex: 1 }}>{data.name}</Text>
                        {data.status == Global.BLOCK && <Text variant="bodyLarge" style={{ flex: 1, color: "red" }}>Blocked</Text>}
                        <Text style={{ paddingLeft: 12, paddingRight: 13, fontSize: 18 }}>⌵</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        {data?.["class" + Global.getYear()]?.classId && <Text style={{ fontWeight: "bold", paddingRight: 15 }}>{data?.["class" + Global.getYear()]?.class}{data?.["class" + Global.getYear()]?.div ? (" - " + data?.["class" + Global.getYear()].div) : ""}</Text>}
                        {data?.["class" + Global.getYear()]?.gr && <Text style={{ paddingRight: 15 }}>,GR : {data?.["class" + Global.getYear()]?.gr}</Text>}
                        {/* {data?.class2023?.class && <Text style={{ paddingRight: 15 }}>,  {data?.class2023?.class} {data?.class2023?.roll}</Text>} */}
                    </View>
                    <Text style={{}}>{data.retired ? "પૂર્વ " : ""}{Global.TYPES[data.type]} {data.designation ? ", " + data.designation : ""}</Text>

                </View>
            </TouchableOpacity>
            <Modal
                isVisible={show}
                onBackdropPress={() => setShow(false)}
                onDismiss={() => setShow(false)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: op.color }}>
                    <View style={{ justifyContent: "center" }}>
                        <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{data?.name}...</Text>
                        {data?.mobile ?
                            <TouchableOpacity onPress={() => { Linking.openURL('tel:' + data?.mobile); onClose() }} >
                                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                    <IconButton icon="phone-outgoing" />
                                    <Text style={{ paddingLeft: 10 }} >{"Call"} ({data?.mobile})</Text>
                                </View>
                            </TouchableOpacity>
                            : <></>}
                        {/* <TextInput value={roll + ""} onChangeText={(t) => setRoll(parseInt(t))} />
                        <TouchableOpacity onPress={() => {
                            const db = getFirestore()
                            const c203 = {
                                class: "ધોરણ 12",
                                classId: "HeXrc5vvAEnMihacxQom",
                                year: 2023
                            }
                            const c20310b = {
                                class: "ધોરણ 10",
                                classId: "ScOIOD7FxmSKYUl6pPYC",
                                div: "બ",
                                gr:data.gr,
                                roll:data.roll,
                                year: 2023
                            }
                            
                            const c202 = {
                                class: "ધોરણ 11",
                                classId: "lZsTzyzRFUfqMdEN9yy6",
                                year: 2022,
                            }


                            const ref = doc(db, "Users", data.id)
                            setDoc(ref, { "class2023": c20310b }, { merge: true }).then(e => {
                                console.log("Done")
                            })
                            setShow(false)
                        }} >
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="phone-outgoing" />
                                <Text style={{ paddingLeft: 10 }} >Add to 10b</Text>
                            </View>
                        </TouchableOpacity> */}

                        {location.origin.includes("localhost") ?
                            <TouchableOpacity onPress={() => {
                                const ndata = { ...data }
                                ndata.mobile = ""
                                ndata.loginid = ""
                                ndata.password = ""
                                const db = getFirestore()
                                const ref = doc(collection(db, "Users"))
                                ndata.id = ref.id
                                setDoc(ref, ndata, { merge: true })
                                alert("Done " + ndata.id)
                            }
                            } >
                                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                    <IconButton icon="phone-outgoing" />
                                    <Text style={{ paddingLeft: 10 }} >Copy And Create New User</Text>
                                </View>
                            </TouchableOpacity>
                            : <></>}
                        {data?.whatsApp && <TouchableOpacity onPress={() => {
                            if (data?.whatsApp) {
                                let url =
                                    'https://wa.me/91' + data?.whatsApp + '?text=' + "નમસ્કાર" + ' ? \n -' +
                                    data?.name + " " + data?.surname
                                Linking.openURL(url)
                            } else {
                                alert("Whatsapp No not given")
                            }
                            setShow(false)
                        }} >
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="whatsapp" />
                                <Text style={{ paddingLeft: 10 }}>{"Whats App"} {data.whatsApp}</Text>
                            </View>

                        </TouchableOpacity>}
                        {(data?.type !== Global.PRINCIPAL || data?.type !== Global.TEACHER) && <TouchableOpacity onPress={() => {
                            setShow(false)
                            const db = getFirestore();
                            const r = doc(db, "Users", data.id)
                            if (r) {
                                setDoc(r, { status: data?.status == undefined || data?.status == Global.ACTIVE ? Global.BLOCK : Global.ACTIVE }, { merge: true })
                            }
                        }} >
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon={data?.status == Global.BLOCK ? "check" : "block-helper"} />
                                <View style={{ paddingLeft: 10 }}>
                                    <Text>{(data?.status == Global.ACTIVE || data?.status == undefined) ? "Block" : "Active"} </Text>
                                    {data?.status == Global.ACTIVE && <Text style={{ fontStyle: "italic" }}>(Blocked User cannot login, like, or comment)</Text>}
                                </View>
                            </View>
                        </TouchableOpacity>}

                        {(data?.type !== Global.PRINCIPAL || data?.type !== Global.TEACHER) && <TouchableOpacity onPress={() => {
                            setShow(false)
                            setShowDelete(true)
                        }} >
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="delete-outline" />
                                <View style={{ paddingLeft: 10 }}>
                                    <Text>Delete </Text>
                                </View>
                            </View>
                        </TouchableOpacity>}
                    </View>
                    <IconButton icon={"window-close"} onPress={() => setShow(false)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </Modal >
            <Modal
                isVisible={showDelete}
                onBackdropPress={() => setShowDelete(false)}
                onDismiss={() => setShowDelete(false)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >
                <View style={{ backgroundColor: op.color }}>
                    <View style={{ padding: 10, justifyContent: "center" }}>
                        <Text variant="headlineSmall">Are You sure you want to delete User ? </Text>
                        <View style={{ flex: 1, alignSelf: "stretch", flexDirection: "row" }}>
                            <Button style={{ flex: 1 }} onPress={() => setShowDelete(false)}>Cancel</Button>
                            <Button style={{ flex: 1 }} onPress={() => {
                                setShow(false)
                                const db = getFirestore();
                                const r = doc(db, "Users", data.id)
                                if (r) {
                                    setDoc(r, { flag: -10 }, { merge: true })
                                }
                            }} mode="contained">Delete</Button>
                        </View>
                    </View>
                    <IconButton icon={"window-close"} onPress={() => setShowDelete(false)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </Modal >

        </View >
    )
}



export default React.memo(UserRow)