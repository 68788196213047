import { useSelector } from 'react-redux'
import { doc, getFirestore, setDoc } from 'firebase/firestore';

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView } from 'react-native';
import { Text, IconButton, Divider } from 'react-native-paper';

import * as React from 'react';
import CommentRow from '../components/CommentRow';
import { FlatList } from 'react-native';
import { useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import DAvatar from '../components/DAvatar';
import Global from '../constants/global';

export default function AboutSTVM({ route, navigation }) {
    const { colors } = useTheme()
    return (
        <ScrollView>
            <View style={{ padding: 10, alignItems: "center", backgroundColor: colors.background, paddingLeft: 12, flex: 1 }}>

                <DAvatar source={route?.params?.stvm?.logo} type={Global.FORM_DP} />
                <Text variant="headlineSmall" style={{ fontWeight: "bold" }}  >{route?.params?.stvm?.fullName}</Text>
                <Text>{route?.params?.stvm?.since}</Text>
                <Divider />
                <Text variant="titleLarge" style={{ color: "dodgerblue", paddingTop: 20, alignSelf: "stretch", fontWeight: "bold" }}># શ્રી ઠક્કરબાપા વિષે</Text>
                <Text style={{ textAlign: "justify" }}>આદરણીય ઠક્કરબાપા નું પૂરું નામ અમૃતલાલ વિઠ્ઠલદાસ ઠક્કર હતું, અભ્યાસે તેઓ Civil Engineer હતા પરંતુ, તેમણે પોતાનું સમગ્ર જીવન આદિવાસીઓ અને હરિજનો ના ઉદ્ધાર માટે વ્યતીત કરી દીધેલ હતું. સમાજ સેવા માટે જંગલો, પર્વતો, જેવા દુર્ગમ સ્થાનો પર પણ પહોંચી જતાં. તેમણે સમાજ ઉદ્ધાર માટે આખા ભારત માં, અને ખાસ કરી ગુજરાત, મહારાષ્ટ્ર, મધ્યપ્રદેશ, તમિલનાડુમાં ભ્રમણ કર્યું.  આદિવાસીઓ માટે સર્વપ્રથમ trible શબ્દ નો ઉપયોગ તેઓએ કર્યો હતો તેમના અથાગ જ્ઞાન અને હાજરજવાબીના કારણે તમિલ લોકો તેમણે 'આપા ઠક્કર' કહેતાં. સમાજના લોકો પ્રત્યેના તેમના પ્રેમ અને પ્રયત્નોના કારણેજ </Text>
                <Text variant="bodyMedium" style={{ fontWeight: "bold", textDecorationLine: "underline" }}>ગાંધીજી તેમને આદરથી ઠક્કરબાપા કહેતા </Text>


                <Text variant="titleLarge" style={{ color: "dodgerblue", paddingTop: 20, alignSelf: "stretch", fontWeight: "bold" }}># શ્રી ઠક્કરબાપા વિદ્યામંદિર</Text>
                <Text style={{ textAlign: "justify" }}>આદરણીય ઠક્કરબાપા ના આદર્શો થી પ્રેરિત થઈ અમદાવાદ ના હૃદય સમા વિસ્તાર ઠક્કરનગર માં શ્રી ઠક્કરબાપા વિદ્યામંદિરની સ્થાપના 1970માં થઈ જે સરકારી અનુદાનિત લઘુમતી શાળા છે. અહીં ધોરણ 9 થી 12 ગુજરાતી મધ્યમનું શિક્ષણ અપાય છે.</Text>
                <Text variant="titleLarge" style={{ color: "dodgerblue", paddingTop: 20, alignSelf: "stretch", fontWeight: "bold" }}># અમારું ધ્યેય : વિદ્યા થકી સમાજોત્કર્ષ </Text>
                <Text>ઠક્કરબાપાના ઉત્કૃષ્ટ આદર્શો પર ચાલતા અત્યાર સુધી લગભગ 10,000 થી વધુ વિદ્યાર્થીઓ આ સંસ્થા માં શિક્ષણ પ્રાપ્ત કરી ચૂક્યા છે</Text>
                <Text>અમારા પ્રયત્નો ચોપડીયા જ્ઞાનથી ઉપર, વિદ્યાર્થીઓમાં સંસ્કારોનું સિંચન, દેશભક્તિ નું સિંચન, માતાપિતા અને વડીલો પ્રત્યે આદરભાવને પ્રેરિત કરવું, નશામુક્તિ માટે પ્રેરણા અને ઉપયુક્ત કારકિર્દી માટે માર્ગદર્શન અને પ્રેરણા માટે ના હોય છે, કે જેથી ઠક્કરબાપા વિદ્યામંદિર માંથી એક વિદ્યાર્થી નહિ અપિતુ એક ઉત્કૃષ્ટ માનવ અને શ્રેષ્ઠ નાગરિક બહાર નીકળે.        </Text>

                <Text variant="titleLarge" style={{ color: "dodgerblue", paddingTop: 20, alignSelf: "stretch", fontWeight: "bold" }}># અમારો પરિવાર </Text>
                <Text style={{ textAlign: "justify" }}>શ્રી ઠક્કરબાપા વિદ્યામંદિર ના વિદ્યાર્થીઓ બધાજ એ એક પરિવાર છે, અને અમને અમારા હોનહાર વિદ્યાર્થીઓ Guj Govt, ISRO, Police Dept., IT, અને ધંધાકીય સ્તરે અનેક ક્ષેત્રો માં ઉચ્ચ સ્થાન પર વિરાજે છે, જેનો અમને ગર્વ છે, અને સૌની પ્રગતિ અને ખુશહાલી માટે કામના કરીએ છીએ</Text>
            </View >
        </ScrollView>
    );

}

const styles = StyleSheet.create({
    input: {
        borderRadius: 8,
        outlineWidth: 0,
        flex: 1,
        margin: 6,
    }
});

