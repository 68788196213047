import { useState, useEffect } from 'react';
import { ActivityIndicator, SafeAreaView, View, Text, VirtualizedList, StyleSheet, Pressable, FlatList, Image } from 'react-native';
import { Provider, Button, Searchbar, Divider, Avatar } from 'react-native-paper';
import * as React from 'react';

import { RootStackScreenProps } from '../types';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

export default function Backup({ route, navigation }) {
    const users = useSelector((state) => state.list.Users)
    const posts = useSelector((state) => state.list.Posts)
    const subjects = useSelector((state) => state.list.Posts)
    const marks = useSelector((state) => state.list.Marks)
    const exams = useSelector((state) => state.list.Exams)
    const leaves = useSelector((state) => state.list.Leaves)
    const stvm = useSelector((state) => state.list.stvm)
    const noti = useSelector((state) => state.list.Notifications)
    const donations = useSelector((state) => state.list.Donations)

    const [str, setStr] = useState("");
    const [fileName, setFileName] = useState("");
    const [loading, setLoging] = useState(true);

    useEffect(() => {
        let j = {}
        j.Users = users
        j.posts = posts
        j.exams = exams
        j.marks = marks
        j.subjects = subjects
        j.leaves = leaves
        j.stvm = stvm
        j.noti = noti
        j.donations = donations
        j.backupDate = dayjs().unix()
        j.backupDay = dayjs().format("DD/MMM/YYY")
        let t = JSON.stringify(j)
        setStr(t)
        setFileName(j.backupDay + ".bkp")
        setLoging(false)
    }, [])


    return (
        <SafeAreaView style={{ backgroundColor: "white", flex: 1 }}>
            {loading ? <Text>WOrking</Text> :
                <a download={fileName} href={
                    URL.createObjectURL(new Blob([str],
                        { type: 'text/plain;charset=utf-8' }))}>Download</a>}
        </SafeAreaView>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    input: {
        padding: 8,
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 8,
        outlineWidth: 0,
        margin: 6,
        backgroundColor: 'lightgray',
    }
    ,
    focused: {
        padding: 8,
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 8,
        outlineWidth: 0,
        margin: 6,
        backgroundColor: 'skyblue',
    },
    line2: {
        fontSize: 12
    },
    item: {
        padding: 8
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    fab: {
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 0,
    }
});

