import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Share, Dimensions, Linking, StyleSheet, TouchableOpacity } from 'react-native';
import Global from "../constants/global"
import { useSelector } from 'react-redux';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import DAvatar from './DAvatar'
import { Text, Card, IconButton, Portal, Dialog, Button } from 'react-native-paper';
import Modal from 'react-native-modal';
import Preferances from '../constants/Preferances';

interface option {
    info: any
    callback(user: any): void
    navigation: any
    extra: any
    index: number
}
const UserRow = (op: option) => {
    const [isOpen, setOpen] = useState(false);
    const [data, setData] = React.useState(op.info);
    const [showDelete, setShowDelete] = useState(false);
    function onClose() { setOpen(false) }
    const [show, setShow] = React.useState(false)


    useEffect(() => {
        setTimeout(() => {
            setData(op.info)
        })
    }, [op.info])

    return (
        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8, alignSelf: "stretch" }}>
            <DAvatar title={data.name} file={data.dp} type={Global.ROW_DP} />
            <TouchableOpacity style={{ flex: 1 }} onPress={() => setShow(true)}>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", marginLeft: 4, flex: 1 }}>
                        <Text variant="headlineSmall" style={{ flex: 1 }}>{data.name}</Text>
                        <Text variant="headlineSmall" style={{}}>{data.amount ? Global.RS + " " + data.amount : ""}</Text>
                        <Text style={{ paddingLeft: 12, paddingRight: 13, fontSize: 18 }}>⌵</Text>
                    </View>
                    {data.things && <Text style={{}}>{data.things} </Text>}
                </View>
            </TouchableOpacity>
            <Modal
                isVisible={show}
                onBackdropPress={() => setShow(false)}
                onDismiss={() => setShow(false)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: op.color }}>
                    <View style={{ justifyContent: "center" }}>
                        <Text variant="titleLarge" style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{data?.name}...</Text>
                        {data?.mobile &&
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <TouchableOpacity style={{ flex: 1 }} onPress={() => { Linking.openURL('tel:' + data?.mobile); onClose() }} >
                                    <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
                                        <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="phone-outgoing" />
                                        <Text style={{ paddingLeft: 10, paddingRight: 10 }} >{"Mobile -"} ({data?.mobile})</Text>
                                    </View>
                                </TouchableOpacity>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="whatsapp" onPress={() => { Share.share({ title: "નમસ્કાર", "message": "આપનો દિવસ શુભ રહે 🙂" }) }} />
                            </View>
                        }
                        {data?.receipt &&
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="printer" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10, flex: 1 }} >{"Receipt -"} {data?.receipt}</Text>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="whatsapp" onPress={() => { Share.share({ title: "નમસ્કાર", "message": "આપના યોગદાન હૃદયપૂર્વક આભાર.\ન Receipt No :" + data.receipt + "\n\nવિગતો જોવા ક્લિક કરો https://stvmeducation.web.app?donation=" + data.id }) }} />
                            </View>
                        }
                        <TouchableOpacity onPress={() => op.navigation.navigate("Donation", { mode: 2, data: data })}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="lead-pencil" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Edit</Text>
                            </View>
                        </TouchableOpacity>

                    </View>
                    <IconButton icon={"window-close"} onPress={() => setShow(false)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </Modal >
            {/* <Modal
                isVisible={showDelete}
                onBackdropPress={() => setShowDelete(false)}
                onDismiss={() => setShowDelete(false)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: op.color }}>
                    <View style={{ padding: 10, justifyContent: "center" }}>
                        <Text variant="headlineSmall">Are You sure you want to delete User ? </Text>
                        <View style={{ flex: 1, alignSelf: "stretch", flexDirection: "row" }}>
                            <Button style={{ flex: 1 }} onPress={() => setShowDelete(false)}>Cancel</Button>
                            <Button style={{ flex: 1 }} onPress={() => {
                                setShow(false)
                                const db = getFirestore();
                                const r = doc(db, "Users", data.id)
                                if (r) {
                                    setDoc(r, { flag: -10 }, { merge: true })
                                }
                            }} mode="contained">Delete</Button>
                        </View>
                    </View>
                    <IconButton icon={"window-close"} onPress={() => setShowDelete(false)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </Modal > */}

        </View >)
}


const styles = StyleSheet.create({
    item: {
        textColor: 'white',
        fontWeight: '900',
        flex: 1,
        alignItems: "center",
        marginVertical: 2,
        marginHorizontal: 2,
        paddingLeft: 20,
        flexDirection: "row"
    },
    phone: {
        color: "teal"
    },
    title: {
        fontWeight: "bold"
    },
    city: {
        fontStyle: "italic",
    },
    subset: {
        fontSize: 9,
    },
    onletter: {
        fontSize: 20
    }
});
//const Bhudev = React.memo(OBhudev)
export default UserRow