import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, StatusBar } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Chip } from 'react-native-paper';

import * as React from 'react';
import DonationRow from '../components/DonationRow';
import { useTheme } from 'react-native-paper';


let baselist: [] = []
export default function Users({ navigation, route }) {
    const [filter, setFilter] = React.useState(0);
    const bhudevList = useSelector((state) => state.list.Donations)
    const [alllist, setAllList] = React.useState({ baselist: [] })

    React.useEffect(() => {
        baselist = bhudevList.filter(e => e.flag !== -10)
        setAllList({ baselist:bhudevList })
    }, [bhudevList]);

    React.useEffect(() => {
        if (filter > 0) {
            const x = baselist.filter(e => (filter == 1 ? (e.status == 1 || e.status == undefined) : e.status == 2))
            setAllList({ baselist: x })
        } else {
            setAllList({ baselist: [...baselist] })
        }
    }, [filter]);
    console.log(alllist.baselist)

    function dataSearch(text: string) {
        if (text) {
            // console.log("bhudeve search" + baselist.length)
            let list: [] = baselist.filter((ele) =>
                (ele.name + ele.fatherName + ele.surname + ele.cityName + ele.hometownName + ele.mobile + ele.industryName + ele.samajcityName + ele.samajcity + ele.city as String).toLowerCase().includes(text.toLowerCase())) as []
            setAllList(p => ({ ...p, list: list }))
        } else {
            setAllList(p => ({ ...p, list: baselist }))
        }
    }

    const { colors } = useTheme();
    return (
        <View style={{ backgroundColor: colors.background, flex: 1, paddingTop: route?.params?.topMargin ? StatusBar.currentHeight : 0 }}>
            <View style={{ flexDirection: "row" }}>
                <Chip style={{ margin: 3 }} selected={filter == 0} onPress={() => setFilter(0)}>All</Chip>
                <Chip style={{ margin: 3 }} selected={filter == 1} onPress={() => setFilter(1)}>Pending Enquiry</Chip>
                <Chip style={{ margin: 3 }} selected={filter == 2} onPress={() => setFilter(2)}>Donations</Chip>
            </View>

            <FlashList
                data={alllist.baselist}
                key="id"
                estimatedItemSize={200}
                contentContainerStyle={{ backgroundColor: "transparent" }}
                keyboardShouldPersistTaps={'handled'}
                renderItem={({ item, index }) =>
                    <DonationRow
                        info={item}
                        navigation={navigation}
                        color={colors.surfaceVariant}
                        index={index} />
                } />


        </View >
    );


}

