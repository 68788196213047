import { View } from '../components/Themed';

import * as React from 'react';
import { Text } from 'react-native-paper';
import Hemberger from './Hemberger';
// const Hemberger = React.lazy(() => import('./Hemberger'));
import Loading from '../components/Loading';

// const wid = Dimensions.get("window").width
// import LottieView from 'lottie-react-native';
// const DAvatar = React.lazy(() => import('./DAvatar'));
import DAvatar from './DAvatar'
import Global from '../constants/global';
// const PlaySchoolSong = React.lazy(() => import('../components/PlaySchoolSong'));

import PlaySchoolSong from '../components/PlaySchoolSong';
// const AboutGroup = React.lazy(() => import('../components/AboutGroup'));

import AboutGroup from '../components/AboutGroup';
import ImportantForYou from '../components/ImportantForYou';

// const DashboardCarousel = React.lazy(() => import('../components/DashboardCarousel'));
import DashboardCarousel from '../components/DashboardCarousel';
// const Posts = React.lazy(() => import('../components/Posts'));
import Posts from '../components/Posts';
import { useSelector } from 'react-redux';
// const NeedUpdate = React.lazy(() => import('./NeedUpdate'));

import NeedUpdate from './NeedUpdate';
// const DashTitle = React.lazy(() => import('./DashTitle'));
import DashTitle from './DashTitle';

const Dashboard = React.memo(({ navigation }) => {
    const stvm = useSelector(s => s.list.stvm[0])
    // const [edit, setEdit] = React.useState(false)
    return <View style={{ flex: 1, backgroundColor: "transparent" }}>
        <View style={{ alignItems: "center", flexDirection: "row", backgroundColor: "transparent" }}>
            <DAvatar type={Global.BIG_ROW_DP} source={stvm?.logo} />
            <View style={{ paddingStart: 10, paddingTop: 10, paddingBottom: 10, flex: 1, backgroundColor: "transparent" }}>
                <Text variant="headlineLarge" >{Global.isMobile() ? stvm?.name : stvm?.fullName}</Text>
                <Text>{stvm?.tag}</Text>
            </View>
            {/* <IconButton icon="pencil" iconColor={edit ? "red" : "gray"} onPress={() => setEdit(!edit)} /> */}
            <PlaySchoolSong />
            <Hemberger stvm={stvm} navigation={navigation} />
        </View>

        <DashboardCarousel data={stvm} />
        {/* <ShortCuts stvm={stvm} navigation={navigation} /> */}
        {/* <LeaveComponent /> */}
        <AboutGroup stvm={stvm} navigation={navigation} />
        <ImportantForYou navigation={navigation} />
        <DashTitle title="Posts" />
        <Posts navigation={navigation} />
        <NeedUpdate />
    </View>

})
export default Dashboard
