import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Global from '../constants/global';
function removeFromArray(arr, id) {
  let index = arr.findIndex(el => el.id == id)
//  console.log("removing +" + index, arr)
  if (index >= 0) {
    try {
   //   console.log("splice" )
      arr.splice(index, 1)
    } catch (e) { console.log(e) }
  }

}
export const listsTask = createSlice({
  name: 'list',
  initialState: {
    Posts: [],
    Users: [],
    Leaves: [],
    Classes: [],
    Subjects: [],
    Notifications: [],
    Exams: [],
    Marks: [],
    ExamDump: [],
    Donations: [],
    Numbering: [],
    stvm: [],
    Alert:{message:""}
  },
  reducers: {
    remove(state, action) {
      let data = action.payload.data;
      let type = action.payload.type;
      removeFromArray(state[type], data.id)
    },
    Alert(state, action) {
      state["Alert"] = action.payload
    },

    updateActivity(state, action) {
      let data = action.payload.data;
      let type = action.payload.type;
      let tochk = "id";
      if (action.payload.tocheck) {
        tochk = action.payload.tocheck;
      }

      if (action.payload?.isArray) {
        for (let i = 0; i < action.payload.data.length; i++) {
          try {
            let row = action.payload.data[i]
            if (row) {
              if (row?.data?.flag == -10) {
                removeFromArray(state[type], row.data.id)
              } else {
                if (row.data[tochk]) {
                  let index = state[type].findIndex(el => el[tochk] === row.data[tochk])
                  if (index >= 0) {
                    state[type][index] = row.data;
                  } else {
                    state[type].push(row.data);
                  }
                }
              }
            }
          } catch (err) {
       //     console.log("redux list updation error", err)
          }

        }

      } else {
        if (data) {
          if (data.flag == -10) {
            removeFromArray(state[type], data.id)
          } else {
            if (data[tochk]) {
              let index = state[type].findIndex(el => el[tochk] === data[tochk])
              if (index >= 0) {
                state[type][index] = data;
              } else {
                state[type].push(data);
              }
            }
          }
        }
      }
    },
    setActivityList(state, action) {
      let type = action.payload.type
      let list = action.payload.list
      state["List"] = list;

    },
    updateBadgeCount(state, action) {
      const fnd = state.Counts.find(e => e.id == action.payload.id)
      if (fnd) {
        fnd.count = action.payload.count
      } else {
        state.Counts.push({ id: action.payload.id, count: action.payload.count })
      }
    },

    serverUpdate(state, action) {
      let raw = JSON.parse(action.payload);
      let data = raw.INFO
      if (data) {
        if (data?.id) {
          let index = state[raw.TYPE].findIndex(el => el.id === data.id)
          //console.log.log("checking index", index);
          if (index >= 0) {
            state[raw.TYPE][index] = data;
          } else {
            state[raw.TYPE].unshift(data);
          }
        }
      }
    },
  },
})

export function getArrayName(type: number): string {
  switch (type) {
    case Global.BIRTHDAY:
      return "birthday"
    case Global.ACHIEVEMENT:
      return "achievements"
    case Global.DEATH:
      return "death"
    case Global.WISHES:
      return "wishes"
    case Global.OURPEOPLE:
      return "ourpeopole"
    case Global.ACTIVITY:
      return "activities"
    case Global.GAURAV:
      return "gaurav"
    case Global.COMMINFO:
      return "comminfo"
  }
  return "birthday"

}

export default listsTask.reducer;


/*
          switch (data.type) {
          case Global.BIRTHDAY:
            if (result.isInsert) {
              state.birthday.unshift(data)
            } else {
              let index = state.birthday.findIndex(el => el.id === data.id)
              state.birthday[index] = data;
            }
            state.birthdayVer = state.birthdayVer + 1
            break;
          case Global.ACHIEVEMENT:
            if (result.isInsert) {
              state.achievements.unshift(data)
            } else {
              let bindex = state.achievements.findIndex(el => el.id === data.id)
              state.achievements[bindex] = data;
            }
            state.achievementsVer = state.achievementsVer + 1
            break;
          case Global.WISHES:
            if (result.isInsert) {
              state.wishes.unshift(data)
            } else {
              let cindex = state.wishes.findIndex(el => el.id === data.id)
              state.wishes[cindex] = data;
            }
            state.wishesVer = state.wishesVer + 1
            break;
          case Global.DEATH:
            if (result.isInsert) {
              state.death.unshift(data)
            } else {
              let dindex = state.death.findIndex(el => el.id === data.id)
              state.death[dindex] = data;
            }
            state.deathVer = state.deathVer + 1
            break;
          case Global.OURPEOPLE:
            if (result.isInsert) {
              state.ourpeopole.unshift(data)
            } else {
              let eindex = state.ourpeopole.findIndex(el => el.id === data.id)
              state.ourpeopole[eindex] = data;
            }
            state.ourpeopoleVer = state.ourpeopoleVer + 1
            break;
          case Global.ACTIVITY:
            if (result.isInsert) {
              state.activities.unshift(data)
            } else {
              let findex = state.activities.findIndex(el => el.id === data.id)
              state.activities[findex] = data;
            }
            state.activitiesVer = state.activitiesVer + 1
            break;
        }
*/