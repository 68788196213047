import { Button, Card, IconButton, Portal, Dialog, Surface } from 'react-native-paper';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Share, Dimensions, Pressable, View, Modal, Platform, TouchableOpacity } from 'react-native';
import { startActivityAsync, ActivityAction } from 'expo-intent-launcher';
import * as FileSystem from 'expo-file-system';

import Global from '../constants/global';
import { Text, useTheme } from 'react-native-paper';
import { arrayRemove, arrayUnion, doc, getFirestore, setDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import dayjs from 'dayjs';
import ScaledImage from './ScaledImage';
import DAvatar from './DAvatar';
import * as Clipboard from 'expo-clipboard';
import * as Sharing from 'expo-sharing';

interface detail {
    row: any
    call(): () => void
    callback(): void
    index: number
    edit(): void
    del(): void
    stamp: number,
    L: any
}
const wx = Dimensions.get("window").width
const w = 200
const h = 300
{/* <LinearGradient
            colors={['rgba(0,0,0,1)', 'transparent']}
            end={{ x: 1, y: 0.5 }}
            start={{ x: 0, y: 1 }}
            style={{ alignSelf: "stretch", width: "100%", position: "absolute", padding: 0, bottom: 0 }}> */}

const element = (src: string, title: string, desc: string, width: number, height: number) => {
    const url = src?.includes("https://") ? src : Global.getPicUrl(src)
    return <View>
        {height && <ScaledImage uri={url} width={width} height={height} />}
        {!height && <ScaledImage uri={url} width={width} />}

        {(title || desc) && <View style={{ flex: 1, alignSelf: "stretch" }}>
            {title ? <Text variant="titleMedium" numberOfLines={1} style={{ paddingLeft: 15, color: "white" }}>{title}</Text> : <></>}
            {desc ? <Text variant="bodySmall" numberOfLines={1} style={{ paddingLeft: 15, paddingBottom: 5, color: "white" }}>{desc}</Text> : <></>}
        </View>}


    </View>
}

const MPostRow = (op: detail) => {
    const [showDelete, setDelete] = React.useState(false)
    let ago = dayjs.unix(op.row?.stamp).diff(dayjs(), "days")
    const { colors } = useTheme()


    return <Card style={{ borderRadius: 10, paddingTop: 10, alignSelf: "stretch", flex: 1, margin: 0, marginBottom: 25 }} elevation={5} mode="elevated" >
        <View style={{ flexDirection: "row", paddingLeft: 10 }}>
            {op.row?.postedBy && <DAvatar title={op.row.postedBy.name} type={Global.SMALL_DP} source={op.row.postedBy.pic} variant="headlineLarge" />}
            {op.row?.title ? <Text variant="headlineMedium" style={{ paddingLeft: 15 }}>{op.row.title}</Text> : <></>}
        </View>
        {op.row?.desc ? <Text variant="titleSmall" style={{ paddingLeft: 15, paddingBottom: 5 }}>{op.row?.desc}</Text> : <></>}
        {op.row?.cheif ? <Text style={{ fontWeight: "bold", paddingLeft: 15, paddingBottom: 5 }}>મુખ્ય અતિથી : {op.row?.cheif}</Text> : <></>}
        {op.row?.atten ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>આમંત્રિત મહેમાનો : {op.row?.atten}</Text> : <></>}
        {op.row?.date ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>તારીખ : {dayjs.unix(op.row?.date).format("DD/MMM/YYYY")}</Text> : <></>}
        {op.row?.parti ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>સ્પર્ધકો : {op.row?.parti}</Text> : <></>}
        {op.row?.winner ? <Text style={{ paddingLeft: 15, paddingBottom: 5 }}>વિજેતા : {op.row?.winner}</Text> : <></>}
        <View style={{ flexDirection: "row", paddingLeft: 10 }}>
            {op.row?.postedBy ? <Text variant="labelSmall" style={{ flex: 1, textAlign: "right" }}>By {op.row.postedBy.name} </Text> : <></>}
            {ago ? <Text variant="labelSmall" style={{ textAlign: "right" }}>{Math.abs(ago)} days ago </Text> : <></>}
        </View>
        <View style={{ paddingTop: 10 }}>
            {op.row?.media.length == 1 && element(op.row?.media[0].content, op.row?.media[0]?.title, op.row?.media[0]?.desc, wx, wx / 3)}
            {op.row?.result && <View>
                {op.row?.result.map((r, i) => {
                    return <View key={i + ""} style={{ marginLeft: 20 }}>
                        <View>
                            <Text style={{ width: "fit-content", borderBottomWidth: 1, marginTop: 5, fontWeight: "bold" }}>{r.class}</Text>
                        </View>
                        {r.r1 && <Text style={{ marginLeft: 20 }}>પ્રથમ  : {r.r1}</Text>}
                        {r.r2 && <Text style={{ marginLeft: 20 }}>બીજો  : {r.r2}</Text>}
                        {r.r3 && <Text style={{ marginLeft: 20 }}>ત્રીજો  : {r.r3}</Text>}
                    </View>
                })}
            </View>}
            {op.row?.media.length >= 2 && <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {op.row?.media.map((e, i) => {
                    return <View key={i + ""} style={{ padding: 1, borderRadius: 5 }}>{element(e.content, e?.title, e?.desc, w, h)}</View>
                }
                )}
            </View>}

            {/* {op.row?.media.length == 2 &&
                <View style={{ flexDirection: "row" }}>
                    {element(op.row?.media[0].content, op.row?.media[0]?.title, op.row?.media[0]?.desc, w / 2, w / 4)}
                    {element(op.row?.media[1].content, op.row?.media[1]?.title, op.row?.media[1]?.desc, w / 2, w / 4)}
                </View>
            }
            {op.row?.media.length == 3 &&
                <View>
                    {element(op.row?.media[0].content, op.row?.media[0]?.title, op.row?.media[0]?.desc, w, h / 2)}
                    <View style={{ flexDirection: "row" }} >
                        {element(op.row?.media[1].content, op.row?.media[1]?.title, op.row?.media[1]?.desc, w / 2, w / 4)}
                        {element(op.row?.media[2].content, op.row?.media[2]?.title, op.row?.media[2]?.desc, w / 2, w / 4)}
                    </View>
                </View>
            }
            {op.row?.media.length == 4 &&
                <View>
                    {element(op.row?.media[0].content, op.row?.media[0]?.title, op.row?.media[0]?.desc, w, h / 2)}
                    <View style={{ flexDirection: "row" }} >
                        {element(op.row?.media[1].content, op.row?.media[1]?.title, op.row?.media[1]?.desc, w / 3, w / 4)}
                        {element(op.row?.media[2].content, op.row?.media[2]?.title, op.row?.media[2]?.desc, w / 3, w / 4)}
                        {element(op.row?.media[3].content, op.row?.media[3]?.title, op.row?.media[3]?.desc, w / 3, w / 4)}
                    </View>
                </View>
            }
            {op.row?.media.length > 4 &&
                <View>
                    {element(op.row?.media[0].content, op.row?.media[0]?.title, op.row?.media[0]?.desc, w, h)}
                    <View style={{ flexDirection: "row" }} >
                        {element(op.row?.media[1].content, op.row?.media[1]?.title, op.row?.media[1]?.desc, w / 4, w / 4)}
                        {element(op.row?.media[2].content, op.row?.media[2]?.title, op.row?.media[2]?.desc, w / 4, w / 4)}
                        {element(op.row?.media[3].content, op.row?.media[3]?.title, op.row?.media[3]?.desc, w / 4, w / 4)}
                        <View style={{ height: w / 4, width: w / 4 }}>
                            {element(op.row?.media[4].content, op.row?.media[4]?.title, op.row?.media[4]?.desc, w / 4, w / 4)}
                            <TouchableOpacity onPress={() => op.navigation.navigate("PostGallary", { entry: op.row })} style={{ flexDirection: "row", position: "absolute", height: w / 4, width: w / 4, backgroundColor: "rgba(0,0,0,0.5)", justifyContent: "center", alignItems: "center" }}>
                                <View>
                                    <Text style={{ color: "white", textAlign: "center" }}>View All</Text>
                                    <Text style={{ color: "white", textAlign: "center" }}>({op.row?.media?.length})</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            } */}
            <TouchableOpacity onPress={() => op.navigation.navigate("LikesList", { entry: op.row?.likes })} style={{ marginTop: 10, flexDirection: "row", alignItems: "center" }}>
                <IconButton iconColor='green' icon={"thumb-up"} size={15} style={{ backgroundColor: "#F0E68C" }} />
                <Text style={{ flex: 1 }}>{op.row?.likes?.length} likes</Text>
                {op.row?.comments?.length && <Text>{op.row?.comments?.length} comments</Text>}
            </TouchableOpacity>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ alignItems: "center", justifyContent: "center" }}>
                    <IconButton style={{ marginTop: 0, paddingTop: 0 }} icon="thumb-up"
                        iconColor={op.row?.likes?.find(e => e.id == op.login?.id) ? "green" : "gray"}
                        onPress={() => {
                            if (op.login?.id) {
                                const db = getFirestore()
                                let ref
                                const data = { ...op.row }
                                if (!data.likes) {
                                    data.likes = []
                                }
                                const recd = data.likes.findIndex(e => e.id == op.login.id)

                                ref = doc(db, "Posts", data.id)
                                try {
                                    if (recd == -1) {
                                        setDoc(ref, {
                                            likes: arrayUnion({
                                                id: op.login?.id,
                                                name: op.login?.name,
                                                dp: op.login?.dp ? op.login.dp : "",
                                                stamp: dayjs().unix()
                                            })
                                        }, { merge: true })
                                    } else {
                                        setDoc(ref, {
                                            likes: arrayRemove(data.likes[recd])
                                        }, { merge: true })
                                        // lk.splice(recd, 1)
                                    }
                                } catch (e) {
                                    console.log("err", e)
                                    Toast.show("Error : saving data. Check internet connection")
                                }

                            } else {
                                op.navigation.navigate("Login")
                            }
                        }} />
                </View>
                <IconButton icon="comment-multiple-outline" onPress={() => {
                    if (op.login?.id) {
                        op.navigation.navigate("Comments", { entry: op.row, login: op.login })
                    } else {
                        op.navigation.navigate("Login")
                    }
                }}
                />

                <IconButton icon="share-outline" onPress={async () => {
                    try {
                        if (Platform.OS == 'web') {
                            FileSystem.downloadAsync(
                                Global.getImageLink(op.row?.media[0].content),
                                FileSystem.documentDirectory + '.jpeg'
                            )
                                .then(({ uri }) => {
                                    Clipboard.setStringAsync('*' + op.row.title + '*\n' + op.row.desc + "\n" + "*શ્રી ઠક્કરબાપવિદ્યામંદિર*");
                                    Sharing.shareAsync(uri, { dialogTitle: op.row.title, mimeType: "image/*" });
                                })
                                .catch(error => {
                                    console.error(error);
                                });

                        } else {
                            startActivityAsync("android.intent.action.SEND", { category: "android.intent.action.SEND", className: "com.whatsapp", data: op.row?.media[0].content, packageName: "com.whatsapp", type: "image/*" }).catch(e => {
                            });

                        }
                    } catch (e) {
                        alert(e)
                    }
                    // Share.share({title: "Sharing you ", "message": "Sharing you details of *" + op.row.title.trim() + "* at _STVM_.\n\nPlease check out https://stvmeducation.web.app/\n\nHave a nice day 🙂" })
                }} />
                <IconButton icon="image-multiple-outline" onPress={() => op.navigation.navigate("PostGallary", { entry: op.row })} />

                {op.login?.id && Global.isEdit(op?.login?.type) && (op.login.id == op.row.postedBy.id || op.login?.allowedToEditPost || op.login?.type == Global.PRINCIPAL) && <IconButton icon="pencil" onPress={() =>
                    op.navigation.navigate("NewPost", { entry: op.row })
                } />}
                {op.login?.id && Global.isEdit(op?.login?.type) && (op.login.id == op.row.postedBy.id || op.login?.allowedToDeletePost || op.login?.type == Global.PRINCIPAL) && <IconButton icon="delete-outline" onPress={() => { setDelete(true) }
                } />}
            </View>
        </View>

        {/* <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} visible={zoom.zoom} onDismiss={() => setZoom({ zoom: false, source: "", type: "IMAGE" })} animationType="fade" transparent>
            {
                zoom.type == "IMAGE" ?
                    <Pressable onPress={() => { setZoom({ zoom: false, source: "", type: "IMAGE" }) }}>
                        <View style={{ backgroundColor: "rgba(0,0,0,0.9)", height: Dimensions.get("window").height, width: Dimensions.get("window").width, alignItems: "center", justifyContent: "center" }}>
                            {/* <GallerySwiper
                            onSingleTapConfirmed={() => { setZoom({ zoom: false, source: "", type: "IMAGE" }) }}
                            images={[{ uri: zoom.source }]}
                        /> }
                            <Image source={{ uri: zoom.source }} style={{ width: Dimensions.get("window").width, aspectRatio: 3 / 2 }} />
                            <View style={{ backgroundColor: "rgba(0,0,0,0.8)", alignSelf: "stretch" }}>
                                {zoom.title ? <Text variant="titleMedium" style={{ paddingLeft: 15, color: "white" }}>{zoom.title}</Text> : <></>}
                                {zoom.desc ? <Text variant="bodySmall" style={{ paddingLeft: 15, paddingBottom: 5, color: "white" }}>{zoom.desc}</Text> : <></>}
                            </View>

                        </View>
                    </Pressable>
                    :
                    <View style={{ backgroundColor: "rgba(0,0,0,0.9)", height: Dimensions.get("window").height, flex: 1, alignSelf: "stretch" }}>
                        <Video
                            source={{ uri: zoom.source }}
                            isMuted={false}
                            shouldPlay
                            contentFit='contain'
                            useNativeControls
                            isLooping
                            style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height }}
                        />
                        <IconButton style={{ position: "absolute", top: 0, right: 0 }} iconColor={"dimgray"} icon="close" size={24} onPress={() => setZoom({ zoom: false, source: "", type: "IMAGE" })} />

                    </View>
            }
        </Modal> */}
        <Portal>
            <Dialog style={{ backgroundColor: colors.background }} visible={showDelete} onDismiss={() => setDelete(false)}>
                <Dialog.Title>Delete ?</Dialog.Title>
                <Dialog.Content>
                    <Text variant="headlineSmall">{op.row?.title}</Text>
                    <Text>{op.row?.desc}</Text>
                </Dialog.Content>
                <View style={{ justifyContent: "space-evenly", flexDirection: "row", padding: 10 }}>
                    <Button onPress={() => setDelete(false)}>Cancel</Button>
                    <Button mode="contained" onPress={() => {
                        setDelete(false);
                        Toast.show("Post will be deleted soon", { duration: 500 })
                        setTimeout(() => {
                            const db = getFirestore();
                            const docRef = doc(db, "Posts", op.row.id)
                            setDoc(docRef, { flag: -10 }, { merge: true }).then(() => {
                                Toast.show("Deleted")
                            }).catch(e => {
                                Toast.show("Error")
                            })
                        })

                    }}>Delete
                    </Button>
                </View>
            </Dialog>
        </Portal>

    </Card >
}
const PostRow = React.memo(MPostRow)
export default PostRow
