import { FlashList } from "@shopify/flash-list";

import { SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Share } from 'react-native';
import { IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';
import { Image } from "expo-image"

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import { collection, doc, getFirestore, increment, setDoc, updateDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import Prferances from '../constants/Preferances';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import Global from "../constants/global";
import { SaveFormat } from 'expo-image-manipulator';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import ScaledImage from "../components/ScaledImage";
import { loginTasks } from "../task/loginTasks";

let baselist: [] = []
export default function Profile({ navigation, route }) {
    const [focus, setFocus] = React.useState(undefined)
    const [working, setWorking] = React.useState(false)
    const [show, showPass] = React.useState(false)
    const { colors } = useTheme();
    const pref = React.useContext(Prferances)
    React.useEffect(() => {
        if (route?.params?.data) {
            setFocus(route.params.data)
        }
    }, [])
    const inSchool =
        (focus?.type == Global.PRINCIPAL || focus?.type == Global.TEACHER || focus?.type == Global.ADMIN || focus?.type == Global.STAFF || focus?.type == Global.VISITING_TEACHER
        )
    const trushtee = (!focus?.retired) && (focus?.type == Global.TRUSTEE)

    const pickImage = async () => {
        setWorking(true)
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [4, 3]
        });

        if (!result.cancelled) {
            const towork = { ...focus }
            if (!focus?.dp) {
                towork.dp = ""
            }
            const red = await ImageManipulator.manipulateAsync(
                result.uri,
                [{ resize: { width: 500 } }], // resize to width of 300 and preserve aspect ratio 
                { compress: 1, format: SaveFormat.WEBP },
            )
            towork.local = red
            setFocus(towork)
        }
        setWorking(false)
    };
    const dispatch = useDispatch()
    const toc = focus?.local ? focus?.local?.uri : focus?.dp
    const isData = toc?.substring(0, 10) == "data:image"
    // const isImage = toc?.includes(".jpg") || toc?.includes(".jpeg") || toc?.includes(".png") || toc?.includes(".jfif") || toc?.includes(".gif")
    const uri = toc?.includes("file:/") ? toc : toc?.includes("https://") ? toc : (isData ? toc : Global.getPicUrl(toc))
    return (
        <ScrollView style={{ flex: 1 }}>

            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                <View style={{ alignItems: "center", marginTop: 20, marginBottom: 20 }}>
                    {(toc && !isData) && <ScaledImage width={150} height={150} uri={uri} />}
                    {(toc && isData) && <Image style={{ borderRadius: 75, width: 150, height: 150 }} source={{ uri: uri }} />}
                    {(!toc) && <IconButton icon="file-image-plus" onPress={pickImage} />}

                    <IconButton style={{ position: "absolute", bottom: 0, right: 0 }} icon={"pen"} onPress={pickImage} />
                </View>
                <TextInput label="નામ" value={focus?.name ? focus?.name : ""} onChangeText={(t) => setFocus(p => ({ ...p, name: t }))} />
                {inSchool && <TextInput label="પૂરું નામ" value={focus?.shortName ? focus?.shortName : ""} onChangeText={(t) => setFocus(p => ({ ...p, shortName: t }))} />}
                <TextInput label="મોબાઈલ નં" value={focus?.mobile ? focus?.mobile : ""} onChangeText={(t) => setFocus(p => ({ ...p, mobile: t }))} />

                {(pref?.current?.id == focus?.id || focus?.id == undefined) &&
                    <TextInput label="પાસવર્ડ " value={focus?.password ? focus?.password : ""}

                        onChangeText={(t) => setFocus(p => ({ ...p, password: t }))} />}
                {inSchool && <TextInput label="સરનામું " value={focus?.address ? focus?.address : ""} onChangeText={(t) => setFocus(p => ({ ...p, address: t }))} />}
                {/* {inSchool && trushtee && <TextInput label="જન્મદિવસ" value={focus?.mobile ? focus?.mobile : ""} onChangeText={(t) => setFocus(p => ({ ...p, mobile: t }))} />} */}
                {inSchool && trushtee && <TextInput label="ઈમેલ" value={focus?.email ? focus?.email : ""} onChangeText={(t) => setFocus(p => ({ ...p, email: t }))} />}
                {inSchool && <TextInput label="અનુભવ (વર્ષ)" value={focus?.experience ? focus?.experience : ""} onChangeText={(t) => setFocus(p => ({ ...p, experience: t }))} />}
                {inSchool && <TextInput label="Education" value={focus?.education ? focus?.education : ""} onChangeText={(t) => setFocus(p => ({ ...p, education: t }))} />}
                {inSchool && <TextInput label="Qualification (Exams passout)" value={focus?.exams ? focus?.exams : ""} onChangeText={(t) => setFocus(p => ({ ...p, exams: t }))} />}
                {trushtee && <TextInput label="Description" value={focus?.desc ? focus?.desc : ""} onChangeText={(t) => setFocus(p => ({ ...p, desc: t }))} />}
                {trushtee && <TextInput label="Designation" value={focus?.designation ? focus?.designation : ""} onChangeText={(t) => setFocus(p => ({ ...p, designation: t }))} />}
                <View style={{ flex: 1, alignItems: "center", flexDirection: "row", marginTop: 20 }}>
                    <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Cancel</Button>
                    <Button style={{ flex: 1 }} mode="contained" onPress={async () => {
                        if (focus.mobile.length == 10 && focus?.password?.length > 3) {
                            const db = getFirestore()
                            let ref
                            if (focus.id) {
                                ref = doc(db, "Users", focus.id)
                            } else {
                                ref = doc(collection(db, "Users"))
                                focus.id = ref.id
                                focus.createdOn = dayjs().unix()
                            }
                            if (focus.local) {
                                let nm = Global.uploadName(focus.local, "").name
                                if (focus?.local?.uri?.substring(0, 10) == "data:image") {
                                    nm = Global.getUniqueID() + ".jpg"
                                } else {
                                    nm = Global.uploadName(focus.local, "").name
                                }
                                console.log("got newname", nm)
                                await Global.upload(focus.local, nm, () => { }, "").then(e => {
                                    console.log("upload result")
                                }).catch(e => console.log("got error", e))
                                focus.dp = nm
                                delete focus.local
                            }
                            focus.stamp = dayjs().unix()
                            await setDoc(ref, focus)
                            if (pref?.current?.id == focus.id) {
                                dispatch(loginTasks.actions.allowLogin({ user: focus }));
                            }
                            setTimeout(() => Toast.show("Saved"), 500)
                            navigation.goBack()
                        } else {
                            Toast.show("Mobile no. must be 10 degits, and password must be atleat 4 chars")
                        }

                    }}>Save</Button>
                </View>
            </View >
        </ScrollView >
    );


}

