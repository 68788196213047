import { ScrollView, View } from 'react-native';
import { HelperText, IconButton, Text, useTheme } from "react-native-paper";
import { Avatar } from "react-native-paper";
import React, { useEffect, useRef, useState } from "react";
import Global from '../constants/global';
import ReactToPrint from 'react-to-print';
import { MaterialIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import Preferance from '../constants/Preferances';

const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;


export default function Marksheet({ navigation, route }) {
    const pref = useContext(Preferance)
    const [list, setList] = useState([])
    const coreSubjects = useSelector(s => s.list.Subjects)
    const users = useSelector(React.useMemo(Global.getUserList, []))
    useEffect(() => {
        if (coreSubjects && route.params && users) {
            setList(route.params.list)
        }
    }, [route.params, coreSubjects, users])
    const ht = 27
    const bw1 = 3
    const bw2 = 2
    const ref = useRef()
    const t = useTheme()
    const yr = route.params.exam.year
    return <ScrollView horizontal style={{ flex: 1, alignSelf: "stretch" }}>
        <View style={{ minWidth: 700 }}>
            {route?.params?.blockPrint !== true && <View style={{ flexDirection: "row" }}>
                <IconButton icon="arrow-left" style={{}} onPress={() => navigation.goBack()} />
                {
                    //{pref?.current?.supervisor == true &&
                }
                <ReactToPrint
                    pageStyle={pageStyle}
                    trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                    content={() => ref.current}
                />

            </View>}
            <div ref={ref} style={{ display: "block", width: '100%', marginLeft: "2%", marginRight: "2%" }}>
                <style type="text/css" media="print">{"@page { size: portrait}"}</style>

                {list.map(value => {
                    let subject: [] = []
                    const exam = value?.marks[yr][route.params.exam.id]

                    let pg1 = 0
                    const usr = users.find(f => f.id == value?.id)
                    // console.log(usr, exam, value)
                    const chk = usr ? usr : exam.student
                    let uid = ""
                    if (exam) {
                        uid = chk["class" + exam.exam.year]?.uid ? chk["class" + exam.exam.year]?.uid : value?.uid
                    }
                    let outOf = 0
                    try {

                        Object.keys(exam).forEach(function (key) {
                            if (Global.isSubject(exam, key)) {
                                const got = coreSubjects.find(f => f.id == key)
                                if (got?.order) {
                                    subject.push({ ...exam[key], order: got.order, name: got.name })
                                } else {
                                    subject.push({ ...exam[key] })
                                }
                                if (!exam[key]?.grad && exam[key].name !== "Total") {
                                    // console.log("outOf" + exam[key].name, outOf)
                                    outOf = outOf + 100
                                }
                            }
                        });
                        // console.log("outOf", outOf)
                        subject = subject.sort((a, b) => (a?.order ? a.order : 0) - (b?.order ? b.order : 0))

                    } catch { }
                    let cnd = 0
                    pg1++
                    return <div style={{ pageBreakInside: "avoid", paddingTop: 15 }}>
                        <div style={{ width: '100%', borderWidth: bw1, borderStyle: "solid", marginBottom: pg1 == 1 ? 100 : 50 }}>
                            <div style={{ display: "flex", position: "relative", flexDirection: "column", backgroundColor: t.dark ? "transparent" : "#DCDCDC", borderBottomWidth: bw2, borderBottomStyle: "solid", alignItems: "center", justifyContent: "center" }}>

                                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <Avatar.Image style={{ marginTop: 5, marginEnd: 10 }} size={60} source={{ uri: require("../assets/images/LOGO.jpg") }} />
                                    <View>
                                        <Text variant="headlineMedium" style={{ fontWeight: "900", marginTop: 10 }}>શ્રી ઠક્કરબાપા વિદ્યામંદિર</Text>
                                        <Text variant="titleMedium" style={{ paddingLeft: 10 }}>ઠક્કરબાપાનગર, અમદાવાદ-૩૮૨૩૫૦</Text>
                                    </View>
                                </View>
                                <View style={{ alignItems: "center", justifyContent: "center", flex: 1, flexDirection: "row" }}>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}>{exam?.exam?.final ? "વાર્ષિક પરીક્ષાનું" : exam?.exam?.name} પરિણામ</Text>
                                    <Text variant="titleLarge" style={{ textAlign: "center" }}> (વર્ષ : {Global.YearString(exam?.exam?.year)}) </Text>
                                </View>
                                {/* <Text style={{ position: "absolute", right: 0, top: 0, textAlign: "right" }}>ફોન : ૨૨૯૦૦૫૦૬ </Text> */}

                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginLeft: 10 }}>
                                <View style={{ flex: 1, flexDirection: "row" }}>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text variant="titleMedium" style={{}}>વિદ્યાર્થી નું નામ   :   </Text>
                                        <Text variant="titleMedium" style={{ fontWeight: "900", textDecorationLine: "underline" }}>{usr.name}</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text variant="titleMedium" style={{ marginTop: 10, flex: 1, fontWeight: "900" }}>{exam?.classInfo?.name} {exam?.classInfo?.div}</Text>
                                    </View>
                                </View>
                                <View style={{ flex: 1, flexDirection: "row" }}>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text variant="titleMedium" style={{}}>રોલ નં - {chk["class" + exam?.exam?.year]?.roll}</Text>
                                        <Text variant="titleMedium" style={{}}>               GR નં - {exam?.exam?.year ? chk["class" + exam.exam.year]?.gr : ""}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text variant="titleMedium" style={{ flex: 1 }}>UID નં - {uid}    </Text>
                                    </View>
                                </View>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <View style={{ width: "70%", alignSelf: "stretch", borderRightWidth: 2, borderStyle: "solid" }}>
                                    <View style={{ alignItems: "center", justifyContent: "center", paddingLeft: 5, backgroundColor: t.dark ? "transparent" : "#DCDCDC", flexDirection: "row", borderTopWidth: bw2, borderStyle: "solid", borderBottomWidth: bw2 }}>
                                        <View style={{ height: (ht * 1.25), flex: 3, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                            <Text variant="titleMedium" style={{ textAlign: "center" }}>અ.નં</Text>
                                        </View>

                                        <View style={{ height: (ht * 1.25), flex: 20, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                            <Text variant="titleMedium" style={{ textAlign: "center" }}>વિષય</Text>
                                        </View>

                                        <View style={{ height: (ht * 1.25), flex: 5, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                            <Text variant="titleSmall" style={{ textAlign: "center" }}>કુલ ગુણ</Text>
                                        </View>

                                        <View style={{ height: (ht * 1.25), flex: 7, alignItems: "center", justifyContent: "center", borderRightWidth: bw2, }}>
                                            <Text variant="titleSmall" style={{ textAlign: "center" }}>મેળવેલ ગુણ</Text>
                                        </View>
                                        {exam?.exam?.final && <View style={{ height: (ht * 1.25), flex: 7, alignItems: "center", justifyContent: "center", borderRightWidth: bw2, }}>
                                            <Text variant="titleSmall" style={{ textAlign: "center" }}>સિદ્ધિ ગુણ</Text>
                                        </View>}
                                        {exam?.exam?.final && <View style={{ height: (ht * 1.25), flex: 7, alignItems: "center", justifyContent: "center", }}>
                                            <Text variant="titleSmall" style={{ textAlign: "center" }}>કૃપા ગુણ</Text>
                                        </View>}
                                    </View>

                                    {
                                        subject?.map((subject, sr) => {
                                            let showM = subject.selfMarks ? subject.selfMarks : subject.theory
                                            try {
                                                const nt = parseInt(subject.theory)
                                                if (!Number.isNaN(nt) && nt < 10) {
                                                    showM = "0" + nt
                                                }
                                            } catch (e) {

                                            }
                                            const toShow = subject.totalMarks > 0 || subject?.grad
                                            cnd = toShow ? cnd + 1 : cnd
                                            return (
                                                toShow ? <View style={{ alignItems: "center", justifyContent: "center", paddingLeft: 5, flexDirection: "row", borderBottomWidth: bw2, borderStyle: "solid" }}>
                                                    <View style={{ height: ht, flex: 3, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                                        <Text variant="titleMedium" style={{ textAlign: "center", }}>{cnd}</Text>
                                                    </View>

                                                    <View style={{ height: ht, flex: 20, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                                        <Text variant="titleMedium" style={{ textAlignVertical: "center", overflow: "hidden" }}> {subject.name}</Text>
                                                    </View>
                                                    <View style={{ height: ht, flex: 5, borderRightWidth: bw2, borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                                        <Text variant="titleMedium" style={{ fontWeight: "900", textAlignVertical: "center", textAlign: "center" }}> {subject?.grad ? "" : exam?.exam?.final ? 100 : subject.totalMarks}</Text>
                                                    </View>
                                                    {/* {console.log(exam.status,subject?.kripa,subject?.siddhi, exam.student.name ,subject.name)} */}
                                                    <View style={{ flexDirection: "row", height: ht, flex: 7, justifyContent: "center", borderRightWidth: bw2, }}>
                                                        <Text variant="titleMedium" style={{ fontWeight: "900", textAlign: "center", textDecorationLine: subject.status == "Fail" ? "underline" : "none" }}>
                                                            {subject?.grad ? subject?.grade : showM}</Text>
                                                    </View>
                                                    {exam?.exam?.final && <View style={{ flexDirection: "row", height: ht, flex: 7, justifyContent: "center", borderRightWidth: bw2, }}>
                                                        {(exam?.status == "Pass" && !subject.grad && subject.siddhi) ? <Text variant="bodyMedium" style={{ fontWeight: "bold", color: "red", padding: 0 }}>
                                                            {(subject.siddhi ? "+" + subject.siddhi : "")}</Text> : <></>}
                                                    </View>}
                                                    {exam?.exam?.final && <View style={{ flexDirection: "row", height: ht, flex: 7, justifyContent: "center", }}>
                                                        {(exam?.status == "Pass" && !subject.grad && subject.kripa) ? <Text variant="bodyMedium" style={{ fontWeight: "bold", color: "green", padding: 0 }}>
                                                            {(subject.kripa ? "+" + subject.kripa : "")}</Text> : <></>}
                                                    </View>}
                                                </View> : <></>)
                                        })
                                    }
                                    <View style={{ backgroundColor: t.dark ? "transparent" : "#DCDCDC", paddingLeft: 5, flexDirection: "row", borderBottomWidth: bw2 }}>
                                        <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 23, textAlign: "center", borderRightWidth: bw2, borderStyle: "solid" }}>કુલ ગુણ</Text>
                                        <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 5, textAlign: "center", borderRightWidth: bw2, borderStyle: "solid" }}>{exam?.exam?.final ? outOf : exam?.totalMarks}</Text>
                                        <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 7, textAlign: "center", borderRightWidth: bw2, }}>{exam?.selfMarks ? exam?.selfMarks : (exam?.subjectMarks ? exam?.subjectMarks : "0")}</Text>
                                        {exam?.exam?.final && <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 7, color: "red", textAlign: "center", borderRightWidth: bw2, }}>{(exam?.status == "Pass" && exam?.siddhiGiven) ? "+" + exam?.siddhiGiven : ""}</Text>}
                                        {exam?.exam?.final && <Text variant="titleMedium" style={{ height: ht, fontWeight: "900", flex: 7, color: "green", textAlign: "center", }}>{(exam?.status == "Pass" && exam?.kripaGiven) ? "+" + exam?.kripaGiven : ""}</Text>}
                                    </View>

                                </View>
                                {/* {console.log(exam)} */}
                                <View style={{ width: "30%", borderTopWidth: bw2, borderBottomWidth: bw2, alignItems: "center", justifyContent: "center" }}>
                                    <View style={{ flexDirection: "row", borderStyle: "solid", alignItems: "center", justifyContent: "center" }}>
                                        <View style={{ justifyContent: "center", alignItems: 'center' }}>
                                            <Text style={{ height: 35 }} variant="titleMedium">પરિણામ </Text>
                                            <Text style={{ height: 35 }} variant="titleMedium">ટકાવારી </Text>
                                            <Text style={{ height: 35 }} variant="titleMedium">પાસ નંબર </Text>
                                        </View>

                                        <View style={{ justifyContent: "center", alignItems: 'center', borderWidth: bw1, borderStyle: "solid", }}>
                                            <Text variant="titleMedium" style={{ height: 35, borderBottomWidth: bw1, fontWeight: "900", textAlign: "center", width: 100, }}>{exam?.exam?.final ? (exam?.status == "Pass" ? (exam?.kripaGiven > 0 ? "ઉપર ચઢાવ્યા" : exam?.status) : exam?.status) : exam?.status}</Text>
                                            <Text variant="titleMedium" style={{ height: 35, borderBottomWidth: bw1, fontWeight: "900", borderStyle: "solid", width: 100, textAlign: "center" }}> {exam?.status == "Pass" ? (exam?.pc ? exam?.pc + " %" : "") : "-"} </Text>
                                            <Text variant="titleMedium" style={{ height: 35, fontWeight: "900", borderStyle: "solid", width: 100, textAlign: "center" }}> {exam?.status == "Pass" ? (exam?.rank > 0 ? exam?.rank : "-") : "-"}  </Text>
                                        </View>
                                    </View>
                                    {exam?.exam.final && <View style={{ flexDirection: "row", borderStyle: "solid", alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                        <View style={{ justifyContent: "center", alignItems: 'center' }}>
                                            <Text style={{ flex: 1 }} variant="titleMedium">{chk["class" + exam?.exam?.year]?.totalDays}</Text>
                                            <Text style={{ flex: 1, borderTopWidth: 1 }} variant="titleMedium">કુલ દિવસ</Text>
                                        </View>
                                        <Text style={{ flex: 1 }} variant="titleSmall"> માંથી </Text>

                                        <View style={{ justifyContent: "center", alignItems: 'center', borderStyle: "solid", }}>
                                            <Text style={{ flex: 1 }} variant="titleMedium">{chk["class" + exam?.exam?.year]?.present}</Text>
                                            <Text style={{ flex: 1, borderTopWidth: 1 }} variant="titleMedium">હાજર દિવસ</Text>
                                        </View>
                                    </View>}

                                    <Text style={{ textDecorationLine: "underline", fontWeight: "bold", marginTop: 20 }}>{(exam?.rank == 1 || exam?.rank == 2 || exam?.rank == 3) ? "Congratulations" : ""}</Text>

                                </View>
                            </div>
                            {route?.params?.blockPrint !== true &&
                                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                    <div style={{ display: "flex", marginTop: 30, flex: 1, flexDirection: "row" }}>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}>વર્ગશિક્ષકની  સહી</Text>
                                            {/* <HelperText style={{ flex: 1, textAlign: "center" }}></HelperText> */}
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}>ચેકરની સહી</Text>
                                            {/* <HelperText style={{ flex: 1, textAlign: "center" }}></HelperText> */}
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}>આચાર્યની સહી</Text>
                                            {/* <HelperText style={{ flex: 1, textAlign: "center" }}></HelperText> */}
                                        </View>
                                    </div>

                                    {exam?.exam.final && <Text style={{}}>{route.params?.exam?.note1}</Text>}
                                    {exam?.exam.final && <Text style={{}}>{route.params?.exam?.note2}</Text>}
                                </div>
                            }
                        </div >
                        {pg1 == 2 && <div className="pagebreak" />}
                    </div>
                })}
            </div>
        </View>
    </ScrollView>
}