import { ScrollView, View } from 'react-native';
import { IconButton, Text, useTheme } from "react-native-paper";
import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';

import dayjs from 'dayjs';
import { MaterialIcons } from '@expo/vector-icons';
import Global from '../constants/global';

const totalTag = "Total Students"
const resultTag = "Result (%)"
const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
      page-break-after: always;
    }
  }

  @media print {
    .pagebreak {
      page-break-after: always;
    }
  }
`;

export default function FinalTarij({ navigation, route }) {
    const [value, setValue] = useState({ stream: [], class: undefined, student: [], subjects: [], exam: undefined, tarij: 0, totalStudents: 0, attend: 0, pass: 0, fail: 0, ranklist: [], left: 0, year: "" })
    console.log(route)

    useEffect(() => {
        const subj = [...route.params.subjects]
        const stud = [...route.params.student]
        const year = route.params.examyear
        const as: [] = [...new Set(subj.map(item => item.stream))]
        // console.log("subb",as,subj)
        // console.log("subb",stud)
        const val = {
            total: 0, students: [], appeared: [], left: [],
            passed: [], promoted: [], fail: [], pc: 0, pcApp: 0,
        }
        const streams: [] = []
        as.map(a => {
            const v = { ...val }
            v.students = stud.filter(s => (s["class" + route.params.examyear].stream == a))
            // const x = stud.filter(s => (s["class" + route.params.examyear].stream !== a))
            // console.log(x)
            // console.log(v.students)

            // stud.map(s => {
            //     console.log(s["class" + route.params.examyear].stream, a)
            // })
            let absent = 0

            v.students?.map(s => {
                const allex = s.marks[route.params.examyear]
                let sAbsent = 0

                // if (s["class"+route])
                Object.keys(allex).forEach(function (exid) {
                    let subCount = 0
                    let Acount = 0
                    Object.keys(allex[exid]).forEach(function (sub) {
                        if (Global.isSubject(allex[exid], sub)) {
                            if (s["class" + route.params.examyear].stream == allex[exid][sub].stream) {
                                const Int = allex[exid].exam.isInternal
                                const fin = allex[exid].exam.final
                                if (!Int && !fin) {
                                    if (allex[exid][sub].theory == "Absent") {
                                        Acount = Acount + 1
                                    } else {
                                        if (s["class" + route.params.examyear].roll == 60) {
                                            console.log("roll66", allex[exid].exam.name, allex[exid][sub].name, allex[exid][sub].theory)
                                        }
                                    }
                                    subCount = subCount + 1
                                }
                            }
                        }
                    })
                    if (subCount > 0) {
                        // console.log("RORR" + s["class" + route.params.examyear].roll, allex[exid].exam.name, "Ab=" + Acount, "Sub" + subCount,)
                        if (Acount >= 7) {
                            sAbsent = sAbsent + 1
                            console.log(s["class" + route.params.examyear].roll, allex[exid].exam.name, "Ab=" + Acount, "Sub" + subCount,)
                        }
                    }
                });
                // console.log("exOll", s["class" + route.params.examyear].roll, "AllAbsentEx=" + sAbsent)

                if (sAbsent == 3) {
                    // console.log("Absent ROll", s["class" + route.params.examyear].roll)
                    absent++
                }

            })
            // console.log("absent", absent)
            v.absent = absent
            if (v.students.length > 0) {
                v.left = v.students.filter(s => s.left == true || s.name.toLowerCase().trim() == "left")
                v.appeared = v.students.filter(s => s.left !== true && s.name.toLowerCase().trim() !== "left")
                v.total = v.students.length - v.left.length
                v.promoted = v.appeared.filter(f => f.marks[year][route.params.eid]["kripaGiven"] > 0)
                v.passed = v.appeared.filter(f => f.marks[year][route.params.eid]["kripaGiven"] == 0 && f.marks[year][route.params.eid]["status"] == "Pass")
                v.fail = v.appeared.filter(f => f.marks[year][route.params.eid]["kripaGiven"] == 0 && f.marks[year][route.params.eid]["status"] == "Fail")
                v.stream = a
                v.pc = Math.round(((v.passed.length + v.promoted.length) / (v.total)) * 100)
                v.pcApp = Math.round(((v.passed.length + v.promoted.length) / (v.appeared.length - v.absent)) * 100)
                streams.push(v)
            }
        })

        const djs = dayjs.unix(route.params.exam?.createdOn)
        console.log("stream", streams)
        console.log("subj", subj)
        console.log("student", stud)
        subj.map(subb => {
            const allPass = stud.filter(f => f.display.findIndex(ff => ff.secStatus == "Pass" && subb.id == ff.id && ff.left !== true) > -1)
            subb.pass = allPass.length
            const app = stud.filter(f => f.display.findIndex(ff => subb.id == ff.id && ff.finA !== true && ff.left !== true) > -1)
            let SubAbsent = 0


            stud?.map(s => {
                const allex = s.marks[route.params.examyear]
                let ssAbsent = 0

                // if (s["class"+route])
                Object.keys(allex).forEach(function (exid) {
                    let subCount = 0
                    let Acount = 0
                    Object.keys(allex[exid]).forEach(function (sub) {
                        if (Global.isSubject(allex[exid], sub)) {
                            if (s["class" + route.params.examyear].stream == allex[exid][sub].stream) {
                                if (sub == subb.id) {
                                    const Int = allex[exid].exam.isInternal
                                    const fin = allex[exid].exam.final
                                    if (!Int && !fin) {
                                        if (allex[exid][sub].theory == "Absent") {
                                            ssAbsent = ssAbsent + 1
                                        }
                                    }
                                }
                            }
                        }
                    })
                    // if (subCount > 0) {
                    //     // console.log("RORR" + s["class" + route.params.examyear].roll, allex[exid].exam.name, "Ab=" + Acount, "Sub" + subCount,)
                    //     if (Acount >= 7) {
                    //         sAbsent = sAbsent + 1
                    //         console.log(s["class" + route.params.examyear].roll, allex[exid].exam.name, "Ab=" + Acount, "Sub" + subCount,)
                    //     }
                    // }
                });
                // console.log("exOll", s["class" + route.params.examyear].roll, "AllAbsentEx=" + sAbsent)

                if (ssAbsent == 3) {
                    // console.log("Absent ROll", s["class" + route.params.examyear].roll)
                    SubAbsent++
                }

            })



            // stud.map(s => {
            //     const allex = s.marks[route.params.examyear]
            //     let sAbsent = false
            //     Object.keys(allex).forEach(function (exid) {
            //         Object.keys(allex[exid]).forEach(function (sub) {
            //             if (Global.isSubject(allex[exid], sub)) {
            //                 if (sub == subb.id) {
            //                     if (allex[exid][sub]?.theory == "Absent") {
            //                         sAbsent = true
            //                     }
            //                 }
            //             }
            //         })
            //     });
            //     if (sAbsent) {
            //         absent++
            //     }

            // })


            subb.appeared = app.length - SubAbsent
        })
        let yr = ""
        if (djs.month() < 6) {
            yr = (djs.year() - 1) + "-" + djs.format("YY")
        } else {
            const y = djs.year()
            const ndjs = djs.add(1, "year")
            yr = y + "-" + ndjs.format("YY")
        }
        setValue({ subjects: subj, stream: streams, class: { ...route.params.seleClass }, year: year })
    }, [route.params])

    const ref = useRef()
    const t = useTheme()
    return <ScrollView>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start" }}>
            <IconButton icon={"arrow-left"} onPress={() => navigation.goBack()} />
            <ReactToPrint
                pageStyle={pageStyle}
                trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                content={() => ref.current}
            />
        </View>
        <ScrollView horizontal>
            <div ref={ref} style={{ display: "block", width: "98%", marginLeft: 10, boxSizing: "border-box" }}>
                <div style={{ marginLeft: 100, display: "block", marginRight: 30 }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text style={{ flex: 1, padding: 10, marginTop: 10, backgroundColor: "green", color: "white", textAlign: "center" }} variant="titleLarge">સંગ્રહિત પરિણામ પત્રક</Text>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1, }}>
                                <Text variant="titleLarge">{value.class?.name}</Text>
                            </View>
                            {value.class?.div && <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1, }}>
                                <Text variant="titleLarge">વર્ગ {value.class?.div}</Text>
                            </View>}
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 1 }}>
                                <Text variant="titleLarge">વર્ષ {Global.YearString(parseInt(value.year))}</Text>
                            </View>
                        </View>
                        <View style={{ padding: 2, borderWidth: 2, marginTop: 20 }}>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                {value.stream.length > 1 && <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{ fontWeight: "bold" }} variant="titleMedium">પરિણામ ની તારીજ</Text>
                                </View>}
                                {value.stream.map(s => {
                                    return <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center", fontWeight: "bold" }} variant="titleMedium">{Global.STREAM[s.stream]}</Text>
                                    </View>
                                })}
                            </View>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>

                                    <Text style={{}} variant="titleMedium">વિદ્યાર્થીઓની કુલ સંખ્યા</Text>
                                </View>
                                {value.stream.map(s =>
                                    <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{(s.total)}</Text>
                                    </View>
                                )}
                            </View>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>

                                    <Text style={{}} variant="titleMedium">પરીક્ષામાં બેઠેલા વિદ્યાર્થીઓની સંખ્યા </Text>
                                </View>
                                {value.stream.map(s =>
                                    <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{(s.appeared.length - s.absent)}</Text>
                                    </View>
                                )}
                            </View>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{}} variant="titleMedium">ઉત્તીર્ણ થનાર વિદ્યાર્થીઓની સંખ્યા </Text>
                                </View>
                                {value.stream.map(s => {
                                    return <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{s.passed.length}</Text>
                                    </View>
                                })}
                            </View>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>
                                    <Text style={{}} variant="titleMedium">ઉપલા વર્ગમાં ચઢાવેલ વિદ્યાર્થીઓની સંખ્યા </Text>
                                </View>
                                {value.stream.map(s => {
                                    return <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{s.promoted.length}</Text>
                                    </View>
                                })}
                            </View>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>

                                    <Text style={{}} variant="titleMedium">નાપાસ થનાર વિદ્યાર્થીઓની સંખ્યા </Text>
                                </View>
                                {value.stream.map(s => {
                                    return <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{s.fail.length}</Text>
                                    </View>
                                })}
                            </View>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>

                                    <Text style={{}} variant="titleMedium">વિદ્યાર્થીઓની કુલ સંખ્યા પર પરિણામના ટકા </Text>
                                </View>
                                {value.stream.map(s => {
                                    return <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{s.pc}</Text>
                                    </View>
                                })}
                            </View>

                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "row", borderWidth: 1, borderStyle: "solid" }}>
                                <View style={{ flex: 4, alignSelf: "center", borderRightWidth: 1, borderStyle: "solid" }}>

                                    <Text style={{}} variant="titleMedium">પરીક્ષામાં બેઠેલ વિદ્યાર્થીઓની સંખ્યા પર પરિણામના ટકા</Text>
                                </View>
                                {value.stream.map(s => {
                                    return <View style={{ flex: 1, borderRightWidth: 1, borderStyle: "solid", alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
                                        <Text style={{ flex: 1, textAlign: "center" }} variant="titleLarge">{s.pcApp}</Text>
                                    </View>
                                })}
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 30, flex: 1, alignSelf: "stretch" }}>
                            <View style={{ alignItems: "center", justifyContent: "center", flex: 1, alignSelf: "stretch" }}>
                                <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid", backgroundColor: t.dark ? "transparent" : "#DCDCDC" }}>
                                    <Text style={{ flex: 150, flexShrink: 0, textAlign: "center", borderRightWidth: 1 }}>વિષય </Text>
                                    <Text style={{ flex: 150, textAlign: "center", borderRightWidth: 1 }}>વિષયશિક્ષક</Text>
                                    <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>પરીક્ષામાં બેઠેલ સંખ્યા</Text>
                                    <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>ઉતીર્ણ સંખ્યા</Text>
                                    <Text style={{ flex: 50, textAlign: "center" }}>ટકાવારી</Text>
                                </View>
                                <View style={{ alignSelf: "stretch", flex: 1 }}>
                                    {
                                        value.subjects.map(s => {
                                            return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderStyle: "solid" }}>
                                                <Text style={{ flexShrink: 0, flex: 150, borderRightWidth: 1 }}>{s.name}</Text>
                                                <Text style={{ flex: 150, borderRightWidth: 1 }}>{s.teacherName}</Text>
                                                <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>{s.appeared}</Text>
                                                <Text style={{ flex: 100, textAlign: "center", borderRightWidth: 1 }}>{s.pass}</Text>
                                                <Text style={{ flex: 50, textAlign: "center" }}>{Math.round((s.pass * 100) / s.appeared)} %</Text>
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                        </View>
                        <View style={{ alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                            {/* <View style={{ flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid", backgroundColor: "#DCDCDC" }}>
                                <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>ક્રમાંક નં</Text>
                                <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>રોલ નં</Text>
                                <Text style={{ flex: 300, textAlign: "center", borderRightWidth: 1 }}>વિદ્યાર્થીનું નામ</Text>
                                <Text style={{ flex: 80, textAlign: "center", borderRightWidth: 1 }}>મેળવેલ ગુણ</Text>
                                <Text style={{ flex: 80, textAlign: "center" }}>ટકાવારી (%)</Text>
                            </View> */}
                            {/* <View style={{ alignSelf: "stretch", flex: 1 }}>
                                {
                                    value.ranklist.map((s) => {
                                        return <View style={{ borderWidth: 1, flexDirection: "row", alignSelf: "stretch", borderWidth: 1, borderStyle: "solid" }}>
                                            <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>{s.rank}</Text>
                                            <Text style={{ flex: 50, textAlign: "center", borderRightWidth: 1 }}>{s.roll}</Text>
                                            <Text style={{ flex: 300, borderRightWidth: 1 }}>{s.name}</Text>
                                            <Text style={{ flex: 80, textAlign: "center", borderRightWidth: 1 }}>{s.totalGained}</Text>
                                            <Text style={{ flex: 80, textAlign: "center" }}>{Math.round((s.totalGained * 100) / s.grandTotal)} %</Text>
                                        </View>
                                    })
                                }
                            </View> */}
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 70 }}>
                            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                <Text variant="titleLarge" style={{ textAlign: "center" }}>  વર્ગ શિક્ષક  </Text>
                            </View>
                            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                <Text variant="titleLarge" style={{ textAlign: "center" }}>  તારીખ  </Text>
                            </View>
                            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                <Text variant="titleLarge" style={{ textAlign: "center" }}>  આચાર્ય  </Text>
                            </View>
                        </View>
                    </div>
                    <div className="pagebreak" />
                </div>

            </div>

            {/* <Text style={{ flex: 1 }}  >Generated on {dayjs().format("DD/MM/YY HH:mm")}</Text> */}
        </ScrollView>
    </ScrollView >


}