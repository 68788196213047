import React from "react"
import { View } from "./Themed"
import { Text, Button, TextInput, Divider, Badge, useTheme } from "react-native-paper"
import { ScrollView } from "react-native-gesture-handler"
import { debounce, filter } from "lodash"
import { type } from "../constants/Preferances"
import Global from "../constants/global"

const OnlyStudentEntry = (op) => {
    const [values, setValues] = React.useState(undefined)
    // const [data, setData] = React.useState({ internal: 0, theory: 0, practical: 0 })

    // const [working, setWorking] = React.useState(false)
    const theme = useTheme()

    React.useEffect(() => {
        setValues(op.item)
        // const t = {}
        // if (op.item?.theory !== data?.theory) {
        //     if (typeof op.item?.theory == "string" || typeof op.item?.theory == "number") {
        //         setData(p => ({ ...p, theory: op.item?.theory == "Absent" ? 0 : op.item?.theory }))
        //     }
        // }
        // if (op.item?.practical !== data?.practical) {
        //     t.practical = values?.practical
        //     setData(p => ({ ...p, practical: op.item?.practical }))
        // }
        // if (op.item?.internal !== data?.internal) {
        //     t.internal = values?.internal
        //     setData(p => ({ ...p, internal: op.item?.internal }))
        // }
    }, [op])
    // React.useEffect(() => {
    //     const t = {}
    //     console.log("csas", values?.theory, data?.theory)
    //     if (values?.theory !== data?.theory) {
    //         console.log("csas", values?.theory)
    //         t.theory = values?.theory == "Absent" ? 0 : values?.theory
    //     }
    //     if (values?.practical !== data?.practical) {
    //         t.practical = values?.practical
    //     }
    //     if (values?.internal !== data?.internal) {
    //         t.internal = values?.internal
    //     }
    //     setData(p => ({ ...p, ...t }))
    // }, [values])

    const debounceLoadData = React.useCallback(debounce((v) => {
        // console.log("zero bounc", v)
        // console.log("zero bounc", op.updateMarks)
        op.updateMarks(v.o1, v.o2, v.o3, v.change)
    }, 500), []);

    function getColor() {
        if (values?.theory == "Absent") {
            return "gray"
        }
        return values?.status == "Fail" ? theme.dark ? "#962424" : "#ffe6e6" : values?.status == "Pass" ? theme.dark ? "#2d6e43" : "#ccffdd" : "transparent"
    }

    function getValue(marks, id, sid, type) {
        try {
            const t1 = marks[op.year][id][sid].theory
            const i1 = marks[op.year][id]?.[sid]?.internal ? marks[op.year][id]?.[sid]?.internal : 0
            const p1 = marks[op.year][id]?.[sid]?.practical ? marks[op.year][id]?.[sid]?.practical : 0
            const t = Global.getInt(t1)
            const i = Global.getInt(i1)
            const p = Global.getInt(p1)

            const tt = t == "Absent" ? 0 : (t + i + p)
            const tm = marks[op.year][id][sid].totalMarks
            //console.log(marks[op.year][id][sid], t, "-", i, "-", p)
            return { theory: tt, status: t == "Absent" ? 0 : tt, pc: t == "Absent" ? 0 : (tt * 100) / tm }
        } catch (e) {
            return { theory: 0, status: 0, pc: 0 }
        }
    }

    let mar1 = getValue(values?.marks, op.allExams?.e1?.id, values?.subject_id, 2)
    const ab = values?.theory == "Absent"
    const thisExam = (ab ? 0 : values?.theory) + (values?.internal ? values?.internal : 0) + (values?.practical ? values?.practical : 0)
    const thisStatus = { theory: ab ? "Absent" : thisExam, status: thisExam, pc: ab ? 0 : (thisExam * 100) / values?.totalMarks }
    if (op.allExams?.e1?.id == op.thisExam.exam.id) {
        mar1 = thisStatus
    }
    let mar2 = getValue(values?.marks, op.allExams?.e2?.id, values?.subject_id, 2)
    if (op.allExams?.e2?.id == op.thisExam.exam.id) {
        mar2 = thisStatus
    }
    let internal = getValue(values?.marks, op.allExams?.internal?.id, values?.subject_id, 2)
    if (op.allExams?.internal?.id == op.thisExam.exam.id) {
        internal = thisStatus
    }
    let ffinal = getValue(values?.marks, op.allExams?.e3?.id, values?.subject_id, 2)
    if (op.allExams?.e3?.id == op.thisExam.exam.id) {
        ffinal = thisStatus
    }

    const mar = mar1?.status + mar2?.status + internal?.status + ffinal?.status
    const cond = (mar1?.pc ? 1 : 0) + (mar2?.pc ? 1 : 0)
    const condM = (mar1?.pc ? mar1?.pc : 0) + (mar2?.pc ? mar2?.pc : 0)
    const alert = cond > 0 ? condM / cond : 0 // is avg pc
    const tm = values?.totalMarks
    const p1 = Math.floor((tm * ((alert * 0.9) / 100)))
    const p2 = Math.ceil((tm * ((alert * 1.1) / 100)))
    const warn = values?.theory > 0 && (values?.theory < p1 || values?.theory > p2)
    // if (alert > 0 && values?.theory !== undefined && values?.theory > 0) {
    //     if ((t1 < (alert * 0.9)) || (t1 > (alert * 1.1))) {
    //         high = alert
    //     } else {
    //         high = -2
    //     }
    // }
    // console.log(values)
    return <View style={{ backgroundColor: getColor(), margin: 5, borderRadius: 5, marginBottom: 20 }}>
        {values?.left == true ?
            <View style={{ flexDirection: "row" }}>
                <Text style={{ width: 50, textAlign: "center" }} variant="headlineSmall">{values?.roll + ""}</Text>
                <Text style={{ textDecorationLine: 'line-through' }}>LEFT {values?.student_name}</Text>
            </View>
            : <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 50, textAlign: "center" }} variant="headlineSmall">{values?.roll + ""}</Text>

                <View style={{ flex: 1, backgroundColor: "transparent" }}>
                    <View style={{ flexDirection: "row", alignItems: "center", flex: 1, backgroundColor: "transparent" }}>
                        {/* value={"" + (values?.theory ? values?.theory : values?.theory == 0 ? "0" : "")} */}

                        {values?.theoryMarks ?
                            <TextInput
                                disabled={values?.theory == "Absent"}
                                selectTextOnFocus
                                value={"" + (values?.theory !== undefined ? (values?.theory == 0 && values?.entry !== true) ? "" : values?.theory : "")}
                                label={values?.student_name} placeholder={"write marks here"} style={{ backgroundColor: "transparent", flex: 1, fontSize: 20 }}
                                onChangeText={(t) => {
                                    // op.updateMarks(0, t, values.student_id)
                                    //console.log("zero", "in", t)
                                    let pint = parseFloat(t)
                                    if (t == "0") {
                                        pint = 0
                                        //  console.log("zero", "pint", 0)
                                    }
                                    //                                    console.log("zero", pint)
                                    if (!Number.isNaN(pint) && pint <= values.theoryMarks) {
                                        const combo = Global.getCombo({ theory: pint, internal: values?.internal, practical: values?.practical })
                                        if (combo <= values.totalMarks) {
                                            //console.log("zero loading", pint)
                                            setValues(p => ({ ...p, theory: pint }))
                                            op.updateMarks(0, pint + "", values.student_id, "")

                                            // debounceLoadData({ o1: 0, o2: pint + "", o3: values.student_id, change: new Date().getTime() })
                                        }
                                    } else {
                                        //                                        console.log("zero", "isNan")
                                        setValues(p => ({ ...p, theory: 0 }))
                                        if (t == "") {
                                            debounceLoadData({ o1: 0, o2: "", o3: values.student_id, change: new Date().getTime() })
                                        } else {
                                            if (Number.isNaN(pint)) {
                                                debounceLoadData({ o1: 0, o2: 0, o3: values.student_id, change: new Date().getTime() })
                                            }
                                        }
                                    }

                                }}
                                dense keyboardType="decimal-pad" /> :
                            <Text>{values?.theory ? values?.theory : ""}</Text>}
                        {(values?.internalMarks > 0 || values?.practicalMarks > 0) && < View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
                            {values?.internalMarks > 0 && <TextInput disabled={values?.theory == "Absent"} selectTextOnFocus
                                value={"" + (values?.internal ? values?.internal : values?.internal == 0 ? "0" : "")} label={"Internal "} placeholder={"Marks"} style={{ flex: 1, backgroundColor: "transparent" }}
                                onChangeText={(t) => {
                                    // op.updateMarks(0, t, values.student_id)
                                    const pint = parseFloat(t)
                                    if (!Number.isNaN(pint) && pint <= values.internalMarks) {
                                        const combo = Global.getCombo({ theory: values?.theory, internal: pint, practical: values?.practical })
                                        if (combo <= values.totalMarks) {
                                            setValues(p => ({ ...p, internal: pint }))
                                            debounceLoadData({ o1: 1, o2: pint, o3: values.student_id })
                                        }
                                    } else {
                                        if (Number.isNaN(pint)) {
                                            setValues(p => ({ ...p, internal: 0 }))
                                            if (t = "") {
                                                debounceLoadData({ o1: 1, o2: "", o3: values.student_id })
                                            } else {
                                                debounceLoadData({ o1: 1, o2: 0, o3: values.student_id })
                                            }
                                        }
                                    }
                                }}

                                dense keyboardType="decimal-pad" />}
                            {values?.practicalMarks > 0 && <TextInput disabled={values?.theory == "Absent"}
                                selectTextOnFocus
                                value={"" + (values?.practical ? values?.practical : "")} label={"Practicle "} placeholder={"Marks"} style={{ flex: 1, backgroundColor: "transparent" }}
                                onChangeText={(t) => {
                                    // op.updateMarks(0, t, values.student_id)
                                    const pint = parseFloat(t)
                                    if (!Number.isNaN(pint) && pint <= values.practicalMarks) {
                                        const combo = Global.getCombo({ theory: values?.theory, internal: values?.internal, practical: pint })
                                        if (combo <= values.totalMarks) {
                                            setValues(p => ({ ...p, practical: pint }))
                                            debounceLoadData({ o1: 2, o2: pint, o3: values.student_id })
                                        }
                                    } else {
                                        if (Number.isNaN(pint)) {
                                            setValues(p => ({ ...p, practical: 0 }))
                                            debounceLoadData({ o1: 2, o2: 0, o3: values.student_id })
                                        }
                                    }

                                }}
                                dense keyboardType="decimal-pad" />}
                        </View>}
                        <Button focusable={false} onPress={() => {
                            op.updateMarks(0, values?.theory == "Absent" ? 0 : "Absent", values.student_id)
                        }}  >{values?.theory == "Absent" ? "Present" : "Absent"}</Button>
                    </View>
                    <View>
                        <ScrollView horizontal style={{}}>
                            <View style={{ width: 30, alignItems: "center" }}>
                                <Text>{mar1?.status >= 0 ? "+" + mar1?.status : ""}</Text>
                                <Text style={{ borderTopWidth: 1 }}>E1</Text>
                            </View>
                            <View style={{ width: 40, alignItems: "center" }}>
                                <Text>{mar2?.status >= 0 ? "+" + mar2?.status : " "}</Text>
                                <Text style={{ borderTopWidth: 1 }}>+ E2</Text>
                            </View>
                            <View style={{ width: 40, alignItems: "center" }}>
                                <Text>{internal?.status >= 0 ? "+" + internal?.status : " "}</Text>
                                <Text style={{ borderTopWidth: 1 }}>+ Int</Text>
                            </View>
                            <View style={{ width: 40, alignItems: "center" }}>
                                <Text>{ffinal?.status >= 0 ? "+" + ffinal?.status : " "}</Text>
                                <Text style={{ borderTopWidth: 1 }}>+ Fi</Text>
                            </View>
                            {op.thisExam.exam > 2 && <View style={{ width: 80, alignItems: "center" }}>
                                <Text>{mar ? "=" + (mar / 2).toFixed(2) : " "}</Text>
                                <Text style={{ borderTopWidth: 1 }}>Total %</Text>
                            </View>}
                            {op.thisExam.exam > 2 && <View>
                                <Text style={{ color: (mar / 2) < 33 ? "red" : "green" }}>{(mar / 2) < 33 ? "Fail" : "Pass"}</Text>
                                <Text style={{ color: (mar / 2) < 33 ? "red" : "green" }}>Annual Status</Text>
                            </View>}
                            <View>

                                {mar < 66 && <Badge size={36} style={{ backgroundColor: "red", color: "white" }}>{mar - 66}</Badge>}
                            </View>
                        </ScrollView>
                        <ScrollView horizontal contentContainerStyle={{ alignItems: "center" }}>
                            {/* <Text variant="titleLarge" style={{ color: "red" }}>{warn ? "Warning " : ""}</Text> */}
                            <Text style={{ color: "#FF7F50" }}>{warn && (p1 > 0 && p2 > 0) ? "(range: " + p1 + " - " + p2 + ")" : ""}</Text>

                        </ScrollView>
                    </View>
                    <Divider />
                </View>
            </View>
        }
    </View >
}
export default OnlyStudentEntry