import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";
import * as XLSX from "xlsx"
import Preferance from '../constants/Preferances';

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Alert } from 'react-native';
import { Text, IconButton, Button, FAB, shadow, TextInput, Chip } from 'react-native-paper';

import * as React from 'react';
import DYear from '../components/DYear';
import { useTheme } from 'react-native-paper';
import NotificationRow from '../components/NotificationRow';
import ReactNativeModal from 'react-native-modal';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import StudentPlainRow from '../components/StudentRowPlain';
import dayjs from 'dayjs';
import * as Share from 'expo-sharing';
import DImage from '../components/DImage';
import Global from '../constants/global';
import DIcon from '../components/DIcon';
import DModal from '../components/DModal';
const cy = Global.getYear()


let baselist: [] = []
export default function StudentList({ navigation, route }) {
    const bhudevList = useSelector(React.useMemo(Global.getUserList, []))
    const classes = useSelector((state) => state.list.Classes)
    const [alllist, setAllList] = React.useState({ baselist: [], class: {}, list: [], sortedClasses: [] })
    const [focus, setFocus] = React.useState(undefined)
    const [deletConf, setDeletConf] = React.useState(undefined)
    const [show, setShow] = React.useState(false)
    const [currentYear, setCY] = React.useState(cy)


    const pref = React.useContext(Preferance)


    React.useEffect(() => {
        const a = []
        baselist = bhudevList.map(e => {
            a.push({ ...e })
        })
        // const nnewP = a.sort((a, b) => a?.roll < b?.roll ? -1 : 0)
        setAllList(p => ({ ...p, baselist: a }))
        if (alllist.class?.id) {
            updateClassList(a)
        }
        // console.log("coming here", nnewP)
    }, [bhudevList]);

    React.useEffect(() => {
        if (classes) {
            const s: [] = []
            classes.map(p => {
                if (p.year == currentYear) {
                    s.push({ ...p })
                }
            })
            const nnewP = s.sort((a, b) => a?.seq < b?.seq ? -1 : 0)
            setAllList(p => ({ ...p, sortedClasses: nnewP }))
            //            console.log(nnewP)
        }
    }, [classes, currentYear])

    function updateClassList(blint) {
        const bslist = blint ? blint : alllist.baselist
        // console.log("bslist", bslist)
        const l = bslist.filter(st => st["class" + currentYear]?.classId == alllist.class.id)
        const l1 = l.sort((a, b) => a["class" + currentYear]?.roll < b["class" + currentYear]?.roll ? -1 : 0)
        // console.log("coming here l1", l1,alllist.class.id)
        setAllList(p => ({ ...p, list: l1 }))
    }

    React.useEffect(() => {
        updateClassList(undefined)
    }, [alllist.class])

    async function delStudent() {
        const db = getFirestore()
        const d = doc(db, "Users", deletConf.id)
        // console.log("its d", deletConf.id)
        await setDoc(d, { flag: -10 }, { merge: true })
        setDeletConf(undefined)
        Toast.show("Deleted")
    }
    async function left(student) {
        const db = getFirestore()
        const d = doc(db, "Users", student.id)
        const nst = student.left ? false : true
        // console.log("its nst" + nst, student.id)
        await setDoc(d, { left: nst }, { merge: true })
        setDeletConf(undefined)
        Toast.show("Done")
    }
    const StudentRow = React.memo((r) => <TouchableOpacity onPress={() => setFocus(r.item)}>
        <StudentPlainRow info={r.item} withGr={true} withStream={true} currentYear={currentYear} />
    </TouchableOpacity>)

    const { colors } = useTheme();
    return (
        <View style={{ backgroundColor: colors.background, flex: 1 }}>
            <View style={{ backgroundColor: "white", alignSelf: "stretch" }}>
                <DImage width={170} uri="https://i.pinimg.com/564x/d2/57/67/d257676b21bfdda5ca88d3b7e4b54d87.jpg" />
            </View>

            <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                <TouchableOpacity onPress={() => { setShow(true) }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text>{Global.getYearString(currentYear)}</Text>
                        <DIcon name="cog" color='black' />
                    </View>
                </TouchableOpacity>

                {
                    alllist.sortedClasses.map(e => {
                        return <Chip style={{ margin: 2 }} selected={e == alllist.class} onPress={() => setAllList(p => ({ ...p, class: e }))}>{e.name + (e.div ? " " + e.div : "")}</Chip>
                    })
                }
            </View>
            {/* <Button onPress={() => {
                alllist.baselist.map(f=>{
                    const l =bhudevList.filter(e=> e.name == f.name)
                    if (l.length > 1){
                        console.log(f.name,l.length)
                    }
                })
            }}>Chck</Button> */}
            <FlashList
                data={alllist.list}
                key="id"
                estimatedItemSize={50}
                contentContainerStyle={{ backgroundColor: "transparent" }}
                keyboardShouldPersistTaps={'handled'}
                renderItem={({ item, index }) =>
                    <StudentRow item={item} />
                } />
            {pref?.current.supervisor == true && <FAB
                icon="download"
                style={{
                    position: 'absolute',
                    margin: 16,
                    right: 0,
                    bottom: 60,
                }}
                onPress={() => {
                    const workbook = XLSX.utils.book_new();
                    const headers = ["Roll", "Left", "Name", "GR No", "UID", "Address", "Mobile", "Birth Day"]
                    alllist.sortedClasses.map(e => {
                        const bslist = alllist.baselist
                        //console.log("bslist", bslist)
                        const l = bslist.filter(st => st["class" + currentYear]?.classId == e.id)
                        const l1 = l.sort((a, b) => a.roll < b.roll ? -1 : 0)
                        const l2: [] = []
                        l1.map(st => {
                            let b = ""
                            if (st.bday) {
                                b = dayjs.unix(st.bday).format("DD/MM/YYYY")
                            }
                            const lf = st.left == true ? "Yes" : ""
                            l2.push({ roll: st.roll, left: lf, name: st.name, gr: st.gr, uid: st.uid, address: st.address, mobile: st.mobile, bday: b })
                        })
                        const worksheet = XLSX.utils.json_to_sheet(l2)
                        //                        console.log(l2)
                        XLSX.utils.book_append_sheet(workbook, worksheet, e.name + (e.div ? " " + e.div : ""));
                        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
                    })
                    XLSX.writeFile(workbook, "StudentRecord.xlsx");
                }
                }
            />}

            < FAB
                icon="plus"
                style={{
                    position: 'absolute',
                    margin: 16,
                    right: 0,
                    bottom: 0,
                }}
                onPress={() => {
                    if (alllist.class.id) {
                        navigation.navigate("NewStudent",
                            { data: { bday: dayjs().unix(), classId: alllist.class.id, class: alllist.class.name }, classId: alllist.class.id, class: alllist.class.name })
                    } else {
                        alert("Please select class first")
                    }
                }

                }
            />

            <DYear show={show} year={currentYear} close={() => {
                setShow(false)
            }} change={(yr) => {
                setCY(yr)
                setAllList(p => ({ ...p, list: [] }))
                setShow(false)
            }} />
            < ReactNativeModal
                isVisible={focus !== undefined}
                onBackdropPress={() => setFocus(undefined)}
                onDismiss={() => setFocus(undefined)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: colors.surface }}>
                    <View style={{ justifyContent: "center" }}>
                        <Text variant="titleLarge" style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{focus?.name}...</Text>
                        {focus?.mobile &&
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <TouchableOpacity style={{ flex: 1 }} onPress={() => { Linking.openURL('tel:' + focus?.mobile); setFocus(undefined) }} >
                                    <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
                                        <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="phone-outgoing" />
                                        <Text style={{ paddingLeft: 10, paddingRight: 10 }} >{"Mobile -"} ({focus?.mobile})</Text>
                                    </View>
                                </TouchableOpacity>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="whatsapp" onPress={() => { Share.shareAsync({ title: "નમસ્કાર", "message": "આપનો દિવસ શુભ રહે 🙂" }) }} />
                            </View>
                        }
                        <TouchableOpacity onPress={() => { setFocus(undefined); navigation.navigate("NewStudent", { data: focus }) }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="lead-pencil" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Edit</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            setFocus(undefined);
                            left(focus)
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="account-off" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Mark as {focus?.left == true ? "Not Left" : "Left"}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { setDeletConf(focus); setFocus(undefined) }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton style={{ marginLeft: 10, marginRight: 10 }} icon="delete" />
                                <Text style={{ paddingLeft: 10, paddingRight: 10 }} >Delete</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <IconButton icon={"window-close"} onPress={() => setFocus(undefined)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </ReactNativeModal>
            < ReactNativeModal
                isVisible={deletConf !== undefined}
                onBackdropPress={() => setDeletConf(undefined)}
                onDismiss={() => setDeletConf(undefined)}
                style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: colors.surface }}>
                    <View style={{ justifyContent: "center" }}>
                        <Text variant="titleLarge" style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{deletConf?.name}...</Text>
                        <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>Are you sure ? All Student's data including his result data will be removed </Text>
                        <View style={{ flexDirection: "row" }}>
                            <Button onPress={() => setDeletConf(undefined)} style={{ flex: 1 }}>Cancel</Button>
                            <Button onPress={() => { delStudent() }} mode="contained" style={{ flex: 1 }}>Delete</Button>
                        </View>

                    </View>
                    <IconButton icon={"window-close"} onPress={() => setDeletConf(undefined)} style={{ position: "absolute", top: 0, right: 0 }} />
                </View>
            </ReactNativeModal>

        </View >
    );


}

