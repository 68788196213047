import { FlashList } from "@shopify/flash-list";
import { SaveFormat } from 'expo-image-manipulator';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Share } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import { collection, doc, getFirestore, increment, setDoc, updateDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import Prferances from '../constants/Preferances';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Global from "../constants/global";
import { Image } from "expo-image";
import DIcon from "../components/DIcon";


let baselist: [] = []
export default function NewNotification({ navigation, route }) {
    const [focus, setFocus] = React.useState(undefined)
    const [working, setWorking] = React.useState({ working: false, stage: 0, views: "" })
    // const users = useSelector(React.useMemo(Global.getUserList, []))
    const { colors } = useTheme();
    const pref = React.useContext(Prferances)
    function addOn(i: number) {
        const c: [] = []
        if (!focus?.sendTo) {
            focus.sendTo = []
        }
        const fnd = focus?.sendTo?.findIndex(e => e == i) > -1
        if (fnd) {
            focus.sendTo.map(e => {
                if (e !== i) {
                    c.push(e)
                }
            })
            setFocus(p => ({ ...p, sendTo: c }))

        } else {
            focus.sendTo.push(i)
            setFocus(p => ({ ...p, sendTo: [...focus.sendTo] }))
        }
    }

    React.useEffect(() => {
        if (route?.params?.data) {
            setFocus(route?.params?.data)
        } else {
            setFocus({ title: "", desc: "", sendTo: [] })
        }
    }, [route.params])
    const pickImage = async () => {
        setWorking(p => ({ ...p, working: true }))
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
            allowsEditing: true,
            aspect: [4, 3]
        });

        if (!result.cancelled) {
            const towork = { ...focus }
            if (!focus?.dp) {
                towork.dp = ""
            }
            const red = await ImageManipulator.manipulateAsync(
                result.uri,
                [{ resize: { width: 500 } }], // resize to width of 300 and preserve aspect ratio 
                { compress: 1, format: SaveFormat.WEBP },
            )
            towork.local = red
            setFocus(towork)
        }
        setWorking(p => ({ ...p, working: false }))
    };
    const toc = focus?.dptxt ? focus?.dptxt : (focus?.local ? focus?.local?.uri : focus?.dp)
    const isData = toc?.substring(0, 10) == "data:image"
    const uri = toc?.includes("file:/") ? toc : toc?.includes("https://") ? toc : (isData ? toc : Global.getPicUrl(toc))
    console.log("uri", uri)
    console.log("toc", toc)
    console.log("focus?.dptxt", focus?.dptxt)
    console.log("focus?.local?.uri", focus?.local?.uri)
    return (
        <ScrollView style={{ flex: 1 }}>

            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                <IconButton size={60} icon="bell-ring" style={{ alignSelf: "center" }} />
                <TextInput label="Title" value={focus?.title ? focus?.title : ""} onChangeText={(t) => setFocus(p => ({ ...p, title: t }))} />
                <TextInput label="Description" value={focus?.desc ? focus?.desc : ""} onChangeText={(t) => setFocus(p => ({ ...p, desc: t }))} />
                <View style={{ alignSelf: "stretch", alignItems: "center" }}>
                    <TouchableOpacity onPress={pickImage} style={{ padding: 10 }}>
                        <Image
                            placeholder='|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj['
                            transition={500}
                            contentFit="cover" style={{ width: 200, height: 100 }} source={{ uri: uri }} />
                    </TouchableOpacity>
                    <Text>Image in 2:1 ration as shown above will be displayed in notificaiton</Text>
                </View>
                <TextInput label="(Optional) You may copy past internet image link here" placeholder="" value={focus?.dptxt ? focus?.dptxt : ""} onChangeText={(t) => setFocus(p => ({ ...p, dptxt: t }))} />

                <Text style={{ marginTop: 10 }}>Send Notification To :</Text>
                {/* <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                    <Chip selected={focus?.sendTo?.find(e => e == -1)} onPress={() => addOn(-1)} >{"All"}</Chip>
                    {Global.TYPES.map((t, i) => {
                        return <Chip style={{ margin: 3 }} selected={focus?.sendTo?.findIndex(e => e == i) == -1 ? false : true} onPress={() => addOn(i)} >{t}</Chip>
                    })}
                </View> */}
                <View style={{ flex: 1, alignItems: "center", flexDirection: "row", marginTop: 40 }}>
                    <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Cancel</Button>
                    <Button style={{ flex: 1 }} loading={working.working} mode="contained-tonal" onPress={async () => {
                        if (focus?.title && focus?.desc) {
                            setWorking(p => ({ ...p, working: true, stage: 1 }))
                            const db = getFirestore()
                            let ref
                            if (focus?.dptxt && !focus?.dptxt?.startsWith("data:image")) {
                                focus.dp = focus.dptxt
                            } else {
                                if (focus?.dptxt?.startsWith("data:image")) {
                                    focus.local = {}
                                    focus.local.uri = focus?.dptxt
                                }
                                if (focus.local) {
                                    let nm = Global.uploadName(focus.local, "").name
                                    if (focus?.local?.uri?.substring(0, 10) == "data:image") {
                                        nm = Global.getUniqueID() + ".jpg"
                                    } else {
                                        nm = Global.uploadName(focus.local, "").name
                                    }
                                    // console.log("got newname", nm)
                                    await Global.upload(focus.local, nm, () => { }, "")
                                    // console.log("upload result",nm,focus)
                                    focus.dp = nm
                                    delete focus.local
                                }
                            }
                            console.log("focus", focus)
                            setWorking(p => ({ ...p, stage: 2 }))

                            if (!focus.id) {
                                ref = doc(collection(db, "Notifications"))
                                focus.id = ref.id
                                focus.createdOn = dayjs().unix()
                                focus.createdBy = pref?.current?.id
                                focus.createdByName = pref?.current?.name
                                focus.createdByDp = pref?.current?.dp
                            } else {
                                ref = doc(db, "Notifications", focus.id)
                            }
                            focus.stamp = dayjs().unix()
                            // const isAll = focus?.sendTo?.find(e => e == -1)
                            // let l = []
                            // if (isAll) {
                            //     l = [...new Set(users.map(item => item.token))]
                            // } else {
                            //     users.map(e => {
                            //         const xxx = focus.sendTo.find(x => x == e.type)
                            //         if (xxx) {
                            //             l.push(e.token)
                            //         }
                            //     })
                            // }
                            // console.log("New DP", focus.dp)
                            const result = await Global.sendNotification(focus.title, focus.desc, focus.dp?.startsWith("https://") ? focus.dp?.startsWith("https://") : Global.getPicUrl(focus.dp))
                            setWorking(p => ({ ...p, stage: 3 }))
                            // console.log("gor result",result)

                            focus.responseId = result

                            // setTimeout(() => Toast.show("Notification send"), 500)
                            setWorking(p => ({ ...p, stage: 4 }))
                            setTimeout(async () => {
                                const res = await Global.getDetails(focus.responseId)
                                focus.views = res
                                setDoc(ref, focus, { merge: true }).then(e => {
                                    setWorking({ working: false, stage: 5, views: res })
                                })
                            }, 15000)

                        } else {
                            Toast.show("Error : Title અને Description  જરૂરી છે")
                        }

                    }}>Save</Button>
                </View>
                <View style={{ marginTop: 20 }}>
                    <Text variant="titleLarge" style={{ color: working.stage >= 1 ? "green" : "lightgray" }}> Process {working.stage >= 1 ? "Started" : "not started"} </Text>
                    <Text style={{ color: working.stage >= 1 ? "green" : "lightgray" }}>{working.stage >= 1 ? <DIcon name="check" color={"green"} /> : <View style={{ width: 24 }}></View>}1. Preparing Image</Text>
                    <Text style={{ color: working.stage >= 2 ? "green" : "lightgray" }}>{working.stage >= 2 ? <DIcon name="check" color={"green"} /> : <View style={{ width: 24 }}></View>}2. Sending Notification</Text>
                    <Text style={{ color: working.stage >= 3 ? "green" : "lightgray" }}>{working.stage >= 3 ? <DIcon name="check" color={"green"} /> : <View style={{ width: 24 }}></View>}3. Gathering Data</Text>
                    <Text style={{ color: working.stage >= 4 ? "green" : "lightgray" }}>{working.stage >= 4 ? <DIcon name="check" color={"green"} /> : <View style={{ width: 24 }}></View>}4. Saving Notification</Text>
                    <Text style={{ color: working.stage >= 5 ? "green" : "lightgray" }}>{working.stage >= 5 ? <DIcon name="check" color={"green"} /> : <View style={{ width: 24 }}></View>}5. Successfull. {focus?.views && "Sent Notification to " + focus?.views}</Text>
                    <Button disabled={working.stage <= 5} onPress={() => navigation.goBack()} >Go Back</Button>
                </View>
            </View>
        </ScrollView>
    );


}

