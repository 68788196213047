import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Network from 'expo-network';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import 'react-native-gesture-handler'
import { getAuth, ApplicationVerifier, signInAnonymously, signInWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier, createUserWithEmailAndPassword } from "firebase/auth";
import {
    MD3LightTheme,
    MD3DarkTheme,
} from 'react-native-paper';
import {
    DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import { adaptNavigationTheme } from 'react-native-paper';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { Platform } from "react-native"
// import 'expo-firestore-offline-persistence'
import { initializeApp } from "firebase/app";
import { initializeFirestore, CACHE_SIZE_UNLIMITED, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";
import { getMessaging } from "firebase/messaging/sw";
import { getToken, onMessage } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import * as firebase from 'firebase/app'
import 'firebase/firestore'

import store from './task/store';
import { Provider } from 'react-redux'
import { MD3LightTheme as DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import Global from './constants/global';
import runOneSignal from './runOneSignal';
import React, { useEffect } from 'react';
import { GoogleAuthProvider } from "firebase/auth";
import InstallPrompt from './components/InstallPrompt';



const firebaseConfig = {
    apiKey: "AIzaSyCl-05NYry5tismTFs9SZjCDcBmHEsExs0",
    authDomain: "stvmeducation.firebaseapp.com",
    projectId: "stvmeducation",
    storageBucket: "stvmeducation.appspot.com",
    messagingSenderId: "746774815756",
    appId: "1:746774815756:web:9bb4cfff9573638ce6ba29",
    measurementId: "G-LCWD7WPPZS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeFirestore(app,
    {
        localCache:
            persistentLocalCache(/*settings*/{ tabManager: persistentMultipleTabManager(), cacheSizeBytes: CACHE_SIZE_UNLIMITED })
    });

// You'll want to use the instance of Firestore you've already created, instead of firebase.firestore()

const messaging = getMessaging(app);
const storage = getStorage(app);
function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            getToken(messaging, { vapidKey: "BK7AQ0N4qecTx9SNUSWRSJgIjIWZJSq13XBZb07cAKn3ZNzAfkYsply3vI6zwhE-39uQGrEajbiuvFcJ6CqtC6A" }).then(e => {
                Global.TMP_TOCKEN = e
                console.log("tockent error", e)
            }).catch(e => {
                console.log("tockent error", e)
            })
        }
    })
}

async function ip() {
    // runOneSignal()
    Global.IP = await Network.getIpAddressAsync()

    requestPermission()
}
const unsubscribe = onMessage(messaging, async remoteMessage => {
    console.log(JSON.stringify(remoteMessage))
});

export default function App() {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();
    //const auth = getAuth();
    // signInWithEmailAndPassword(auth, "hardik.kone@gmail.com", "Twara123#")
    //     .then(() => {
    //         // console.log(auth.config)
    //         // console.log(auth.currentUser)
    //         // Signed in..
    //     })
    //     .catch((error) => {
    //         const errorCode = error.code;
    //         const errorMessage = error.message;
    //         console.log("signerr", error)
    //         // ...
    //     });

    useEffect(() => {
        ip()
        // return () => { unsubscribe() }
    }, [])

    const { LightTheme, DarkTheme } = adaptNavigationTheme({
        reactNavigationLight: NavigationDefaultTheme,
        reactNavigationDark: NavigationDarkTheme,
    });
    //  console.log("root para",pref)
    const CombinedDefaultTheme = {
        ...Global.light,
        ...LightTheme,
        colors: {
            ...Global.light.colors,
            ...LightTheme.colors,
        },
    };
    const CombinedDarkTheme = {
        ...Global.dark,
        ...DarkTheme,
        colors: {
            ...Global.dark.colors,
            ...DarkTheme.colors,
        },
    };

    let param = ""

    if (Platform.OS == "web" || Platform.OS == "windows") {
        try {
            const sr = location.href.indexOf("/stat/")
            if (sr > -1) {
                param = location.href.substring(sr + 6)
            }
        } catch (e) { }
    }
    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <Provider store={store}>
                <PaperProvider theme={colorScheme == "dark" ? CombinedDarkTheme : CombinedDefaultTheme}>
                    <SafeAreaProvider>
                        <InstallPrompt />
                        <Navigation colorScheme={colorScheme == "dark" ? CombinedDarkTheme : CombinedDefaultTheme} />
                        <StatusBar />
                    </SafeAreaProvider>
                </PaperProvider>
            </Provider>
        );
    }
}

serviceWorkerRegistration.register();


//   enableIndexedDbPersistence(firestoreDb)
//     .catch((err) => {
//       if (err.code == 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//       } else if (err.code == 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//       }
//     });
