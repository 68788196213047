import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Colors from '../constants/Colors';

export const loginTasks = createSlice({
  name: 'login',
  initialState: {
    version: 0,
    current: {},
    THEME: Colors.sherine,
  },
  reducers: {
    rejectLogin(state, action) {
      state.current = {}
      AsyncStorage.setItem('savedLoginId', "")
    },
    allowLogin(state, action) {
      const log = action.payload.user
      if (log) {
        state.current = log;
      }
    },
  }
})


export default loginTasks.reducer