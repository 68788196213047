import { FlashList } from "@shopify/flash-list";

import { Alert, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Share } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import { collection, doc, getFirestore, increment, setDoc, updateDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import Prferances from '../constants/Preferances';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import ReactNativeModal from "react-native-modal";
import DImage from "../components/DImage";
import Global from "../constants/global";


let baselist: [] = []
export default function NewExam({ navigation, route }) {
    const [focus, setFocus] = React.useState({})
    const [working, setWorking] = React.useState(false)
    const [classList, setClassList] = React.useState([])
    const classes = useSelector((state) => state.list.Classes)
    const subjects = useSelector((state) => state.list.Subjects)
    const [subjectSheet, showSubjectsheet] = React.useState(undefined)
    const [amt, setAmt] = React.useState(100)

    React.useEffect(() => {
        if (classes) {
            const s: [] = []
            let data = {}
            if (route?.params?.data) {
                data = route?.params?.data
            } else {
                data.year = Global.getYear()
                data.forClass = []
            }
            classes.map(p => {
                if (p.year == data?.year) {
                    s.push({ ...p })
                }
            }
            )
            const nnewP = s.sort((a, b) => a?.seq < b?.seq ? -1 : 0)
            setClassList(nnewP)
            setFocus(data)
        }
    }, [classes, route?.params?.data])

    function getClassSubject(cl) {
        if (!cl?.subjects) {
            const slist = subjects.filter(s => s.classid == cl.id)
            cl.subjects = slist
        }
        return cl
    }

    function addOn(i: any) {
        const clList: [] = []
        let newCl: [] = []
        focus?.forClass?.map(c => clList.push({ ...c }))
        const fnd = clList.findIndex(e => e.id == i.id) > -1
        if (fnd) {
            clList.map(e => {
                if (e.id !== i.id) {
                    const conso = getClassSubject(e)
                    newCl.push(conso)
                }
            })

        } else {
            newCl = [...clList]
            const conso = getClassSubject(i)
            newCl.push(conso)
        }
        newCl.map(c => console.log(c.name + (c?.div ? c.div : "")))
        setFocus(p => ({ ...p, forClass: newCl }))
    }

    const { colors } = useTheme();
    const pref = React.useContext(Prferances)
    React.useEffect(() => {
        if (route?.params?.data) {
            setFocus(route?.params?.data)
        } else {

        }
    }, [route.params])

    function setValue(what, value, obj) {
        const sub: [] = []
        subjectSheet?.subjects?.map(e => {
            const s = { ...e }
            if (s.id == obj.id) {
                const v = parseInt(value)
                let val = 0
                if (v !== NaN) {
                    val = v
                }
                if (what == 0) {
                    s.theoryMarks = val
                } else if (what == 1) {
                    s.practicalMarks = val
                } else {
                    s.internalMarks = val
                }
                s.totalMarks = (s.internalMarks ? s.internalMarks : 0) + (s.practicalMarks ? s.practicalMarks : 0) + (s.theoryMarks ? s.theoryMarks : 0)
                // console.log("ssss", s)
            }
            sub.push(s)
        })
        showSubjectsheet(s => ({ ...s, subjects: sub }))
    }
    function AddOn(t) {
        addOn(t)
    }
    const rf = React.useRef()
    return (
        <ScrollView style={{ flex: 1 }}>

            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                <View style={{ backgroundColor: "white", alignSelf: "stretch" }}>
                    <DImage uri="https://img.freepik.com/premium-vector/list-concept-undone-checklist-deadline-poor-time-management-vector-illustration-time-management-concept-effective-business-planning_453397-50.jpg" />
                </View>
                <View style={{ padding: 10 }}>
                    <TextInput ref={rf} autoFocus label="Name" value={focus?.name ? focus?.name : ""} onChangeText={(t) => setFocus(p => ({ ...p, name: t }))} />
                    <Text style={{ marginTop: 20 }}>શું આ પરીક્ષા ના માર્કસ ફાઇનલ માર્કશીટ માં ગણાશે  ?</Text>
                    <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                        <Chip style={{ margin: 3 }} selected={focus?.isInMarksheet} onPress={() => setFocus(p => ({ ...p, isInMarksheet: true }))} >Yes</Chip>
                        <Chip style={{ margin: 3 }} selected={!focus?.isInMarksheet} onPress={() => setFocus(p => ({ ...p, isInMarksheet: false }))} >No</Chip>
                    </View>

                    <Text style={{ marginTop: 20 }}>શું આ ફાઇનલ પરીક્ષા છે  ?</Text>
                    <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                        <Chip style={{ margin: 3 }} selected={focus?.final} onPress={() => setFocus(p => ({ ...p, final: true }))} >Yes</Chip>
                        <Chip style={{ margin: 3 }} selected={!focus?.final} onPress={() => setFocus(p => ({ ...p, final: false }))} >No</Chip>
                    </View>
                    {(focus?.max_siddhi || focus?.max_krupa) && <Text style={{ marginTop: 20, fontWeight: "bold" }}>સિદ્ધિ ગુણ તથા કૃપા ગુણ ની વિગતો</Text>}
                    {focus?.max_siddhi && <Text>Max સિદ્ધિ ગુણ : {focus?.max_siddhi} above {focus?.siddhi_over} % for each 1 % </Text>}
                    {focus?.max_krupa && <Text>Max કૃપા ગુણ : {focus?.max_krupa}</Text>}
                    <View>
                        {(focus?.weitage?.length > 0) && <Text style={{ marginTop: 20, fontWeight: "bold" }}>પરીક્ષાવાર ગુણભાર ની વિગતો</Text>}
                        {focus?.weitage?.map(w => {
                            return <View>
                                {w.exam && <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{w.final ? "Final Exam" : "Exam " + w.exam}</Text>
                                    <Text>: weightage {w.weight} </Text>
                                </View>}
                                {w.internal && <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>Internal</Text>
                                    <Text>: weightage {w.weight} </Text></View>}
                            </View>
                        })}
                    </View>
                    {focus.weitageOutOf && <View style={{ flexDirection: "row" }}   >
                        <Text style={{ fontWeight: "bold", borderTopWidth: 1, width: 100 }}>Total weightage</Text>
                        <Text style={{ fontWeight: "bold", borderTopWidth: 1, width: 50 }}>: {focus.weitageOutOf} </Text>
                    </View>}

                    <Text style={{ marginTop: 20 }}>For class :</Text>
                    <View style={{}}>
                        {classList.map((t, i) => {
                            const myClass = focus?.forClass?.find(e => e.id == t.id && e.year == focus.year)
                            const hasClasss = myClass !== null && myClass !== undefined
                            let hasMarks = false
                            // console.log("myclass", myClass)
                            myClass?.subjects?.map(e => {
                                if (e.theoryMarks > 0 && !hasMarks) {
                                    hasMarks = true
                                }
                            })
                            return <View style={{ backgroundColor: hasMarks ? "#00cc44" : "transparent", flexDirection: "row" }}>
                                <TouchableOpacity onPress={() => {
                                    if (hasClasss) {
                                        Alert.alert('Remove', 'Are you sure to remove' + myClass?.name + " from examp. All data (i.e. subject wise total marks etc.) will be lost ", [
                                            { text: 'Canecl' },
                                            { text: 'No' },
                                            {
                                                text: 'Yes', onPress: () =>
                                                    AddOn(t)
                                            }])

                                    } else {
                                        addOn(t)
                                        showSubjectsheet(t)
                                    }

                                }} style={{ flexDirection: "row", alignItems: "center", margin: 3 }}>
                                    <IconButton icon={hasClasss ? "check" : undefined} size={28} />
                                    <Text style={{ width: 200 }}>{t.name + (t?.div ? " " + t.div : "")}</Text>
                                </TouchableOpacity>
                                <IconButton onPress={() => {
                                    const workinClass = myClass ? myClass : t
                                    const slist = subjects.filter(s => s.classid == workinClass?.id)
                                    const allIn: [] = []
                                    slist.map(e => {
                                        const got = workinClass?.subjects?.find(ss => ss.id == e.id)
                                        if (!got) {
                                            allIn.push(e)
                                        } else {
                                            allIn.push(got)
                                        }
                                    })
                                    const tt = { ...workinClass }
                                    tt.subjects = allIn
                                    showSubjectsheet(tt)
                                }} icon={"cog-outline"} size={28} />
                            </View>
                        })}
                    </View>

                    <View style={{ flex: 1, alignItems: "center", flexDirection: "row", marginTop: 40, marginBottom: 60 }}>
                        <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Back</Button>
                        <Button style={{ flex: 1 }} loading={working} mode="contained" onPress={async () => {
                            setWorking(true)
                            if (focus?.name && focus?.forClass?.length > 0) {
                                const db = getFirestore()
                                let ref
                                if (focus?.id) {
                                    ref = doc(db, "Exams", focus.id)
                                } else {
                                    ref = doc(collection(db, "Exams"))
                                    focus.id = ref.id
                                    focus.createdOn = dayjs().unix()
                                    focus.createdBy = pref?.current?.id
                                    focus.createdByName = pref?.current?.name
                                }
                                focus.stamp = dayjs().unix()
                                // console.log(focus)
                                await setDoc(ref, focus)
                                Toast.show("Saved ")
                                navigation.goBack()
                            } else {
                                Toast.show("Error : Name and class selection required")
                            }
                            setWorking(false)
                        }}>Save</Button>
                    </View>
                </View>
            </View >
            <ReactNativeModal
                isVisible={subjectSheet}
                onBackdropPress={() => showSubjectsheet(undefined)}
                onDismiss={() => showSubjectsheet(undefined)}
                style={{ maxHeight: Dimensions.get("window").height - 250, margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="slideInUp" >

                <View style={{ backgroundColor: colors.surface, padding: 5 }}>
                    <ScrollView style={{ maxHeight: Dimensions.get("window").height - 250 }}>
                        <View style={{ justifyContent: "center" }}>
                            <Text style={{ padding: 13, fontWeight: "bold" }}>{subjectSheet?.name + (subjectSheet?.div ? subjectSheet?.div : "")}...</Text>
                            <Text style={{ marginTop: 5 }}>શું પરીક્ષામાં Internal marks પણ છે ?</Text>
                            <View style={{ flex: 1, flexDirection: "row", alignItems: "baseline" }}>
                                <Chip style={{ margin: 3 }} selected={subjectSheet?.isInternal} onPress={() => showSubjectsheet(p => ({ ...p, isInternal: true }))} >Yes</Chip>
                                <Chip style={{ margin: 3 }} selected={!subjectSheet?.isInternal} onPress={() => showSubjectsheet(p => ({ ...p, isInternal: false }))} >No</Chip>

                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 10 }}>
                                <TextInput value={amt + ""} onChangeText={(t) => {
                                    if (t) {
                                        setAmt(parseInt(t))
                                    } else {
                                        setAmt(0)
                                    }
                                }
                                } style={{ width: 200 }} dense label={"Total Marks"} keyboardType="decimal-pad" />
                                <Button onPress={() => {
                                    const revClass = { ...subjectSheet }
                                    const revSubj: [] = []
                                    revClass?.subjects?.map(s => {
                                        const newS = { ...s }
                                        let remMarks = amt
                                        if (subjectSheet?.isInternal) {
                                            newS.internalMarks = 20
                                            remMarks = amt - 20
                                        }
                                        if (s?.practical) {
                                            newS.theoryMarks = remMarks / 2
                                            newS.practicalMarks = remMarks / 2
                                        } else {
                                            newS.theoryMarks = remMarks
                                        }
                                        newS.totalMarks = amt
                                        revSubj.push(newS)
                                    })
                                    showSubjectsheet(p => ({ ...p, subjects: revSubj }))
                                }} style={{ marginLeft: 10 }} mode="contained">Copy to all</Button>
                            </View>
                            {
                                subjectSheet?.subjects?.map(s => {
                                    return <View style={{ flexDirection: "row" }}>
                                        <TextInput dense onChangeText={(t) => { setValue(0, t, s) }} value={s.theoryMarks ? (s.theoryMarks + "") : "0"} style={{ margin: 0, padding: 0, flex: 1 }} keyboardType="decimal-pad" label={s.name} />
                                        {s?.practical &&
                                            <TextInput onChangeText={(t) => setValue(1, t, s)} value={s.practicalMarks ? (s.practicalMarks + "") : "0"} dense style={{ flex: 1, marginLeft: 2 }} keyboardType="decimal-pad" label={"Practical"} />
                                        }
                                        {subjectSheet?.isInternal &&
                                            <TextInput onChangeText={(t) => setValue(2, t, s)} value={s.internalMarks ? (s.internalMarks + "") : "0"} dense style={{ flex: 1, marginLeft: 2 }} keyboardType="decimal-pad" label={"Internal"} />
                                        }
                                    </View>
                                })
                            }
                            <View style={{ flexDirection: "row" }}>
                                <Button style={{ flex: 1 }} onPress={() => showSubjectsheet(undefined)}>Close</Button>
                                <Button style={{ flex: 1 }} mode="contained" onPress={() => {
                                    const newCl: [] = []
                                    let updated = false
                                    focus?.forClass.findIndex(c => {
                                        if (c.id == subjectSheet.id) {
                                            delete subjectSheet.days
                                            newCl.push(subjectSheet)
                                            updated = true
                                        } else {
                                            newCl.push(c)
                                        }
                                    })
                                    if (!updated) {
                                        newCl.push(subjectSheet)
                                    }
                                    setFocus(p => ({ ...p, forClass: newCl }))
                                    showSubjectsheet(undefined)
                                }}>Save</Button>
                            </View>
                        </View>
                        <IconButton icon={"window-close"} onPress={() => showSubjectsheet(undefined)} style={{ position: "absolute", top: 0, right: 0 }} />
                    </ScrollView>
                </View >
            </ReactNativeModal >

        </ScrollView >
    );


}

