import Global from '../constants/global';

import { where, collection, getFirestore, onSnapshot, doc, updateDoc, increment, getDoc, query, setDoc, orderBy, limit, getDocs, writeBatch } from "firebase/firestore";
import { useDispatch, useSelector } from 'react-redux';
import { listsTask } from '../task/listTasks';
import { useContext, useEffect, useState } from 'react';
import { View } from './Themed';
import Prferances from '../constants/Preferances';
import { Button } from 'react-native-paper';
import * as Device from 'expo-device';
import { NetworkStateType } from 'expo-network';
export default function Loader() {
    const pref = useContext(Prferances)
    // const baselist = useSelector(React.useMemo(Global.getUserList, []))

    useEffect(() => {
        const a1 = register(Global.POSTS, false)
        const a2 = register(Global.STVM, false)


        let a3
        let a5

        let a4
        let a6
        let a7
        let a8
        let a10

        const isWorkingInSchool = (!pref?.current?.retired)
            && (pref?.current?.type == Global.PRINCIPAL || pref?.current?.type == Global.TEACHER || pref?.current?.type == Global.ADMIN || pref?.current?.type == Global.STAFF || pref?.current?.type == Global.VISITING_TEACHER)
        if (isWorkingInSchool) {
            a3 = register(Global.USERS, false)
            a5 = register(Global.DONATIONS, false)

            a4 = register(Global.LEAVES, false)
            a6 = register(Global.NUMBERING, false)
            a7 = register(Global.CLASSES, false)
            a8 = register(Global.SUBJECTS, false)
            a10 = register(Global.NOTIFICATIONS, false)
        }
        // const a11 = register(Global.MARKS, false)

        const a9 = register(Global.EXAMS, false)

        //        const a12 = register(Global.EXAM_DUMP, false)
        // }
        // register(Global.CHAPTERS, false)
        // register(Global.TOPICS, false)
        // register(Global.MATERIAL, false)
        // register(Global.Entry, false)   // it's display content
        // register(Global.NOTES, false)   // it's all notes
        if (pref?.current?.id && Global.TMP_TOCKEN) {
            if (Global.TMP_TOCKEN !== pref?.current?.token) {
                const db = getFirestore()
                const ref = doc(db, "Users", pref?.current?.id)
                setDoc(ref, { token: Global.TMP_TOCKEN }, { merge: true })
            }
        }
        return () => {
            a1()
            a2()
            if (a3) { a3() }
            if (a4) { a4() }
            if (a5) { a5() }
            if (a6) { a6() }
            if (a7) { a7() }
            if (a8) { a8() }
            if (a10) { a10() }
            a9()

        }
    }, [pref])

    const dispatch = useDispatch()

    function register(type: string, onlyMe: boolean) {
        const s = onSnapshot(
            collection(getFirestore(), type), { includeMetadataChanges: true },
            (docc) => {
                let tklist: [] = []
                let del = []
                docc.docChanges().forEach((change) => {
                    if (change.type === "removed") {
                        dispatch(listsTask.actions.remove({ type: type, data: change.doc.data() }))
                    } else {
                        const d = change.doc.data()
                        tklist.push({ data: d })
                    }
                });

                // console.log("Fetch " + type, tklist)

                dispatch(listsTask.actions.updateActivity({ type: type, data: tklist, isArray: true }))

            });
        return s
    }
    // async function loadDataAsync() {
    //     console.log("loading loader")
    //     load()
    // }

    // const users = useSelector(s => s.list.Users)
    // function internal() {
    //     const inter = "I81CCYDlvzUiNuyPvhl6"
    //     const c11 = "lZsTzyzRFUfqMdEN9yy6"
    //     const yr = "2022"
    //     users.map(m => {
    //         if (m?.classId == c11) {
    //             let o
    //             try {
    //                 o = m["marks"]["2022"]
    //             } catch (e) { console.log("Not s", m.name) }
    //             if (o) {

    //             }
    //         }
    //     }, [users])
    //     // // const exams = useSelector(s => s.list.Exams)
    //     // async function upd(ref, yr, s1, key, tm) {
    //     //     await updateDoc(ref, {
    //     //         [`marks.${yr}.${s1}.${key}.theoryMarks`]: tm
    //     //     }, { merge: true }).then(() => {
    //     //         console.log("DONE")
    //     //     })

    //     // }
    
    //     useEffect(() => {
    // let patch = [] 
    //         list.Users.map(f => {
    //             const cn = f?.marks["2023"]?.["mHGsJ3FtB3ECd4qcwMke"].className?.trim()
    //             try {
    //                 if (cn== "ધોરણ 11" || cn== "ધોરણ 12"){
    //     patch.push(f)                     
    //                 }
    //             }catch(e){}
    //             }
    //         )
    //         console.log("filtered",patch)
    //     }, [])
    //     // }

    return <View>
        {/* <Button onPress={() => {

            //          yZUMbExod1b7RkSOwnyS
            //          IsWmNo9NP5onrhfS1qOs
            const db = getFirestore()
            let ddd = 0
            const c11 = "lZsTzyzRFUfqMdEN9yy6"
            const c9a = "0oMovCzIqo2qrd9UrCRK"
            const c9b = "jjm6AyLHGfjzCTjGayeO"
            const s1 = "yZUMbExod1b7RkSOwnyS"
            const s2 = "IsWmNo9NP5onrhfS1qOs"
            let yr = 2022
            let cnt = 0
            users.map(m => {
                if (m?.classId == c11 || m?.classId == c9a || m?.classId == c9b) {
                    let o
                    try {
                        o = m["marks"]["2022"]
                    } catch (e) { console.log("Not s", m.name) }

                    if (o) {
                        const ref = doc(db, "Users", m.id)

                        // const e1 = exams.find(e => e.id == s1)
                        // const e2 = exams.find(e => e.id == s2)
                        // console.log(o[s1], o[s2])
                        Object.keys(o[s1]).forEach(function (key) {
                            if (Global.isSubject(o[s1], key)) {
                                const tm :number = o[s1][key]['totalMarks']
                                const thm = o[s1][key]['theoryMarks']
                                if (tm > 0 && tm !== thm && thm == undefined) {
                                    if (cnt == 0) {
                                        console.log(m.name, o[s1][key]["name"], ", tm=" + tm, ",the=" + thm)
                                        upd(ref, yr, s1, key, tm)
                                        cnt++
                                    }

                                }
                            }
                        });

                        try {
                            // updateDoc(ref, {
                            //     [`marks.${yr}.${ex}.${s1}`]: v
                            // }, { merge: true }).then(() => {
                            //     console.log("DONE")
                            // })
                        } catch (e) { console.log(e, v) }

                    }
                }

            })
            // const db = getFirestore()
            // const citiesRef = collection(db, "Users");
            // let q = query(citiesRef, where("id", "!=", ""));

            // q.get().then(function (querySnapshot) {
            //     querySnapshot.forEach(function (doc) {
            //     });
            // });
            // const db = getFirestore()
            // let i = 0
            // const todel = []
            // users.map(m => {
            //     const m1 = JSON.parse(JSON.stringify(m))
            //     delete m1.result
            //     let o
            //     try {
            //         o = m1["marks"]["2022"]
            //     } catch (e) { console.log("Not s", m.name) }
            //     if (o) {
            //         Object.keys(o).forEach(function (key) {
            //             if (o[key].classInfo.days
            //                 || o[key].classInfo.subjects
            //                 || o[key].classInfo.days
            //                 || o[key].student.class
            //                 || o[key].student.student
            //                 || o[key].student.exam
            //                 || o[key].student.subjects
            //                 || o[key].exam.forClass) {
            //                 todel.push(o)
            //             }
            //         });
            //         // console.log(m1["marks"])
            //         // updateDoc(ref, { result: "", marks: m1["marks"] }, { merge: true }).then(() => {
            //         //     console.log("Cleaned" + (i++))
            //         // })

            //     }

            // })
            // let once = 0
            // const db = getFirestore()
            // users.map(m => {
            //     const m1 = JSON.parse(JSON.stringify(m))
            //     delete m1.result
            //     let o
            //     try {
            //         o = m1["marks"]["2022"]
            //     } catch (e) { console.log("Not s", m.name) }
            //     if (o) {
            //         Object.keys(o).forEach(function (key) {
            //             if (!o[key].student) {
            //                 console.log("No Student red")
            //             } else {
            //                 if (!o[key]?.student?.name) {
            //                     console.log("No Student Name", o[key]?.exam?.name)
            //                     const yr = 2022
            //                     const ex = o[key]?.exam?.id
            //                     console.log("Doxx", m.id)
            //                     const ref = doc(db, "Users", m.id)
            //                     try {

            //                         updateDoc(ref, {
            //                             [`marks.${yr}.${ex}.student`]: { name: m.name, roll: m.roll, gr: m.gr },
            //                         }, { merge: true }).then(() => { console.log("Mone" + (once++)) })
            //                     } catch (e) { console.log("ett", e) }
            //                 }
            //                 // if (!o[key]?.student?.roll){
            //                 //     console.log("No Student roll")
            //                 // }
            //                 // if (!o[key]?.student?.gr){
            //                 //     console.log("No Student gr")
            //                 // }
            //             }
            //         });
            //         console.log(m1["marks"])
            //         updateDoc(ref, { result: "", marks: m1["marks"] }, { merge: true }).then(() => {
            //             console.log("Cleaned" + (i++))
            //         })

            //     }

            // })

        }}>Clean</Button> */}
        {/* <Button onPress={() => { internal() }}>Internal</Button> */}
    </View >
}
