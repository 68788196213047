import React, { memo, useContext, useEffect, useMemo, useState } from 'react';

export const type = {
    L: "e",
    current:undefined,
    env:undefined
}

export const Prferances = React.createContext(type);

export default Prferances


