import * as Calendar from 'expo-calendar';
import { DeviceType } from "expo-device";
import dayjs from "dayjs";
import { Dimensions, Platform } from "react-native";
import uuid from 'react-native-uuid';
import { createSelector } from '@reduxjs/toolkit';
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';


const list = createSelector(
  (state) => state.list.Users,
  (users) => users.filter((b) => b.flag !== -10)
)

export default class Global {
  static TMP_TOCKEN: any = undefined
  static light = {
    "colors": {
      "primary": "rgb(0, 103, 131)",
      "onPrimary": "rgb(255, 255, 255)",
      "primaryContainer": "rgb(188, 233, 255)",
      "onPrimaryContainer": "rgb(0, 31, 42)",
      "secondary": "rgb(150, 73, 0)",
      "onSecondary": "rgb(255, 255, 255)",
      "secondaryContainer": "rgb(255, 220, 198)",
      "onSecondaryContainer": "rgb(49, 19, 0)",
      "tertiary": "rgb(92, 91, 125)",
      "onTertiary": "rgb(255, 255, 255)",
      "tertiaryContainer": "rgb(226, 223, 255)",
      "onTertiaryContainer": "rgb(25, 24, 54)",
      "error": "rgb(186, 26, 26)",
      "onError": "rgb(255, 255, 255)",
      "errorContainer": "rgb(255, 218, 214)",
      "onErrorContainer": "rgb(65, 0, 2)",
      "background": "rgb(251, 252, 254)",
      "onBackground": "rgb(25, 28, 30)",
      "surface": "rgb(251, 252, 254)",
      "onSurface": "rgb(25, 28, 30)",
      "surfaceVariant": "rgb(220, 228, 233)",
      "onSurfaceVariant": "rgb(64, 72, 76)",
      "outline": "rgb(112, 120, 125)",
      "outlineVariant": "rgb(192, 200, 205)",
      "shadow": "rgb(0, 0, 0)",
      "scrim": "rgb(0, 0, 0)",
      "inverseSurface": "rgb(46, 49, 50)",
      "inverseOnSurface": "rgb(239, 241, 243)",
      "inversePrimary": "rgb(99, 211, 255)",
      "elevation": {
        "level0": "transparent",
        "level1": "rgb(238, 245, 248)",
        "level2": "rgb(231, 240, 244)",
        "level3": "rgb(223, 236, 241)",
        "level4": "rgb(221, 234, 239)",
        "level5": "rgb(216, 231, 237)"
      },
      "surfaceDisabled": "rgba(25, 28, 30, 0.12)",
      "onSurfaceDisabled": "rgba(25, 28, 30, 0.38)",
      "backdrop": "rgba(42, 50, 53, 0.4)"
    }
  }
  static dark = {
    "colors": {
      "primary": "rgb(99, 211, 255)",
      "onPrimary": "rgb(0, 53, 69)",
      "primaryContainer": "rgb(0, 77, 99)",
      "onPrimaryContainer": "rgb(188, 233, 255)",
      "secondary": "rgb(255, 183, 134)",
      "onSecondary": "rgb(80, 36, 0)",
      "secondaryContainer": "rgb(114, 54, 0)",
      "onSecondaryContainer": "rgb(255, 220, 198)",
      "tertiary": "rgb(197, 194, 234)",
      "onTertiary": "rgb(46, 45, 77)",
      "tertiaryContainer": "rgb(69, 67, 100)",
      "onTertiaryContainer": "rgb(226, 223, 255)",
      "error": "rgb(255, 180, 171)",
      "onError": "rgb(105, 0, 5)",
      "errorContainer": "rgb(147, 0, 10)",
      "onErrorContainer": "rgb(255, 180, 171)",
      "background": "rgb(25, 28, 30)",
      "onBackground": "rgb(225, 226, 228)",
      "surface": "rgb(25, 28, 30)",
      "onSurface": "rgb(225, 226, 228)",
      "surfaceVariant": "rgb(64, 72, 76)",
      "onSurfaceVariant": "rgb(192, 200, 205)",
      "outline": "rgb(138, 146, 151)",
      "outlineVariant": "rgb(64, 72, 76)",
      "shadow": "rgb(0, 0, 0)",
      "scrim": "rgb(0, 0, 0)",
      "inverseSurface": "rgb(225, 226, 228)",
      "inverseOnSurface": "rgb(46, 49, 50)",
      "inversePrimary": "rgb(0, 103, 131)",
      "elevation": {
        "level0": "transparent",
        "level1": "rgb(29, 37, 41)",
        "level2": "rgb(31, 43, 48)",
        "level3": "rgb(33, 48, 55)",
        "level4": "rgb(34, 50, 57)",
        "level5": "rgb(35, 54, 62)"
      },
      "surfaceDisabled": "rgba(225, 226, 228, 0.12)",
      "onSurfaceDisabled": "rgba(225, 226, 228, 0.38)",
      "backdrop": "rgba(42, 50, 53, 0.4)"
    }
  }

  static getInt(i) {
    if (typeof i == "number") {
      return i
    }
    if (typeof i == "string") {
      const v = parseInt(i)
      if (Number.isNaN(v)) {
        return 0
      } else {
        return v
      }
    }
  }
  static getUserList() {
    return list
  }
  static getValue(e, ab) {
    if (e?.theory == "Absent") {
      return ab
    }
    const eVal = (e?.theory ? e?.theory + (e?.practical ? Global.getInt(e?.practical) : 0) + (e?.internal ? Global.getInt(e?.internal) : 0) : 0)
//    console.log(e, eVal)
    return eVal
  }
  public static IP: string = ""
  public static VERSION: number = 9.253;
  public static POSTS: string = "Posts";
  public static USERS: string = "Users";
  public static STVM: string = "stvm";
  public static LEAVES: string = "Leaves";
  public static NUMBERING: string = "Numbering";
  public static CLASSES: string = "Classes";
  public static SUBJECTS: string = "Subjects";
  public static EXAMS: string = "Exams";
  public static DONATIONS: string = "Donations";
  public static NOTIFICATIONS: string = "Notifications";
  public static MARKS: string = "Marks";
  public static EXAM_DUMP: string = "ExamDump";


  public static ROW_DP: number = 0;
  public static FORM_DP: number = 1;
  public static FULL_DP: number = 2;
  public static SMALL_DP: number = 3;
  public static BIG_ROW_DP: number = 4;
  public static BOY: number = 1;
  public static GIRL: number = 0;
  public static MOBILE_WIDTH: number = 768;

  public static NOUSER_DP: string = "../assets/images/nouser.png";

  public static LOGIN_NOT_INITIATED: number = 0;
  public static LOGIN_PROCESSING: number = 0;
  public static LOGIN_DONE: number = 0;


  public static LINK_UPLOAD: string = "https://gtech.co.in/bhudevData/upload.php";
  public static SNAP_LOADED = false
  public static WHAT_NEW_TILL: number = 1.150;
  public static MASTER_URL = "https://i.pinimg.com/564x/ee/de/3a/eede3a1e17bca9bdc131e0e628069dd8.jpg"
  public static MASTER_MESSAGE = "Be so GOOD that they can't ignore you"
  public static MASTER_MESSAGE1 = "- Steve Martin"
  public static FURL = "https://firebasestorage.googleapis.com/v0/b/samaj-3a020.appspot.com/o/XXX?alt=media&token=a2291856-912f-46e3-ac89-47098354ae42"
  public static CY: number = 0;

  public static getUrl(dp: string) {
    return "https://gtech.co.in/stvm/" + dp
  }
  public static getPicUrl(dp: string) {
    return "https://gtech.co.in/stvm/pic/" + dp
  }
  public static getImageLink(dp: string) {
    return dp?.startsWith("https://") ? dp : "https://gtech.co.in/stvm/pic/" + dp
  }
  static getAllYear() {
    return [2022, 2023]
  }
  static getYearString(cy: number) {
    return cy + "-" + (cy - 1999)
  }
  static async getData(val: string) {
    const db = getFirestore()
    const citiesRef = collection(db, "Users");
    let q = query(citiesRef, where("mobile", "==", val), limit(1));
    let res = undefined
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      res = doc.data()
    });
    if (res) {
      return res
    } else {
      let q = query(citiesRef, where("loginid", "==", val), limit(1));
      let res = undefined
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        res = doc.data()
      });
      if (res) {
        return res
      } else {
        return false
      }
    }
  }

  static getYear() {
    if (this.CY == 0) {
      let y = new Date().getFullYear()
      const m = new Date().getMonth()
      if (m <= 6) {
        y = y - 1
      }
      this.CY = y
      return y

    } else {
      return this.CY
    }
  }
  public static DEVICE_TYPE: number = DeviceType.PHONE

  public static TYPES = ["પ્રિન્સિપાલ", "શિક્ષક", "પ્રવાસી શિક્ષક", "એડમીન", "સ્ટાફ", "ટ્રસ્ટી", "વિદ્યાર્થી", "વાલી", "યુઝર", "ગેસ્ટ"]
  public static LEAVE_TYPES = ["CL", "PL", "Medical", "Other"]
  public static VISIBILITY = ["All", "Management", "Core Team", "Teacher", "Class Teacher", "Student", "Guest"]
  public static SECTIONS = ["બાલમંદિર", "પ્રાયમરી", "અપર-પ્રાયમરી", "માધ્યમિક", "ઉચ્ચતર માધ્યમિક"]
  public static MEDIUM = ["ગુજરાતી", "અંગ્રેજી", "હિન્દી"]
  public static STREAM = ["Common", "વાણિજ્ય", "આર્ટસ", "વિજ્ઞાન"]

  public static DAYS = ["સોમ", "મંગળ", "બુધ", "ગુરુ", "શુક્ર", "શનિ"]
  public static CAST = ["હિન્દુ ચમાર", "હિન્દુ મેઘવાડ", "હિન્દુ દેવીપૂજક", "હિન્દુ વાલ્મીકિ"]
  public static SUCAST = ["SC", "ST", "BAKSHI", "General"]

  static getCombo(a) {
    let tmar = 0
    if (a?.theory) {
      tmar = a?.theory !== "Absent" ? (a?.theory == "" ? 0 : a?.theory) : 0
    }
    if (a?.internal) {
      tmar = tmar + a?.internal ? a?.internal : 0
    }
    if (a?.practical) {
      tmar = tmar + a?.practical ? a?.practical : 0
    }
    return tmar
  }
  static getPassFail(got: number, total: number) {
    if (total == 100) { return got >= 33 ? "Pass" : "Fail" }
    if (total == 50) { return got >= 17 ? "Pass" : "Fail" }
    if (total == 80) { return got >= 27 ? "Pass" : "Fail" }
    if (total == 0) {
      console.log("total is zero ", got)
    }

    const needed = Math.round(((total / 3) + Number.EPSILON) * 100) / 100
    return (got >= needed ? "Pass" : "Fail")
  }
  static YearString(y: number) {
    return y ? y + "-" + ((y - 2000) + 1) : ""
  }
  static getSubjectName(id: string, array: []) {
    if (array) {
      const rec = array?.find(e => e.id == id)
      return rec?.name ? rec.name : ""
    }
    return ""
  }
  static isSubject(o: any, key: string) {
    let isSub = false
    if (key !== "pass" && key !== "failed" && key !== "absent" && key !== "pending") {
      if (typeof o[key] == "object") {
        if ("theory" in o[key]) {
          isSub = true
        }
      }

    }
    // console.log("key=" + key, typeof o[key])
    // console.log("key=" + key, o[key], "theory" in o[key])

    return isSub
  }
  public static CAT_CORE_TEAM: number = 1

  public static ACTIVE: number = 1
  public static BLOCK: number = 0

  public static PRE_PRIMARY: number = 0
  public static PRIMARY: number = 1
  public static UPPER_PRIMARY: number = 2
  public static SECONDARY: number = 3
  public static HIGHER_PRIMARY: number = 4

  public static PRINCIPAL: number = 0
  public static TEACHER: number = 1
  public static VISITING_TEACHER: number = 2
  public static ADMIN: number = 3
  public static STAFF: number = 4
  public static TRUSTEE: number = 5
  public static STUDENT: number = 6
  public static PARENT: number = 7
  public static USER: number = 8
  public static GUEST: number = 9

  public static ROLE_SUPERVISOR: number = 1

  public static isEdit(type: number) {
    return type == this.TEACHER || type == this.VISITING_TEACHER || type == this.TRUSTEE || type == this.PRINCIPAL || type == this.STAFF || type == this.ADMIN
  }

  public static getDate(d: number) {
    return Global.getDateString(d, "DD-MM-YYYY")
  }

  public static getDateString(d: number, s: string) {
    try {
      const d1 = d ? ((d + "").length > 10 ? parseInt(((d + "").substring(0, 10))) : d) : 0
      if (d1) {
        return dayjs.unix(d1).format(s)
      }

    } catch (e) { }
    return ""
  }
  public static async getDefaultCalendarSource() {
    const defaultCalendar = await Calendar.getDefaultCalendarAsync();
    return defaultCalendar.source;
  }

  public static async createCalendar() {
    const defaultCalendarSource =
      Platform.OS === 'ios'
        ? await getDefaultCalendarSource()
        : { isLocalAccount: true, name: 'Expo Calendar' };

    const newCalendarID = await Calendar.createEventAsync("1", {
      title: 'Shripad',
      allDay: true,
      notes: "Hi there",
      startDate: new Date('2020-10-12 17:40'),
      endDate: new Date('2020-10-12 17:45'),
    });
    Calendar.openEventInCalendar(newCalendarID)// that will give the user the ability to access the event in phone calendar 
    //    setEventIdInCalendar(newCalendarID)

    console.log(`Your new calendar ID is: ${newCalendarID}`);
  }
  static async getDetails(id: string) {
    try {
      var data = new URLSearchParams();
      data.append("id", id)
      data.append("type", "STVM")
      var url = new URL("https://gtech.co.in/store/NotiResult.php")
      url.search = data.toString()
      console.log("url`", url)
      const response = await fetch(url, {
        method: "GET"
      })
      if (response.ok) {
        const json = await response.json()
        const rs = JSON.parse(json)
        return rs.successful
      }
    } catch (e) {

    }

  }

  static async sendNotification(title: string, body: string, pic: string) {
    var data = new URLSearchParams();
    data.append("type", "STVM")
    data.append("title", title)
    data.append("desc", body)
    data.append("ids", "all")
    data.append("name", "default")
    data.append("burl", pic)
    data.append("goto", location.origin)

    var url = new URL("https://gtech.co.in/store/sendNotification.php")
    url.search = data.toString()

    console.log(url)

    const response = await fetch(url, {
      method: "GET"
    })
    if (response.ok) {
      const j = await response.json()
      console.log("Recd", j)
      const json = JSON.parse(j)
      console.log(typeof json)
      if (json.hasOwnProperty("id")) {
        console.log("Recd.di", json["id"])
        return json["id"]
      }
    }
    return ""
  }

  public static sendNotificationExpo(title: string, body: string, data: any, users: []) {
    // const lst = [...new Set(users.map(item => item.token))]
    // const lst1 = lst.filter(r => (r))

    // const message = {
    //   to: users,
    //   sound: 'default',
    //   title: title,
    //   body: body,
    //   data: data,
    // };
    // console.log("MYFCM msf", message)
    // try {
    //   fetch('https://exp.host/--/api/v2/push/send', {
    //     method: 'POST',
    //     headers: {
    //       Accept: 'application/json',
    //       'Accept-encoding': 'gzip, deflate',
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(message),
    //   }).then(e => { }).catch(e => console.log("fcm err", e))
    // } catch (e) { console.log("fcm escape", e) }

  }
  public static uploadName(res, newName: string) {
    let ext = "png"
    let type = "image"

    try {

      //                    ext = res.uri.substring(res.uri.indexOf("/") + 1, res.uri.indexOf(";"))
      ext = res.uri.substring(res.uri.lastIndexOf(".") + 1)
      if (!ext) {
        ext = "jpeg"
      }
      ext = ext.toLowerCase().trim()
      if (ext == "jpg") {
        ext = "jpeg"
      }
      if (ext == "jpeg" || ext == "png" || ext == "webp" || ext == "jiff" || ext == "bmp") {
        type == "image"
      } else {
        if (ext == "mov" || ext == "mp4" || ext == "mpeg" || ext == "wmv" || ext == "avi" || ext == "mkv") {
          type = "video"
        } else {
          if (ext == "gif") {
            type = "image"
          } else {
            type = "doc"
          }
        }
      }

      let name = newName ? newName + (newName.indexOf(".") == -1 ? "." + ext : "") : this.getUniqueID() + "." + ext
      return { name: name, ext: ext }
    } catch (e) { }
    let name = this.getUniqueID() + "." + ext

    return { name: name, ext: ext }
  }
  public static getUniqueID() {
    const r = uuid.v4().toString()
    const reg = /-/g
    const stamp = dayjs().unix()
    const rr = r.replace(reg, "")
    return rr + stamp
  }
  public static async upload(res, newName: string, callback: (n: string, r: boolean) => void, oldFileToDelete: string) {
    const onUri = res.uri || res.localUri
    const Oname = newName
    //    const ext = res.uri.substring(res.uri.lastIndexOf("." + 1))
    const name = Oname //.includes(".") ? Oname : Oname + "." + ext

    let filename = onUri.split('/').pop();
    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;

    // Upload the image using the fetch and FormData APIs
    let formData = new FormData();
    // Assume "photo" is the name of the form field the server expects
    console.log("firn bane", name)
    console.log("firn type", type)
    console.log("firn onUri", onUri)
    const obj = { uri: onUri, name: name, type }
    formData.append('photo', obj);
    console.log("firn formData", formData)

    console.log("firn uri", Global.getPicUrl("upload.php"))
    const isData = onUri?.substring(0, 10) == "data:image"
    if (isData) {
      const obj1 = { uri: onUri, name: name }
      return await fetch(Global.getPicUrl("uploadBase.php"), {
        method: 'POST',
        body: JSON.stringify(obj1),
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

    } else {
      return await fetch(Global.getPicUrl("upload.php"), {
        method: 'POST',
        body: formData,
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

    }



    //         "https://gtech.co.in/bhudev/pic/upload.php",
    //         onUri,
    //         {
    //           headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'multipart/form-data',
    //           },
    //           uploadType: FileSystem.FileSystemUploadType.MULTIPART,
    //           fieldName: "name",
    //           parameters: { name: name, file: t1 }

    // try {
    //   const response = await FileSystem.uploadAsync(
    //     this.getPicUrl("upload.php"), onUri, {
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'multipart/form-data',
    //     },
    //     uploadType: FileSystem.FileSystemUploadType.MULTIPART,
    //     fieldName: "file",
    //     parameters: { name: name },
    //     httpMethod: "POST",
    //   });

    //   console.log(JSON.stringify(response, null, 4));
    // } catch (error) {
    //   console.log(error);
    // }
    // console.log(name)
    // try {
    //   var xhr = new XMLHttpRequest();
    //   xhr.onload = function () {
    //     var reader = new FileReader();
    //     reader.onloadend = function () {
    //       const trimmed = reader.result.trim()
    //       const t1 = trimmed.substring(trimmed.indexOf(",") + 1)
    //       console.log("Para heck" + Global.getPicUrl("upload.php") + " : " + name, onUri)
    //       FileSystem.uploadAsync(
    //         "https://gtech.co.in/bhudev/pic/upload.php",
    //         onUri,
    //         {
    //           headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'multipart/form-data',
    //           },
    //           uploadType: FileSystem.FileSystemUploadType.MULTIPART,
    //           fieldName: "name",
    //           parameters: { name: name, file: t1 }
    //         }).catch(e => console.log("upload err 11", e))
    //       if (callback) {
    //         callback(name, true)
    //       }
    //     }
    //     reader.readAsDataURL(xhr.response);
    //   };
    //   xhr.open('GET', onUri);
    //   xhr.responseType = 'blob';
    //   xhr.send();

    // console.log("uploaded",xhr.respon se)

    // } catch (e) { console.log("upload err", e) }


  }

  public static getColor(ix: number) {
    let i = ix % 10
    switch (i) {
      case 0: return "teal"
      case 1: return "#6495ED"
      case 2: return "#D2691E"
      case 3: return "#008B8B"
      case 4: return "#9932CC"
      case 5: return "#FF7F50"
      case 6: return "#2F4F4F"
      case 7: return "#228B22"
      case 8: return "#DAA520"
      case 9: return "#CD5C5C"
    }
  }
  public static ROW_COLOR = {
    c0: { color: "teal", fontWeight: "bold" },
    c1: { color: "#6495ED", fontWeight: "bold" },
    c2: { color: "#D2691E", fontWeight: "bold" },
    c3: { color: "#008B8B", fontWeight: "bold" },
    c4: { color: "#9932CC", fontWeight: "bold" },
    c5: { color: "#FF7F50", fontWeight: "bold" },
    c6: { color: "#2F4F4F", fontWeight: "bold" },
    c7: { color: "#228B22", fontWeight: "bold" },
    c8: { color: "#DAA520", fontWeight: "bold" },
    c9: { color: "#CD5C5C", fontWeight: "bold" }
  }
  public static RS = "₹"


  public static NOUSER_DP: string = "../assets/images/nouser.png";
  static noteError(err, values) {
    const db = getFirestore();
    addDoc(collection(db, "Errors"), {
      UserId: Global.USER.id,
      UserName: Global.USER.name,
      OrgId: Global.USER.org_id,
      on: new Date().toString(),
      error: err.toString(),
      data: JSON.stringify(values)
    });

  }
  static getWord(nu) {
    let num = Math.abs(nu)
    if (Number.isInteger(num)) {
      return Global.getSingleWord(num) + " only"
    } else {
      let deci = num - Math.floor(num)
      let base = Math.floor(num)
      let deci1s = (deci + "").replace(".", "")
      let deci1 = parseInt(deci1s)
      let sdeci = this.getSingleWord(deci1)
      let sbase = this.getSingleWord(base)
      return sbase + " rupees " + sdeci + " paise only"
    }
  }

  static getSingleWord(num) {
    var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
    var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str;
  }
  static isMobile() { return (window.innerWidth ? window.innerWidth : Dimensions.get("window").width) <= 768 }

  public static TAGS = ["Imporatant", "Exam Tip", "Explanation", "Example", "Refer Later", "To Do", "Asked in NEET", "Asked in School"]

  static sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }
  static  sortStudent(arr, year) {
    return arr.sort((a, b) => a?.["class" + year]?.roll - b?.["class" + year]?.roll)
  }
  static sortedUsers(arr) {
    try {
      let nnewP = arr.sort((a, b) => a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 0)
      return nnewP
    } catch (e) {
      ////console.log("ARRAY", e)
    }
    return arr
  }
  static getFirebase() {

  }
  static getCity() {
    const s = {
      id: 0,
      name: "",
      dp: "",
      tag: "કોઈ ને હરાવવું સરળ છે, પરંતુ જીતવું ઘણુજ અઘરું છે.",
      tagby: "Dr. APJ Kalam",
      samaj: -1,
    }
    return { ...s }
  }

  static async DistributionSMS(cust: []) {
    let templateid = "1307164509550611005"; ///
    let senderid = "HRKJSH";
    let api = "djYRF0eK4UOdIp8PLpyYMQ";
    cust.map(c => {
      let message = "Hi, Auto-distribution finished. click to view " + location.origin + "/InvoicePrint?id=" + c.id + "  From VTCC, (HRKJSH)"
      var data = new URLSearchParams();
      data.append("APIKey", api)
      data.append("senderid", senderid)
      data.append("channel", "2")
      data.append("DCS", "0")
      data.append("flashsms", "0")
      data.append("number", c.mobile)
      data.append("text", message)
      data.append("route", "30")
      data.append("EntityId", "1301163117511620871")
      data.append("dlttemplateid", templateid)
      var url = new URL('https://www.smsgatewayhub.com/api/mt/SendSMS')
      /*
                  url.search = data.toString()
                  ////console.log("smsurl", url.toString())
                  fetch(url.toString()).then(response => {
                      ////console.log(response)
                  }).catch(error => {
                      //console.error("smserror", error);
                  })
      */
    })


  }

}


