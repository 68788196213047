import { Portal, Snackbar } from "react-native-paper"
import { useDispatch, useSelector } from "react-redux"
import { listsTask } from '../task/listTasks';
import { memo } from "react"
const COLOR = ["black", "green", "red"]
const Snack = memo(() => {
    const mess = useSelector(s => s.list.Alert)
    const dispatch = useDispatch()

    return <Portal>

        <Snackbar
            style={{ backgroundColor: COLOR[mess.type ? mess.type : 0] }}
            visible={mess.message}
            onDismiss={() => {
                dispatch(listsTask.actions.Alert({ message: "" }));
            }}
            icon={"check"}
            duration={mess.duration ? mess.duration : 4000}
            action={{
                label: mess.button ? mess.button : "OK",
                onPress: () => {
                    console.log(mess.callback)
                    if (mess.callback) {
                        console.log("mess.callback")
                        mess.callback()
                    }
                },
            }}>
            {mess.message}
        </Snackbar></Portal>
})
export default Snack