// TODO =>
// CHECK FOR THEORY AND PRACTICAL OF GRAD SUBJECTS
// YD Rana
// KS Jayswani
// MM Dodiya bsmH7vDNBk8hUvzsHzGE
// ht pandya z2BWQlaq9Tw3CTUlc4JJ
// aasha zGY9fiEZFVpPUaZ54EYC
// arti 7NmYgLcALuapMr78PH3S
// pinky 8YGRBwB5ntF76ugrXRMX

import { ScrollView, View } from 'react-native';
import { Button, IconButton, Text, useTheme } from "react-native-paper";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import * as XLSX from "xlsx"
import Preferance from '../constants/Preferances';

import dayjs from 'dayjs';
import { MaterialIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import Global from '../constants/global';
import { transform } from '@babel/core';

const totalTag = "Total Students"
const resultTag = "Result (%)"
const pageStyle = `

  @media all {
    .pagebreak {
      display: none;
      page-break-after: always;
    }
  }

  @media print {
    .pagebreak {
      page-break-after: always;
    }
  }
`;
const bw = 0.1
const bs = "solid"
const bc = "darkgray"
function f(s) {
    if (typeof s == "string") {
        //        console.log("str", typeof s, s)
        if (s.trim() == "") {
            return 0
        } else {
            return parseInt(s)
        }
    }
    else { return s }
}
export default function FinalSummarySheet({ navigation, route }) {
    // const [value, setValue] = useState({ class: undefined, student: [], subjects: [], exam: undefined, tarij: 0, totalStudents: 0, attend: 0, pass: 0, fail: 0, ranklist: [], left: 0, year: "" })
    const [data, setData] = useState({ students: [], subjcts: [], filteredSubject: [] })
    // const originalSUbjects = useSelector(s => s.list.Subjects)
    const pref = useContext(Preferance)

    useEffect(() => {
        const d: [] = []
        route.params.student.map(u => {
            const display = u.display
            console.log(display)
            const yr = route.params.examyear
            const id = route.params.eid
            const pc = u.marks[yr][id].pc
            const rank = u.marks[yr][id].rank
            const status = u.marks[yr][id].status
            const count = u.marks[yr][id].subjectCount
            const selfMarks = u.marks[yr][id].selfMarks
            let e1 = 0
            let e2 = 0
            let internal = 0
            let ffinal = 0
            display.map(p => {
                if (p.grad !== true && p.name !== "Total") {
                    if (p?.e1) {
                        e1 = e1 + f(p.e1)
                    }
                    if (p?.e2) {
                        e2 = e2 + f(p.e2)
                    }
                    if (p?.internal) {
                        internal = internal + f(p.internal)
                    }
                    if (p?.final) {
                        ffinal = ffinal + f(p.final)
                    }
                }
            })
            const te = display.find(p => p.name == "Total")
            //console.log("tott", te)
            const t = {
                e1: e1,
                e2: e2,
                internal: internal,
                final: ffinal,
                total: e1 + e2 + internal + ffinal,
                sgiven: te.siddhi,
                kgiven: te.kripa,
                roll: u["class" + route.params.examyear].roll,
                uid: u["class" + route.params.examyear].uid,
                totD: u["class" + route.params.examyear].totalDays,
                preD: u["class" + route.params.examyear].present,
                gr: u["class" + route.params.examyear].gr,

                subjects: display, pc: pc, rank: rank, status: status,
                scount: count, selfMarks: selfMarks,
                obj: u.marks[yr][id]
            }
            d.push(t)
        })
        //console.log(route.params.subjects)
        const fs = route.params.subjects.filter(f => f.grade !== true)
        setData({ students: d, subjcts: route.params.subjects, filteredSubject: fs })
    }, [route.params])

    // const RollSheet = ({ till, above, subject }) => {
    //     const russia = value.student.filter(e => (e.roll <= till && e.roll >= above) && e.totalling !== true)
    //     return <View>
    //         {russia.length > 0 &&
    //             <View style={{ alignItems: "center", justifyContent: "center", marginRight: 2 }}>
    //                 <View style={{ flexDirection: "row", alignSelf: "stretch", borderStyle: bs, borderColor: bc,  borderWidth: bw, borderStyle: "dashed" }}>
    //                     <Text variant="titleMedium" style={{ backgroundColor: "#DCDCDC", width: 65, textAlign: "center", borderRightWidth: 1, fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>રોલ નં</Text>
    //                     <Text variant="titleMedium" style={{ width: 65, textAlign: "center", fontWeight: "900", paddingTop: 2, paddingBottom: 2 }}>ગુણ</Text>
    //                 </View>
    //                 <View style={{ flex: 1 }}>
    //                     {
    //                         russia.map(s => {
    //                             const re = s.marks[value.exam.year][value.exam.id][subject.id]
    //                             console.log(s.roll, subject.id, re?.status, re)
    //                             const fal = re?.status == "Fail" ? { borderBottomWidth: 1, color: "red" } : {}
    //                             const able = (re?.status == "Absent" || s.isLeft == true) ? { backgroundColor: "#DCDCDC" } : {}
    //                             return <View style={{ borderStyle: bs, borderColor: bc,  borderWidth: bw, flexDirection: "row", alignSelf: "stretch", borderStyle: bs, borderColor: bc,  borderWidth: bw, height: 33 }}>
    //                                 <Text variant="titleMedium" style={{ backgroundColor: "#DCDCDC", width: 65, textAlign: "center", borderRightWidth: 1, paddingTop: 2, paddingBottom: 2 }}>{s.roll}</Text>
    //                                 <View style={{ paddingBottom: 4, alignItems: "center", justifyContent: "center", width: 65, ...able }}>
    //                                     <Text variant="titleMedium" style={{ textAlign: "center", paddingTop: 2, paddingBottom: 2, ...fal }}>{s.left == true ? "Left" : re?.theory}</Text>
    //                                 </View>
    //                             </View>
    //                         })
    //                     }
    //                 </View>
    //             </View>
    //         }
    //     </View >
    // }


    const ref = useRef()
    // const Row1 = ({ roll, gr, name, title, left }) => {
    //     const tt = title ? {} : { fontSize: 10 }
    //     return <><div style={{ textAlign: "center", display: "flex", width: 30, maxWidth: 30, borderStyle: bs, borderColor: bc,  borderWidth: bw, borderStyle: "dashed" }}>{roll}</div>
    //         <div style={{ width: 40, maxWidth: 40, borderStyle: bs, borderColor: bc,  borderWidth: bw, borderStyle: bs, borderColor: bc,  borderWidth: bw, textAlign: "center" }}>{gr}</div>
    //         <div style={{ width: 150, maxWidth: 150, borderStyle: bs, borderColor: bc,  borderWidth: bw, borderStyle: bs, borderColor: bc,  borderWidth: bw, ...tt, textDecorationLine: left == true ? 'line-through' : "" }}>{name}</div></>
    // }


    // const Row3 = ({ v1, v2, v3 }) => {
    //     return <><div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 60, borderStyle: bs, borderColor: bc,  borderWidth: bw, borderStyle: bs, borderColor: bc,  borderWidth: bw, textAlign: "center", fontWeight: "900" }}>{v1}</div>
    //         <div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 55, borderStyle: bs, borderColor: bc,  borderWidth: bw, borderStyle: bs, borderColor: bc,  borderWidth: bw, textAlign: "center", fontWeight: "900" }}>{v2}</div>
    //         <div variant={"titleMedium"} style={{ backgroundColor: "#DCDCDC", width: 55, borderStyle: bs, borderColor: bc,  borderWidth: bw, borderStyle: bs, borderColor: bc,  borderWidth: bw, textAlign: "center", fontWeight: "900" }}>{v3}</div></>
    // }
    const t = useTheme()
    function w(n: number) {
        return ((100 / ((data.filteredSubject.length * 2) + 20)) * n) + "%"
        //        return (4.80 * n) + "mm"
    }
    // if (s?.obj?.student?.roll == 5) {
    //     console.log(s.obj.student.name, data?.filteredSubject)
    // }


    // { console.log(s.obj.student.name, data.filteredSubject) }

    return <View>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start" }}>
            <IconButton icon={"arrow-left"} onPress={() => navigation.goBack()} />
            <ReactToPrint
                pageStyle={pageStyle}
                trigger={() => <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: t.colors.primary, color: t.colors.onPrimary, padding: 5, borderRadius: 5, paddingRight: 10 }}><MaterialIcons style={{ marginLeft: 5, marginRight: 5 }} name="print" color={t.colors.onPrimary} />Print</div>}
                content={() => ref.current}
            />
            {pref?.current.supervisor == true && <Button onPress={() => {
                try {
                    const workbook = XLSX.utils.book_new();

                    const headers = ["રોલ", "GR", "વિદ્યાર્થી નું નામ"]
                    value.subjects.map(s => headers.push(s.name))
                    headers.push("પરિણામ")
                    headers.push("મેળવેલ ગુણ")
                    headers.push("Rank")
                    const l2: [] = []
                    value?.sheet?.filter(e => e?.totalling !== true)?.map(s => {
                        const me = { "roll": s.student.roll, gr: s.gr, name: s.student.name + (s.student.left == true ? "(Left)" : "") }
                        value.subjects.map(e => {
                            const vv = s.subject.find(f => f?.subject_id == e.id)
                            if (vv) {
                                me[e.name] = vv?.theory == "Absent" ? "Ab" : vv?.theory
                            } else {
                                me[e.name] = ""
                            }
                        }
                        )
                        me.status = s.student.left == true ? "Left" : s.examObject.status
                        me.marks = s.examObject.subjectMarks
                        me.rank = s.examObject.rank ? s.examObject.rank : ""
                        l2.push(me)
                    })
                    const worksheet = XLSX.utils.json_to_sheet(l2)
                    console.log(l2)
                    XLSX.utils.book_append_sheet(workbook, worksheet, "SummarySheet");
                    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
                    XLSX.writeFile(workbook, "SummarySheet" + value.class.name + (value.class.div ? " " + value.class.div : "") + ".xlsx");
                } catch (e) {
                    // console.log("me the e", e)
                    alert("error")
                }
            }}>Export</Button>}
        </View>
        <View>
            <div ref={ref} style={{ display: "block", width: "100%", boxSizing: "border-box", paddingLeft: 50 }}>
                <style type="text/css" media="print">{"@page { size: A4, margin: 25mm 25mm 25mm 25mm}"}</style>
                {/* <style type="text/css" media="screen">{"@page { size: landscape, margin: 25mm 25mm 25mm 25mm}"}</style> */}
                {/* <style type="text/css" media="print">{"@page { size: portrait}"}</style> */}
                <div style={{ width: "100%", display: "flex", marginTop: 5 }}>
                    <Text variant="titleMedium" style={{ fontWeight: "bold", alignSelf: "stretch", flex: 1, textAlign: 'center' }}>વર્ષ દરમિયાન લેવાયેલ પરીક્ષાઓ તથા આંતરિક મૂલ્યાંકનનું પરિણામ પત્રક</Text>
                </div>
                <View style={{ flex: 1, flexDirection: "row", marginTop: 5 }}>
                    <View style={{ flex: 1, alignItems: "center", flexDirection: "row", justifyContent: "center" }}>
                        <Text style={{ textAlign: 'center' }}>Standard : </Text>
                        <Text style={{ fontWeight: "bold", textDecorationLine: "underline", textAlign: 'center' }}>{route.params?.seleClass?.name}</Text>
                    </View>
                    {route.params?.seleClass?.div && <View style={{ flex: 1, alignItems: "center", flexDirection: "row", justifyContent: "center" }}>

                        <Text style={{ textAlign: 'center' }}>Class : </Text>
                        <Text style={{ fontWeight: "bold", textDecorationLine: "underline", textAlign: 'center' }}>{route.params?.seleClass?.div}</Text>
                    </View>}
                    <View style={{ flex: 1, alignItems: "center", flexDirection: "row", justifyContent: "center" }}>
                        <Text style={{ textAlign: 'center' }}>Examination Year : </Text>
                        <Text style={{ fontWeight: "bold", textDecorationLine: "underline", textAlign: 'center' }}>{(route.params?.examyear) + "-" + (route.params?.examyear - 1999)}</Text>
                    </View>
                </View>
                <table style={{ maxWidth: "100%", marginLeft: 15, marginRight: 10 }}>
                    <thead style={{ padding: 0, margin: 0 }}>
                        <div style={{ width: "100%", display: "flex", pageBreakInside: "avoid", flexDirection: "row", marginTop: 30, height: 130, maxHeight: 130 }}>
                            <View style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, alignItems: "center", justifyContent: "center" }}><Text>રોલ</Text></View>
                            <View style={{ flex: 10, borderStyle: bs, borderColor: bc, borderWidth: bw, alignItems: "center", justifyContent: "center" }}><Text>વિદ્યાર્થી નું નામ તથા વિગતો</Text></View>
                            <View style={{ flex: 5, borderStyle: bs, borderColor: bc, borderWidth: bw, alignItems: "center", justifyContent: "center" }}><Text>ગુણવિવરણ</Text></View>
                            <View style={{ flex: 2, borderStyle: bs, borderColor: bc, borderWidth: bw, alignItems: "center", justifyContent: "center" }}><Text>ગુણાંક</Text></View>
                            <View style={{ flex: data.filteredSubject.length * 2, flexDirection: "row" }}>
                                {
                                    data.filteredSubject.filter(e => e.grade !== true).map(e => {
                                        return <View style={{ flexDirection: "column", justifyContent: "center", flex: 2, borderStyle: bs, borderColor: bc, borderWidth: bw, alignItems: "center" }}>
                                            <Text style={{
                                                fontSize: e.name?.length > 9 ? 11 : undefined,
                                                transform: [{ rotate: '-90deg' }]
                                            }}>{e.name}</Text></View>
                                    })
                                }
                            </View>
                            <View style={{ minWidth: w(2), borderStyle: bs, borderColor: bc, borderWidth: bw, alignItems: "center", justifyContent: "center" }}><Text>કુલ</Text></View>
                        </div>
                    </thead>
                    <tbody style={{ padding: 0, margin: 0 }}>
                        {data.students.map(s => {
                            return <div style={{ width: "100%", display: "flex", pageBreakInside: "avoid", flexDirection: "column", marginTop: 5 }}>
                                <div style={{ width: "100%", display: "flex", pageBreakInside: "avoid", flexDirection: "row" }}>
                                    <div style={{ minWidth: w(1), borderLeftStyle: bs, borderLeftWidth: bw, borderTopStyle: bs, borderTopWidth: bw, borderTopColor: bc, borderRightStyle: bs, borderRightWidth: bw, borderRightColor: bc, textAlign: "center" }}>{s.roll}</div>
                                    <div style={{ display: "flex", flexDirection: "column", minWidth: w(10), borderTopStyle: bs, borderTopWidth: bw, borderTopColor: bc, borderRightStyle: bs, borderRightWidth: bw, borderRightColor: bc, textAlign: "center", alignItems: "center", borderBottomStyle: "none" }}>

                                        <View style={{ flexDirection: "row" }}>
                                            <div>G.R. No :</div>
                                            <Text style={{}}>{s.gr}</Text>
                                        </View>
                                        <Text style={{ paddingTop: 10, textAlign: "center" }} variant="titleLarge">{s.obj?.student?.name}</Text>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                            <Text style={{ textAlign: "center", flex: 1, backgroundColor: "gray", color: "white" }} variant="titleMedium">{s.obj?.status == "Pass" ? (s.kgiven + s.sgiven) > 0 ? "Promoted Pass" : "Pass" : s.obj?.status}</Text>
                                            <Text style={{ borderTopWidth: 1, borderBottomWidth: 1, flex: 1, textAlign: "center" }} variant="titleMedium">{s.obj?.pc} %</Text>
                                        </div>
                                        <Text style={{ flex: 1, textAlign: "center", textDecorationColor: "underline" }} variant="titleMedium">{s.obj?.rank ? "Rank " + s.obj?.rank : ""}</Text>
                                        <Text style={{ flex: 1, textAlign: "center", textDecorationColor: "underline" }} variant="titleMedium">{s.uid ? "UID :" + s.uid : ""}</Text>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
                                                <Text style={{ flex: 1 }} variant="titleMedium">{s.totD}</Text>
                                                <Text style={{ flex: 1, borderTopWidth: 1 }} variant="titleMedium">કુલ દિવસ</Text>
                                            </div>
                                            <Text style={{ flex: 1 }} variant="titleSmall"> માંથી </Text>

                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', }}>
                                                <Text style={{ flex: 1 }} variant="titleMedium">{s?.preD}</Text>
                                                <Text style={{ flex: 1, borderTopWidth: 1 }} variant="titleMedium">હાજર દિવસ</Text>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", minWidth: w(5), flexDirection: "column" }}>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1 }}><Text>પ્રથમ કસોટી</Text></div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1 }}><Text>દ્વિતીય કસોટી</Text></div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1 }}><Text>વાર્ષિક પરીક્ષા</Text></div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, fontSize: 11 }}><Text>આંતરિક મૂલ્યાંકન</Text></div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1 }}><Text>કુલગુણ</Text></div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, backgroundColor: "gray", color: "white" }}><Text>કુલગુણના ટકા</Text></div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1 }}><Text>સિદ્ધિગુણ</Text></div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1 }}><Text>કૃપાગુણ</Text></div>
                                    </div>
                                    <div style={{ display: "flex", minWidth: w(2), flexDirection: "column" }}>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>50</div> */}
                                            <div style={{ flex: 1 }}><Text>50</Text></div>
                                            {/* <div style={{ flex: 1 }}> </div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>50</div> */}
                                            <div style={{ flex: 1 }}><Text>50</Text></div>
                                            {/* <div style={{ flex: 1 }}> </div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>80</div> */}
                                            <div style={{ flex: 1 }}><Text>80</Text></div>
                                            {/* <div style={{ flex: 1 }}>40</div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>20</div> */}
                                            <div style={{ flex: 1 }}><Text>20</Text></div>
                                            {/* <div style={{ flex: 1 }}>20</div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            <div style={{ flex: 1 }}><Text>200</Text></div>
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            <div style={{ flex: 1, backgroundColor: "gray", color: "white" }}><Text>100%</Text></div>
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div style={{ flex: 1, alignItems: "center" }}><Text>15</Text></div>
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div style={{ flex: 1, alignItems: "center" }}><Text>10</Text></div>
                                        </div>
                                    </div>
                                    <div style={{ minWidth: w(data.filteredSubject.length * 2), maxWidth: w(data.filteredSubject.length * 2), display: "flex", width: "100%", flexDirection: "row" }}>
                                        {
                                            data.filteredSubject.filter(f => f.grade !== true).map(ds => {
                                                const e = s.subjects.find(f => f.id == ds.id)
                                                const tt = (e?.internal ? e?.internal : 0) + (e?.e1 ? e?.e1 : 0) + (e?.e2 > 0 ? e?.e2 : 0) + (e?.final > 0 ? e?.final : 0)
                                                // console.log("fflt", e)
                                                return <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                                                    <div style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, textAlign: "center" }}> <Text>{e?.grad ? "" : e?.e1A ? "Ab" : e?.e1}</Text></div>
                                                    <div style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, textAlign: "center" }}><Text> {e?.grad ? "" : e?.e2A ? "Ab" : e?.e2}</Text></div>
                                                    <div style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, textAlign: "center" }}><Text> {e?.finA ? "Ab" : e?.final}</Text></div>
                                                    <div style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, textAlign: "center" }}><Text> {e?.intA ? "Ab" : e?.internal}</Text></div>
                                                    <div style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, textAlign: "center" }}><Text> {tt ? tt : ""}</Text></div>
                                                    <div style={{ flex: 1, textAlign: "center", backgroundColor: "gray",  }}><Text style={{color: "white"}}>{e?.selfMarks}</Text></div>
                                                    <div style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, textAlign: "center" }}><Text> {e?.siddhi}</Text></div>
                                                    <div style={{ flex: 1, borderStyle: bs, borderColor: bc, borderWidth: bw, textAlign: "center" }}><Text> {e?.kripa}</Text></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div style={{ display: "flex", minWidth: w(2), flexDirection: "column" }}>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>50</div> */}
                                            <div style={{ flex: 1, textAlign: "center" }}><Text>{s?.e1}</Text></div>
                                            {/* <div style={{ flex: 1 }}> </div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>50</div> */}
                                            <div style={{ flex: 1, textAlign: "center" }}><Text>{s?.e2}</Text></div>
                                            {/* <div style={{ flex: 1 }}> </div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>80</div> */}
                                            <div style={{ flex: 1, textAlign: "center" }}><Text>{s?.final}</Text></div>
                                            {/* <div style={{ flex: 1 }}>40</div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ flex: 1 }}>20</div> */}
                                            <div style={{ flex: 1, textAlign: "center" }}><Text>{s?.internal}</Text></div>
                                            {/* <div style={{ flex: 1 }}>20</div> */}
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            <div style={{ flex: 1, textAlign: "center" }}><Text>{s.total}</Text></div>
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row" }}>
                                            <div style={{ flex: 1, borderRightWidth: 1, backgroundColor: "gray", color: "white", textAlign: "center" }}><Text>{s.selfMarks}</Text></div>
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div style={{ flex: 1, alignItems: "center", textAlign: "center" }}><Text>{s.obj?.status == "Fail" ? "" : s.sgiven}</Text></div>
                                        </div>
                                        <div style={{ borderStyle: bs, borderColor: bc, borderWidth: bw, flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div style={{ flex: 1, alignItems: "center", textAlign: "center" }}><Text>{s.obj?.status == "Fail" ? "" : s.kgiven}</Text></div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderColor: bc, borderWidth: bw, borderStyle: bs, borderTopWidth: 0, display: "flex", flexDirection: "row" }}>
                                    <div style={{ minWidth: w(11), display: "flex" }}>
                                        <Text style={{ flex: 1, borderRightWidth: 1, borderRightColor: bc, borderRightStyle: bs }}> </Text>
                                        <Text style={{ flex: 10, }}> </Text>
                                    </div>
                                    <div style={{ borderLeftWidth: 1, borderLeftColor: bc, borderLeftStyle: bs, flex: 1, display: "flex", flexDirection: "column" }}>
                                        <Text style={{ flex: 1, textAlign: "center", textDecorationLine: "underline", fontWeight: "bold" }}>વૈકલ્પિક વિષયોનું મૂલ્યાંકન</Text>
                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                            <div style={{ flex: 1, borderColor: bc, borderWidth: bw, borderStyle: bs, alignItems: "center", justifyContent: "center", display: "flex" }}><span>
                                                <Text>{"વિષય"}</Text>
                                            </span></div>

                                            <div style={{ flex: 1, borderColor: bc, borderWidth: bw, borderStyle: bs, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <div style={{ flex: 1, textAlign: "center", borderBottomWidth: bw, borderBottomStyle: bs, borderColor: bc }}><Text>{"સૈદ્ધાંતિક ગુણ"}</Text></div>
                                                <div style={{ flex: 1, textAlign: "center" }}><Text>{"40"}</Text></div>
                                            </div>
                                            <div style={{ flex: 1, borderColor: bc, borderWidth: bw, borderStyle: bs, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <div style={{ flex: 1, textAlign: "center", borderBottomWidth: bw, borderBottomStyle: bs, borderColor: bc }}><Text>{"પ્રાયોગિક ગુણ"}</Text></div>
                                                <div style={{ flex: 1, textAlign: "center" }}><Text>{"40"}</Text></div>
                                            </div>
                                            <div style={{ flex: 1, borderColor: bc, borderWidth: bw, borderStyle: bs, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <div style={{ flex: 1, textAlign: "center", borderBottomWidth: bw, borderBottomStyle: bs, borderColor: bc }}><Text>{"આંતરિક મૂલ્યાંકન"}</Text></div>
                                                <div style={{ flex: 1, textAlign: "center" }}><Text>{"20"}</Text></div>
                                            </div>
                                            <div style={{ flex: 1, borderColor: bc, borderWidth: bw, borderStyle: bs, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <span><Text>{"ગ્રેડ"}</Text></span>
                                            </div>
                                        </div>
                                        {s.subjects.filter(f => f.grad == true).map(f => {
                                            // console.log(f)
                                            return <div style={{ flexDirection: "row", display: "flex", }}>
                                                <div style={{ flex: 1, borderBottomColor: bc, borderBottomWidth: bw, borderRightWidth: bw, borderRightColor: bc, borderBottomStyle: bs, borderRightStyle: bs, textAlign: "center" }}><Text>{f?.name}</Text></div>
                                                <div style={{ flex: 1, borderBottomColor: bc, borderBottomWidth: bw, borderRightWidth: bw, borderRightColor: bc, borderBottomStyle: bs, borderRightStyle: bs, textAlign: "center" }}><Text>{f?.ftheory}</Text></div>
                                                <div style={{ flex: 1, borderBottomColor: bc, borderBottomWidth: bw, borderRightWidth: bw, borderRightColor: bc, borderBottomStyle: bs, borderRightStyle: bs, textAlign: "center" }}><Text>{f?.fpractical}</Text></div>
                                                <div style={{ flex: 1, borderBottomColor: bc, borderBottomWidth: bw, borderRightWidth: bw, borderRightColor: bc, borderBottomStyle: bs, borderRightStyle: bs, textAlign: "center" }}><Text>{f.internal}</Text></div>
                                                <div style={{ flex: 1, borderBottomColor: bc, borderBottomWidth: bw, borderBottomStyle: bs, textAlign: "center" }}>{f?.grade}</div>
                                            </div>
                                        })}
                                    </div>
                                </div>

                            </div>
                        }
                        )
                        }
                    </tbody >
                </table >

            </div >
        </View >
    </View >


}