import { useSelector } from 'react-redux'
import { FlashList } from "@shopify/flash-list";

import { ActivityIndicator, SafeAreaView, View, StyleSheet, ScrollView, SectionList, Dimensions, Touchable, TouchableOpacity, Linking, Share } from 'react-native';
import { Text, IconButton, Divider, FAB, shadow, TextInput, Button, Chip } from 'react-native-paper';

import * as React from 'react';
import { useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import Global from '../constants/global';
import Modal from 'react-native-modal';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import ClassRow from '../components/ClassRow';
import MapView, { PROVIDER_GOOGLE } from 'react-native-maps';
import { Icon } from 'react-native-paper/lib/typescript/components/Avatar/Avatar';


let baselist: [] = []
export default function Arrangements({ navigation, route }) {
    const classList = useSelector((state) => state.list.Classes)
    const [sortedClassList, setSortedClassList] = React.useState([])
    const subjectList = useSelector((state) => state.list.Subjects)
    const teachers = useSelector(React.useMemo(Global.getUserList, []))
    const [filteredSubjects, setFIlteredSubjects] = React.useState([])
    const [teacherList, setTeacherList] = React.useState([])
    const [classes, setClass] = React.useState(undefined)
    const [focus, setFocus] = React.useState(undefined)

    const [focusSubject, setFocusSubject] = React.useState(undefined)

    React.useEffect(() => {
        if (focus) {
            const s: [] = subjectList.filter(e => e.classid == focus.id)
            setFIlteredSubjects(s)
        }
    }, [focus, subjectList])

    React.useEffect(() => {
        if (teachers) {
            const s: [] = teachers.filter(e => (e.retired !== true) && (e.type == Global.PRINCIPAL || e.type == Global.TEACHER || e.type == Global.VISITING_TEACHER))
            setTeacherList(s)
        }
    }, [teachers])

    React.useEffect(() => {
        if (classList) {
            const s: [] = []
            classList.map(p => s.push({ ...p }))
            const nnewP = s.sort((a, b) => a?.seq < b?.seq ? -1 : 0)
            setSortedClassList(nnewP)
        }
    }, [classList])
    const { colors } = useTheme();
    return (
        <ScrollView style={{ flex: 1 }}>

            <View style={{ backgroundColor: colors.background, flex: 1, justifyContent: "center" }}>
                <IconButton size={60} icon="connection" style={{alignSelf:"center"}} />
                <IconButton style={{ position: "absolute", right: 0, top: 0 }} icon="share" onPress={() => {
                    const str = "*" + route.params.stvm.nameLocal + "*" + "\nAddress:" + route.params.stvm.address
                        + "\n" + route.params.stvm.city + " - " + route.params.stvm.pin
                        + "\n" + "Phone:" + route.params.stvm.phone
                        + "\n" + "website:" + route.params.stvm.web
                        + "\n\nLocation: " + route.params.stvm.locationStr
                    Share.share({
                        message: str
                    }, { dialogTitle: "STVM Contact details" });

                }} />
                <View>
                    <TouchableOpacity onPress={() => Linking.openURL(`tel:${route.params.stvm.phone}`)}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <IconButton icon="phone" />
                            <Text style={{ width: 100 }}>Phone</Text>
                            <Text style={{}}>{route.params.stvm.phone} </Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() =>
                        Share.share({
                            message: route.params.stvm.nameLocal + ", " + route.params.stvm.address + ", " +
                                route.params.stvm.city + " - " + route.params.stvm.pin
                        }, { dialogTitle: "STVM Address" })
                    }>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <IconButton icon="home" />
                            <Text style={{ width: 100 }}>સરનામું</Text>
                            <View>
                                <Text style={{}}>{route.params.stvm.nameLocal}</Text>
                                <Text style={{}}>{route.params.stvm.address}</Text>
                                <Text style={{}}>{route.params.stvm.city + " - " + route.params.stvm.pin}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => Linking.openURL(`email:${route.params.stvm.phone}`)}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <IconButton icon="email" />
                            <Text style={{ width: 100 }}>Email</Text>
                            <Text style={{}}>{route.params.stvm.email}</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton icon="handshake-outline" />
                        <Text style={{ width: 100 }}>મુલાકાત નો સમય </Text>
                        <Text style={{}}>- {route.params.stvm.timing}</Text>
                    </View>

                    <View>
                        {/* <MapView
                            style={{
                                width: Dimensions.get('window').width,
                                height: 300,
                            }}
                            provider={PROVIDER_GOOGLE}

                            region={{
                                latitude: route.params.stvm.lati,
                                longitude: route.params.stvm.longi,
                                latitudeDelta: 0.0005,
                                longitudeDelta: 0.0005,
                            }}
                        /> */}
                        <IconButton size={40} mode="contained-tonal" style={{ position: "absolute", bottom: 0, right: 0 }} icon="directions" onPress={() => Linking.openURL(route.params.stvm.locationStr)} />
                    </View>
                </View>
            </View >
        </ScrollView >
    );


}

