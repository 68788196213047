import React, { useEffect } from "react"
import ReactNativeModal from "react-native-modal";
import { Text, Button, IconButton } from 'react-native-paper';
import { Updates } from 'expo';

import { Dimensions, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { View } from './Themed';
import Global from "../constants/global";

export default function NeedUpdate() {
    const stvm = useSelector(state => state.list.stvm[0])
    const [update, needUpdate] = React.useState(false)
    useEffect(() => {
        if (stvm?.Version) {
            if (stvm.Version > Global.VERSION) {
                needUpdate(true)
            }

        }
    }, [stvm])

    return <ReactNativeModal isVisible={update} onBackdropPress={() => needUpdate(false)} style={{ margin: 0, position: "absolute", bottom: 0, width: Dimensions.get("window").width }} animationIn="fadeIn" animationOut={"fadeOut"}>
        <View style={{ backgroundColor: "rgba(0,0,0,0.7)", height: Dimensions.get("window").height, width: Dimensions.get("window").width, alignItems: "center", justifyContent: "center" }}>
            <Text style={{ marginBottom: 20, fontWeight: "bold", fontSize: 16, color: "#90EE90" }}>Your App is running old version</Text>
            <View style={{ backgroundColor: "transparent", flexDirection: "row", padding: 15 }}>
                <Text style={{ fontWeight: "bold", fontSize: 16, color: "orange" }}>Version {stvm?.Version} is Available</Text>
            </View>
            <View style={{ backgroundColor: "transparent", flexDirection: "row", padding: 15 }}>
                {!stvm?.force && <Button mode="text" onPress={() => { needUpdate(false) }} >Later  </Button>}
                <Button mode="contained" onPress={() => {
                    if (Platform.OS == "web") {
                        window.location.reload()
                    } else {
                        //Updates.reload()
                    }
                }} >Refresh now</Button>
            </View>

            <IconButton iconColor='white' style={{ position: "absolute", top: 0, right: Global.isMobile() ? 0 : 30 }} icon="window-close" onPress={() => needUpdate(false)} />
        </View>
    </ReactNativeModal>



}