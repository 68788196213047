import { View } from 'react-native';
import { Text } from 'react-native-paper';
import * as React from 'react';
import Global from '../constants/global';
import DAvatar from './DAvatar';
import dayjs from 'dayjs';


const Item = ({ item }: { item }) => {
    const hr = dayjs.unix(item.date).diff(dayjs(), "hours")
    const hrs = hr == 0 ? "few memonts ago" : hr > 24 ? dayjs.unix(item.date).diff(dayjs(), "days") + " days ago" : hr + " hours ago"
    return <View style={{ alignSelf: "stretch", flexDirection: "row" }} >
        <DAvatar title={item.name} source={item.dp} type={Global.ROW_DP} />
        <View style={{ flex: 1, marginLeft: 15 }}>
            <Text>{item.message}</Text>
            <View style={{ flexDirection: "row" }}>
                <Text style={{ fontSize: 9, fontStyle: "italic" }}>{item.person_id}</Text>
                <Text style={{ textAlign: "right", marginLeft: 10, fontSize: 9, fontStyle: "italic" }}>{hrs} </Text>
            </View>
        </View>
    </View >
}
const MItem = React.memo(Item)
export default MItem
